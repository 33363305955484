import _ from 'lodash';

export function normalize( conf ) {
  if ( _.isArray( conf ) ) {
    conf = _.compact( _.map( conf, normalize ) );
    return conf.length ? conf : undefined;
  }
  if ( ! _.isPlainObject( conf ) ) return;
  if ( ! _.isString( conf.keys ) ) {
    throw new TypeError( `Shortcut keys must be string` );
  }
  conf.keys = conf.keys.split( /\s+/u ).join( ' ' );
  const handlers = _.pick( conf, 'href', 'handler', 'modal' );
  const size = _.size( handlers );
  if ( size !== 1 ) {
    const found = `(found ${size} ${_.keys( handlers ).join( ', ' )})`;
    throw new Error( 'Shortcut must have one handler ' + found );
  }
  return conf;
}
