import _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as icons from './icon-registry.js';

library.add( ..._.values( icons ) );

// Blank icon, used by the Icon component for when you do something
// like <Icon label="foo" />.  The blank icon is intended to make the
// alignment still work correctly even though you don't have an icon
// specified.
library.add( {
  prefix    : 'ssp',
  iconName  : 'blank',
  icon      : [ 16, 16, [], null, '' ],
} );
