import { PropTypes } from '~/forms/utils';

import type { InferProps } from 'prop-types';

export function Message( props: InferProps<typeof Message.propTypes> ) {
  const { message } = props;
  if ( ! message ) return null;
  const msg = message.replace( /^ERROR:/u, '' ).trim();
  const err = message !== msg;
  const classes = [ 'alert', 'alert-' + ( err ? 'danger' : 'warning' ) ];
  return (
    <div className={classes.join( ' ' )} css={{ position: 'relative' }}>
      {msg}
    </div>
  );
}
Message.propTypes = {
  message       : PropTypes.string,
};
