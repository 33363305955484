import { PropTypes } from '~/forms/utils';

export { PropTypes };

export const AddonPropShape = PropTypes.shape( {
  type      : PropTypes.string,
  icon      : PropTypes.string,
  label     : PropTypes.string,
  title     : PropTypes.string,
  position  : PropTypes.oneOf( [ 'prepend', 'append' ] ),
  className : PropTypes.string,
  getProps  : PropTypes.func.isRequired,
  onClick   : PropTypes.func.isRequired,
  children  : PropTypes.node,
} );

export const AddonPropTypes = {
  field : PropTypes.fieldConfig.isRequired,
  addon : AddonPropShape.isRequired,
};
