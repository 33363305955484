import { PropTypes } from '~/utils';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useEnsureRoute } from '../hooks';
import { theme } from '~/layout/theme';

const StepWrapper = styled( 'div' )( () => css`
  strong {
    color: ${theme.colors.pop};
  }
` );

export function Content( props ) {
  const {
    children,
    ensureRoute, // for globally registered tours that run on specific routes
  } = props;

  useEnsureRoute( ensureRoute );

  return (
    <StepWrapper className="h5">
      {children}
    </StepWrapper>
  );
}
Content.propTypes = {
  children : PropTypes.children,
  ensureRoute : PropTypes.string,
};
