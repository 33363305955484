import { Input } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';

export function Textarea( { field } ) {
  const input = useInput( field, {
    type        : 'textarea',
    configProps : { rows : 3, cols : 0 },
  } );
  return <Input {...input} />;
}
Textarea.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
