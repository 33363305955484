import { DB } from '@ssp/database';
import { SummaryPage, RouterTabs } from '~/pages/builder';
import { CollectionView } from '~/views';
import { PropTypes } from '~/utils';
import { useResource, useLoading } from '~/hooks';

import { ManageMembers } from './members';

function handleBuilder( builder ) {
  const { resource } = builder;

  builder.group( 'main' ).add(
    {
      path      : '',
      title     : 'Home',
      icon      : resource.schema.icon,
      component : SummaryPage,
    },
  );

  const actions = builder.group( 'actions' );
  actions.add( 'edit', [ 'delete', { props : resource } ], 'action' );

  if ( resource.schema.hasBehavior( 'hasMembers' ) ) {
    actions.add(
      {
        path      : 'members',
        title     : 'Members',
        icon      : 'far:users',
        component : ManageMembers,
        exact     : false,
        count     : 'SSP.User',
      },
      {
        path      : 'teamlinks',
        title     : 'Team Links',
        icon      : 'far:link',
        component : CollectionView,
        props     : () => ( {
          resultset : DB.SSP.TeamLink.resultset( {
            minlength : false,
          } ).forResource( resource ),
        } ),
        count     : 'SSP.TeamLink',
      },
    );
  }

  actions.add( {
    path      : 'jobs',
    title     : 'Jobs',
    icon      : 'far:history',
    component : CollectionView,
    props     : () => ( {
      resultset : DB.SYSTEM.Job.find( { 'resource.id' : resource._id }, {
        minlength : false,
        limit     : 100,
      } ),
    } ),
    count     : 'SYSTEM.Job',
  } );
}
export function ResourceView( props ) {
  const resource = useResource( props.resource, { label : 'ResourceView' } );
  const owner = useResource( props.owner, { label : 'ResourceView+owner' } );
  const loading = useLoading( 'Resource', resource || null, owner || null );
  if ( loading ) return loading;
  return (
    <RouterTabs builder={handleBuilder} resource={resource} owner={owner} />
  );
}
ResourceView.propTypes = {
  owner     : PropTypes.oneOfType( [ PropTypes.project, PropTypes.user ] ),
  resource  : PropTypes.resource,
};
