import _ from 'lodash';
import { useMemo } from 'react';
import { useResultSetContext, useViewContext } from '~/context';
import { useToggle, useFace } from '~/hooks';
import { Icon } from '~/icons';
import {
  Button, ButtonGroup, ButtonDropdown, DropdownToggle,
  DropdownMenu, DropdownItem,
} from 'reactstrap';

import { configs } from '../subviews';

export function ViewSwitch() {
  const { schema } = useResultSetContext();
  const context = useViewContext( useMemo( () => ( {
    face      : { type : 'string' as const, default : 'summary' },
    view      : { type : 'string' as const, default : 'card' },
  } ), [] ) );

  const face = useFace( schema );
  const { views, faces } = useMemo( () => {
    if ( ! schema ) return { views : [], faces : [] };
    // TO-DO: uncomment the panel view button tests in
    // app/ssp-webapp-client/test/views/collection/controls/ViewSwitch.js
    const allViews = schema.getViews().filter( v => v !== 'panel' );
    return {
      views : configs.filter( v => allViews.includes( v.name ) ).map( v => ( {
        ...v,
        active  : v.name === face?.type,
        onClick : () => context.update( { view : v.name } ),
      } ) ),
      faces : context.view ?  schema.getFaces( context.view ).map( f => ( {
        name    : f.name,
        label   : f.label || _.startCase( f.name ),
        active  : face && f.name === face.name,
        onClick : () => context.update( { face : f.name } ),
      } ) ) : [],
    };
  }, [ context, schema, face ] );
  const [ isOpen, toggleOpen ] = useToggle( false );

  const res = [];
  if ( views.length > 1 ) {
    res.push(
      <ButtonGroup key="views" aria-label="Select a View Type">
        {views.map( ( { active, onClick, title, icon, name } ) => {
          return (
            <Button key={name} {...{ active, onClick }}>
              <Icon {...{ icon, title }} />
            </Button>
          );
        } )}
      </ButtonGroup>,
    );
  }
  if ( faces.length > 1 ) {
    const active = _.find( faces, { active : true } );
    res.push(
      <ButtonGroup size="sm" key="faces">
        <ButtonDropdown isOpen={isOpen} toggle={toggleOpen}>
          <DropdownToggle caret>{active.label}</DropdownToggle>
          <DropdownMenu>
            { faces.map( ( { name, label, ...f } ) => {
              return <DropdownItem key={name} {...f}>{label}</DropdownItem>;
            } )}
          </DropdownMenu>
        </ButtonDropdown>
      </ButtonGroup>,
    );
  }
  return res;
}
