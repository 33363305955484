import _ from 'lodash';
import { Action, _performAction } from '@ssp/database';
import { useCallback } from 'react';
import { toast, PropTypes } from '~/utils';
import { useRouter } from '~/hooks';

import type { TResource, ActionHandlerType } from '@ssp/database';

export function useActionHandler( props ) {
  const router = useRouter();

  return useCallback( ( arg ) => {
    const { resource, action } = props;

    const ev = ( typeof arg.preventDefault === 'function' ) ? arg : undefined;
    const data = ev ? undefined : arg;
    if ( ev ) ev.preventDefault();

    if ( action.disabled ) {
      toast.error( `${action.label} is not available: ${action.disabled}` );
      return toast.error( msg );
    }

    if ( action.needs_view ) {
      return router.go( resource.route( 'action', action.name ) );
    } else {
      const res = finalizeAction( { action, router, resource, data } );
      if ( _.isError( res ) ) {
        log.error( 'Error from finalizeAction:', res );
        toast.error( res );
      } else {
        return res;
      }
    }
  }, [ props, router ] );
}
useActionHandler.propTypes = {
  resource  : PropTypes.resource.isRequired,
  action    : PropTypes.instanceOf( Action ).isRequired,
};

type ActionContext = {
  action: Action;
  resource: TResource<any>;
  router: $TSFixMe;
  data?: Record<string, unknown>;
};

function result<T=unknown, A extends any[] = unknown[]>(
  value: T | (
    ( this: TResource<any>, cx: ActionContext, ...a: A ) => T
  ),
  context: ActionContext,
  ...args: A
): T {
  if ( typeof value === 'function' ) {
    return value.call( context.resource, context, ...args );
  } else {
    return value;
  }
}

const handlers: {
  [K in ActionHandlerType]: ( context: ActionContext ) => any;
} = {
  href( context ) {
    const { action } = context;
    const href = result( action.href, context );
    document.location = href;
  },
  route( context ) {
    const { action, resource, router } = context;
    let route = result( action.route, context );
    if ( ! route.startsWith( '/' ) ) route = resource.route( route );
    return router.go( route );
  },
  modal( context ) {
    const { action, resource } = context;
    const modal = result( action.modal, context );
    SSP.modal.open( { modal, resource } );
  },
  job_name( context ) {
    const { action, resource } = context;
    const name = result( action.job_name, context );
    const data = getData( context );
    return resource.job( name, data ).then( job => {
      toast.info( `Job "${name}" enqueued as ${job._id}`, {
        resource : job,
      } );
    } ).catch( error => {
      log.error( `Job "${name}" failed:`, error );
      toast.error( `Job "${name}" failed: ${error}`, {
        resource,
        error,
      } );
    } );
  },
  method( context ) {
    const { action, resource } = context;
    const name = result( action.method, context );
    const data = getData( context );
    return resource[ name ]( data );
  },
  handler( context ) {
    const { action, resource } = context;
    const { name } = action;
    const data = getData( context );
    return _performAction( resource, name, data ).then( res => {
      if ( ! res.ok && res.error ) {
        log.error( `Action "${name}" failed:`, res.error );
        toast.error( `Action "${name}" failed: ${res.error.message}` );
      } else {
        toast.info( `Action "${name}" successful` );
      }
    } ).catch( err => {
      log.error( `Action "${name}" failed:`, err );
      toast.error( `Action "${name}" failed: ${err}` );
    } );
  },
};

function getData( context: ActionContext ) {
  return _.assign( {}, result( context.action.data, context ), context.data );
}

export function finalizeAction( opts ) {
  const type = opts.action.handlerType;
  const handler = handlers[ type ];
  if ( typeof handler !== 'function' ) {
    throw new TypeError( `Invalid handler type "${type}"` );
  }
  return handler( opts );
}
