import { PropTypes } from '~/utils';
import { HeaderBar } from './HeaderBar';
import { RouterTabs } from '~/pages/builder';
import { ResourceContextProvider } from '~/context';
import { useTitle } from '~/hooks';

export function Page( props ) {
  let {
    /* eslint-disable prefer-const */
    title, subtitle, icon, showHeader, children,
    resource, component,
    builder,
    addl_resources,
    /* eslint-enable prefer-const */
  } = props;
  if ( ! title ) {
    title = resource?.display_name || ( resource || component )?.schema.name;
  }
  if ( ! icon ) icon = ( resource || component )?.schema.icon;
  useTitle( title ? `SSP - ${title}` : 'Self Service Portal' );
  const header_props = {
    title, subtitle, icon, resource,
  };
  const body = (
    <main>
      {showHeader && <HeaderBar {...header_props}  />}
      {children}
      {builder && <RouterTabs builder={builder} />}
    </main>
  );
  if ( resource || component ) {
    return (
      <ResourceContextProvider {...{ resource, component, addl_resources }}>
        {body}
      </ResourceContextProvider>
    );
  }
  return body;
}
Page.propTypes = {
  title           : PropTypes.string,
  subtitle        : PropTypes.string,
  icon            : PropTypes.string,

  showHeader      : PropTypes.bool,
  children        : PropTypes.children,
  resource        : PropTypes.resource,
  component       : PropTypes.component,
  addl_resources  : PropTypes.arrayOf( PropTypes.resource ),
  builder         : PropTypes.func,
};
Page.defaultProps = {
  showHeader  : true,
};
