import _ from 'lodash';
import { useMemo } from 'react';
import { cx, PropTypes } from '~/utils';
import { Icon } from '~/icons';
import { useToggle } from '~/hooks';
import { Link } from '~/router';
import { Card, CardHeader, CardFooter, CardBody } from './cards';

export function Panel( props_in ) {
  const {
    title, icon, link, header, footer, decorations, status,
    collapsed : collapsed_in, collapsible,
    className, children, body,
    ...props
  } = props_in;
  const [ collapsed, toggleCollapsed ] = useToggle( collapsed_in );

  const Header = useMemo( () => {
    if ( _.isFunction( header ) ) return header();
    if ( header ) return header;
    if ( title && link ) {
      return <Link to={link}><Icon icon={icon} label={title} /></Link>;
    } else if ( title ) {
      return <Icon icon={icon} label={title} />;
    } else if ( link ) {
      return <Link to={link} />;
    }
    return null;
  }, [ icon, header, title, link ] );

  const Footer = useMemo( () => {
    if ( _.isFunction( footer ) ) return footer();
    if ( footer ) return footer;
    if ( status ) return <span>{status}</span>;
    return null;
  }, [ footer, status ] );

  const Body = useMemo( () => {
    if ( collapsed ) return null;
    if ( _.isFunction( children ) ) return children();
    return body ? <CardBody>{children}</CardBody> : children;
  }, [ children, collapsed, body ] );

  const Decorations = useMemo( () => {
    const decs = [];
    if ( collapsible ) {
      decs.push( <Icon
        className="header-chevron"
        key="decorations-collapsible"
        icon={ collapsed ? 'far:chevron-down' : 'far:chevron-up' }
        onClick={toggleCollapsed}
        css={{ float : 'right' }}
      /> );
    }
    if ( decorations ) decs.push( ...decorations );
    return decs;
  }, [ decorations, collapsed, collapsible, toggleCollapsed ] );

  const classes = cx( 'panel', className, 'mb-2' );
  return (
    <Card className={classes} {...props}>
      <CardHeader>
        {Header}
        {Decorations}
      </CardHeader>
      {Body}
      {Footer && <CardFooter>{Footer}</CardFooter>}
    </Card>
  );
}
Panel.propTypes = {
  title       : PropTypes.string.isRequired,
  link        : PropTypes.string,
  header      : PropTypes.oneOfType( [ PropTypes.node, PropTypes.func ] ),
  footer      : PropTypes.oneOfType( [ PropTypes.node, PropTypes.func ] ),
  className   : PropTypes.className,
  children    : PropTypes.node,
  collapsible : PropTypes.bool,
  decorations : PropTypes.arrayOf( PropTypes.node ),
  status      : PropTypes.string,
  body        : PropTypes.bool,
};
Panel.defaultProps = {
  collapsed   : false,
  collapsible : false,
};
