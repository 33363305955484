import { ResourceView } from '~/views';
import { ActionPanel } from '~/actions';
import { Panel } from '~/components';
import { useResourceContext } from '~/hooks';

export function SummaryPage() {
  const user = useResourceContext();
  return (
    <>
      <ActionPanel resource={user} />
      <Panel title="Summary" body>
        <ResourceView resource={user} face='summary' view='panel' />
      </Panel>
    </>
  );
}
