import _ from 'lodash';
import { DataItem } from '../DataItem';
import { PropTypes } from '~/utils';

export function Values( { items=[], delimiter } ) {
  items = _.filter( items, 'component' );

  if ( _.isEmpty( items ) ) return null;

  return _.map( items, ( item, idx ) => {
    const { component : Comp, value, ...props } = item;
    return [
      idx > 0 && delimiter,
      <Comp key={item.name} {...props} value={value} />,
    ];
  } );
}

Values.propTypes = {
  items     : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
  delimiter : PropTypes.string,
};
Values.defaultProps = {
  delimiter : ', ',
};
