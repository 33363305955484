import { Fragment, useState } from 'react';
import { Button } from 'reactstrap';
import { PropTypes } from '~/utils';
import { Modal } from './Modal';

export function ModalButton( { label, children, ...props } ) {
  const [ isOpen, setIsOpen ] = useState( false );
  const toggle = () => setIsOpen( prev => !prev );

  return (
    <Fragment>
      <Button onClick={ toggle }>{ label }</Button>
      <Modal isOpen={ isOpen } toggle={ toggle } { ...props }>
        { children }
      </Modal>
    </Fragment>
  );
}
ModalButton.propTypes = {
  label     : PropTypes.string.isRequired,
  children  : PropTypes.node.isRequired,
};
