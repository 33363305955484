import { useCallback, useMemo } from 'react';
import { useRouter } from '~/router';
import { useResultSetContext, useResourceContext } from '~/context';
import { useSubject } from '~/hooks';
import { Icon } from '~/icons';
import { Button, ButtonGroup } from 'reactstrap';
import { PropTypes } from '~/utils';

export function AddResourceButton( { help=true } ) {
  const { schema } = useResultSetContext();
  const resource = useResourceContext();
  const router = useRouter();
  const subject = useSubject();

  const { route, reason } = useMemo( () => {
    if ( ! schema ) return { reason : 'No schema' };
    const id = schema.id;
    if ( id === 'SSP.User' ) return { reason : 'SSP.User cannot be added' };
    if ( id === 'SSP.Project' ) {
      if ( ! subject ) return { reason : 'SSP.Project but no subject' };
      if ( ! subject.hasCapability( 'create-projects' ) ) {
        return { reason : 'Subject lacks create-projects capability' };
      }
      return { route : '/projects/create' };
    }
    if ( ! resource ) return { reason : 'No resource' };
    if ( resource.schema.is_user_resource ) {
      return { reason : `Can't add User Resources` };
    }

    const [ result, msg ] = resource.canBeManagedBy( subject ) || [];
    if ( ! result ) return { reason : msg };

    if ( ! schema.canAttachTo( resource ) && id !== 'SSP.TeamLink' ) {
      return { reason : `${resource.schema.id} cannot attach ${id}` };
    }
    return { route : schema.model.getCreateRoute( resource ) };
  }, [ resource, subject, schema ] );

  const handleClick = useCallback( () => {
    if ( reason ) log.error( `AddResourceButtonHandle:`, reason );
    if ( route ) router.go( route );
  }, [ route, router, reason ] );

  const markdown = useMemo( () => {
    return schema.getMarkdown( 'details' )
      || schema.service.getMarkdown( 'details' );
  }, [ schema ] );

  if ( reason || ! route ) return null;

  return (
    <ButtonGroup>
      <Button className="add-resource-button" onClick={handleClick}>
        <Icon icon='far:plus' label={`Add ${schema.name}`} />
      </Button>
      {help && markdown &&
        <Button className="add-resource-help"
          onClick={() => SSP.modal.open( { modal : 'markdown', markdown } )}
        >
          <Icon icon="fas:question" />
        </Button>
      }
    </ButtonGroup>
  );
}
AddResourceButton.propTypes = {
  help : PropTypes.bool,
};
