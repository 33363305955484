import _ from 'lodash';
import {
  InputGroupAddon, InputGroupButtonDropdown, DropdownMenu,
  DropdownItem, DropdownToggle,
} from 'reactstrap';
import { PropTypes } from '~/forms/utils';
import { useToggle } from '~/hooks';
import { getSchema } from '@ssp/database';

export function TypeSelector( { type, types, setType, field } ) {
  const [ isOpen, toggle ] = useToggle();

  const name = ( t ) => t && getSchema( t ).name;

  // If there is only one type, then just use it
  if ( type && ! types ) {
    return (
      <InputGroupAddon addonType="prepend">{name( type )}</InputGroupAddon>
    );
  }

  return (
    <InputGroupButtonDropdown addonType="prepend" {...{ isOpen, toggle }}>
      <DropdownToggle caret className="formbtn" field={field}>
        {type ? name( type ) : 'Select Resource Type...'}
      </DropdownToggle>
      <DropdownMenu field={field}>
        {_.map( types, t => (
          <DropdownItem key={t} onClick={() => setType( t )}>
            {name( t )}
          </DropdownItem>
        ) )}
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
}
TypeSelector.propTypes = {
  type    : PropTypes.string,
  types   : PropTypes.arrayOf( PropTypes.string ),
  setType : PropTypes.func,
  field   : PropTypes.fieldConfig.isRequired,
};
