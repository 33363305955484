import { createRegistry } from '@ssp/utils';
import { globals } from './globals';

export const registry = createRegistry( {
  name        :'shortcuts',
  description : 'Registry of active keyboard shortcuts',
  merge       : 'squish',
  unique      : 'keys',
} );
registry.update( 'globals', globals );
