import _ from 'lodash';
import { createContext, useContext } from 'react';
import { useOptions } from '~/hooks';
import { PropTypes } from './prop-types';

export const FormContext = createContext( null );

export function FormContextProvider( { children, ...context } ) {
  const value = useOptions( context, 'shallow' );
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}
FormContextProvider.propTypes = {
  children  : PropTypes.node.isRequired,
  config    : PropTypes.formConfig.isRequired,
};

export function useFormContext( prop, safe=false ) {
  const context = useContext( FormContext );
  if ( ! context ) throw new Error( `No Form Context` );
  if ( ! prop ) return context;
  if ( ! ( safe || _.has( context, prop ) ) ) {
    throw new Error( `Form Context does not have ${prop}` );
  }
  return _.get( context, prop );
}
export function useFormConfig() {
  return useFormContext( 'config' );
}
