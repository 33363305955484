import { useSubject, useMemo, useResourceContext } from '~/hooks';
import { getActionsFor } from '@ssp/database';

/**
 * @param {*} resource - a resource instance, model class, or resultset
 */
export function useActionList( resource, hidden=false ) {
  const subject = useSubject();
  const context = useResourceContext();

  return useMemo( () => {
    if ( ! ( resource && subject ) ) return null;
    return getActionsFor( { resource, context, subject, hidden } );
  }, [ context, resource, subject, hidden ] );
}
