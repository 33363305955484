import _ from 'lodash';
import { createContext, useEffect, useContext } from 'react';
import { PropTypes } from '~/utils';
import { Members } from './Members';
import {
  useWatchable, useViewContext, useConstant, useSubject, useResource,
} from '~/hooks';

const MembersContext = createContext<Members>( null );

export function MembersContextProvider( props ) {
  const resource = useResource( props.resource, {
    label : 'MembersContextProvider',
  } );
  const members = useConstant( () => new Members( resource ) );
  const subject = useSubject();

  useEffect(
    () => members.onSubjectUpdate( subject ),
    [ subject, members ],
  );
  useEffect(
    () => members.onResourceUpdate( resource ),
    [ resource, members ],
  );

  return (
    <MembersContext.Provider value={members}>
      {props.children}
    </MembersContext.Provider>
  );
}
MembersContextProvider.propTypes = {
  children : PropTypes.node.isRequired,
  resource : PropTypes.resource.isRequired,
};

export function useMembers(): Members {
  return useWatchable( useContext( MembersContext ) ) as Members;
}
export function useMembersViewContext() {
  return useViewContext( useConstant( {
    sort    : {
      type    : 'string' as const,
      default : 'name',
      transform( value, _prev ) {
        if ( ! _.isString( value ) ) return;
        // The leading + on sort is optional, so we omit it to keep the
        // URL cleaner since it will have to be url encoded
        if ( value.startsWith( '+' ) ) return value.slice( 1 );
        return value;
      },
      augment( value: string = 'name' ) {
        if ( value.startsWith( '+' ) ) value = value.slice( 1 );
        if ( value.startsWith( '-' ) ) {
          return {
            sortIndicator : '-',
            sortAscending : false,
            sortDirection : 'Descending',
            sortBackwards : 'Ascending',
            sortKey       : value.slice( 1 ),
            sortIcon      : 'far:sort-alpha-up',
          };
        } else {
          return {
            sortIndicator : '+',
            sortAscending : true,
            sortDirection : 'Ascending',
            sortBackwards : 'Descending',
            sortKey       : value,
            sortIcon      : 'far:sort-alpha-down',
          };
        }
      },
      methods : {
        updateSort( field ) {
          if ( ! field ) return;
          this( ( { sortKey, sortAscending } ) => {
            if ( sortKey === field ) {
              return { sort : ( sortAscending ? '-' : '' ) + field };
            } else {
              return { sort : field };
            }
          } );
        },
      },
    },
  } ) );
}
