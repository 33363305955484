import { Redirect } from '~/router';
import { useSettings, useLoading } from '~/hooks';

export default function HomeRoutes() {
  const settings = useSettings() || null;
  const loading = useLoading( 'User Settings', settings );
  if ( loading ) return loading;
  const start = settings.getStartPage();
  return <Redirect to={start} />;
}
