import _ from 'lodash';
import 'react-circular-progressbar/dist/styles.css';
import {
  CircularProgressbar, buildStyles as cpbBuildStyles,
} from 'react-circular-progressbar';
import { Progress as RSProgress } from 'reactstrap';

import { PropTypes } from '~/utils';
import { useSetting } from '~/hooks';

const styles = {
  circular  : Circular,
  linear    : Linear,
  numeric   : Numeric,
} as const;
type Styles = typeof styles;
export type ProgressStyle = keyof Styles;

export function Progress( props_in ) {
  const config_style = useSetting( 'progress_style', 'circular' );
  const { style = config_style, ...props } = props_in;
  if ( ! props.value ) props.value = 0;
  if ( props.text === true ) props.text = `${props.value}%`;
  const Comp = styles[ style ];
  if ( ! Comp ) throw new Error( `Invalid progress style "${style}"` );
  return <Comp {...props} />;
}

function Circular( props_in ) {
  const { color, ...props } = props_in;
  return (
    <CircularProgressbar
      {...props}
      styles={cpbBuildStyles( {
        strokeLinecap : 'butt',
        pathColor     : color,
      } )}
    />
  );
}
Circular.propTypes = {
  color       : PropTypes.string,
  text        : PropTypes.string,
  strokeWidth : PropTypes.number,
};

function Numeric( props_in ) {
  const { color, text, value } = props_in;
  return ( <h4 css={{ color }} >{ text || `${value}%` }</h4> );
}
Numeric.propTypes = {
  color       : PropTypes.string,
  text        : PropTypes.string,
};

function Linear( props_in ) {
  const { text, ...props } = props_in;
  return ( <RSProgress {...props} children={text} /> );
}
Linear.propTypes = {
  color       : PropTypes.string,
  text        : PropTypes.string,
  striped     : PropTypes.bool,
  animated    : PropTypes.bool,
};

Progress.propTypes = {
  className   : PropTypes.className,
  value       : PropTypes.number.isRequired,
  ...Circular.propTypes,
  ...Linear.propTypes,
  ...Numeric.propTypes,
  text        : PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
  style       : PropTypes.oneOf( _.keys( styles ) ),
};
