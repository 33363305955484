import { PropTypes } from '~/utils';
import { useResourceResolver, useResourceContext } from '~/hooks';
import { Link } from './Link';

export function Owner( props ) {
  const { name, value : id } = props;
  const type = name === 'project_id' ? 'SSP.Project' : 'SSP.User';
  const resource = useResourceResolver( { type, id } );
  const contextResource = useResourceContext()?._id;

  if ( resource?.[ name ] === contextResource ) return null;

  return <Link value={resource} />;
}
Owner.propTypes = {
  name  : PropTypes.oneOf( [ 'project_id', 'user_id' ] ).isRequired,
  value : PropTypes.string.isRequired,
};
