import _ from 'lodash';
import { PropTypes } from '~/utils';
import { Badge } from 'reactstrap';

export function NumberBadge( { value, ...props_in } ) {
  const props = _.pick( props_in, [ 'color', 'pill' ] );
  return <Badge {...props}>{value}</Badge>;
}
NumberBadge.propTypes = {
  value : PropTypes.number.isRequired,
  color : PropTypes.reactstrapColor,
  pill  : PropTypes.bool,
};
NumberBadge.defaultProps = {
  color : 'secondary',
  pill  : false,
};
