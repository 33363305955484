import { useSubject } from '~/hooks';
import { NavMenu } from './NavMenu';

const items = [
  {
    route : '/user/me',
    label : 'View My User',
    icon  : 'far:user',
    exact : true,
  },
  {
    route : '/user/me/settings',
    label : 'Edit My Settings',
    icon  : 'fad:sliders-v-square',
  },
];

export function UserMenu( props ) {
  const user = useSubject();
  const name = user && ( user.name || user.email || user._id );
  return (
    <NavMenu
      label={name}
      icon="fal:user-circle"
      items={items}
      name="user-menu"
    />
  );
}
