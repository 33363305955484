import { PropTypes } from '~/forms/utils';

export const rffArrayShape = {
  name      : PropTypes.string.isRequired,
  value     : PropTypes.arrayOf( PropTypes.any ),
  forEach   : PropTypes.func.isRequired,
  insert    : PropTypes.func.isRequired,
  map       : PropTypes.func.isRequired,
  move      : PropTypes.func.isRequired,
  pop       : PropTypes.func.isRequired,
  push      : PropTypes.func.isRequired,
  remove    : PropTypes.func.isRequired,
  shift     : PropTypes.func.isRequired,
  swap      : PropTypes.func.isRequired,
  update    : PropTypes.func.isRequired,
  unshift   : PropTypes.func.isRequired,
};
