
import { NavBar } from './NavBar';
import Pages from '~/pages';
import { ErrorBoundary } from '~/widgets';

import { StatusBar } from './StatusBar';
import { ImpersonatingBar } from './ImpersonatingBar';
import { Banner } from './Banner';
import { DebugPanels } from './DebugPanels';
import { useBooted, BootProgress } from './BootProgress';

export function MainLayout() {
  const booted = useBooted();
  return (
    <>
      <ErrorBoundary name="header">
        <header>
          { SSP.meta.server?.banner &&
            <Banner content={SSP.meta.server.banner} />
          }
          <ImpersonatingBar />
          <NavBar />
        </header>
      </ErrorBoundary>
      <ErrorBoundary name="main">
        { booted ? <Pages /> : <BootProgress /> }
      </ErrorBoundary>
      <ErrorBoundary name="footer">
        <footer>
          <StatusBar />
          <DebugPanels />
        </footer>
      </ErrorBoundary>
    </>
  );
}
