import _ from 'lodash';
import { PropTypes } from '~/utils';
import Json from 'react-json-view';
import styled from '@emotion/styled';
import { jsonStringify } from '@ssp/utils';

const Code = styled( 'code' )`
  font-family: 'courier, monospace';
  background-color: '#eee';
  text-align: left;
`;

export default function Dump( { data, children, ...props } ) {
  const obj = data || children;
  if ( _.isPlainObject( obj ) ) {
    return <Json src={obj} collapsed={1} name={false} {...props} />;
  }
  if ( _.isString( obj ) ) return <pre><Code>{obj}</Code></pre>;
  return <pre><Code>{jsonStringify( obj, { space : 2 } )}</Code></pre>;
}
/* eslint-disable react/forbid-prop-types */
Dump.propTypes = {
  data                : PropTypes.any,
  children            : PropTypes.any,
  displayObjectSize   : PropTypes.bool,
  displayDataTypes    : PropTypes.bool,
  quotesOnKeys        : PropTypes.bool,
  displayArrayKey     : PropTypes.bool,
};
Dump.defaultProps = {
  displayObjectSize   : false,
  displayDataTypes    : false,
  quotesOnKeys        : false,
  displayArrayKey     : false,
};
