import _ from 'lodash';
import { TResource } from '@ssp/database';

export type ResourceBreadcrumbOptions = {
  // For a "normal" resource all you need is resource, unless it
  // belongs to a different owner, then include the owner prop too
  resource?: TResource<any>;
  owner?: TResource<'SSP.User' | 'SSP.Project'>;
};
export type LinkedBreadcrumbOptions = {
  // For "multi-parent" resources like SSP.TeamLink include from and
  // to refs, and optionally a joiner (the default joiner is just ' to ')
  from?: TResource<any>;
  to?: TResource<any>;
  joiner?: string;
};
export type NormalBreadcrumbOptions = {
  // For regular, non-resource breadcrumbs
  url: string;
  label: string;
};

export type BreadcrumbOptions =
  | ResourceBreadcrumbOptions
  | LinkedBreadcrumbOptions
  | NormalBreadcrumbOptions;

export type BreadcrumbAugmented = {
  first: boolean;
  last: boolean;
  index: number;
};
export type BreadcrumbInfo =
  | ( ResourceBreadcrumbOptions & BreadcrumbAugmented )
  | ( LinkedBreadcrumbOptions & BreadcrumbAugmented )
  | ( NormalBreadcrumbOptions & BreadcrumbAugmented );

export function normalizeBreadcrumbs(
  items: BreadcrumbOptions[],
): BreadcrumbInfo[] {
  return items.map( ( item, index, arr ) => ( {
    ...item, index,
    first : index === 0,
    last  : index === arr.length - 1,
  } ) );
}
export function isResourceBreadcrumbOptions(
  value: any,
): value is ResourceBreadcrumbOptions {
  return Boolean( value.resource );
}
export function isLinkedBreadcrumbOptions(
  value: any,
): value is LinkedBreadcrumbOptions {
  return Boolean( value.to && value.from );
}
export function isNormalBreadcrumbOptions(
  value: any,
): value is NormalBreadcrumbOptions {
  return Boolean( value.to && value.from );
}
export function stringifyBreadcrumb( bc: ResourceBreadcrumbOptions ): string;
export function stringifyBreadcrumb( bc: LinkedBreadcrumbOptions ): string;
export function stringifyBreadcrumb( bc: NormalBreadcrumbOptions ): string;
export function stringifyBreadcrumb( bc: BreadcrumbOptions ): string {
  if ( isResourceBreadcrumbOptions( bc ) ) return String( bc.resource );
  if ( isLinkedBreadcrumbOptions( bc ) ) {
    if ( bc.from && bc.to ) return [ bc.from, 'to', bc.to ].join( ' ' );
  }
  if ( isNormalBreadcrumbOptions( bc ) ) return bc.label;
}
export function stringifyBreadcrumbs( items: BreadcrumbOptions[] ): string {
  return items.map( stringifyBreadcrumb ).join( ' > ' );
}

export function log_crumb( crumb: BreadcrumbInfo ) {
  const data = _.map( crumb, ( val, key ) => {
    if ( _.isBoolean( val ) ) return val ? key : `!${key}`;
    return `${key}=${val}`;
  } ).join( ' ' );
  log.debug( `${crumb.index}: ${data}` );
}
