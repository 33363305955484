import _ from 'lodash';
import { extractEmails } from '@ssp/utils';
import { useMemo } from '~/hooks';
import { useMembers } from '../context';
import { Form, FormConfig } from '~/forms';

export function AddRemoveForm() {
  const members = useMembers();
  const conf = useMemo( () => {
    const resource = members.resource;
    const schema = resource.members.schema;

    const main_field: $TSFixMe = {
      name      : 'user_id',
      dbfield   : false, // Don't provide custom field validations
      label     : [
        members.action === 'remove' ? 'Remove' : 'Add',
        members.bulk ? 'Members' : 'Member',
      ].join( ' ' ),
    };

    if ( members.bulk ) {
      _.assign( main_field, {
        type      : 'Textarea',
        help      : [
          'List of emails separated by commas, semicolon, or whitespace',
        ],
        rows      : 4,
        cols      : 80,
        default   : '',
      } );
    } else if ( members.action === 'remove' ) {
      _.assign( main_field, {
        type    : 'Message',
        // color   : 'info',
        label   : false,
        message : [
          'Members can be removed individually by using the remove',
          'button on each row. You can also use the "Bulk Mode" button',
          'below to switch to bulk mode and paste in the emails of the',
          'members you want to remove.',
        ],
      } );
    }
    if ( ! ( members.action === 'remove' ) && members.bulk ) {
      main_field.validate = ( value ) => {
        if ( ! value ) throw new Error( 'Field is required' );
      };
    }

    const fields = [ main_field ];
    if ( members.action === 'add' ) {
      fields.push( ...members.config_fields );
    }

    const { message } = members.getFormMessages();

    const config: $TSFixMe = {
      schema, resource, message,
      fieldset : schema.getFieldSet( ...fields ),
      icon          : 'fad:user-plus',
      onCancel() { members.update( { action : null } ); },
      extra_buttons : [ members.bulk ? {
        label   : 'Single Mode',
        onClick : () => members.update( { bulk : false } ),
      } : {
        label   : 'Bulk Mode',
        onClick : () => members.update( { bulk : true } ),
      } ],
      validate() { return true; }, // validation happens in the members object
    };
    if ( members.bulk ) {
      config.onSubmit = async ( data ) => {
        const { user_id, ...opts } = data;
        const emails = extractEmails( user_id );
        if ( members.action === 'remove' ) {
          await members.remove( emails );
        } else {
          await members.add( _.map( emails, email => ( {
            config : opts, email, change_type : 'add',
          } ) ) );
        }
        members.update( { action : null } );
        return false;
      };
    } else if ( members.action === 'add' ) {
      config.onSubmit = async ( data ) => {
        const { user_id : user, ...opts } = data;
        if ( user ) {
          await members.add( { config : opts, user, change_type : 'add' } );
        }
        members.update( { action : null } );
        return false;
      };
    } else {
      config.onSubmit = async () => {
        members.update( { action : null } );
        return false;
      };
    }

    return new FormConfig( config );
  }, [ members ] );
  return <Form config={conf} />;
}
