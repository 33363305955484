import _ from 'lodash';
import { getIdentity, ResultSet, Face } from '@ssp/database';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ResourceCard } from './ResourceCard';
import { PropTypes } from '~/utils';
import { VisibilityWrapper } from '~/widgets';

const Collection = styled( 'div' )( ( { theme, face } ) => {
  const width = _.get( face, 'grid_width', '330px' );
  const height = _.get( face, 'grid_height', '240px' );
  const gap = _.get( face, 'grid_gap', '10px' );
  return css`
    display: grid;
    gap: ${gap};
    grid-template-columns: repeat(auto-fill, minmax(${width}, 1fr) );
    grid-auto-rows: ${height};
  `;
} );

export function CardsView( { face, resultset } ) {

  const visprops = {
    margin  : face.margin || '0.5em',
  };

  return (
    <Collection
      className="collection-view-cards"
      data-testid="collectionview-subview-cards"
    >
      {resultset.mapSync( item => (
        <VisibilityWrapper key={getIdentity( item )} {...visprops}>
          { () => (
            <ResourceCard css={{ height: '100%' }}
              resource={item} face={face}
            />
          ) }
        </VisibilityWrapper>
      ) )}
    </Collection>
  );
}
CardsView.propTypes = {
  resultset   : PropTypes.instanceOf( ResultSet ).isRequired,
  face        : PropTypes.instanceOf( Face ).isRequired,
};
CardsView.config = {
  name      : 'card',
  title     : 'Card View',
  icon      : 'far:th',
};
