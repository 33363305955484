import _ from 'lodash';
import { Icon } from '~/icons';
import { NavLink } from '~/router';
import {
  Navbar, Collapse, NavbarToggler, Nav, NavbarBrand, NavItem, Button,
} from 'reactstrap';
import { useCallback, useSettings, useSubject,
  useToggle, useMemo } from '~/hooks';
import { css, useTheme } from '@emotion/react';
import { UserMenu } from './UserMenu';
import { HelpMenu } from './HelpMenu';
import { useLocation } from 'react-router-dom';
import { toast } from '~/utils';
import { Tooltip } from '~/widgets';

const items = [
  {
    route   : '/projects',
    label   : 'Projects',
  },
  {
    route   : '/users',
    label   : 'Users',
    setting : 'show_users_page',
  },
  {
    route   : '/jobs',
    label   : 'My Jobs',
  },
  {
    route      : '/admin',
    label      : 'Admin',
    capability : 'use-support-tools',
  },
  {
    route      : '/develop',
    label      : 'Dev',
    capability : 'use-developer-tools',
  },
];

const brand_css = theme => css`
  cursor : pointer;
  color : ${theme.navbar.fg};
  &:hover { color : ${theme.navbar.fg}; }
`;
const collapse_css = theme => css`
  > ul > li > a > span {
    color : ${theme.navbar.fg};
  }
`;
const toggle_css = theme => css`
  background-color : ${theme.navbar.bg};
  cursor : pointer;
  svg { color : ${theme.navbar.fg}; }
`;

const item_css = theme => css`
  background-color : ${theme.navbar.bg};
  white-space: no-wrap;
  cursor : pointer;
  a.nav-link, a.nav-item {
    color: ${theme.navbar.fg};
  }
  a:hover.nav-link, .nav-link.active {
    background-color : ${theme.colors.slight_pop};
  }
`;

const nav_css = theme => css`
  background-color : ${theme.navbar.bg};
  color : ${theme.navbar.fg};
  white-space : nowrap;
`;

export function NavBar( props ) {
  const [ isOpen, toggleIsOpen ] = useToggle( true );
  const [ tooltipOpen, toggleTooltip ] = useToggle( false );
  const subject = useSubject();
  const settings = useSettings();

  const routes = useMemo( () => {
    return _.filter( items, item => {
      if ( item.capability ) {
        if ( ! subject ) return false;
        if ( ! subject.hasCapability( item.capability ) ) return false;
      }
      if ( item.setting ) {
        if ( ! ( settings && settings[ item.setting ] ) ) return false;
      }
      return true;
    } );
  }, [ settings, subject ] );

  return (
    <Navbar expand="md" css={nav_css}>
      <NavbarBrand tag={NavLink} to="/" css={brand_css} id="NavbarBrand">
        Self Service Portal
        <Tooltip
          placement="bottom"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          target="NavbarBrand"
        >
          Navigate to your SSP start page
        </Tooltip>
      </NavbarBrand>
      <NavbarToggler onClick={toggleIsOpen} className="mr-2" css={toggle_css}>
        <Icon name={isOpen ? 'fas:caret-down' : 'fas:caret-right'} />
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar css={collapse_css}>
        <Nav navbar pills>
          {routes.map( item => (
            <NavItem key={item.route} css={item_css}>
              <NavLink to={item.route} data-testid="core-nav">
                {item.label}
              </NavLink>
            </NavItem>
          ) )}
        </Nav>
      </Collapse>
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="ml-auto">
          <UserMenu />
          <HelpMenu />
          <SetStartPage />
        </Nav>
      </Collapse>
    </Navbar>
  );
}

function SetStartPage() {
  const settings = useSettings();
  const location = useLocation();
  const theme = useTheme();

  const icon = useMemo( () => {
    if ( !settings ) return 'far:bookmark';
    if ( settings.start_page === location.pathname ) {
      return 'fas:bookmark';
    }
    return  'far:bookmark';
  }, [ settings, location.pathname ] );

  const updateSettings = useCallback( async () => {
    if ( !settings ) return null;
    settings.start_page = location.pathname;
    await settings.save();
    toast.info(
      [
        `Your Start Page is now set to "${location.pathname}". Click`,
        'this prompt for more customization options.',
      ].join( ' ' ),
      {
        url : '/user/me/settings',
        autoClose : 4000,
      },
    );
  }, [ location, settings ] );

  return (
    <Button onClick={updateSettings}
      style={{
        backgroundColor : theme.navbar.bg,
        border          : 'none',
      }}
    >
      <Icon icon={icon} fw
        tooltip="Make the current page your SSP start page"
      />
    </Button>
  );
}
