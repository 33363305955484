import { CustomInput } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';

export function Switch( { field } ) {
  const input = useInput( field, { type : 'checkbox' } );
  return <CustomInput {...input} type="switch" />;
}
Switch.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
