import _ from 'lodash';
import { CustomInput } from 'reactstrap';
import { Field } from 'react-final-form';
import { PropTypes, useInput } from '~/forms/utils';

/*
 * This is an abstract component that serves as the base of
 * `CheckboxGroup`, `RadioGroup` and `SwitchGroup` types.  Use one of
 * those, don't use this directly.
 */
export function SelectableArrayGroup( { field, type, fieldType } ) {
  const options = field.getOptions();
  useInput( field, type );
  return (
    <div className={`${type}-group`}>
      {_.map( options, opt => (
        <Field
          key={opt.id}
          name={field.name}
          type={ fieldType || type}
          value={opt.value}
          render={( { input, meta } ) => (
            <CustomInput {...opt} {...input} type={type} />
          )}
        />
      ) )}
    </div>
  );
}
SelectableArrayGroup.propTypes = {
  field     : PropTypes.fieldConfig.isRequired,
  type      : PropTypes.string.isRequired,
  fieldType : PropTypes.string,
};
