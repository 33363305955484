import _ from 'lodash';
import { Icon } from '~/icons';

export function renderOptions( options ) {
  const groups = _.groupBy( options, opt => {
    if ( _.isNil( opt.group ) ) return '';
    if ( opt.group === '' ) return '';
    return opt.group;
  } );
  return [
    ...renderOptionItems( groups[ '' ] ),
    ...renderOptionGroups( _.omit( groups, '' ) ),
  ];
}

export function renderOptionItems( options ) {
  return _.map( options, opt => {
    const { group, label, icon, ...rest } = opt;
    const item = icon ? <Icon icon={icon} label={label} fw /> : label;
    return <option key={label} {...rest}>{item}</option>;
  } );
}

export function renderOptionGroups( groups ) {
  return _.map( groups, ( options, group ) => {
    const opts = this.renderOptionItems( options );
    return <optgroup label={group} key={group}>{opts}</optgroup>;
  } );
}

