import { CustomInput } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';

export function File( { field } ) {
  const input = useInput( field, {
    type        : 'file',
    inputLabel  : 'Select File',
  } );
  return <CustomInput {...input} />;
}
File.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
