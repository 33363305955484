import { ActionPanel } from '~/actions';
import { getSchemas } from '@ssp/database';

export function ActionsPage() {
  return getSchemas().map( schema => {
    return (
      <ActionPanel key={schema.id}
        label={schema.id}
        icon={schema.icon}
        resource={schema.model}
      />
    );
  } );
}
