import { css } from '@emotion/react';
import { Progress } from 'reactstrap';

import { Icon } from '~/icons';
import { useConnState } from '~/hooks';

import { useBootProgress } from './BootProgress';

const status_css = css`
  width: 100%;
  text-align: center;
  a { color: grey; }
  & > span { padding-left: 10px; }
`;

const states = {
  offline      : { color : 'red', icon : 'fal:signal-slash' },
  disconnected : { color : 'red', icon : 'fal:plug' },
  pending      : { color : 'yellow', icon : 'fas:question' },
  error        : { color : 'red', icon : 'fal:exclamation-triangle' },
  connected    : { color : 'green', icon : 'fad:network-wired' },
  unknown      : { color : 'red', icon : 'fal:question' },
};

export function StatusBar() {
  const progress = useBootProgress();
  if ( progress ) {
    return (
      <div data-testid="bootprogress" css={status_css}>
        <Progress value={progress} striped animated color="info" />
      </div>
    );
  }
  return (
    <div data-testid="statusbar" css={status_css}>
      <VersionInfo />
      <ConnectionStatus />
    </div>
  );
}

function VersionInfo() {
  const data = SSP.meta;
  const client = data?.client?.version;
  const server = data?.server?.version;
  if ( ! ( client || server ) ) {
    return <Icon icon="fad:code-branch" label='Loading ...' />;
  }
  if ( client === server ) {
    return <Icon icon="fad:code-branch" label={client} />;
  }
  return <Icon icon="fad:code-branch" label={`${client} / ${server}`} />;
}

function ConnectionStatus() {
  const state = useConnState();
  return (
    <span data-connection-status={state} className="connection-status">
      <Icon {...( states[ state ] || states.unknown )} label={state} />
    </span>
  );
}
