import { DB, Schema } from '@ssp/database';
import { RouterTabs, AddResources, SummaryPage } from '~/pages/builder';
import { CollectionView } from '~/views';
import { useResourceContext } from '~/hooks';

function handleBuilder( builder ) {
  const project = builder.resource;
  if ( ! project ) return;

  const grp = builder.group( 'main' );
  grp.add( 'edit', [ 'delete', { props : project } ], 'action' );
  grp.add(
    {
      path      : '',
      title     : 'Home',
      icon      : project.schema.icon,
      component : SummaryPage,
    },
    {
      path      : 'add',
      title     : 'Add Resources',
      exact     : false,
      icon      : 'far:layer-plus',
      access    : 'admin',
      component : AddResources,
    },
  );
  grp.service( 'SSP.Team', { title : 'Teams' } );
  grp.add( {
    path      : 'members',
    title     : 'Members',
    icon      : 'far:users',
    component : CollectionView,
    count     : 'SSP.User',
    resultset : () => DB.SSP.User.find( {}, {
      minlength : false,
      limit     : 100,
    } ).project( project._id ),
  } );
  grp.service( 'SYSTEM.Job', {
    path      : 'jobs',
    title     : 'Jobs',
    count     : 'SYSTEM.Job',
    component : CollectionView,
    resultset : () => DB.SYSTEM.Job.find( { 'project.id' : project._id }, {
      minlength : false,
      limit     : 100,
    } ),
  } );
  const services = builder.group( 'services' );
  Schema.filter( 'is_project_resource' ).forEach( schema => {
    services.service( schema.id, {
      attachable : schema.canAttachTo( project ),
    } );
  } );
}

export function ProjectView( props ) {
  const project = useResourceContext();
  return <RouterTabs builder={handleBuilder} resource={project} {...props} />;
}
