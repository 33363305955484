import _ from 'lodash';
import { NotFound } from '@ssp/utils';
import { Face, FaceSection } from '@ssp/database';

import { PropTypes } from '~/utils';
import { DataView } from './DataView';
import { useMemo, useResource, useLoading } from '~/hooks';

export function ResourceViewSection( { resource, section, face } ) {
  const props = useMemo( () => {
    return {
      ..._.pick( section, 'className', 'layout', 'items' ),
      resource, face,
      values : _.pick( resource, section.names ),
    };
  }, [ section, resource, face ] );
  return ( <DataView {...props} /> );
}
ResourceViewSection.propTypes = {
  resource  : PropTypes.record.isRequired,
  section   : PropTypes.instanceOf( FaceSection ).isRequired,
  face      : PropTypes.instanceOf( Face ).isRequired,
};

export function ResourceView( props_in ) {
  const {
    children, resource : resource_in, face : face_in, view : view_in,
    ...props
  } = props_in;
  const resource = useResource( resource_in, { label : 'ResourceView' } );
  const loading = useLoading( 'Resource', resource || null );
  const face = useMemo( () => {
    if ( loading ) return;
    if ( face_in instanceof Face ) return face_in;
    if ( _.isString( face_in ) && _.isString( view_in ) && resource?.schema ) {
      return resource.schema.getFace( view_in, face_in );
    }
    throw new NotFound( `Unable to resolve face (${view_in}/${face_in})`, {
      data : { face : face_in, view : view_in, schema : resource?.schema.id },
    } );
  }, [ resource?.schema, face_in, view_in, loading ] );
  if ( loading ) return loading;
  return ( <> {
    _.map( face.sections, section => (
      <ResourceViewSection
        key={section.id}
        {...props}
        {...{ section, face, resource }}
      />
    ) )
  } </> );
}
ResourceView.propTypes = {
  resource  : PropTypes.record.isRequired,
  face      : PropTypes.oneOfType( [
    PropTypes.instanceOf( Face ), PropTypes.string,
  ] ).isRequired,
  view      : PropTypes.string,
};
