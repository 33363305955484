import { DB } from '@ssp/database';
import { PropTypes } from '~/utils';
import { RouterTabs } from '~/pages/builder';
import { CollectionView } from '~/views';
import { SummaryPage } from './SummaryPage';

function handleBuilder( builder ) {
  const comp = builder.component;
  if ( ! comp ) return;
  builder.group( 'main' ).add(
    {
      icon : 'fad:space-station-moon-alt',
      path      : '',
      title     : 'Home',
      component : SummaryPage,
      props     : { component : comp },
    },
    'action',
    {
      path      : 'jobs',
      title     : 'Jobs',
      icon      : 'far:history',
      component : CollectionView,
      props     : () => ( {
        resultset : DB.SYSTEM.Job.find( {
          resource_type : comp.schema.id,
        }, { minlength : true } ),
      } ),
    },
  );
}

export function ComponentView( props ) {
  return <RouterTabs builder={handleBuilder} component={props.component} />;
}
ComponentView.propTypes = {
  component  : PropTypes.model.isRequired,
};
