import _ from 'lodash';
import { createStore, init, InitProgress } from '@ssp/utils';
import { Loading } from '~/widgets';
import { useStore, useDebugFlag } from '~/hooks';

export type BootProgress = InitProgress & {
  booted: boolean;
  key: string;
};

let cleanup: ( () => void | null ) = null;
const store = createStore<BootProgress>( {
  name        : 'bootprogress',
  description : 'Startup progress status',
  reduce      : 'replace',
  initialize() {
    return {
      total     : 0,
      complete  : 0,
      remaining : 0,
      index     : -1,
      pct       : 0,
      items     : [],
      booted    : false,
    };
  },
  public      : {
    update( state ) {
      const booted = state.pct === 100;
      _.each( state.items, item => {
        item.key = _.at( item, 'method', 'type', 'id' ).join( '-' );
      } );

      this.setState( { ...state, booted } );
      if ( booted && cleanup ) {
        cleanup();
        cleanup = null;
      }
    },
  },
} );
cleanup = init.on( 'progress', data => store.update( data ) );

export function useBootProgress() {
  const data = useStore<BootProgress>( 'bootprogress' );
  if ( data.booted ) return;
  return data.pct;
}
export function useBooted() {
  const data = useStore<BootProgress>( 'bootprogress' );
  return data.booted;
}
export function BootProgress() {
  const data = useStore<BootProgress>( 'bootprogress' );
  const debug = useDebugFlag( 'show-boot-progress' );
  if ( ! debug ) return <Loading />;
  return (
    <div className="align-self-center mt-5 mb-5">
      <h1>Starting Up</h1>
      <ul>
        {data.items?.map( i => (
          <li key={i.key} css={{ color: i.complete ? 'green' : 'black' }}>
            {i.id} ({i.method})
          </li>
        ) )}
      </ul>
    </div>
  );
}
