import _ from 'lodash';
import { lazy } from 'react';
import { createStore } from '@ssp/utils';
import { useStore } from '~/hooks';
import { Modal } from '~/components';

const modals = {
  shortcuts  : lazy( () => load( import( './ShortcutsModal' ) ) ),
  actions    : lazy( () => load( import( './ActionsHelpModal' ) ) ),
  markdown   : lazy( () => load( import( './MarkdownModal' ) ) ),
};

const store = createStore( {
  name        : 'active-modal',
  description : 'Active Modal Store',
  state       : {},
  reduce      : 'replace',
  public      : {
    open( opts ) {
      if ( _.isString( opts ) ) opts = { modal : opts };
      const { modal } = opts;
      if ( modal && modals[ modal ] ) {
        this.setState( opts );
      } else if ( modal ) {
        log.error( 'INVALID Modal:', modal );
      } else {
        this.setState( {} );
      }
    },
    close() { this.setState( {} ); },
  },
} );

function load( loader ) {
  return loader.then( ( { default : Component, title } ) => {
    Component.title = title;
    return { default( props ) {
      return (
        <Modal isOpen size='lg' scrollable toggle={store.close} title={title}>
          <Component {...props} />
        </Modal>
      );
    } };
  } );
}

declare module '@ssp/preload' {
  export interface SSP {
    modal: {
      open( opts: { modal: string; [key: string]: any; } ): void;
      close(): void;
      names: string[];
    };
  }
}

SSP.modal = {
  open    : store.open,
  close   : store.close,
  names   : _.keys( modals ),
};

export function Modals() {
  const { modal, ...props } = useStore( 'active-modal' );
  const Component = modals[ modal ];
  if ( ! Component ) return null;
  return <Component {...props} />;
}
