import { useDimensions } from './useDimensions';

/** @module "UI.hooks" */

/* breakpoints:
  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) { ... }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { ... }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { ... }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { ... }
*/

export function useBreakpoint() {
  const { width } = useDimensions();
  if ( width > 1200 ) return 'xl';
  if ( width > 992 ) return 'lg';
  if ( width > 768 ) return 'md';
  if ( width > 576 ) return 'sm';
  return 'xs';
}
