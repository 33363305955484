import _ from 'lodash';
import { PropTypes } from '~/utils';
import { Type } from '@ssp/types';
import { Action } from '@ssp/database';
import { Form } from './Form';
import { FormConfig } from './config';
import { useOptions, useMemo } from '~/hooks';
import { finalizeAction } from '~/actions';

export function ActionForm( { resource, action, ...props } ) {
  const opts = useOptions( props, 'shallow' );
  const config = useMemo( () => {
    const schema = action.typeSchema;
    const fields = _.map( schema.params, ( fd, name ) => {
      // Prepare field variable.
      const field = {
        name,
        disabled      : Boolean( fd.readonly ),
      };

      const type = Type.get( fd.type );

      if ( ! type ) {
        throw new TypeError( `Type "${fd.type}" does not exist` );
      }
      if ( ! _.isNil( fd.default ) ) field.defaultValue = fd.default;

      _.assign( field, fd, type.form, fd.form );
      return field;
    } );

    const onSubmit = async function onSubmit( data, { router } ) {
      return finalizeAction( { action, resource, router, data } );
    };

    return new FormConfig( {
      onSubmit,
      title : action.label,
      ..._.pick( action, 'danger', 'warning', 'message' ),
      ...opts,
      resource, action, schema, fields,
    } );
  }, [ opts, resource, action ] );

  return (
    <Form
      config={config}
      resource={resource}
      action={action}
    />
  );
}

ActionForm.propTypes = {
  resource  : PropTypes.actionable.isRequired,
  action    : PropTypes.instanceOf( Action ).isRequired,
  data      : PropTypes.shape( {} ),
};
