import { PropTypes } from '~/forms/utils';

export function FieldElementList( { field, config } ) {
  // TODO - Use the available context information to determine if this
  // is a field that can be managed elsewhere (like `members`) and put
  // a link here to take you to that manager.
  return null;
}
FieldElementList.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  config  : PropTypes.formConfig.isRequired,
};
