import { Children } from 'react';
import {
  Col, FormGroup, ListGroup, ListGroupItem as ListGroupItemRS,
} from 'reactstrap';
import { cx, PropTypes, useFieldError, useFieldArray } from '~/forms/utils';
import {
  ArrayElement, ArrayTools,
  FieldHelp, FieldLabel, FieldState, FieldError, FieldErrorBoundary,
} from '..';

/* FieldArrayRenderProps available in `fields`:
 *  .forEach( iterator )
 *  .insert( index, value )
 *  .map( iterator )
 *  .move( from, to )
 *  .name
 *  .pop()
 *  .push( value )
 *  .remove( index )
 *  .shift()
 *  .swap( a, b )
 *  .update( index, value )
 *  .unshift( value )
 *  .value
 */

export function FieldElementArray( { field, config } ) {
  // eslint-disable-next-line no-unused-vars
  const { fields : array, meta } = useFieldArray( field.name, {
    // defaultValue, initialValue,
    // isEqual,
    // subscription,
    // validate,
  } );
  const error = useFieldError( field.name );
  const content = array.map( ( name, index ) => {
    const subfield = field.subfield( { name, array : false } );
    return (
      <ArrayElement
        key={subfield.id}
        field={subfield}
        config={config}
        array={array}
        index={index}
      />
    );
  } );
  const classes = cx( field.groupClassName, { 'has-error' : error } );
  const { valueWidth } = field;
  return (
    <FieldErrorBoundary field={field} config={config}>
      <FormGroup row className={classes}>
        <FieldLabel field={field} />
        <Col sm={valueWidth}>
          <ListGroup flush>
            {content}
            <ListGroupItem>
              <ArrayTools config={config} field={field} array={array} />
            </ListGroupItem>
          </ListGroup>
          <FieldHelp field={field} />
          <FieldError field={field} error={error} />
        </Col>
        <FieldState field={field} />
      </FormGroup>
    </FieldErrorBoundary>
  );
}
FieldElementArray.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  config  : PropTypes.formConfig.isRequired,
};

function ListGroupItem( { children, ...props } ) {
  if ( ! Children.count( children ) ) return null;
  return ( <ListGroupItemRS {...props}>{children}</ListGroupItemRS> );
}
ListGroupItem.propTypes = { children : PropTypes.node.isRequired };
