import { Action } from '@ssp/database';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import {
  Dropdown, DropdownToggle, ButtonDropdown as ButtonDropdownRS,
  DropdownMenu as DropdownMenuRS, DropdownItem as DropdownItemRS,
} from 'reactstrap';

import { Icon } from '~/icons';
import { PropTypes } from '~/utils';
import { useToggle } from '~/hooks';
import { ActionRenderer } from './ActionRenderer';

// TO-DO: Export ButtonDropdown as a widget to standardize the styles
const ButtonDropdown = styled( ButtonDropdownRS )( ( { theme } ) => {
  return css`
    > button {
      cursor: pointer;
      background-color: ${theme.buttons.bg};
      border : ${theme.buttons.border};
      svg { color: ${theme.icon.fg}; }
      color : ${theme.buttons.fg};
      padding : 8px;
      &:hover {
        color : ${theme.buttons.fg};
        background-color : ${theme.colors.very_light_gray};
        border : ${theme.buttons.border};
      };
      border-radius: 2rem;
    }
  `;
} );

const DropdownItem = styled( DropdownItemRS )( () => {
  return {
    whiteSpace      : 'nowrap',
  };
} );
const DropdownMenu = styled( DropdownMenuRS )( () => {
  return {
    whiteSpace      : 'nowrap',
  };
} );

export function ActionMenuItem( { action, onClick, disabled } ) {
  const tooltip = disabled || action.help;
  return (
    <DropdownItem disabled={Boolean( disabled )} onClick={onClick}>
      <Icon icon={action.icon}
        fw
        label={action.label}
        tooltip={tooltip}
        placement='left'
      />
    </DropdownItem>
  );
}
ActionMenuItem.propTypes = {
  onClick   : PropTypes.func.isRequired,
  action    : PropTypes.instanceOf( Action ).isRequired,
  resource  : PropTypes.actionable.isRequired,
  disabled  : PropTypes.string,
};

export function ActionMenuGroup( { label, icon, children } ) {
  return (
    <>
      <DropdownItem header><Icon icon={icon} label={label} /></DropdownItem>
      {children}
    </>
  );
}
ActionMenuGroup.propTypes = {
  label     : PropTypes.string.isRequired,
  icon      : PropTypes.string,
  children  : PropTypes.node.isRequired,
};

export function ActionMenu( props_in ) {
  const { resource, icon, label, right, meta, button, ...props } = props_in;
  const [ isOpen, toggleIsOpen ] = useToggle( false );

  const content = useMemo( () => {
    if ( ! isOpen ) return null;
    return (
      <ActionRenderer
        Action={ActionMenuItem}
        Group={ActionMenuGroup}
        resource={resource}
        Wrapper={DropdownMenu}
        right={right}
        meta={meta}
      />
    );
  }, [ isOpen, resource, right, meta ] );

  if ( button ) {
    return (
      <>
        <ButtonDropdown
          toggle={toggleIsOpen}
          isOpen={isOpen}
          {...props}
          group={false}
        >
          <DropdownToggle>
            <Icon icon={icon} label={label} />
          </DropdownToggle>
          {content}
        </ButtonDropdown>
      </>

    );
  } else {
    return (
      <Dropdown toggle={toggleIsOpen} isOpen={isOpen} {...props}>
        <DropdownToggle tag='span'>
          <Icon icon={icon} label={label} />
        </DropdownToggle>
        {content}
      </Dropdown>
    );
  }
}
ActionMenu.propTypes = {
  resource    : PropTypes.actionable.isRequired,
  label       : PropTypes.string.isRequired,
  icon        : PropTypes.string,
  button      : PropTypes.bool,
  right       : PropTypes.bool,
  meta        : PropTypes.bool,
};
ActionMenu.defaultProps = {
  label       : '',
  icon        : 'far:bars',
  button      : false,
  right       : false,
  meta        : true,
};
