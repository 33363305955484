import { connect } from 'socket.io-client';
import { getOrigins } from '@ssp/database';
import { mkdebug, throttle, delay, NotAuthenticated } from '@ssp/utils';

import { connstore } from './connection-store';

import type { Socket as SocketIO } from 'socket.io-client';
import type { LiveUpdateMessage } from '@ssp/database';

export interface ServerToClientEvents {
  'error': ( error: Error ) => void;
  'live-update': ( msg: LiveUpdateMessage ) => void;
  'update-channels': ( channels: string[] ) => void;
}

export interface ClientToServerEvents {
  'join-channel': ( channel: string | string[] ) => void;
  'leave-channel': ( channel: string | string[] ) => void;
}

const debug = mkdebug( 'ssp:client:sockets' );

export type Socket = SocketIO<ServerToClientEvents, ClientToServerEvents>;
export const socket: Socket = connect( {
  // reconnection         : true,
  // reconnectionAttempts : Infinity,
  // reconnectionDelay    : 3000,
  // reconnectionDelayMax : 15000,
  // randomizationFactor  : 0.5,
  // timeout              : 10000,
  // addTrailingSlash     : false,
  autoConnect          : false,
  transports           : [ 'polling', 'websocket' ],
  auth( cb ) {
    if ( ! SSP.auth?.token ) throw new NotAuthenticated( 'No token' );
    cb( { token : SSP.auth.token } );
  },
} );

const connstate = ( state, info={} ) => connstore.setState( {
  state, ...info,
} );
export const reconnect = throttle( async () => {
  debug( 'reconnecting' );
  await delay( '1s' );
  connstate( 'connecting' );
  if ( socket.connected ) socket.disconnect();
  return socket.connect();
} );
const on_error = ( error ) => {
  debug( 'on_error', error );
  connstate( 'error', { error } );
  log.error( 'SOCKETIO ERROR:', error );
  reconnect();
};

socket.on( 'connect', () => {
  debug( 'connected' );
  connstate( 'connected' );
} );
socket.on( 'disconnect', reason => {
  debug( 'disconnect', reason );
  connstate( 'disconnected', { reason } );
  if ( [ 'io server disconnect', 'io client disconnect' ].includes( reason ) ) {
    reconnect();
  }
} );
socket.on( 'connect_error', ( error ) => {
  debug( 'connect_error', error );
  on_error( error );
} );

// Disconnect reasons:
// io server disconnect
//    The server has forcefully disconnected the socket with socket.disconnect()
// io client disconnect
//    The socket was manually disconnected using socket.disconnect()
// ping timeout
//    The server did not response to PING
// transport close
//    The connection was closed (example: the user has lost
//    connection, or the network was changed from WiFi to 4G)
// transport error
//    The connection has encountered an error (example: the server was
//    killed during a HTTP long-polling cycle)
//
// In the first two cases (explicit disconnection), the client will
// not try to reconnect and you need to manually call
// socket.connect().

// Reconnect related events:
// open   successful (re)connection
// error  (re)connection failure or error after a successful connection
// close  disconnection
// ping   ping packet
// packet data packet
// reconnect_attempt reconnection attempt
// reconnect         successful reconnection
// reconnect_error   reconnection failure
// reconnect_failed  reconnection failure after all attempts
socket.io.on( 'open', () => {
  debug( 'open' );
} );
socket.io.on( 'error', ( error ) => {
  debug( 'error', error );
  on_error( error );
} );
socket.io.on( 'close', () => {
  debug( 'close' );
} );
// socket.io.on( 'ping', () => { debug( 'ping' ); } );
// socket.io.on( 'packet', () => { debug( 'packet' ); } );
socket.io.on( 'reconnect_attempt', ( attempt ) => {
  debug( 'reconnect_attempt', attempt );
  connstate( 'connecting', { attempt } );
} );
socket.io.on( 'reconnect', attempt => {
  debug( 'reconnect', attempt );
  connstate( 'connected', { attempt } );
} );
socket.io.on( 'reconnect_error', ( error ) => {
  debug( 'reconnect_error', error );
  on_error( error );
} );
socket.io.on( 'reconnect_failed', () => {
  debug( 'reconnect_failed' );
} );


socket.on( 'error', ( error ) => {
  connstate( 'error', { error } );
  log.error( 'SOCKETIO ERROR:', error );
  socket.disconnect().connect();
} );

let channels: string[] = [];

socket.on( 'live-update', data => {
  debug( 'RECEIVED LIVE UPDATE:', data );
  getOrigins().processLiveUpdate( data );
} );

socket.on( 'update-channels', data => {
  debug( 'RECEIVED CHANNELS UPDATE:', data );
  if ( ! Array.isArray( data ) ) return;
  channels = data;
  debug( 'UPDATED CHANNELS:', data );
} );

export function getChannels() { return [ ...channels ]; }
export function joinChannel( channel: string | string[] ) {
  socket.emit( 'join-channel', channel );
}
export function leaveChannel( channel: string | string[] ) {
  socket.emit( 'leave-channel', channel );
}
