import _ from 'lodash';
import { useResource } from '~/hooks';
import { Link } from '~/router';
import React from 'react';
import {
  toast as _toast,
  ToastOptions as CoreToastOptions,
  ToastContent as CoreToastContent,
  Id as ToastId,
} from 'react-toastify';

export type ToastableResource = { route(): string; };

export interface ToastOptions extends CoreToastOptions {
  resource?: ToastableResource;
  url?: string;
}
export type ToastContent = CoreToastContent | Error;

type ResourceToastProps = React.PropsWithChildren<{
  resource: ToastableResource;
  toastProps: ToastOptions;
}>;
function ResourceToast( { resource, ...props }: ResourceToastProps ) {
  const rsrc = useResource( resource, { label : 'ResourceToast' } );
  const url = rsrc.route();
  return <LinkToast url={url} {...props} />;
}

type LinkToastProps = React.PropsWithChildren<{
  url: string;
  toastProps?: ToastOptions;
}>;
function LinkToast( { url, toastProps, children }: LinkToastProps ) {
  const style = _.defaults( {}, toastProps?.style );
  if ( toastProps?.type !== 'default' ) style.color = 'white';
  return <div><Link to={url} css={style as $TSFixMe}>{children}</Link></div>;
}

export type ToastType = 'info' | 'success' | 'warning' | 'error' | 'default';

export type ToastFunc = (
  content: ToastContent, options: ToastOptions,
) => ToastId;

const copied = {
  dismiss  : _toast.dismiss,
  done     : _toast.done,
  isActive : _toast.isActive,
  update   : _toast.update,
  onChange : _toast.onChange,
  POSITION : _toast.POSITION,
  TYPE     : _toast.TYPE,
} as const;
export type SSPToast = ToastFunc & typeof copied & {
  [Method in ToastType]: ToastFunc;
};

function createToastByType( type: ToastType ): ToastFunc {
  return ( content: ToastContent, options: ToastOptions = {} ): ToastId => {
    return toast( content, { type, ...options } );
  };
}

export const toast: SSPToast = Object.assign( (
  content: ToastContent, options: ToastOptions = {},
): ToastId => {
  if ( _.isError( content ) ) {
    if ( BUILD.isDev ) {
      content = content.stack || String( content );
    } else {
      content = content.message || 'Unknown error';
    }
  }
  const { resource, url, ...opts } = options;

  const props = { toastProps : opts, children : content };
  if ( resource ) {
    return _toast( <ResourceToast {...{ resource, ...props }} />, opts );
  }

  if ( url ) {
    return _toast( <LinkToast {...{ url, ...props }} />, opts );
  }
  return _toast( content, options || {} );
}, {
  info     : createToastByType( 'info' ),
  success  : createToastByType( 'success' ),
  warning  : createToastByType( 'warning' ),
  error    : createToastByType( 'error' ),
  default  : createToastByType( 'default' ),
  ...copied,
} );
