import _ from 'lodash';
import { useResource, useToggle, useRef } from '~/hooks';
import { PropTypes } from '~/utils';
import { Tooltip } from '~/widgets';
import { NormalBreadcrumb } from './NormalBreadcrumb';

export function ResourceBreadcrumb( props ) {
  const resource = useResource( props.resource, {
    safe  : true,
    label : 'ResourceBreadcrumb',
  } );
  const label = resource.name || resource._id || '???';
  const url = resource.route();
  const [ isOpen, toggleIsOpen ] = useToggle();
  const ref = useRef();
  if ( _.isError( resource ) ) {
    const message = resource.message || 'Error Loading Breadcrumb';
    return (
      <>
        <div ref={ref}>{label}</div>
        <Tooltip
          key={props.resource._id}
          placement='bottom'
          isOpen={isOpen}
          toggle={toggleIsOpen}
          target={ref}
        >
          {message}
        </Tooltip>
      </>
    );
  }
  return <NormalBreadcrumb {...props} label={label} url={url} />;
}
ResourceBreadcrumb.propTypes = {
  resource  : PropTypes.resource.isRequired,
};
