import _ from 'lodash';
import { PropTypes } from '~/forms/utils';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';
import { FormButton } from '~/forms/elements';

export function Buttons( { field, ...props } ) {
  if ( field.button ) {
    return <Button field={field} button={field.button} />;
  }
  if ( field.buttons || field.group ) {
    return <Group field={field} buttons={field.buttons || field.group} />;
  }
  if ( field.groups || field.toolbar ) {
    return <Toolbar field={field} groups={field.groups || field.toolbar} />;
  }
  throw new Error( `No button configs?` );
}
Buttons.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};

const buttonShape = PropTypes.shape( PropTypes.buttonShape );

function Button( { field, button, ...props } ) {
  return <FormButton outline {...button} field={field} {...props} />;
}
Button.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  button  : buttonShape.isRequired,
};
function Group( { field, buttons, ...props } ) {
  return (
    <ButtonGroup className="p-1">
      {_.map( buttons, button => (
        <Button key={button.title || button.label || button.icon }
          button={button} field={field} {...props}
        />
      ) )}
    </ButtonGroup>
  );
}
Group.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  buttons : PropTypes.arrayOf( buttonShape ).isRequired,
};
function Toolbar( { field, groups, ...props } ) {
  return (
    <ButtonToolbar>
      {_.map( groups, ( group, idx ) => (
        <Group key={idx} field={field} buttons={group} {...props} />
      ) )}
    </ButtonToolbar>
  );
}
Toolbar.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  groups  : PropTypes.arrayOf( PropTypes.arrayOf( buttonShape ) ).isRequired,
};
