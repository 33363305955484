import _ from 'lodash';
import { PropTypes } from '~/utils';
import Json from 'react-json-view';
import { Panel } from '~/components';

export default function DataPanel( {
  data, children, src, title, meta, ...props
} ) {
  const obj = data || children;
  if ( _.isNil( obj ) ) return null;
  const opts = {
    collapsed     : 1,
    quotesOnKeys  : false,
  };
  const footer = meta && <Json src={meta} name="Metadata" />;
  return (
    <Panel title={title || 'Raw Data'} footer={footer}>
      <Json src={data || children || src} {...opts} {...props} />
    </Panel>
  );
}
/* eslint-disable react/forbid-prop-types */
DataPanel.propTypes = {
  data      : PropTypes.any,
  meta      : PropTypes.any,
  children  : PropTypes.any,
  src       : PropTypes.any,
  title     : PropTypes.string,
  name      : PropTypes.string,
};
DataPanel.defaultProps = {
  name  : 'data',
};
