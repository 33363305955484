import _ from 'lodash';
import { PropTypes } from '~/utils';

const style = { color : 'red' };
export function Nil( { value } ) {
  const label = getLabel( value );
  return <span style={style}>&lt;{label}&gt;</span>;
}
Nil.propTypes = {
  value : PropTypes.oneOf( [ null, undefined ] ),
};

function getLabel( value ) {
  if ( _.isUndefined( value ) ) return 'undefined';
  if ( _.isNull( value ) ) return 'null';
  return '???';
}
