import _ from 'lodash';
import { useMemo } from 'react';
import { PropTypes } from '~/forms/utils';
import { ButtonGroup } from 'reactstrap';
import { rffArrayShape } from './utils';
import { FormButton } from '..';

const orderedItemTools = [
  {
    title   : 'Insert Before',
    icon    : 'far:level-up',
    onClick( { field, index, array } ) {
      array.insert( index - 1, field.createItem() );
    },
  },
  {
    title   : 'Insert After',
    icon    : 'far:level-down',
    onClick( { field, index, array } ) {
      array.insert( index + 1, field.createItem() );
    },
  },
  {
    title   : 'Move Up',
    icon    : 'far:arrow-up',
    onClick( { field, index, array } ) {
      array.swap( index, index - 1 );
    },
  },
  {
    title   : 'Move Down',
    icon    : 'far:arrow-down',
    onClick( { field, index, array } ) {
      array.swap( index, index + 1 );
    },
  },
];

const unorderedItemTools = [
];

const allItemTools = [
  {
    title   : 'Remove',
    icon    : 'fad:trash',
    onClick( { field, index, array } ) {
      array.remove( index );
    },
  },
];

/*
if ( field.clickToEdit ) {
  conf.push( {
    title   : 'Edit',
    icon    : 'fad:pencil-alt',
    onClick( { field, index, array } ) {}, // TODO
  } );
}
*/

function itemTools( field ) {
  return _.flatten( [
    field.ordered ? orderedItemTools : unorderedItemTools,
    allItemTools,
  ] );
}

export function ArrayItemTools( { field, index, array } ) {
  const tools = useMemo( () => itemTools( field ), [ field ] );
  const context = useMemo( () => {
    return { field, index, array };
  }, [ field, index, array ] );

  return (
    <div>
      <ButtonGroup size="sm" className="ml-1">
        {_.map( tools, btn => (
          <FormButton key={btn.title} {...btn} context={context} />
        ) )}
      </ButtonGroup>
    </div>
  );
}
ArrayItemTools.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  index   : PropTypes.number,
  array   : PropTypes.shape( rffArrayShape ).isRequired,
};
