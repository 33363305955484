import _ from 'lodash';
import { createContext, useContext } from 'react';
import { Deprecated } from '@ssp/utils';
import { ResultSet } from '@ssp/database';

import { PropTypes } from '~/utils';
import { useQuickSearch } from '~/hooks';
import { useViewContext } from './view';
import { DebugPanel } from '~/widgets';

import type { ReferenceURL } from '@ssp/utils';
import type { Schema } from '@ssp/database';

/** The results object returned by `useResultSet`. */
export interface ResultSetContext {
  /** Set to true when the resultset has loaded. */
  loaded?: boolean;

  /**
   * The resultset being managed.  May be missing if there was an
   * error (such as providing a string that wasn't a valid component
   * id).  Note that the resultset isn't returned unless it's loaded,
   * but the schema is provided separately in case you need schema
   * information.
   */
  resultset?: ResultSet;

  /** The schema of the resultset being managed. */
  schema?: Schema;

  /**
   * The Reference URLs of the results (lazy, and only if the
   * resultset is loaded).
   */
  urls?: string[];

  /**
   * The Reference URLs of the results (lazily parsed into
   * `ReferenceURL` objects, and only if the resultset is loaded).
   */
  refs?: ReferenceURL[];

  /**
   * Resource skeletons for the search results. (lazy, and only if the
   * resultset is loaded).
   */
  results?: Resource[];
  /**
   * An instructional message if there are unmet predicates, or an
   * error message if there was an error.
   */
  message?: string;

  /** The `Error` instance, if there was an error. */
  error?: Error;

  loading?: boolean;

  /** Force a specific view */
  view?: string;
  /** Force a specific face */
  face?: string;
  /** Set a default view */
  defaultView?: string;
  /** Set a default face */
  defaultFace?: string;
}

export const ResultSetContext = createContext<ResultSetContext>( {} );

export function ResultSetContextProvider( props ) {
  [
    'minlength',
    'limit',
    'sort',
  ].forEach( x => {
    if ( ! props[x] ) return;
    throw new Deprecated( `ResultSetContextProvider no longer accepts ${x}` );
  } );
  const {
    resultset,
    children,
    face, defaultFace,
    view, defaultView,
  } = props;

  const viewctx = useViewContext();

  const value = useQuickSearch( resultset, viewctx.search, _.omitBy( {
    // defaultFace, defaultView, face, view,
    ..._.pick( viewctx, 'sort', 'sensitive', 'exact', 'filters' ),
  }, _.isNil ) );

  return (
    <ResultSetContext.Provider value={value as ResultSetContext}>
      {children}
      <DebugPanel
        shortcut='D R'
        title='ResultSet Context Provider Info'
        tables={() => makeTables( value, props )}
      />
    </ResultSetContext.Provider>
  );
}
ResultSetContextProvider.propTypes = {
  resultset   : PropTypes.oneOfType( [
    PropTypes.instanceOf( ResultSet ),
    PropTypes.string,
  ] ).isRequired,
  view        : PropTypes.string,
  face        : PropTypes.string,
  defaultView : PropTypes.string,
  defaultFace : PropTypes.string,
  children    : PropTypes.children.isRequired,
};

export function useResultSetContext() {
  const res = useContext( ResultSetContext );
  if ( _.isError( res.error ) ) {
    log.debug( `useResultSetContext got error:`, res.error );
    throw res;
  }
  return res;
}

function makeTables( context, _props ) {
  const rs = context.resultset;
  const data = {
    // TODO - Figure out why this ends up in an infinite loop
    // 'ResultSetContextProvider Props'  : props,
    'Basic ResultSet Context Info'    : {
      'Schema'            : context.schema.id,
      'Loaded'            : context.loaded,
      'Loading'           : context.loading,
      'ResultSet Loaded'  : rs.loaded,
      'ResultSet Size'    : rs.length,
      'Page Count'        : rs.page_count,
      'Page Number'       : rs.page_number,
      'Error'             : rs.error,
    },
    'Query/Options Sent to Transport' : {
      'ResultSet Query'   : rs.query,
      'ResultSet Options' : rs.options,
    },
  };
  if ( rs.results ) {
    data[ 'Transport Results' ] = _.pick( rs.results, [
      'ok', 'status', 'schema', 'error',
    ] );
    const exp = _.fromPairs( rs.results.explanation );
    data[ 'Explanation of Constructed Query/Options' ] = exp;
  }
  return data;
}
