import _ from 'lodash';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { DropdownItem as DropdownItemRS } from 'reactstrap';

import { ActionItem } from './ActionItem';
import { cx, PropTypes } from '~/utils';
import { useDebugFlag } from '~/hooks';
import { useActionList } from './useActionList';

export const DefaultWrapper = styled( 'div' )( () => {
  return {
    whiteSpace      : 'nowrap',
  };
} );

const style = { zIndex : '2000' };

export function ActionRenderer( props_in ) {
  const {
    resource, className, group, meta,
    Action, Group, Wrapper,
    ...props
  } = props_in;
  const showAll = useDebugFlag( 'show-all-actions' );
  const actions = useActionList( resource, showAll );

  const rendered = useMemo( () => {
    if ( ! actions ) return null;
    const renderItems = items => _.map( items, ( item ) => {
      const { action, disabled } = item;
      if ( _.isArray( disabled ) ) log.warn( 'DISABLED ARRAY:', item );
      return (
        <ActionItem
          key={action.id}
          action={action}
          disabled={disabled}
          resource={resource}
          component={Action}
        />
      );
    } );

    const metas = _.remove( actions, { group : 'meta' } );
    let divs = 1;
    const div = () => <DropdownItemRS divider key={`divider-${divs++}`} />;

    const res = [];
    if ( group ) {
      const groups = _.groupBy( actions, 'group' );
      if ( _.size( groups ) === 1 ) {
        res.push(  renderItems( groups[ _.first( _.keys( groups ) ) ] ) );
      } else {
        res.push( _.map( groups, ( items, grp ) => (
          <Group key={grp} label={grp}>{renderItems( items )}</Group>
        ) ) );
      }
    } else {
      res.push( renderItems( actions ) );
    }
    if ( meta && metas.length ) res.push( div(), renderItems( metas ) );
    return res.flat();
  }, [ actions, resource, group, meta, Action, Group ] );

  const classes = cx( className );
  return (
    <Wrapper className={classes} css={style} {...props}>
      {
        rendered?.length
          ? rendered
          : <div className="px-3 py-2">No actions available...</div>
      }
    </Wrapper>
  );
}
ActionRenderer.propTypes = {
  Action     : PropTypes.func.isRequired,
  Group      : PropTypes.func,
  Wrapper    : PropTypes.elementType,
  resource   : PropTypes.actionable.isRequired,
  className  : PropTypes.className,
  group      : PropTypes.bool,
  right      : PropTypes.bool,
  meta       : PropTypes.bool,
};
ActionRenderer.defaultProps = {
  Wrapper   : DefaultWrapper,
  group     : false,
};
