import {
  FieldElementList, FieldElementNormal, FieldElementArray, FieldElementSubDoc,
  FieldElementConditional,
} from '~/forms/elements';
import * as inputs from '~/forms/inputs';
import * as addons from '~/forms/addons';

export function getInputForField( field ) {
  if ( field.conditional ) return FieldElementConditional;
  if ( field.list ) return FieldElementList;
  if ( field.array ) {
    const input = getInputElement( field.type );
    if ( input.managesArray ) return FieldElementNormal;
    return FieldElementArray;
  }
  if ( field.subdoc || field.subdocObj ) return FieldElementSubDoc;
  return FieldElementNormal;
}

export function getInputElement( type ) {
  if ( ! inputs[ type ] ) throw new Error( `Invalid input type "${type}"` );
  return inputs[ type ];
}

export function getAddon( type ) {
  if ( ! addons[ type ] ) throw new Error( `Invalid addon type "${type}"` );
  return addons[ type ];
}
