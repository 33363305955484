import { useRef } from 'react';
import { PropTypes } from '~/utils';
import { PopCard } from '~/widgets';
import styled from '@emotion/styled';

import type { InferProps } from 'prop-types';

type TriggerProps = {
  isActive: boolean;
  isSelected: boolean;
  height: number;
  start: number;
};
const Trigger = styled.button<TriggerProps>(
  {
    position      : 'absolute',
    top           : 0,
    left          : 0,
    width         : '100%',
    cursor        : 'pointer',
    border        : 'none',
    textAlign     : 'left',
    borderTop     : 'none',
    lineHeight    : '1em',
    fontSize      : '1rem',
    textTransform : 'none',
    boxShadow     : 'none',
    padding       : '.8rem 1.1rem',
    whiteSpace    : 'normal',
    wordWrap      : 'normal',
  },
  ( { isSelected, isActive } ) => ( {
    color : `rgba(0,0,0,${( isSelected || isActive ) ? '.95' : '.87'})`,
    background    : isActive ? 'rgba(0,0,0,.03)' : undefined,
    fontWeight    : isSelected ? '700' : '400',
  } ),
  ( { height, start } ) => ( {
    height        : height + 'px',
    transform     : `translateY(${start}px)`,
  } ),
);

type ItemProps = InferProps<typeof Item.propTypes>;
export function Item( { item, getItemProps, ...props }: ItemProps ) {
  const ref = useRef();
  const label = item?.toString() || `Unknown Item (${item})`;
  return (
    <PopCard
      trigger={<Trigger {...getItemProps( props )} ref={ref}>{label}</Trigger>}
      isOpen={props.isActive}
      target={ref}
      resource={item}
      face='summary'
      hoverable
      hideArrow
    />
  );
}
Item.propTypes = {
  item          : PropTypes.resource.isRequired,
  index         : PropTypes.number.isRequired,
  height        : PropTypes.number.isRequired,
  start         : PropTypes.number.isRequired,
  isActive      : PropTypes.bool,
  isSelected    : PropTypes.bool,
  getItemProps  : PropTypes.func.isRequired,
};
