import _ from 'lodash';
import { PropTypes } from '~/forms/utils';
import { FormButton } from '.';

export function CancelButton( props ) {
  const { config : { onCancel } } = props;
  const handleClick = () => {
    if ( onCancel && _.isFunction( onCancel ) ) return onCancel();
    return 'BACK';
  };
  return (
    <FormButton key="cancel" onClick={handleClick}>
      Cancel
    </FormButton>
  );
}
CancelButton.propTypes = {
  config  : PropTypes.formConfig.isRequired,
};
