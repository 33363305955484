import DB from '@ssp/database';
import { Page } from '~/layout';
import { CollectionView } from '~/views';

function handleBuilder( builder ) {
  builder.group( 'main' ).add(
    {
      path        : '',
      title       : 'My Coworkers',
      icon        : 'far:folder-open',
      component   : CollectionView,
      props       : () => ( {
        resultset : DB.SSP.User.find( {}, { minlength : false } ).coworkers(),
      } ),
    },
    {
      path        : 'all',
      title       : 'All Users',
      icon        : 'far:folder-open',
      component   : CollectionView,
      props       : () => ( {
        resultset : DB.SSP.User.find( {}, { minlength : true } ),
      } ),
    },
  );
}
export default function UsersPage() {
  return ( <Page title='Users' icon='far:users' builder={handleBuilder} /> );
}
