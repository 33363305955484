import _ from 'lodash';

export class DataItem {

  /**
   * Name of this item.  This is the key that will be retrieved from
   * the values to render this item.
   *
   * @type {string}
   */
  name;

  /**
   * Data type, this indicates which type renderer to use.
   *
   * @type {string}
   */
  type;

  /**
   * Label.
   *
   * @type {string}
   */
  label;

  /**
   * If this is true then this item won't be shown at all if the value
   * it represents is falsy.
   */
  hideFalse = false;

  /**
   * If this is true then this item won't be shown at all if the value
   * it represents is truthy.
   */
  hideTrue = false;

  /**
   * You can set this to true to indicate to some types of layouts
   * that this item should have it's label on the top instead of to
   * the side.
   */
  labelOnTop = false;

  /* Also note that any configuration options accepted by the various
   * type renderers can be passed here too.
   */

  /**
   * The DataView will put a reference to the appropriate rendering
   * component here when rendering.
   */
  component;

  constructor( opts ) {
    Object.assign( this, opts );
    [ 'name', 'type' ].forEach( x => {
      if ( ! this[ x ] ) throw new Error( `DataItem requires ${x}` );
    } );
    if ( ! this.label ) this.label = _.startCase( this.name );
  }

  static coerce( opts ) {
    return ( opts instanceof DataItem ) ? opts : new DataItem( opts );
  }
}
