import { Badge } from 'reactstrap';
import { useDebugFlag } from '~/hooks';
import { useMembers } from '../context';

export function DebugBadges() {
  const members = useMembers();
  const debug = useDebugFlag( 'show-members-details' );
  if ( ! debug ) return null;

  return (
    <>
      {[
        'is_saving',
        'is_loading',
        'is_loaded',
        'is_editing',
        'can_edit',
        'has_changes',
        'can_commit',
        'error',
      ].map( x => {
        if ( ! members[x] ) return null;
        return <Badge key={x} css={{ marginRight: '1em' }}>{x}</Badge>;
      } )}
    </>
  );
}
