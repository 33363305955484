import { Badge } from 'reactstrap';
import { Face } from '@ssp/database';

import { PropTypes } from '~/utils';
import { Card, CardHeader, CardBody, CardFooter } from '~/components';
import { useResource } from '~/hooks';
import { ActionMenu } from '~/actions';
import { ResourceView } from '~/views';
import { Progress } from '~/widgets';

export function ResourceCard( {
  resource : rsrc_in, hideActionMenu, face, ...props
} ) {
  const resource = useResource( rsrc_in, { label : 'ResourceCard' } );
  if ( ! resource ) return null;

  const title = resource.display_name
    || resource.findDisplayName()
    || 'UNKNOWN';
  const icon = resource.schema.icon;
  const link = resource.route();
  if ( ! face ) throw new Error( 'ResourceCard requires face' );
  // TODO - Make all this configurable as part of the face
  return (
    <Card {...props}>
      <CardHeader
        {...{ title, icon, link }}
        right={!hideActionMenu && <ActionMenu resource={resource} right />}
      />
      <CardBody className="overflow-auto">
        <ResourceView resource={resource} face={face} />
      </CardBody>
      <FooterSwitch resource={resource} />
    </Card>
  );
}
ResourceCard.propTypes = {
  resource       : PropTypes.resource.isRequired,
  face           : PropTypes.instanceOf( Face ).isRequired,
  className      : PropTypes.className,
  hideActionMenu : PropTypes.bool,
};
ResourceCard.defaultProps = {
  hideActionMenu : false,
};

function FooterSwitch( { resource } ) {
  switch ( resource.schema.id ) {
    case 'SYSTEM.Job' : return <JobFooter resource={resource} />;
    default : return null;
  }
}
FooterSwitch.propTypes = {
  resource  : PropTypes.resource.isRequired,
};

function JobFooter( { resource } ) {
  const {
    completionPct : pct, stepsCompleted, totalSteps,
  } = resource.getStepProgress();
  const { cssClassMap, cssColorMap } = resource.schema.model;
  const pill_color = cssClassMap[ resource.status ];
  const prog_color = cssColorMap[ resource.status ];
  return (
    <CardFooter className="px-2 py-2">
      <div className="d-flex justify-content-between">
        <div className="align-self-center">
          <Badge pill color={pill_color}>{resource.status}</Badge>
        </div>
        <span className="align-self-center text-muted">
          Step {stepsCompleted} of {totalSteps}
        </span>
        <Progress value={pct} color={prog_color} css={{ maxWidth : 30 }} />
      </div>
    </CardFooter>
  );
}
JobFooter.propTypes = {
  resource  : PropTypes.resource.isRequired,
};
