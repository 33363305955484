import { useResourceContext, usePromiseResult } from '~/hooks';
import { MembersView, Controls, SaveChangesButton } from './views';
import { MembersDebug } from './views/MembersDebug';
import { MembersContextProvider } from './context';

export function ManageMembers() {
  const resource = useResourceContext();
  if ( ! resource.schema.hasBehavior( 'hasMembers' ) ) {
    const name = resource.schema.plural_name;
    throw new Error( `${name} do not have members` );
  }

  return (
    <MembersContextProvider resource={resource}>
      <Controls />
      <MembersView />
      <MembersDebug />
      <SaveChangesButton />
    </MembersContextProvider>
  );
}
