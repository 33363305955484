import { normalize } from './utils';

export const globals = normalize( [
  {
    name        : 'projects',
    keys        : 'g p',
    description : 'My Projects',
    href        : '/projects',
    category    : 'Go To...',
  },
  {
    name        : 'users',
    keys        : 'g u',
    description : 'Users page',
    href        : '/users',
    category    : 'Go To...',
  },
  {
    name        : 'home',
    keys        : 'g /',
    description : 'Home Page',
    href        : '/',
    category    : 'Go To...',
  },
  {
    name        : 'profile',
    keys        : 'g m',
    description : 'My Profile',
    href        : '/profile',
    category    : 'Go To...',
  },
  {
    name        : 'jobs',
    keys        : 'g j',
    description : 'Jobs Page',
    href        : '/jobs',
    category    : 'Go To...',
  },
  {
    name        : 'help',
    keys        : 'g h',
    description : 'Help',
    href        : '/help',
    category    : 'Go To...',
  },
  {
    name        : 'shortcuts',
    keys        : '?',
    description : 'Show Shortcuts Help Sheet',
    category    : 'Miscellaneous',
    modal       : 'shortcuts',
  },
] );
