import _ from 'lodash';
import { formSubscriptionItems, fieldSubscriptionItems } from 'final-form';

export { formSubscriptionItems, fieldSubscriptionItems };
export const subscriptionItems = {
  form    : formSubscriptionItems,
  field   : fieldSubscriptionItems,
};

export const emptySubscription = {};
Object.freeze( emptySubscription );

function makeAll( items ) {
  const res = _.fromPairs( _.map( items, item => ( [ item, true ] ) ) );
  Object.freeze( res );
  return res;
}

export const allFormSubscriptionItems = makeAll( formSubscriptionItems );
export const allFieldSubscriptionItems = makeAll( fieldSubscriptionItems );

const allSubscriptions = {
  form  : allFormSubscriptionItems,
  field : allFieldSubscriptionItems,
};

export function makesub( items, defaultType ) {
  const subs = _makesub( items );
  if ( subs !== emptySubscription ) return subs;
  return allSubscriptions[ defaultType ];
}

function _makesub( ...items ) {
  const convert = ( item ) => {
    if ( _.isNil( item ) ) return;
    if ( _.isArray( item ) ) return _makesub( ...item );
    if ( _.isPlainObject( item ) ) return _.mapValues( item, Boolean );
    if ( _.isString( item ) ) return { [ item ] : true };
    throw new Error( `Invalid subscription item '${items}'` );
  };
  const apply = ( res, item ) => _.assign( res, convert( item ) );
  const res = _.reduce( items, apply, {} );
  if ( _.isEmpty( res ) ) return emptySubscription;
}

