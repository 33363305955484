import _ from 'lodash';
import {
  DB, Watchable, MemberInfo, MemberInfoPendingChange, Resource,
} from '@ssp/database';
import { hideProps } from '@ssp/utils';

import type { Members } from './Members';
import type { MembershipChange } from '@ssp/database';

export type Status =
  | 'error'
  | 'loading'
  | 'adding'
  | 'updating'
  | 'removing'
  | 'current';
export type Config = Record<string, unknown>;

export type MemberOptions = Partial<{
  user_id: string;
  name: string;
  email: string;
  memberinfo: MemberInfo;
  config: Config;
  showingEditor: boolean;
  showingDetail: boolean;
  user: DB.SSP.User;
  service_user_id: string;
}>;
export type UpdateOptions = MemberOptions & Partial<{
  is_loading: boolean;
  is_loaded: boolean;
  change_type: 'add' | 'remove' | 'update' | 'none';
  removed_change_type: 'add' | 'remove' | 'update' | 'none';
  error: string | Error;
}>;

export class Member extends Watchable {

  showingEditor: boolean = false;
  showingDetail: boolean = false;

  declare members: Members;

  declare user?: DB.SSP.User;
  /**
   * MemberInfo object. This will not be set if the member being
   * tracked was added during this session.
   */
  declare memberinfo?: MemberInfo;

  /* MemberInfoPendingChange */
  change_type: 'add' | 'remove' | 'update' | 'none' = 'none';
  service_user_id: string = '';
  config: Config = {};

  user_id: string = '';
  name: string = '';
  email: string = '';

  get status(): Status {
    if ( this.error ) return 'error';
    if ( this.is_loading ) return 'loading';
    if ( this.change_type === 'add' ) return 'adding';
    if ( this.change_type === 'update' ) return 'updating';
    if ( this.change_type === 'remove' ) return 'removing';
    return 'current';
  }

  get old_change(): MemberInfoPendingChange {
    return this.memberinfo?.pending_change;
  }

  get needs() {
    return _.reject( _.compact( [ 'email', 'user' ] ), x => this[x] );
  }

  get is_complete() { return _.isEmpty( this.needs ); }

  get user_type() { return this.members.user_type; }
  get MemberInfo() { return this.members.MemberInfo; }

  get key() { return this.user_id || this.email || this.name; }

  get has_changes() { return this.change_type !== 'none'; }

  get can_edit(): boolean {
    return this.members.can_edit
      && Boolean( this.error || this.members.config_field_names.length );
  }
  get can_commit() {
    if ( ! this.is_loaded ) return false;
    if ( ! this.has_changes ) return true;
    if ( this.error ) return false;
    return true;
  }
  getChange(): MembershipChange | undefined {
    if ( this.change_type === 'none' ) return;
    return {
      user_id         : this.user_id,
      change_type     : this.change_type,
      is_discovered   : false,
      service_user_id : this.service_user_id,
      config          : this.config,
    };
  }

  getValue( name: string ) {
    if ( this[ name ] ) return this[ name ];
    const value = this.config?.[ name ]
      ?? this.new_change?.[ name ]
      ?? this.old_change?.[ name ]
      ?? this.memberinfo?.[ name ];
    return value;
  }

  getDisplayValue( name: string ) {
    const value = this.getValue( name );
    return _.isArray( value ) ? value.join( ', ' ) : value;
  }

  // These get set automatically...
  is_loading = true;
  is_loaded = false;

  /**
   * Error message, if an error was raised while attempting to process
   * this user.
   */
  declare error: string;

  constructor( options: MemberOptions, members: Members ) {
    super();
    hideProps( this, { members } );
    this.update( options );
  }

  // eslint-disable-next-line complexity
  update( options: UpdateOptions, quietly: boolean = false ): number {
    const { error, user, memberinfo, user_id, config, ...opts } = options;

    let changes = 0;

    if ( error instanceof Error ) {
      changes += this.update( { error : error.message }, true );
    } else if ( _.isString( error ) ) {
      if ( this.error !== error ) {
        this.error = error;
        changes++;
      }
    }
    if ( user instanceof DB.SSP.User ) {
      if ( this.user !== user ) {
        this.user = user;
        changes++;
      }
      changes += this.update( {
        name    : user.display_name || user.name,
        email   : user.email,
        user_id : user._id,
      }, true );
    }
    if ( memberinfo && this.memberinfo !== memberinfo ) {
      this.memberinfo = memberinfo;
      changes++;
      changes += this.update( {
        name            : memberinfo.name,
        user_id         : memberinfo.user_id,
        service_user_id : memberinfo.service_user_id,
        user            : memberinfo.user,
      }, true );
    }

    if ( user_id && this.user_id !== user_id ) {
      this.user_id = user_id;
      changes++;
      this.deduplicate();
    }

    // memberinfo config field edits
    if ( config && ! _.isEqual( config, this.config ) ) {
      this.config = config;
      if ( !_.isEqual( this.config, this.memberinfo?.getConfig() ) ) {
        // edit is different from current memberinfo, notify update
        if ( this.change_type === 'none' ) this.change_type = 'update';
        changes++;
      } else {
        // edit is reverting pending change to current memberinfo
        this.change_type = 'none';
      }
    }

    _.each( opts, ( val, key ) => {
      if ( _.isNil( val ) ) return;
      if ( _.isEqual( this[ key ], val ) ) return;
      this[ key ] = val;
      changes++;
    } );

    if ( changes && ! quietly ) this.changed();
    return changes;
  }

  async changed(): Promise<any> {
    super.changed();
    this.members.member_changed();
  }

  get new_change() {
    if ( this.change_type === 'none' ) return;
    const config = { ...this.config };
    if ( this.service_user_id ) config.service_user_id = this.service_user_id;
    return MemberInfoPendingChange.create( {
      change_type   : this.change_type,
      is_discovered : false,
      config,
    } );
  }

  deduplicate() {
    const dupof = this.members.get( this.user_id );
    if ( dupof === this ) return; // We're not a duplicate
    _.pull( this.members.members, this );
  }

  get is_ready() {
    return ( this.is_loaded && this.is_complete ) || this.error;
  }

  async load() {
    try {
      const user = await this.getUser();
      await user.load( { cache : true } );
      this.update( { user, is_loaded : true, is_loading : false } );
    } catch ( error ) {
      log.warn( `ERROR loading user:`, error.stack );
      this.update( { error, is_loading : false } );
    }
  }

  toString() { return this.name || this.email || this.user_id; }

  reload = () => {
    this.update( { is_loaded : false, is_loading : true } );
    this.load();
  };

  async getUser() {
    if ( this.user ) return this.user;
    if ( this.memberinfo?.user ) return this.memberinfo.user;
    if ( this.user_id ) return DB.SSP.User.fromId( this.user_id );
    if ( this.email ) return DB.SSP.User.getByEmail( this.email );
  }
  async getServiceUser() {
    if ( ! this.members.need_service_user ) return;
    if ( this.memberinfo?.service_user ) return this.memberinfo.service_user;
    const type = this.user_type;
    if ( type === 'SSP.User' ) return;
    if ( this.service_user_id ) {
      return Resource.fromId( type, this.service_user_id );
    }
    return this.user.findOrCreateRelated( type );
  }

  getConfig() {
    return _.fromPairs( _.map( this.members.config_field_names, field => {
      return [ field, this.getValue( field ) ];
    } ) );
  }

  getEditableMemberInfo() {
    return this.MemberInfo.create( {
      user_id         : this.user._id,
      service_user_id : this.service_user_id,
      name            : this.user.name,
      ...this.getConfig(),
    } );
  }

  /** Returns true if the value matches the user_id or email of the member. */
  matches( value: string ): boolean {
    if ( ! _.isString( value ) ) return false;
    if ( value.includes( '@' ) ) {
      value = value.toLowerCase();
      return value === this.email.toLowerCase()
        || value === this.user?.username
        || this.user?.lower_emails?.includes( value );
    } else {
      return value === this.user_id;
    }
  }

  showEditor = () => this.update( { showingEditor : true } );
  hideEditor = () => this.update( { showingEditor : false } );
  toggleEditor = () => this.update( { showingEditor : ! this.showingEditor } );
  showDetail = () => this.update( { showingDetail : true } );
  hideDetail = () => this.update( { showingDetail : false } );
  toggleDetail = () => this.update( { showingDetail : ! this.showingDetail } );

  declare removed_change_type?: 'add' | 'remove' | 'update';
  remove = () => {
    if ( this.memberinfo ) {
      // If They were a member when the resource was loaded then we
      // just update them here to be a pending remove.
      this.update( {
        change_type         : 'remove',
        removed_change_type : this.change_type,
      } );
    } else {
      // If they weren't a member when the resource was loaded then it
      // means we added them and then removed them again, so in that
      // case we want to actually remove them from the list.
      this.members.nuke( this );
    }
  };
  unremove = () => this.update( { change_type : this.removed_change_type } );
}
