import _ from 'lodash';
import { useCallback } from 'react';

import { getChannels } from '~/app/sockets';
import { DebugPanel, DebugPropTable } from '~/widgets';
import {
  useDimensions, useAuthStore, useConnState, useBreakpoint,
} from '~/hooks';

const pr_url = `https://github.boozallencsn.com/SelfServicePortal/ssp/pull/`;
const ticket_url = `https://jira.boozallencsn.com/browse/`;

export function DebugPanels() {
  return (
    <>
      <BrowserInfo />
      <VersionInfo />
      <MetaInfo />
      <SubscriptionsDebugPanel />
    </>
  );
}

function BrowserInfo() {
  const dims = useDimensions();
  const breakpoint = useBreakpoint();
  const auth = useAuthStore();
  const conn = useConnState();

  const make_data = useCallback( () => ( {
    'Dimensions'              : _.at( dims, 'width', 'height' ).join( ' x ' ),
    'Breakpoint'              : breakpoint,
    'Connection State'        : conn,
    'Authentication State'    : auth.state,
    ...( auth.state === 'authenticated' ? {
      Subject      : String( auth.subject ),
      Impersonator : String( auth.impersonator ),
    } : {} ),
  } ), [ dims, breakpoint, auth, conn ] );

  return (
    <DebugPanel
      title='Browser Info'
      shortcut='D b'
      table={make_data}
    />
  );
}

function VersionInfo() {
  return (
    <DebugPanel
      title='Version Info'
      shortcut='D V'
      render={renderVersionInfo}
    />
  );
}

function renderVersionInfo() {
  const data: Record<string, string> = {};
  const { client, server } = SSP.meta;
  for ( const name of [ 'Version', 'Commit' ] ) {
    const key = name.toLowerCase();
    if ( _.isNil( client[ key ] ) ) continue;
    if ( client[ key ] === server[ key ] ) {
      data[ name ] = client[ key ];
    } else {
      data[ `Client ${name}` ] = client[ key ];
      data[ `Server ${name}` ] = server[ key ];
    }
  }
  const { logs, prs, tickets } = client;

  return (
    <>
      <DebugPropTable data={data} />
      {_.isArray( tickets ) && (
        <>
          <h1>Tickets</h1>
          <ul>
            {_.map( tickets, id => (
              <li key={id}><a href={ticket_url + id}>{id}</a></li>
            ) )}
          </ul>
        </>
      )}
      {_.isArray( prs ) && (
        <>
          <h1>Pull Requests</h1>
          <ul>
            {_.map( prs, id => (
              <li key={id}><a href={pr_url + id}>{id}</a></li>
            ) )}
          </ul>
        </>
      )}
      {_.isArray( logs ) && (
        <>
          <h1>Logs</h1>
          <ul>
            {_.map( logs, ( log, idx ) => (
              <li key={idx}>{log}</li>
            ) )}
          </ul>
        </>
      )}

    </>
  );
}

function MetaInfo() {
  return (
    <DebugPanel
      title='Server Metadata'
      shortcut='D M'
      table={SSP.meta}
    />
  );
}

function SubscriptionsDebugPanel() {

  return (
    <DebugPanel
      title='Subscription Info'
      shortcut='D s'
      table={() => ( { channels : getChannels() } )}
    />
  );
}
