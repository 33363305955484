import _ from 'lodash';
import { Row, Col } from 'reactstrap';
import { Panel } from '~/components';
import { useResourceContext } from '~/hooks';

export type ListPanelProps = {
  title: string;
  item: string;
};
export function ListPanel( { title, item }: ListPanelProps ) {
  const user = useResourceContext();
  return (
    <Panel title={`${title} (Only visible to Support)`} body>
      <ul>{_.map( user[ item ], i => <li key={i}>{i}</li> )}</ul>
    </Panel>
  );
}

export function ProjectIds() {
  const user = useResourceContext();
  return (
    <Panel title="Project Ids (Only visible to Support)" body>
      <Row>
        <Col>
          <h4>Member Project Ids</h4>
          <ul>{_.map( user.project_ids, i => <li key={i}>{i}</li> )}</ul>
        </Col>
        <Col>
          <h4>Admin Project Ids</h4>
          <ul>{_.map( user.admin_project_ids, i => <li key={i}>{i}</li> )}</ul>
        </Col>
      </Row>
    </Panel>
  );
}
