import { Page } from '~/layout';
import { PropTypes } from '~/utils';
import { useResourceResolver, useRouteParam } from '~/hooks';

import { JobView } from './JobView';

export default function JobPage( props ) {
  const job_id = useRouteParam( 'job_id' );
  const job = useResourceResolver( { type : 'SYSTEM.Job', id : job_id } );

  return (
    <Page resource={job} title={job?.display_name}>
      {job && <JobView job={job} />}
    </Page>
  );
}
JobPage.propTypes = {
  job_id : PropTypes.string,
};
