import { Page } from '~/layout';
import { useResourceResolver, useResource, useRouteParams } from '~/hooks';
import { ResourceView } from './ResourceView';

export default function ResourcePage() {
  const { resource_type : type, resource_id : id } = useRouteParams();
  const resource = useResourceResolver( { type, id } );
  const owner = useResource( resource?.owner, {
    label : 'ResourcePage+owner',
  } );

  return (
    <Page resource={resource} addl_resources={[ owner ]}>
      <ResourceView resource={resource} owner={owner} />
    </Page>
  );
}
