import _ from 'lodash';
import { Alert } from '~/widgets';
import { PropTypes } from '~/forms/utils';

export function Message( { field } ) {
  const message = _.flattenDeep( _.castArray( field.message ) ).join( ' ' );
  if ( field.color ) {
    return <Alert color={field.color}>{message}</Alert>;
  } else {
    return <span>{message}</span>;
  }
}
Message.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
