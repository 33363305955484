import _ from 'lodash';

/* palette from offical product identity guidelines */
const colors = {
  primary_dark      : '#253746', // dark slate blue
  slight_pop        : '#2f7394', // lighter slate blue
  slight_pop_darker : '#21546d', // a few shades
  pop               : '#21cde0',
  pop_darker        : `#09A4B5`, // a few shades
  rust              : '#de5f20',
  dark_rust         : '#ab3700',
  dark_gray         : '#5a5656',
  very_light_gray   : '#eaeaea',
  very_light_green  : '#eeffee',
  light_gray        : '#bfbfbf',
  white             : 'white',
  black             : 'black',
};

export const theme = {
  colors,
  breadcrumbs : {
    caret    : colors.light_gray,
    inactive : colors.light_gray,
  },
  table : {
    thead : {
      bg : colors.primary_dark,
      fg : colors.white,
    },
    tbody : {
      bg : colors.white,
      fg : colors.primary_dark,
      tr : {
        success : {
          bg: colors.very_light_green,
        },
        inactive : {
          bg: colors.very_light_gray,
        },
        error : {
          bg: '#fff6f6',
        },
      },
    },
  },
  navbar : {
    bg      : colors.primary_dark,
    fg      : colors.white,
    active  : colors.rust,
    // hover   : colors.dark_rust,
  },
  routertabs  : {
    bg      : colors.white,
    fg      : colors.primary_dark,
    active  : { fg : colors.white, bg : colors.primary_dark },
    hover   : colors.dark_rust,
    border  : colors.very_light_gray,
  },
  cards   : {
    header     : { bg : colors.primary_dark, fg : colors.white },
    body       : { bg : colors.white, fg : colors.primary_dark },
    footer     : { bg : colors.white, fg : colors.primary_dark },
    footer_pop : { bg : colors.white, fg : colors.slight_pop },
  },
  list : { icon : colors.light_gray },
  panels  : {
    header  : { bg : colors.primary_dark, fg : colors.white },
    body    : { bg : colors.white, fg : colors.primary_dark },
    footer  : { bg : colors.white, fg : colors.primary_dark },
  },
  actionmenu  : {
    bg      : colors.primary_dark,
    fg      : colors.white,
    active  : colors.rust,
    hover   : colors.dark_rust,
  },
  markdown  : {
    bg      : colors.white,
    text    : colors.primary_dark,
    strong  : colors.primary_dark,
    bullet  : colors.pop,
    hr      : '#112233',
    code    : '#00d850',
    header  : colors.primary_dark,
  },
  buttons : {
    fg     : colors.slight_pop,
    bg     : colors.white,
    bg_active : colors.very_light_gray,
    border : `1px solid ${colors.light_gray}`,
  },
  dataview : {
    label : colors.slight_pop,
  },
  badge : {
    bg : colors.slight_pop,
  },
  icon : {
    fg : colors.slight_pop,
  },
  leftside  : {
    width   : '220px',
  },
  rightside : {
    width   : '350px',
  },
  defaults    : {
    bg        : colors.white,
    bg_pop    : colors.pop,
    active    : { bg : colors.rust, fg : colors.black },
    hover     : { bg : colors.dark_rust, fg : colors.black },
  },
  text         : colors.primary_dark,
  heading      : colors.slight_pop,
  shadow       : `1px 1px ${colors.primary_dark}`,
  content_pane : colors.very_light_gray,
  get( key, defaultValue ) { return _.get( theme, key, defaultValue ); },
} as const;

export default theme;
