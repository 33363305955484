import _ from 'lodash';
import { PropTypes } from '~/utils';
import { Container, Row, Col } from 'reactstrap';


export function PrettyObject( props ) {
  const { value } = props;
  if ( ! value ) return null;
  if ( ! _.isObject( value ) ) return null;
  return (
    <Container>
      {_.map( value, ( val, key ) => {
        const label = _.capitalize( key ) + ':' ;
        if ( _.isArray( val ) ) val = val.join( ', ' );
        return (
          <Row >
            <Col xs="12" sm="auto" style={{ paddingLeft : 0 }}>{label}</Col>
            <Col xs="auto">{val}</Col>
          </Row>
        );
      } )}
    </Container>
  );
}
PrettyObject.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value : PropTypes.any.isRequired,
};
