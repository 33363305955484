import _ from 'lodash';
import { PropTypes } from '~/utils';
import { Icon } from '~/icons';
import { DataItem } from '../DataItem';
import { Badge as BadgeRS } from 'reactstrap';
import styled from '@emotion/styled';

const Badge = styled( BadgeRS, {
  shouldForwardProp : prop => _.isFunction( BadgeRS.propTypes[ prop ] ),
} )( ( { theme, color } ) => {
  const style = [ 'margin-right: 3px;' ];
  if ( ! color ) style.push( `background-color: ${theme.badge.bg};` );
  return style;
} );

export function Badges( { items } ) {
  const badgeitems = _.compact( _.map( items, item => {
    const badge = _.defaults( {}, item.badge, {
      name    : item.name,
      value   : true,
      label   : item.label,
      invert  : false,
    } );
    const display = badge.invert
      ? ( Boolean( item.value ) !== Boolean( badge.value ) )
      : ( Boolean( item.value ) === Boolean( badge.value ) );
    if ( !display ) return;
    const { name, color, icon, label } = badge;

    return (
      <Badge key={name} color={color} pill tag='li'>
        { icon ? <Icon icon={icon} label={label} /> : label }
      </Badge>
    );
  } ) );
  if ( badgeitems.length === 0 ) return null;
  return <ul className="ml-0 pl-0">{badgeitems}</ul>;
}

Badges.propTypes = {
  items : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
};
