import _ from 'lodash';
import { useResultSetContext } from '~/context';
import { ActionMenu } from '~/actions';

export function Actions() {
  const { resultset } = useResultSetContext();
  if ( _.isNil( resultset ) ) return null;
  return (
    <ActionMenu button label="Actions" icon="far:cogs" resource={resultset} />
  );
}
