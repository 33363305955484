import { Tooltip as TooltipRS } from 'reactstrap';
import { PropTypes } from '~/utils';
import styled from '@emotion/styled';


const TooltipWrapper = styled( TooltipRS )( ( { theme } ) => {
  return `
    .tooltip-inner {
      background-color: ${theme.defaults.bg};
      color: ${theme.text};
      border: thin solid ${theme.content_pane};
    }
    .tooltip.show {
      opacity: 1;
      z-index: 10000;
    }
    .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      border-top-color: ${theme.content_pane};
    }
    .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      border-right-color: ${theme.content_pane};
    }
    .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      border-bottom-color: ${theme.content_pane};
    }
    .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      border-left-color: ${theme.content_pane};
    }
    `;
} );


export function Tooltip( props ) {
  if ( ! props.children ) return null;
  return <TooltipWrapper {...props} />;
}
// Any reactstrap tooltip proptypes
// https://reactstrap.github.io/components/tooltips/
Tooltip.propTypes = {
  target : PropTypes.oneOfType( [
    PropTypes.string, PropTypes.ref,
  ] ).isRequired,
  children  : PropTypes.children,
};
