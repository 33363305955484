import { Button, Popover, PopoverBody } from 'reactstrap';
import { useToggle, useResource } from '~/hooks';
import { css } from '@emotion/react';
import { PropTypes } from '~/utils';
import { ResourceBreadcrumb } from './ResourceBreadcrumb';

const style = css`
  .popover {
    min-width: 300px;
    max-width: 375px;
    font-size: 1rem;
    .popover-body > a {
      padding : unset;
      display : inline;
    }
  }
`;

export function OwnedResourceBreadcrumb( props ) {
  const resource = useResource( props.resource, {
    label : 'OwnedResourceBreadcrumb',
  } );
  const [ isOpen, toggle ] = useToggle( false );

  const target = `bc-pop-${resource._id}`;
  return (
    <>
      <Button id={target} className="bc-label">
        {resource.display_name}
      </Button>
      <Popover
        css={style}
        trigger="legacy"
        placement='bottom'
        isOpen={isOpen}
        toggle={toggle}
        target={target}
      >
        {isOpen && (
          <PopoverBody data-testid={`bc-pop-body-${resource._id}`}>
            The {resource.schema.name} {' '}
            <ResourceBreadcrumb resource={resource} />
            {' '}
            belongs to a different {props.owner.schema.name},{' '}
            <ResourceBreadcrumb resource={props.owner} />.
          </PopoverBody>
        )}
      </Popover>
    </>
  );
}
OwnedResourceBreadcrumb.propTypes = {
  resource  : PropTypes.resource.isRequired,
  owner     : PropTypes.resource.isRequired,
};
