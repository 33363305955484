import _ from 'lodash';
import { createStore } from '@ssp/utils';
import { useStore, useEffect, useSettings } from '~/hooks';
import { TourId, useAutoLaunchTour, useMarkLastStepFinished } from './hooks';
import { TourProvider as ReactourTourProvider,
  withTour, TourProps } from '@reactour/tour';
import { tourMetadata } from './tour-metadata';
import { PropTypes } from '~/utils';

export * from './hooks';

const store = createStore( {
  name        : 'active-tour',
  description : 'Active Tour Store',
  state       : null,
  public      : {
    setActive( tourId : TourId ) {
      this.setState( tourId );
    },
    close() { this.setState( null ); },
  },
} );

declare module '@ssp/preload' {
  export interface SSP {
    tour: {
      setActive( tourId: TourId ): void;
      close(): void;
    };
  }
}

SSP.tour = {
  setActive : store.setActive,
  close     : store.close,
};

function Tours( props : TourProps ) : null {
  const { setIsOpen, setSteps, isOpen } = props;
  const autoLaunchTourIds : TourId[] = useAutoLaunchTour();
  useMarkLastStepFinished();

  useEffect( () => {
    const loadSteps = async ( tourId ) => {
      const { default : loadedSteps } = await import( `./steps/${tourId}` );
      setSteps( loadedSteps );
      SSP.tour.setActive( tourId );
      setIsOpen( true );
    };
    if ( !isOpen && autoLaunchTourIds?.length > 0 ) {
      loadSteps( _.first( autoLaunchTourIds ) );
    }
  }, [
    setSteps, autoLaunchTourIds, setIsOpen, isOpen,
  ] );

  return null;
}
export default withTour( Tours );

export function TourProvider( props ) {
  const tourId : TourId = useStore( 'active-tour' );
  const settings = useSettings();

  const handleBeforeClose = ( { setCurrentStep, setIsOpen } ) => {
    setCurrentStep( 0 );
    setIsOpen( false );
    SSP.tour.close();
  };

  const handleAfterOpen = async () => {
    await settings?.updateTourHistory( {
      id: tourId,
      status: 'started',
      version: tourMetadata[ tourId ].version,
    } );
  };

  return (
    <ReactourTourProvider
      steps={[]}
      onClickClose={handleBeforeClose}
      onClickMask={handleBeforeClose}
      afterOpen={handleAfterOpen}
      showBadge={false}
    >
      { props?.children }
    </ReactourTourProvider>
  );
}
TourProvider.propTypes = {
  children : PropTypes.node,
};
