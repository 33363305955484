import _ from 'lodash';
import { stringifyError } from '@ssp/utils';
import { PropTypes } from '~/utils';
import { Link as RouterLink } from '~/router';
import { useResource } from '~/hooks';

export function Link( props ) {
  const rsrc = useResource( props.value, { safe : true, label : 'Link' } );
  if ( ! rsrc ) return null;
  if ( _.isError( rsrc ) ) {
    const err = stringifyError( rsrc );
    return (
      <span>
        <RouterLink to={props.value} />
        {' '}
        <span className="text-danger">({err})</span>
      </span>
    );
  } else {
    return <RouterLink to={rsrc} />;
  }
}
Link.propTypes = {
  value : PropTypes.resource.isRequired,
};
