import _ from 'lodash';
import { Fragment } from 'react';
import { useFormState, PropTypes } from '~/forms/utils';
import { Alert } from '~/widgets';
import { FORM_ERROR /* , ARRAY_ERROR */ } from 'final-form';

export function FormError( { form, config } ) {
  const { valid } = useFormState( 'invalid' );
  if ( valid ) return null;
  const state = form.getState();
  const { error } = state;
  let { submitError, submitErrors } = state;
  const fields = form.getRegisteredFields();
  if ( _.isString( submitErrors ) ) {
    if ( _.isString( submitError ) ) {
      submitError += `\n${submitErrors}`;
    } else {
      submitError = submitErrors;
    }
    submitErrors = {};
  }
  // There isn't a valid way to get orphaned validation errors, so we
  // only look at submitErrors to find orphans.  This is because if
  // the field isn't part of the form then nothing except submission
  // will attempt to validate it.
  const orphans = _.omit( submitErrors, ...fields, FORM_ERROR );
  return (
    <Fragment>
      <ValidationError error={error} />
      <SubmitError error={submitError} />
      <OrphanErrors errors={orphans} />
    </Fragment>
  );
}
FormError.propTypes = {
  config  : PropTypes.formConfig.isRequired,
  form    : PropTypes.formApi.isRequired,
};

function ErrorAlert( { label, children } ) {
  return (
    <Alert color="danger" key={label}>
      <h3>{label}</h3>
      {children}
    </Alert>
  );
}
ErrorAlert.propTypes = {
  label     : PropTypes.string.isRequired,
  children  : PropTypes.node.isRequired,
};

function ValidationError( { error } ) {
  if ( ! error ) return null;
  log.error( 'Validation Error:', error );
  return <ErrorAlert label="Validation Error">{error}</ErrorAlert>;
}
ValidationError.propTypes = { error : PropTypes.string };

function SubmitError( { error } ) {
  if ( ! error ) return null;
  log.error( 'Submission Error:', error );
  return <ErrorAlert label="Submission Error">{error}</ErrorAlert>;
}
SubmitError.propTypes = { error : PropTypes.string };

function OrphanErrors( { errors } ) {
  if ( _.isEmpty( errors ) ) return null;
  log.error( 'Orphan Errors:', errors );
  return (
    <ErrorAlert label="Errors in Hidden Fields">
      <dl>
        {_.map( errors, ( error, field ) => (
          <Fragment key={field}>
            <dt>{field}</dt>
            <dd>{error}</dd>
          </Fragment>
        ) )}
      </dl>
    </ErrorAlert>
  );
}
OrphanErrors.propTypes = { errors : PropTypes.objectOf( PropTypes.string ) };
