import { ListGroupItem } from 'reactstrap';
import { cx, PropTypes, useFieldError } from '~/forms/utils';
import {
  FieldInput, FieldError, ArrayItemTools, FieldErrorBoundary,
} from '..';

export function ArrayElement( { field, config, array, index } ) {
  const error = useFieldError( field.name );
  const classes = cx( field.groupClassName, { 'has-error' : error } );
  return (
    <FieldErrorBoundary field={field}>
      <ListGroupItem className={classes}>
        <div className='d-flex justify-content-between'>
          <FieldInput field={field} />
          { ' ' }
          <ArrayItemTools field={field} array={array} index={index} />
        </div>
        <FieldError field={field} />
      </ListGroupItem>
    </FieldErrorBoundary>
  );
}
ArrayElement.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  config  : PropTypes.formConfig.isRequired,
  array   : PropTypes.shape( {} ).isRequired,
  index   : PropTypes.number.isRequired,
};
