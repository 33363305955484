import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import { PropTypes } from '~/utils';
import { DataItem } from '../DataItem';
import { Link } from '~/router';
import { Icon } from '~/icons';

const row_css = css`
  line-height: 1.1;
  ul { margin-bottom: 0; }
`;

const Title = styled( Link )( ( { theme } ) => css`
  margin-bottom: 0;
  a { color: ${theme.colors.slight_pop}; }
  a:hover {
    color: ${theme.colors.slight_pop_darker};
    text-decoration: none;
  }
  color: ${theme.colors.slight_pop};
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
` );

function Item( props_in ) {
  const { item } = props_in;
  const { component : Comp, name, value, ...props } = item;
  return ( <Comp key={name} name={name} {...props} value={value} /> );
}
Item.propTypes = {
  item : PropTypes.instanceOf( DataItem ).isRequired,
};

export function ItemsBetweenTitleAndIcons( props ) {
  const { items=[], resource, iconConfigs=[], title } = props;
  const middleItems = items.map( item => <Item key={item.name} item={item} /> );

  return (
    <div className="d-flex justify-content-between" css={row_css}>
      <Title className="flex-grow-1" to={resource} title={title} />
      {middleItems}
      <IconBar icons={iconConfigs} resource={resource} />
    </div>
  );
}
ItemsBetweenTitleAndIcons.propTypes = {
  items      : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
  resource    : PropTypes.record.isRequired,
  iconConfigs : PropTypes.arrayOf( PropTypes.shape( {
    name  : PropTypes.string,
    value : PropTypes.string,
    icon  : PropTypes.string,
  } ) ),
  title       : PropTypes.string,
};

function IconBar( props ) {
  const { icons } = props;
  const theme = useTheme();

  if ( ! icons ) return null;

  const result = icons.map( ( item ) => {
    const { name, icon, value, to } = item;

    if ( to ) {
      return (
        <Link to={to} key={name}>
          <Icon
            className="ml-2" color={theme.list.icon}
            icon={icon} label={value}
          />
        </Link>
      );
    }

    return (
      <Icon className="ml-2" key={name}
        icon={icon} label={value}
      />
    );
  } );

  return (
    <div className="d-flex justify-content-between pr-2">
      {result}
    </div>
  );
}
IconBar.propTypes = {
  icons : PropTypes.arrayOf( PropTypes.shape( {
    name  : PropTypes.string,
    value : PropTypes.string,
    icon  : PropTypes.string,
  } ) ),
  resource : PropTypes.record.isRequired,
};
