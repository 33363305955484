import _ from 'lodash';
import PropTypes from './PropTypes';
import debug from 'debug';
import { createStore, getStore } from '@ssp/utils';
import { useStore } from '~/hooks';

const descriptions = {};

function squish( ...args ) {
  return _.compact( _.uniq( _.flattenDeep( args ) ) );
}

const store = createStore( {
  name        : 'debug-flags',
  description : 'Debug Flags Store',
  initialize() {
    const init_state = {};
    if ( ! localStorage.debug ) return init_state;
    localStorage.debug.split( ',' ).forEach( key => {
      if ( key.startsWith( '-' ) ) {
        init_state[ key.slice( 1 ) ] = '-';
      } else {
        init_state[ key ] = '+';
      }
    } );
    return init_state;
  },
  reduce        : 'merge',
  public        : {
    toString() {
      return _.compact( _.map( this.current(), ( val, key ) => {
        if ( val === '+' ) return key;
        if ( val === '-' ) return '-' + key;
      } ) ).join( ',' );
    },
    update( state, flags ) {
      this.setState( _.fromPairs( _.map( squish( flags ), flag => (
        [ flag, state ]
      ) ) ) );
      this.save();
    },
    save() {
      localStorage.debug = this.toString();
      debug.load();
    },
    enable( ...flags ) { return this.update( '+', flags ); },
    disable( ...flags ) { return this.update( '-', flags ); },
    clear( ...flags ) { return this.update( '', flags ); },
    describe( name, description ) {
      if ( ! description ) return;
      if ( descriptions[ name ] ) return;
      descriptions[ name ] = description;
    },
    toggle( flag ) {
      this.setState( prev => ( { [ flag ] : prev[ flag ] ? '' : '+' } ) );
    },
  },
} );
SSP.debug = _.pick( store, [
  'enable', 'disable', 'clear', 'describe', 'toggle', 'current',
] );

export function useDebugFlag( name, description ) {
  getStore( 'debug-flags' ).describe( name, description );
  return _.get( useStore( 'debug-flags' ), name ) === '+';
}
export function toggleDebugFlag( name ) {
  getStore( 'debug-flags' ).toggle( name );
}

export function DebugFlag( props ) {
  const { flag, description, component : Component, render, children } = props;
  if ( ! useDebugFlag( flag, description ) ) return null;
  if ( Component ) return <Component />;
  if ( render ) return render();
  return children;
}
DebugFlag.propTypes = {
  flag        : PropTypes.string.isRequired,
  description : PropTypes.string,
  component   : PropTypes.elementType,
  render      : PropTypes.func,
  children    : PropTypes.node,
};
