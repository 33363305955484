import _ from 'lodash';
import { Button } from 'reactstrap';
import { useSubject, useImpersonator } from '~/hooks';

export function ImpersonatingBar() {
  const subject = useSubject();
  const impersonator = useImpersonator();
  if ( ! impersonator ) return null;
  if ( impersonator._id === subject._id ) return null;
  const stop = () => SSP.auth.impersonate( null );
  return (
    <Button block color="warning" onClick={stop} data-testid="impersonating">
      You are currently <b>impersonating</b> {' '}
      {_.get( subject, 'name', 'someone' )}
      {' '} -- {' '}
      Click here to go back to being {' '}
      {_.get( impersonator, 'name', 'yourself' )}!
    </Button>
  );
}
