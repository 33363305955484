import { PropTypes, useFormState } from '~/forms/utils';
import { FormButton } from '.';

export function SubmitButton( props ) {
  const { submitting } = useFormState( 'submitting' );

  return (
    <FormButton
      type="submit"
      color="primary"
      disabled={submitting}
    >Submit</FormButton>
  );
}
SubmitButton.propTypes = {
  config  : PropTypes.formConfig.isRequired,
};
