import { Fragment } from 'react';
import { PropTypes } from '~/utils';
import { ActionPanel } from '~/actions';
// import { SupportOnly, DataPanel } from '~/widgets';

export function SummaryPage( props ) {
  const { component : comp } = props;
  return (
    <Fragment>
      <ActionPanel resource={comp} />
      {/*
      <Panel title="Summary" body>
        <ResourceView resource={rsrc} face='summary' view='panel' />
      </Panel>
      <SupportOnly>
        <DataPanel
          title="Raw Data (Only visible to Support)"
          name={comp.schema.name}
          data={comp.schema.toJSON()}
        />
      </SupportOnly>
      */}
    </Fragment>
  );
}
SummaryPage.propTypes = {
  component   : PropTypes.model.isRequired,
};
