import _ from 'lodash';
import { useMemo } from 'react';
import { cx, PropTypes } from '~/utils';
import { DataItem } from './DataItem';
import { isComponent } from '@ssp/database';

import * as layouts from './layouts';
import * as types from './types';

export function DataView( props_in ) {
  const layout = _.isString( props_in.layout )
    ? { type : props_in.layout } : props_in.layout;
  const { iconize } = layout;
  const { values, items : _items, nil, resource, ...props } = props_in;

  const title = props_in.title || resource.findDisplayName();

  const iconConfigs = useMemo( () => {
    return makeIconConfigs( iconize, resource );
  }, [ iconize, resource ] );

  const classes = cx( 'data-view', props.className );
  const items = useMemo( () => _.compact( _.map( _items, item => {
    if ( ! item.name ) return null;
    if ( isComponent( values[ item.name ] ) ) return;
    const value = _.result( values, item.name );
    if ( ( _.isNil( value ) || value === '' ) && ! nil ) return;
    if ( item.hideFalse && ! value ) return;
    if ( item.hideTrue && value ) return;
    if ( ! ( item instanceof DataItem ) ) item = new DataItem( item );
    item.component = _.isNil( value )
      ? types.Nil : types[ item.type ] || types.Any;
    item.value = value;
    return item;
  } ) ), [ _items, values, nil ] );

  if ( _.isPlainObject( layout ) && _.isString( layout.type ) ) {
    const { type, ...conf } = layout;
    const Layout = layouts[ type ];
    return (
      <Layout {...conf} className={classes}
        items={items}
        resource={resource}
        iconConfigs={iconConfigs}
        title={title}
      />
    );
  } else {
    throw new Error( `Invalid layout "${layout}"` );
  }
}

const typeProp = PropTypes.oneOf( _.keys( layouts ) );
DataView.propTypes = {
  layout    : PropTypes.oneOfType( [
    typeProp,
    PropTypes.shape( { type : typeProp.isRequired } ),
  ] ).isRequired,
  items     : PropTypes.arrayOf( PropTypes.oneOfType( [
    PropTypes.instanceOf( DataItem ),
    PropTypes.shape( {
      name  : PropTypes.string.isRequired,
      type  : PropTypes.string.isRequired,
    } ).isRequired,
  ] ) ).isRequired,
  values    : PropTypes.shape().isRequired,
  className : PropTypes.className,
  nil       : PropTypes.bool,
};
DataView.defaultProps = {
  layout  : 'PropTable',
  nil     : false,
};

function makeIconConfigs( iconize, resource ) {
  const getFaceConfig = ( fieldName ) => {
    return resource.schema.fields[ fieldName ]?.face;
  };

  const fieldsAsIcons = [];

  if ( iconize ) {
    iconize.forEach( iconField => {
      const face = getFaceConfig( iconField );

      // take config from schema BooleanIcon
      if ( _.isString( iconField ) && face && face.type === 'BooleanIcon' ) {
        const value = resource[ iconField ];
        fieldsAsIcons.push( {
          value : face[ `${value}Label` ],
          icon  : face[ `${value}Icon` ],
          name  : iconField,
        } );
        return;
      }

      // take config from face config
      if ( _.isObject( iconField ) ) {
        const { fieldName } = iconField;
        const fieldType = resource.schema.fields[ fieldName ].type.name;
        const value = resource[ fieldName ];

        // abort when value nil and not a number
        if ( _.isNil( value ) && fieldType !== 'number' ) return;

        if ( fieldType === 'link' ) {
          const { ref_url, name, schema } = value;
          fieldsAsIcons.push( {
            value : `${name} (${schema.name})`,
            icon  : schema.icon,
            name  : fieldName,
            to    : ref_url,
          } );
          return;
        }

        // dynamic icon based on field value
        if ( _.has( iconField, 'trueIcon' )
          && _.has( iconField, 'falseIcon' )
          && _.has( iconField, 'label' )
        ) {
          const { label } = iconField;

          fieldsAsIcons.push( {
            value : label,
            icon  : iconField[ `${value}Icon` ],
            name  : fieldName,
          } );
          return;
        }

        const { icon } = iconField;

        fieldsAsIcons.push( {
          value : value?.toString(),
          icon,
          name : fieldName,
        } );

        return;
      }

      throw new Error(
        `Field could not be converted to icon: '${iconField}'`,
      );
    } );
  }

  if ( resource.schema.behaviors ) {
    if ( resource.schema?.behaviors?.hasMembers ) {
      fieldsAsIcons.push( {
        value : resource.members.size.toString() || '0',
        icon  : 'far:user',
        name  : 'hasMembers',
      } );
    }

    if ( resource.schema?.behaviors?.hasExternalLink
      && resource.external_link ) {
      fieldsAsIcons.push( {
        value : resource.schema.name,
        icon  : 'fad:external-link',
        name  : 'hasExternalLink',
        to    : resource.external_link,
      } );
    }
  }

  return fieldsAsIcons;
}
