import { Icon } from '~/icons';
import { PropTypes } from '~/utils';

export function BooleanIcon( props ) {
  const value = props.value ? 'true' : 'false';
  const style = { color : props[ `${value}Color` ] };
  const icon = props[ `${value}Icon` ];
  const label = props[ `${value}Label` ];
  return <Icon style={style} label={label} icon={icon} />;
}
BooleanIcon.propTypes = {
  value          : PropTypes.bool.isRequired,
  trueIcon       : PropTypes.string,
  trueStyle      : PropTypes.string,
  falseIcon      : PropTypes.string,
  falseColor     : PropTypes.string,
  trueLabel      : PropTypes.string,
  falseLabel     : PropTypes.string,
};
BooleanIcon.defaultProps = {
  trueIcon       : 'fas:check-circle',
  trueColor      : 'green',
  falseIcon      : 'fas:times-circle',
  falseColor     : 'red',
};
