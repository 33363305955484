import _ from 'lodash';
import { useStore } from './useStore';
import { useResource } from './useResource';

import type { UseResourceOptions } from './useResource';

export function useSettings( options: UseResourceOptions = {} ) {
  const store = useStore( 'auth' );
  return useResource( store?.settings, {
    label : 'useSettings',
    safe  : true,
    ...options,
  } );
}

export function useSetting( key, defaultValue ) {
  return _.get( useSettings(), key, defaultValue );
}
