import { PropTypes, useFormState } from '~/forms/utils';
import { FormButton } from '.';

export function ResetButton( props ) {
  const { submitting, pristine } = useFormState( 'submitting', 'pristine' );
  return (
    <FormButton
      onClick={handleClick}
      disabled={pristine || submitting}
    >Reset</FormButton>
  );
}
ResetButton.propTypes = {
  config  : PropTypes.formConfig.isRequired,
};

async function handleClick( { form } ) {
  form.reset();
}
