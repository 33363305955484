import _ from 'lodash';
import { useState } from 'react';
import { Schema } from '@ssp/database';
import {
  Container, Row, Col,
  Button as ButtonRaw,
} from 'reactstrap';
import InlineSvg from 'react-svg-inline';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Page } from '~/layout';
import { Modal, Markdown } from '~/components';
import { useMemo, useRouter } from '~/hooks';
import { Icon } from '~/icons';
import { PropTypes } from '~/utils';
import diagram from './SSP-Diagram.png';
// import interactiveLogo from './Interactive.png';
import people from './people.jpeg';
import { theme } from '~/layout/theme';

const schemas = Schema.filter( 'is_service', '!hideOnAbout', 'name', schema => {
  return schema.hasMarkdown( 'details' ) && ( schema.logo || schema.icon );
} );

const GrayPanel = styled.div`
  background-color: ${theme.colors.very_light_gray};
`;

const PopButton = styled( ButtonRaw )( css`
  background-color: ${theme.colors.pop_darker};
  &:hover {
    background-color: ${theme.colors.pop};
  }
  border : none;
` );

function Greeting() {
  return (
    <div
      css={{
        backgroundImage : `url('${people}')`,
        backgroundPosition : 'center',
        backgroundSize : 'cover',
        backgroundRepeat : 'no-repeat',
      }}
      className="d-flex justify-content-center px-5 py-5 text-white"
    >
      <div css={{ backgroundColor : 'rgba(37, 56, 70, 0.9)' }}
        className="p-5 rounded"
      >
        <h1 className="mb-0">Self Service Portal</h1>
        <h5 css={{ color : theme.colors.pop }}>
          Development | DevOps | Collaboration Setup
        </h5>
        <p className="py-3">
          Self Service Portal (SSP) helps project managers quickly and
          automatically stand up a suite of services that enhance productivity
          for their team. With SSP people can easily provision Confluence
          Spaces, Jira Projects, GitHub Organizations, and Slack Channels, and
          share these services among their teammates.
        </p>

        <a href="https://ssp-info.bah.com">
          <PopButton className="text-lg">
            Learn more in the <strong>SSP SharePoint Site</strong>
            &nbsp;<Icon icon="fad:external-link" />
          </PopButton>
        </a>

      </div>
    </div>
  );
}

const sspProjectInfo =
  'SSP Project is a container that groups related ' +
  'resources (such as GitHub Org, Jira Project, etc.).';

const resourceInfo =
  'A resource is a generic term for any asset that can be found in ' +
  'an SSP Project, including, but not limited to GitHub Orgs, GitHub ' +
  'Users, SSP Teams, and even API keys';

const teamsInfo =
  'A team is essentially a user group where you can add/remove people.';

/*
const teamLinksInfo =
  'Team links are ways to connect a team to another SSP Resource. For ' +
  'example, you can create a "Users" team. Then you can create a team link ' +
  'from the "Users" team to a Jira project. Any user added/removed from ' +
  'the "Users" team will also automatically be added/removed from the Jira ' +
  'project.';
*/

function HowItWorks() {
  return (
    <GrayPanel className="py-5" css={{ position : 'relative' }}>
      <div className="d-flex justify-content-center">
        <h1 className="mb-0 text-muted">
          How It Works
        </h1>
      </div>
      <div className="d-flex justify-content-center p-3">
        <img css={{ maxWidth: '875px' }}
          className="mx-auto w-100" src={diagram} alt="SSP Diagram"
        />
      </div>
      <div className="w-100" css={{ position : 'absolute', bottom : '40px' }} >
        <ArrowNextSection />
      </div>
    </GrayPanel>
  );
}

function SimpleStep( { label, ...props } ) {
  return (
    <Col xs="12" sm="12" md="4">
      <GrayPanel
        className="d-flex flex-row align-items-center p-5 rounded"
      >
        <div css={{ fontSize: '6rem', color : theme.colors.pop_darker }}
          className="pr-3"
        >
          {label}
        </div>
        <div css={{
          fontSize: '1.5rem', fontWeight: 'lighter',
          lineHeight : 'normal',
        }} className="d-flex align-items-center"
        >
          <div>
            {props.children}
          </div>
        </div>
      </GrayPanel>
    </Col>
  );
}
SimpleStep.propTypes = {
  label    : PropTypes.string.isRequired,
  children : PropTypes.node,
};

function SimpleSteps() {
  return (
    <div className="py-5">
      <div className="d-flex justify-content-center">
        <h1 className="mb-0 text-muted">
          It takes just&nbsp;
          <span className="text-info">3 simple steps</span>&nbsp;!
        </h1>
      </div>
      <Container className="p-3 mt-2">
        <Row className="gx-5 gy-5">
          <SimpleStep label="1">
            Create an <abbr title={sspProjectInfo}>SSP Project</abbr>
          </SimpleStep>
          <SimpleStep label="2">
            Add <abbr title={resourceInfo}>resources</abbr>
          </SimpleStep>
          <SimpleStep label="3">
            Add <abbr title={teamsInfo}>team</abbr> members
          </SimpleStep>
        </Row>
      </Container>
      <ArrowNextSection />
    </div>
  );
}
Slide.propTypes = {
  schema : PropTypes.instanceOf( Schema ).isRequired,
};

function ArrowNextSection() {
  return (
    <div className="w-100 d-flex justify-content-center">
      <Icon icon="far:chevron-down" size="3x"
        css={{ color : 'gray' }}
      />
    </div>
  );
}

function Slide( { schema, ...props } ) {
  const [ isOpen, setIsOpen ] = useState( false );
  const toggle = () => setIsOpen( prev => !prev );

  const logo = useMemo( () => {
    if ( schema.logo ) {
      return (
        <InlineSvg svg={schema.logo} />
      );
    }
    return <Icon icon={schema.icon} />;
  }, [ schema.icon, schema.logo ] );

  if ( ! schema.name ) return null;
  const summary = schema.getMarkdownSummary( 'details' );
  if ( ! summary ) return null;

  return (
    <GrayPanel className="about-slide d-flex rounded p-3" {...props}>
      <div className="d-flex align-items-center pr-3">
        <div css={css`
          width: 110px;
          height: 110px;
          svg {
            fill: ${theme.colors.primary_dark};
            color: ${theme.colors.primary_dark};
            width: 110px !important;
            height: 110px !important;
          }
        `}
        >
          {logo}
        </div>
      </div>
      <div className="d-flex flex-column justify-content-between">
        <div>
          <h4 css={{ color : theme.colors.pop_darker }}>{ schema.name }</h4>
          <Markdown content={ summary } />
        </div>
        <div>
          <PopButton onClick={ toggle }>
            Learn More
          </PopButton>
          <Modal isOpen={isOpen} toggle={toggle} size='lg' scrollable>
            <Markdown content={ schema.getMarkdown( 'details' ) } />
          </Modal>
        </div>
      </div>
    </GrayPanel>
  );
}
Slide.propTypes = {
  schema : PropTypes.instanceOf( Schema ).isRequired,
};

function AvailableServices() {
  const slides = _.map( schemas, schema => (
    <Slide schema={schema} key={schema.id} />
  ) );
  return (
    <div className="py-5 mx-4">
      <div className="d-flex justify-content-center">
        <h1 className="mb-0 text-muted">
          Available Services
        </h1>
      </div>
      <div className="py-4"
        css={css`
          display: grid;
          gap: 20px;
          grid-template-columns: repeat(auto-fill, minmax(430px, 1fr) );
          grid-auto-rows: '240px';
        `}
      >
        {slides}
      </div>
      <ArrowNextSection />
    </div>
  );
}

function CreateAProject() {
  const router = useRouter();
  const goToProjects = () => {
    router.go( '/projects/create' );
  };
  return (
    <GrayPanel className="pt-5">
      <div className="d-flex justify-content-center mb-0">
        <h1 className="mb-0 text-muted">
          Let's Get Started!
        </h1>
      </div>
      <div className="d-flex justify-content-center pt-4 pb-5">
        <PopButton className="w-50 p-3"
          size="xl" block
          onClick={goToProjects}
        >
          <span css={{ fontSize: '2rem' }}>
            Create your first SSP Project!
          </span>
        </PopButton>
      </div>
    </GrayPanel>
  );
}

/*
function InteractiveTour() {
  return (
    <GrayPanel className="pt-5">
      <div className="d-flex justify-content-center mb-0">
        <h1 className="mb-0 text-muted">
          Let's Get Started!
        </h1>
      </div>
      <div className="d-flex justify-content-center pt-4 pb-5">
        <PopButton className="w-50 p-5"
          size="xl" block
        >
          <span css={{ fontSize: '3rem' }}>
            Start Interactive Tour
          </span>
        </PopButton>
      </div>
    </GrayPanel>
  );
}
*/

export default function AboutPage( props ) {
  return (
    <Page title='About' showHeader={false}>
      <Container className="about-page px-0" fluid>
        <Greeting />
        <SimpleSteps />
        <HowItWorks />
        <AvailableServices />
        <CreateAProject />
        {/* <InteractiveTour /> */}
      </Container>
    </Page>
  );
}
