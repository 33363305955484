import _ from 'lodash';
import { useMemo } from 'react';
import { Icon } from '~/icons';
import {
  ButtonGroup, ButtonDropdown, DropdownToggle,
  DropdownMenu, DropdownItem, Button,
} from 'reactstrap';
import { useResultSetContext, useViewContext } from '~/context';
import { useToggle } from '~/hooks';

export function Sort() {
  const [ isSortOpen, toggleSortOpen ] = useToggle( false );
  const { schema } = useResultSetContext();
  const view = useViewContext( useViewContextConfig( schema ) );

  const { sortOptions, sortLabel } = useMemo( () => {
    const res = { sortOptions : [], sortLabel : 'Sort' };
    if ( ! schema ) return res;
    const fields = schema.getFields( '@sortable' );
    res.sortOptions = fields.map( field => _.pick( field, 'name', 'label' ) );
    if ( view.sortKey ) {
      const field = schema.getField( view.sortKey );
      if ( field && field.label ) res.sortLabel = `Sort: ${field.label}`;
    }
    return res;
  }, [ schema, view.sortKey ] );

  return (
    <ButtonGroup
      size="sm"
      className="sorter"
      data-sort-field={view.sortKey}
      data-sort-dir={view.sortIndicator}
      data-sort-ascending={String( view.sortAscending )}
      aria-label="Choose Sort Options"
    >
      <ButtonDropdown isOpen={isSortOpen} toggle={toggleSortOpen}>
        <Button onClick={view.toggleSort} data-testid="sort-order"
          aria-label="Flip Sort Order"
        >
          <div aria-label={view.sortDirection}>
            <Icon icon={view.sortIcon} />
          </div>
        </Button>
        <DropdownToggle caret aria-label="Choose a sort field">
          {sortLabel}
        </DropdownToggle>
        <DropdownMenu aria-label="Sortable fields list"
          data-testid="sort-parameters-list" role="menu"
        >
          { sortOptions.map( ( { name, label } ) => (
            <DropdownItem
              key={name}
              onClick={() => view.updateSort( name )}
              active={name === view.sortKey}
            >{label}</DropdownItem>
          ) ) }
        </DropdownMenu>
      </ButtonDropdown>
    </ButtonGroup>
  );
}

function useViewContextConfig( schema ) {
  return useMemo( () => {
    const defaultSort = schema?.ResultSet.config.sort ?? 'display_name';
    return {
      sort      : {
        type    : 'string' as const,
        default : defaultSort,
        transform( value, _prev ) {
          if ( ! _.isString( value ) ) return;
          // The leading + on sort is optional, so we omit it to keep the
          // URL cleaner since it will have to be url encoded
          if ( value.startsWith( '+' ) ) return value.slice( 1 );
          return value;
        },
        augment( value: string = defaultSort ) {
          if ( value.startsWith( '+' ) ) value = value.slice( 1 );
          if ( value.startsWith( '-' ) ) {
            return {
              sortIndicator : '-',
              sortAscending : false,
              sortDirection : 'Descending',
              sortKey       : value.slice( 1 ),
              sortIcon      : 'far:sort-alpha-up',
            };
          } else {
            return {
              sortIndicator : '+',
              sortAscending : true,
              sortDirection : 'Ascending',
              sortKey       : value,
              sortIcon      : 'far:sort-alpha-down',
            };
          }
        },
        methods : {
          toggleSort() {
            this( ( { sort = '' } ) => {
              if ( sort.startsWith( '+' ) ) {
                return { sort : '-' + sort.slice( 1 ) };
              }
              if ( sort.startsWith( '-' ) ) {
                return { sort : '' + sort.slice( 1 ) };
              }
              return { sort : '-' + sort };
            } );
          },
          updateSort( field ) {
            if ( ! field ) return;
            this( ( { sort = '' } ) => {
              if ( field.startsWith( '+' ) || field.startsWith( '-' ) ) {
                return { sort : field };
              }
              return { sort : sort.startsWith( '-' ) ? '-' + field : field };
            } );
          },
        },
      },
    };
  }, [ schema ] );
}
