import { createStore } from '@ssp/utils';
import { useStore } from '~/hooks';

export const connstore = createStore( {
  name        : 'conn',
  description : 'Connection Status',
  state       : { state : 'pending' },
  reduce      : 'replace',
} );

export function useConnStore( key ) {
  const a = useStore( 'conn' );
  if ( ! key ) return a;
  if ( a && key ) return a[ key ];
  return;
}

export function useConnState() {
  return useConnStore( 'state' );
}
