import _ from 'lodash';
import { useMemo } from 'react';
import { toast } from '~/utils';
import { Loading, Button } from '~/widgets';
import { Icon } from '~/icons';
import { Panel } from '~/components';
import { useResourceContext, usePromiseResult, useRouter } from '~/hooks';

export function DeleteResource() {
  const router = useRouter();
  const ctxRsrc = useResourceContext();
  // Determine if there are resources that
  // depend on this resource in order to warn users of potential
  // side effects or actions required to proceed with deletion
  const dependents = usePromiseResult(
    () => {
      if ( ctxRsrc.type === 'SSP.Project' ) {
        return ctxRsrc.getResources( 'is_external_resource' );
      }
    },
    [ ctxRsrc ],
  );

  const body = useMemo( () => {
    if ( ! _.isArray( dependents )
    && ctxRsrc.type === 'SSP.Project' ) {
      return <Loading label="Delete Project Resource List" />;
    }
    const handleCancelClick = () => {
      router.back();
    };

    if ( ! dependents || dependents.length === 0 ) {
      const handleDeleteClick = async () => {
        try {
          await ctxRsrc.remove();
          toast.info( `${ctxRsrc.name} was successfully deleted`, {} );
          router.go( getRoute( ctxRsrc ) );
        } catch ( err ) {
          toast.error( `Failed to delete project`, err );
        }
      };
      return (
        <>
          <p>Are you sure you want to delete "{ctxRsrc.name}"? </p>
          <p>This action cannot be undone!</p>
          <Button spaced onClick={handleDeleteClick} label="Delete!"
            icon="far:trash-alt" danger
          />
          <Button spaced onClick={handleCancelClick} label="Cancel"
            icon="far:ban"
          />
        </>
      );
    } else {
      return (
        <div>
          <h3>
            Before deleting this, you must remove these
            resources by either deleting them, or changing their owner:
          </h3>
          <ul>
            { dependents.map( rsrc => (
              <li key={rsrc._id}>
                <Icon icon={rsrc.schema.icon}
                  label={`${rsrc.name} (${rsrc.schema.name})`} to={rsrc}
                />
              </li>
            ) ) }
          </ul>
          <Button spaced onClick={handleCancelClick} label="Cancel"
            icon="far:ban"
          />
        </div>
      );
    }
  }, [ ctxRsrc, dependents, router ] );

  return (
    <Panel body icon="far:trash-alt" title={`Delete ${ctxRsrc.name}?`}>
      {body}
    </Panel>
  );
}

function getRoute( rsrc ) {
  if ( rsrc.owner ) return rsrc.owner.route();
  switch ( rsrc.schema.id ) {
    case 'SSP.Project'  : return '/projects';
    case 'SYSTEM.Job'   : return '/jobs';
    case 'SSP.User'     : return '/users';
    default             : return '/projects';
  }
}
