import _ from 'lodash';
import { Watchable, isResource, Resource } from '@ssp/database';
import { useEffect, useState, useOptions } from '.';

import type { WatchOptions } from '@ssp/database';

export interface UseResourceOptions extends WatchOptions {}

/**
 * Use this hook to ensure that a resource has loaded before rendering.
 *
 * @param resource_in - The resource to manage.
 * @param options - Options to pass to the resource's `.watch` method.
 * @returns The Resource instance being managed.
 *
 * @example
 * import { useResource } from '~/hooks';
 *
 * function MyComponent( props ) {
 *   const resource = useResource( props.resource );
 *   return <ResourceView resource={resource} />;
 * }
 */
export function useResource(
  resource_in?: Resource,
  options: UseResourceOptions = {},
) {
  const realResource = isResource( resource_in )
    ? Watchable.unwrap( resource_in ) : null;
  const [ resource, setResource ] = useState( realResource );
  let label = 'useResource';
  if ( options.label ) {
    label += `(${options.label})`;
  } else if ( resource ) {
    label += `(${resource.instance_id}`;
    if ( resource.broker ) label += `+${resource.broker.broker_id}`;
    label += `)`;
  }
  const opts = useOptions( { label, ...options }, 'deep' );

  useEffect( () => {
    if ( realResource ) {
      if ( realResource.creating ) {
        setResource( realResource );
      } else {
        return realResource.watch( setResource, opts );
      }
    }
  }, [ realResource, opts ] );

  if ( _.isError( resource ) ) {
    log.debug( 'resource is error', resource, opts );
    if ( opts.safe ) return resource;
    throw resource;
  }
  if ( isResource( resource ) ) return resource;
}

function resolveResource( rsrc ) {
  if ( _.isNil( rsrc ) ) return null;
  if ( _.isString( rsrc ) ) return Resource.fromRef( rsrc );
  const { type, id, ident } = _.isObject( rsrc ) ? rsrc : {};
  if ( type && id ) {
    return Resource.fromId( type, id );
  } else if ( type && ident ) {
    return Resource.fromIdent( type, ident );
  } else if ( id ) {
    return Resource.fromId( id );
  }
  log.error( 'Cannot resolve', rsrc );
  throw new Error( `Unable to resolve resource: ${rsrc}` );
}

export function useResourceResolver( data_in, options={} ) {
  if ( ! ( _.isPlainObject( data_in ) || _.isString( data_in ) ) ) {
    throw new TypeError(
      `useResourceResolver requires plain object or string`,
    );
  }
  const [ resource, setResource ] = useState();
  const data = useOptions( data_in, 'shallow' );
  const opts = useOptions( options, 'shallow' );
  useEffect( () => { setResource( resolveResource( data ) ); }, [ data ] );
  return useResource( resource, opts );
}
