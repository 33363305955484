import _ from 'lodash';
import { hideProps, ConfigError } from '@ssp/utils';
import { hashString } from '~/forms/utils/hashing';
import { AddonConfig } from './AddonConfig';
import { FormConfig } from './FormConfig';
import { runTransforms } from '~/forms/utils/transforms';
import { ARRAY_ERROR } from 'final-form';

/** @module "UI.forms" */

export class FieldConfig {

  /**
   * @property name
   *
   * Field name.
   *
   * @type {string}
   */

  /**
   * @property id
   *
   * Field HTML ID.
   *
   * @type {string}
   */

  /**
   * @property label
   *
   * Field label.
   *
   * @type {string}
   */

  /**
   * @property help
   *
   * Field help text.
   *
   * @type {string}
   */

  /**
   * @property transform
   *
   * Formatting transform(s) to apply to the value.  The currently
   * available transforms can be found in
   * `src/forms/utils/transforms.js`
   *
   * @type {string|string[]}
   */

  /**
   * @property beforeSubmit
   *
   * Function to be called before form submission.  If it returns
   * false then the form will not be submitted.  Once one field
   * returns false, any remaining fields with beforeSubmit functions
   * will be skipped.
   *
   * @type {Function}
   * @see https://final-form.org/docs/final-form/types/FieldConfig#beforesubmit
   */

  /**
   * @property afterSubmit
   *
   * Function to be called after form submission.
   *
   * @type {Function}
   * @see https://final-form.org/docs/final-form/types/FieldConfig#afteresubmit
   */

  /**
   * @property data
   *
   * Initial state for any arbitrary values to be placed by mutators.
   *
   * @type {Object}
   * @see https://github.com/final-form/final-form#data-any
   */

  /**
   * @property dbfield
   *
   * The schema field definition.
   * Set to false to ignore the schema field definitions
   * and provide custom field definitions.
   *
   * @type {boolean|Field}
   */

  /**
   * @property defaultValue
   *
   * The value of the field upon creation. This value is only needed
   * if you want your field be dirty upon creation (i.e. for its value
   * to be different from its initial value).
   *
   * You don't want this, you want {@link #initialValue}
   *
   * @type {any}
   * @see https://github.com/final-form/final-form#defaultvalue-any
   */

  /**
   * @property initialValue
   *
   * The initial value for the field. This value is used in the
   * comparison to compute `dirty` and `pristine`.
   *
   * @type {any}
   * @see https://github.com/final-form/final-form#initialvalue-any
   */

  get initialValue() {
    if ( this._initialValue ) return this._initialValue;
    if ( this.resource ) return _.get( this.resource, this.name );
  }
  set initialValue( val ) { this._initialValue = val; }

  /**
   * Check if two values are equal.
   *
   * @see https://github.com/final-form/final-form#isequal-a-any-b-any--boolean
   */
  // isEqual( a, b ) { return a === b; }

  /**
   * @property validateFields
   *
   * An array of field names to validate when this field changes.  If
   * `undefined` then every field will be validated when this once
   * changes.  If an empty array then only this field will be
   * validated when it changes.  If other field names are specified
   * then those fields and this one will be validate when this field
   * changes.
   *
   * @type {string[]}
   * @see https://github.com/final-form/final-form#validatefields-string
   */

  /**
   * A function that takes the value from the form values and the name
   * of the field and formats the value to give to the input. Common
   * use cases include converting javascript `Date` values into
   * a localized date string. Almost always used in conjunction with
   * {@link #parse}.
   *
   * @param {any} value - The value to format.
   * @param {string} name - The field name.
   * @returns {any} The formatted value.
   * @see https://github.com/final-form/react-final-form#format-value-any-name-string--any
   */
  format( value, name ) {
    value = runTransforms( this.transform, 'format', value, this );
    return this._format ? this._format( value, name ) : value;
  }

  /**
   * A function that takes the value from the input and name of the
   * field and converts the value into the value you want stored as
   * this field's value in the form. Common usecases include
   * converting strings into `Numbers` or parsing localized dates into
   * actual javascript `Date` objects. Almost always used in conjuction
   * with {@link #format}.
   *
   * @param {any} value - The value to format.
   * @param {string} name - The field name.
   * @returns {any} The formatted value.
   * @see https://github.com/final-form/react-final-form#format-value-any-name-string--any
   */
  parse( value, name ) {
    value = runTransforms( this.transform, 'parse', value, this );
    return this._parse ? this._parse( value, name ) : value;
  }

  /**
   * @property formatOnBlur
   *
   * When true, {@link #format} will be called when field is blurred.
   * When false it will be called on every render.
   *
   * @type {boolean}
   */
  formatOnBlur = true;

  /**
   * Set to true to make this field render larger.
   *
   * @type {boolean}
   */
  large = false;

  /**
   * Set to true to make this field render smaller.
   *
   * @type {boolean}
   */
  small = false;

  /**
   * Set to change the size of the input.
   *
   * @type {number}
   */
  size;

  /**
   * Input group addon configurations.
   *
   * @type {AddonConfig[]}
   */
  get addons() { return this._addons; }
  set addons( addons ) {
    this._addons = _.map( addons, field => new AddonConfig( field, this ) );
  }

  /**
   * Arbitrary attributes to add to the input.
   *
   * @type {Object}
   */
  attributes = {};

  /**
   * Set to true for a disabled field.
   *
   * @type {boolean}
   */
  disabled = false;

  get schema() {
    if ( this._schema ) return this._schema;
    return _.get( this, 'dbfield.type.class.schema' );
  }
  set schema( s ) { this._schema = s; }

  get model() {
    if ( this._model ) return this._model;
    if ( this.schema && this.schema.model ) return this.schema.model;
    return;
  }
  set model( m ) { this._model = m; }

  async handleValidate( value, doc, meta ) {
    try {
      if ( this.dbfield ) {
        await this.dbfield.validate( {
          value, doc, schema : this.form.schema.id,
        } );
      }
      if ( this.validate ) {
        await this.validate( value, doc, meta );
      }
    } catch ( err ) {
      if ( _.isFunction( err.simplify ) ) {
        const res = err.simplify();
        if ( res[ '*' ] ) {
          res[ ARRAY_ERROR ] = res[ '*' ];
          delete res[ '*' ];
        }
        return res;
      }
      return String( err ).split( '\n' )[0];
    }
  }

  constructor( options={}, hidden={} ) {
    this._hiddenProps = _.keys( hidden );
    hideProps( this, hidden );
    const { format, parse, form, ...opts } = options;
    _.assign( this, opts, form, {
      _format   : format,
      _parse    : parse,
    } );
    if ( ! this.name ) {
      throw new ConfigError( `FieldConfig requires name`, { field : this } );
    }
  }

  get label() {
    if ( _.isNil( this._label ) ) {
      const build = () => _.startCase( this.name.split( '_' ) );
      this._label = this.get( 'label', build, true );
    }
    return this._label;
  }
  set label( label ) { this._label = label; }

  get id() {
    if ( ! this._id ) this._id = this.generateId();
    return this._id;
  }
  set id( id ) { this._id = id; }

  /**
   * getId
   *
   * The ID is used as an attribute on the form control, and is used
   * to allow associating the label element with the form control.
   *
   * If we don't explicitly pass an `id` prop, we generate one based
   * on the `name` and `label` properties.
   */
  generateId() {
    const id = [
      this.name.replace( /\W+/gu, '_' ),
      hashString( this.label ),
    ].join( '-' );
    return id;
  }

  getOptions() {
    let opts = this.options;
    if ( _.isString( opts ) && this.form.resource[ opts ] ) {
      opts = this.form.resource[ opts ];
    } else if ( _.isString( opts ) && this.form.resource.schema[ opts ] ) {
      opts = this.form.schema[ opts ];
    } else if ( _.isString( opts ) ) {
      log.error( 'Invalid string opts:', opts );
    }
    if ( typeof opts === 'function' ) {
      // TODO - It would be helpful if this could be called async...
      opts = opts.call( this.form.resource, this );
    }
    // Object with values as keys and configuration or label as value:
    // options : {
    //  foo  : 'The Foo Option',
    //  bar  : { label : 'The Bar Options' },
    // }
    if ( _.isPlainObject( opts ) ) {
      opts = _.map( opts, ( label, value ) => {
        if ( _.isPlainObject( label ) ) {
          label.value = value;
          return label;
        }
        return { label, value };
      } );
    }
    // If it's anything other than that object layout then it has to
    // be an array.
    if ( ! _.isArray( opts ) ) {
      throw new ConfigError( `Invalid options: ${opts}`, { field : this } );
    }
    // It can be an array of objects or an array of strings, if it's
    // an array of strings they will be used as labels and values
    opts = _.map( opts, opt => {
      if ( _.isString( opt ) ) opt = { label : opt, value : opt };
      if ( ! _.isPlainObject( opt ) ) {
        throw new ConfigError( `Invalid option: ${opt}`, { field : this } );
      }
      _.defaults( opt, {
        label     : opt.value,
        value     : opt.label,
        id        : [ this.id, hashString( opt.value ) ].join( '-' ),
      } );
      return opt;
    } );
    return opts;
  }

  get bsSize() {
    if ( this.large ) return 'lg';
    if ( this.small ) return 'sm';
    return;
  }

  toJSON() {
    const data = _.omitBy( this, ( val, key ) => {
      if ( _.isObject( val ) && _.isEmpty( val ) ) return true;
      // TODO - This assumes that `false` is the default value for all
      // the flag options, so it may accidentally exclude something
      // that shouldn't have been excluded...
      if ( val === false ) return true;
      return false;
    } );
    return _.mapKeys( data, ( val, key ) => key.replace( /^_+/u, '' ) );
  }

  createEmptyItem() {
    if ( this.model ) return this.model.create( {} );
    if ( this.schema ) return {};
    return '';
  }

  getHidden( ...more ) {
    return _.assign(
      { parent : this },
      _.pick( this, this._hiddenProps ),
      ...more,
    );
  }

  /**
   * Returns a `FormConfig` built for the subschema provided by this
   * field.  This is used in rendering SubDoc elements.
   *
   *
   * @param {Object} [opts={}] - Options to override.
   * @param {Object} [hide={}] - Hidden properties to override.
   */
  subform( opts={}, hide={} ) {
    if ( ! this.schema && this.resource?.getSubformSchema ) {
      this.schema = this.resource.getSubformSchema();
    }
    return new FormConfig( {
      ...this.form.pick( 'creating' ),
      ...this.pick( 'fields' ),
      schema  : this.schema,
      fields  : this.fields,
      resource : this.resource,
      ...opts,
    }, {
      ...hide,
      parent  : this,
    } );
  }

  /**
    * Returns a clone of this field with some properties replaced.
    * This is used primarily by things like `FieldElementArray`,
    * which uses it to create a copy of the field config, but with
    * `array` set to false so that it won't try to render an array
    * within an array.
    *
    * @param {Object} [opts={}] - Options to override.
    * @param {Object} [hide={}] - Hidden properties to override.
    */
  subfield( opts={}, hide={} ) {
    const { schema } = this;
    const hidden = this.getHidden( hide, { schema } );
    return new FieldConfig( {
      ...this, ...opts, _id : undefined,
    }, hidden );
  }

  getInputGroupProps() {
    return _.omitBy( {
      ...this.pick( 'id', 'className' ),
      size  : this.get( 'bsSize' ),
    }, _.isNil );
  }

  get attrs() {
    return {
      ...this.pick( 'id', 'className', 'disabled' ),
      ...this.get( 'attributes', {} ),
    };
  }

  get labelWidth() {
    if ( this.label === false ) return 0;
    return this._labelWidth || this.form.labelWidth || 3;
  }
  set labelWidth( w ) { this._labelWidth = w; }
  get stateWidth() {
    return this._stateWidth || this.form.stateWidth || 1;
  }
  set stateWidth( w ) { this._stateWidth = w; }
  get valueWidth() { return 12 - this.labelWidth - this.stateWidth; }

  get resource() { return this.form.resource; }

  get( key, defaultValue, fromGetter ) {
    let val = _.get( this, fromGetter ? `_${key}` : key );
    if ( this.dbfield ) {
      if ( _.isNil( val ) ) val = this.dbfield.get( key, 'form' );
      if ( _.isNil( val ) ) val = this.dbfield.get( key );
    }
    if ( _.isNil( val ) ) val = defaultValue;
    return ( _.isFunction( val ) )
      ? val.call( this, this.form.resource ) : val;
  }

  pick( ...keys ) {
    return _.fromPairs( _.map( _.compact( _.flattenDeep( keys ) ), key => {
      return [ key, this.get( key ) ];
    } ) );
  }

}
