import _ from 'lodash';
import { PropTypes } from '~/forms/utils';
import { FormText } from 'reactstrap';

export function FieldHelp( { field } ) {
  let help = field.get( 'help' );
  if ( ! help ) return null;
  if ( _.isArray( help ) ) help = help.join( ' ' );
  return <FormText id={`${field.id}/help`}>{help}</FormText>;
}
FieldHelp.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};
