import _ from 'lodash';
import { mkdebug, init } from '@ssp/utils';
import { registry } from './registry';

const debug = mkdebug( 'ssp:client:shortcuts' );

let key_buffer = [];
let last = 0;
function getConfigOrPrefixes( seq ) {
  const prefixes = [];
  const configs = registry.current();
  for ( const config of configs ) {
    if ( config.keys === seq ) {
      debug( '  -> FOUND SHORTCUT:', config );
      return config;
    }
    if ( config.keys.startsWith( seq ) ) {
      prefixes.push( config.keys );
      debug( '  -> FOUND PREFIX:', config.keys );
    }
  }
  return prefixes;
}

function ignoreKeyEvent( ev ) {
  // Keypresses in form inputs
  return ev.target.matches( 'input,textarea' )
    // IME composition events
    || ( ev.isComposing || ev.keyCode === 229 );
}

function shortcutListener( ev ) {
  debug( 'KEY DOWN:', ev.key );
  const now = ( new Date() ).getTime();
  const diff = now - last;
  last = now;
  // If the last keypress we processed was more than 2 second ago
  // then start over.
  if ( diff > 2000 ) {
    debug( 'Time diff was', diff, 'clearing buffer' );
    key_buffer = [];
  }
  if ( ignoreKeyEvent( ev ) ) {
    debug( 'Key event was ignored, skipping' );
    return;
  }
  // Add the key to the buffer
  key_buffer.push( ev.key );
  const keys = key_buffer.join( ' ' );
  debug( 'CHECKING FOR SHORTCUTS:', keys );
  // See if the keys currently in the buffer match a config or
  // a prefix
  const config = getConfigOrPrefixes( keys );
  // We got back a shortcut configuration
  if ( _.isPlainObject( config ) ) {
    debug( 'PROCESSING SHORTCUT:', config );
    const { href, handler, modal } = config;
    if ( _.isFunction( handler ) ) return handler();
    if ( _.isString( href ) ) return SSP.go( href );
    if ( _.isString( modal ) ) return SSP.modal.open( modal );
    key_buffer = [];
  } else if ( _.isArray( config ) ) {
    // If we didn't match any prefixes then clear out the key buffer
    // and start fresh
    if ( ! config.length ) key_buffer = [];
  } else {
    log.error( 'Unable to dispatch shortcut:', config );
  }
}

init.register( {
  id    : 'shortcuts',
  after : 'react',
  start() { window.addEventListener( 'keydown', shortcutListener ); },
  stop() { window.removeEventListener( 'keydown', shortcutListener ); },
} );
