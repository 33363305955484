import { Field } from 'react-final-form';
import { getInputForField, PropTypes } from '~/forms/utils';

const Conditional = ( { field, children } ) => {
  const { fieldName, condition } = field.conditional;
  return (
    <Field name={fieldName} subscription={{ value: true }}>
      { ( state ) => {
        const met = condition( field, state );
        return ( met ? children : null );
      } }
    </Field>
  );
};
Conditional.propTypes = {
  field : PropTypes.shape( {
    conditional : PropTypes.shape( {
      fieldName : PropTypes.string.isRequired,
      condition : PropTypes.func.isRequired,
    } ),
  } ),
  children  : PropTypes.node.isRequired,
};

export function FieldElementConditional( props ) {
  const { field } = props;
  const FieldElement = getInputForField( field.conditional );
  return (
    <Conditional field={field} >
      <FieldElement {...props} />
    </Conditional>
  );
}
FieldElementConditional.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};
