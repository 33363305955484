import _ from 'lodash';
import { useMemo } from 'react';
import { PropTypes } from '~/forms/utils';
import { ButtonGroup } from 'reactstrap';
import { rffArrayShape } from './utils';
import { FormButton } from '..';

const orderedArrayTools = [
];
const unorderedArrayTools = [
  {
    title   : 'Add Item',
    label   : 'Add Item',
    icon    : 'far:plus',
    onClick( { field, array } ) { array.push( field.createEmptyItem() ); },
  },
];
const allArrayTools = [
];

export function arrayTools( field ) {
  return _.flatten( [
    field.ordered ? orderedArrayTools : unorderedArrayTools,
    allArrayTools,
  ] );
}


export function ArrayTools( { field, array } ) {
  const tools = useMemo( () => arrayTools( field ), [ field ] );
  const context = useMemo( () => ( { field, array } ), [ field, array ] );

  return (
    <div>
      <ButtonGroup size="sm" className="float-right">
        {_.map( tools, btn => (
          <FormButton key={btn.title} {...btn} context={context} />
        ) )}
      </ButtonGroup>
    </div>
  );
}
ArrayTools.propTypes = {
  field     : PropTypes.fieldConfig.isRequired,
  config    : PropTypes.formConfig.isRequired,
  array     : PropTypes.shape( rffArrayShape ).isRequired,
};

