import _ from 'lodash';
import { DB } from '@ssp/database';
import { Page } from '~/layout';
import { CollectionView } from '~/views';

function handleBuilder( builder ) {
  builder.group( 'main' ).add( ...[
    {
      path  : '',
      title : 'My Jobs',
      icon  : 'far:folder-open',
      query : 'my_owned_jobs',
    },
    {
      path  : 'users',
      title : 'My User Jobs',
      icon  : 'far:folder-open',
      query : 'my_user_jobs',
    },
    {
      path  : 'projects',
      title : 'My Project Jobs',
      icon  : 'far:folder-open',
      query : 'my_project_jobs',
    },
    {
      path  : 'all',
      title : 'All Jobs',
      icon  : 'far:folder-open',
    },
  ].map( jobs_tab ) );
  builder.group( 'interactive', {
    title       : 'Require User Action',
    icon        : 'fal:exclamation-triangle',
    collapsible : true,
    collapsed   : false,
  } ).add(
    ...[
      {
        path       : 'interactive',
        title      : 'All Pending Action',
        icon       : 'fal:exclamation-triangle',
        capability : 'manage-any-job',
        query      : { status : 'interactive' },
      },
      {
        path  : 'my-interactive',
        title : 'Pending Action',
        icon  : 'fal:exclamation-triangle',
        query : { status : 'interactive' },
        mine  : true,
      },
    ].map( jobs_tab ),
  );
  builder.group( 'status', {
    title       : 'By Status',
    icon        : 'far:folder-open',
    collapsible : true,
    collapsed   : false,
  } ).add( ...[
    ..._.pull( DB.SYSTEM.Job.isPendingStatuses(), 'interactive' ),
    ...DB.SYSTEM.Job.isEndedStatuses(),
  ].map( status => jobs_tab( {
    path  : status,
    title : _.startCase( status ),
    query : { status },
  } ) ) );
}

export default function JobsPage() {
  return ( <Page title='Jobs' icon='far:briefcase' builder={handleBuilder} /> );
}

function jobs_tab( options ) {
  const { query = {}, mine, icon = 'far:folder-open', ...opts } = options;
  return {
    ...opts,
    icon,
    exact     : true,
    component : CollectionView,
    props     : () => {
      const rs = DB.SYSTEM.Job.find( query, {
        minlength : false, limit : 100,
      } );
      return { resultset : mine ? rs.mine() : rs };
    },
  };
}
