import { PropTypes } from '~/forms/utils';
import { SelectableArrayGroup } from './SelectableArrayGroup';

export function CheckboxGroup( props ) {
  return <SelectableArrayGroup type="checkbox" {...props} />;
}
CheckboxGroup.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
CheckboxGroup.managesArray = true;
