import _ from 'lodash';
import { Component } from 'react';
import { PropTypes } from '~/forms/utils';
import { Dump, Alert } from '~/widgets';

export class FormErrorBoundary extends Component {

  state = { hasError : false };

  componentDidCatch( error, info ) {
    this.setState( { hasError : true, error, info } );
  }

  render() {
    if ( this.state.hasError ) {
      const { config } = this.props;
      const { error } = this.state;
      const message = error.message || String( error );
      return (
        <Alert color="danger">
          <h2>{message}</h2>
          {/* @ts-ignore */}
          <Dump data={config} />
        </Alert>
      );
    }
    if ( _.isNil( this.props.children ) ) return null;
    return this.props.children;
  }

}
FormErrorBoundary.propTypes = {
  config    : PropTypes.formConfig.isRequired,
  children  : PropTypes.node.isRequired,
};

