import _ from 'lodash';
import cx from 'clsx';
import PropTypes from 'prop-types';

export function IconCounter( props_in ) {
  const {
    text, count, position, className,
    color, background,
    children,
    style={},
    ...props
  } = props_in;

  if ( color ) style.color = color;
  if ( background ) style.background = background;

  if ( ! _.isEmpty( style ) ) props.style = style;

  props.className = cx(
    'fa-layers-counter',
    className,
    `fa-layers-${position}`,
  );
  return <span {...props}>{text || count || children}</span>;
}
IconCounter.propTypes = {
  text        : PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  count       : PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
  className   : PropTypes.className,
  transform   : PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
  position    : PropTypes.oneOf( [
    'bottom-left', 'bottom-right', 'top-left', 'top-right',
  ] ),
  color       : PropTypes.string,
  background  : PropTypes.string,
};
IconCounter.defaultProps = {
  position    : 'top-right',
};
