import { useMemo } from 'react';
import { Button as ButtonRS } from 'reactstrap';
import { Icon } from '~/icons';
import { PropTypes, cx } from '~/utils';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from '~/router';

const Padding = styled( 'div' )( () => css`
  padding : 8px;
` );

const ButtonWrapper = styled( ButtonRS )( ( {
  theme, pill, square, spaced, block, borderless,
  success, warning, danger,
} ) => {
  const style = [ `
    cursor: pointer;
    display: inline-block;
    border: ${theme.buttons.border};
    &.disabled {
      cursor: not-allowed;
      color: ${theme.buttons.fg};
      background-color: ${theme.buttons.bg_active};
      border : ${theme.buttons.border};
    }
    > svg {
      margin : 8px;
    }
    padding : 8px;
    > div {
      padding : unset;
    }
  ` ];

  if ( pill ) style.push( 'border-radius: 2rem;' );
  if ( spaced ) style.push( 'margin-right: 8px;' );
  if ( block ) style.push( 'display: block;' );
  if ( !success && !warning && !danger ) {
    style.push( `svg { color: ${theme.icon.fg}; }` );
    style.push( `color: ${theme.buttons.fg};` );
    style.push( `background-color: ${theme.buttons.bg};` );
    style.push( `
      &:hover {
        color : ${theme.buttons.fg};
        background-color : ${theme.colors.very_light_gray};
        border : ${theme.buttons.border};
      };
      &.active {
        background-color : ${theme.buttons.bg_active} !important;
        border : ${theme.buttons.border} !important;
      }
    ` );
  }
  if ( success || warning || danger || borderless ) {
    style.push( `
      border : unset;
      &:hover { border : unset; }
    ` );
  }
  if ( square ) style.push( 'border-radius : unset;' );

  return style;
} );

export function Button( props_in ) {
  const {
    label, icon, className, pill, spaced, to, square,
    large, small, active, children, tooltip,
    ...props
  } = props_in;

  const classes = [];
  classes.push( className );
  if ( large ) classes.push( 'btn-lg' );
  if ( small ) classes.push( 'btn-sm' );
  if ( props.success ) {
    classes.push( 'btn-success' );
    delete props.success;
  }
  if ( props.warning ) {
    classes.push( 'btn-warning' );
    delete props.warning;
  }
  if ( props.danger ) {
    classes.push( 'btn-danger' );
    delete props.danger;
  }
  if ( active ) classes.push( 'active' );

  props.className = cx( classes );

  const paddedChildren = useMemo( () => {
    if ( !icon && !label ) return <Padding>{children}</Padding>;
    if ( !icon && !children && label ) return <Padding>{label}</Padding>;
    return null;
  }, [ children, icon, label ] );

  const WrappedButton = () => {
    return (
      <ButtonWrapper
        pill={pill ? 1 : 0}
        spaced={spaced ? 1 : 0}
        {...props}
      >
        {icon && <Icon icon={icon} size="lg" label={label} />}
        {paddedChildren || children}
      </ButtonWrapper>
    );
  };

  if ( to ) return ( <Link to={to}><WrappedButton /></Link> );
  return <WrappedButton />;
}
Button.propTypes = {
  label      : PropTypes.string,
  icon       : PropTypes.string,
  spaced     : PropTypes.bool, // append right margin
  pill       : PropTypes.bool, // pill-shaped
  square     : PropTypes.bool, // square corners
  borderless : PropTypes.bool,
  block      : PropTypes.bool, // instead of inline-block
  className  : PropTypes.className,
  large      : PropTypes.bool,
  small      : PropTypes.bool,
  active     : PropTypes.bool,
  disabled   : PropTypes.bool,
  children   : PropTypes.node,
  success    : PropTypes.bool,
  warning    : PropTypes.bool,
  danger     : PropTypes.bool,
  type       : PropTypes.oneOf( [ 'submit', 'reset', 'button', 'menu' ] ),
  to         : PropTypes.string,
  tooltip    : PropTypes.string,
  onClick    : PropTypes.func,
};
Button.defaultProps = {
  type       : 'button',
};
