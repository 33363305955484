import { DB } from '@ssp/database';
import { PropTypes } from '~/utils';
import { ResourceView } from '~/views';
import { ActionPanel } from '~/actions';
import { Panel, Markdown } from '~/components';
import { Badge } from 'reactstrap';
import { Icon } from '~/icons';
import { useResource, useSubject, useCallback } from '~/hooks';
import { Alert, Progress } from '~/widgets';
import { SubDocForm } from '~/forms';

export function SummaryPage( props ) {
  const job = useResource( props.resource, { label : 'SummaryPage' } );
  const next_step = job.findNextInteractiveStep();

  const { completionPct } = job.getStepProgress();

  return (
    <>
      <ActionPanel resource={job} />

      {next_step &&
        <InteractiveStepPanel step={next_step} title="Attention!" />
      }

      <Panel title="Progress Tracker" body>
        <div className="d-flex justify-content-between">
          <TaskView job={job} />
          <div />
          <Progress css={{ maxWidth : 250 }} value={completionPct} text />
          <div />
        </div>
      </Panel>

      <Panel title="Summary" body>
        <ResourceView resource={job} face="summary" view="panel" />
      </Panel>
    </>
  );
}
SummaryPage.propTypes = {
  resource : PropTypes.instanceOf( DB.SYSTEM.Job ).isRequired,
};

function TaskView( { job } ) {
  if ( !job ) return null;

  return (
    <div>
      <OverallStatus job={job} />
      {job.steps && <JobStepsList stepsList={Array.from( job.steps )} />}
    </div>
  );
}
TaskView.propTypes = {
  job : PropTypes.instanceOf( DB.SYSTEM.Job ).isRequired,
};

function JobStepListItem( { step } ) {
  return (
    <>
      <Icon
        name={DB.SYSTEM.Job.iconMap[step.status]}
        size="2x"
        color={DB.SYSTEM.Job.cssColorMap[step.status]}
        label={step.label}
        tooltip={step.status}
        to={step?.waiting_for_job}
        matchLinkToColor
        fw
      />
      { [ 'cancelled', 'error' ].includes( step.status ) &&
        <Alert color="danger">
          {step.status_reason}
        </Alert>
      }
      { step.status === 'complete' && step.type === 'JobStep.Approval' &&
        <Alert color="success">
          {step.status_reason}
        </Alert>
      }
      <br />
    </>
  );
}
JobStepListItem.propTypes = {
  step  : PropTypes.shape( {
    name            : PropTypes.string.isRequired,
    type            : PropTypes.string.isRequired,
    label           : PropTypes.string.isRequired,
    tooltip         : PropTypes.string,
    waiting_for_job : PropTypes.shape(),
    status          : PropTypes.string,
    status_reason   : PropTypes.string,
  } ),
};

function JobStepsList( props ) {
  return props.stepsList.map( ( step, idx ) => {
    return <JobStepListItem key={idx} step={step} />;
  } );
}
JobStepsList.propTypes = {
  stepsList : PropTypes.arrayOf( JobStepListItem.propTypes.step ),
};

function OverallStatus( { job } ) {
  const { stepsCompleted, totalSteps } = job.getStepProgress();

  return (
    <>
      <h4 className="alert-heading">
        Overall Status:&nbsp;
        <Badge pill color={DB.SYSTEM.Job.cssClassMap[job.status]}>
          {job.status}
        </Badge>
      </h4>
      <p className="text-muted">
        Step {stepsCompleted} of {totalSteps} complete
      </p>
    </>
  );
}
OverallStatus.propTypes = {
  job : PropTypes.instanceOf( DB.SYSTEM.Job ),
};

function InteractiveStepPanel( { step, title } ) {
  const subject = useSubject();
  const ui = step.getFulfillmentInterface( subject );

  const onSubmit = useCallback( async ( data ) => {
    await ui.resource.update( data );
    return false;
  }, [ ui.resource ] );

  switch ( ui.component ) {
    case 'Markdown':
      return (
        <Panel title={title} body><Markdown content={ui.content} /></Panel>
      );
    case 'SubDocForm':
      return (
        <Panel title={title} body>
          <Alert color="warning">
            <h4 className="alert-heading">{ui.message}</h4>
          </Alert>
          <SubDocForm onSubmit={onSubmit}
            subdoc={ui.resource} cardless
          />
        </Panel>
      );
    default:
      return null;
  }
}
InteractiveStepPanel.propTypes = {
  step  : PropTypes.resource,
  title : PropTypes.string,
};
