import _ from 'lodash';
import { ErrorBox } from './ErrorBox';
import { PropTypes } from '~/utils';
import { useDebugFlag } from '~/hooks';

const baseCSS = {
  color           : '#253746',
  boxSizing       : 'border-box',
  cursor          : 'help',
};
const svgProps = {
  viewBox             : '0 0 512 512',
  preserveAspectRatio : 'xMidYMid',
  className           : 'fa-spin',
  style               : { fill : 'currentColor', flex : '1 1 auto' },
};
const centerStyle = {
  position  : 'absolute',
  left      : '50%',
  top       : '50%',
  transform : 'translate(-50%,-50%)',
};

const pathdata = [
  'M 440.65 12.57',
  'l 4 82.77',
  'A 247.16 247.16 0 0 0 255.83 8',
  'C 134.73 8 33.91 94.92 12.29 209.82',
  'A 12 12 0 0 0 24.09 224',
  'h 49.05',
  'a 12 12 0 0 0 11.67 -9.26 175.91 175.91 0 0 1 317 -56.94',
  'l -101.46 -4.86',
  'a 12 12 0 0 0 -12.57 12',
  'v 47.41',
  'a 12 12 0 0 0 12 12',
  'H 500',
  'a 12 12 0 0 0 12 -12',
  'V 12',
  'a 12 12 0 0 0 -12 -12',
  'h -47.37',
  'a 12 12 0 0 0 -11.98 12.57',
  'z',
  'M 255.83 432',
  'a 175.61 175.61 0 0 1 -146 -77.8',
  'l 101.8 4.87',
  'a 12 12 0 0 0 12.57 -12',
  'v -47.4',
  'a 12 12 0 0 0 -12 -12',
  'H 12',
  'a 12 12 0 0 0 -12 12',
  'V 500',
  'a 12 12 0 0 0 12 12',
  'h 47.35',
  'a 12 12 0 0 0 12 -12.6',
  'l -4.15 -82.57',
  'A 247.17 247.17 0 0 0 255.83 504',
  'c 121.11 0 221.93 -86.92 243.55 -201.82',
  'a 12 12 0 0 0 -11.8 -14.18',
  'h -49.05',
  'a 12 12 0 0 0 -11.67 9.26',
  'A 175.86 175.86 0 0 1 255.83 432',
  'z',
].join( ' ' );

export function Loading( {
  label, loading, children, width, centered, testId='loading',
} ) {
  const flag = useDebugFlag(
    'loading-dump',
    'Log the thing that is loading when showing a loading spinner',
  );
  if ( children ) {
    if ( loading === false ) return children;
    if ( _.isObject( loading ) && loading.loaded ) return children;
  }
  if ( loading && flag ) log.debug( `LOADING[${label}]:`, loading );
  if ( _.isError( loading ) ) return <ErrorBox error={loading} />;
  return (
    <div css={[ baseCSS, centered && centerStyle ]} data-testid={testId}
      className="d-flex flex-column"
    >
      <svg width={width || 30} {...svgProps}><path d={pathdata} /></svg>
      <span>{label}</span>
    </div>
  );
}
Loading.propTypes = {
  label   : PropTypes.string,
  width   : PropTypes.number,
  loading : PropTypes.oneOfType( [
    PropTypes.shape( { loaded  : PropTypes.bool } ),
    PropTypes.bool,
  ] ),
  children : PropTypes.children,
  centered : PropTypes.bool,
  testId   : PropTypes.string,
};
