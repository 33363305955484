import styled from '@emotion/styled';
import { PropTypes } from '~/utils';

const ControllerBase = styled( 'button' )( {
  backgroundColor : 'transparent',
  border          : 'none',
  position        : 'absolute',
  right           : 0,
  top             : 0,
  cursor          : 'pointer',
  width           : 47,
  display         : 'flex',
  flexDirection   : 'column',
  height          : '100%',
  justifyContent  : 'center',
  alignItems      : 'center',
} );

export function ControllerButton( props ) {
  const {
    selectedItem,
    reset,
    getToggleButtonProps,
    isOpen,
  } = props;
  if ( selectedItem ) {
    return (
      <ControllerBase {...props} onClick={reset} aria-label="clear selection">
        <XIcon />
      </ControllerBase>
    );
  } else {
    return (
      <ControllerBase
        type="button"
        {...getToggleButtonProps()}
        aria-label="toggle menu"
      >
        <ArrowIcon isOpen={isOpen} />
      </ControllerBase>
    );
  }
}
ControllerButton.propTypes = {
  selectedItem          : PropTypes.shape( {} ),
  reset                 : PropTypes.func.isRequired,
  getToggleButtonProps  : PropTypes.func.isRequired,
  isOpen                : PropTypes.bool.isRequired,
};

const svgProps = {
  viewBox             : '0 0 20 20',
  preserveAspectRatio : 'none',
  fill                : 'transparent',
  stroke              : '#979797',
  strokeWidth         : '1.1px',
};

function XIcon() {
  return (
    <svg width={12} {...svgProps}>
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}
XIcon.propTypes = {};

function ArrowIcon( { isOpen } ) {
  const xfm = isOpen ? 'rotate(180)' : undefined;
  return (
    <svg width={16} {...svgProps} transform={xfm}>
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}
ArrowIcon.propTypes = { isOpen : PropTypes.bool };
