import cx from 'clsx';
import PropTypes from 'prop-types';

export function IconLayer( { children, className, ...props } ) {
  const classes = cx( 'fa-layers', 'fa-fw', className );
  return <span className={classes} {...props}>{children}</span>;
}
IconLayer.propTypes = {
  children    : PropTypes.node.isRequired,
  className   : PropTypes.className,
};
