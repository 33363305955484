import { useMemo, useOptions, useEffect, useStore } from '~/hooks';
import { registry } from './registry';
import { normalize } from './utils';

/**
 * @typedef {object} ShortcutConfig
 *
 * @property {string} name - Short name for the shortcut.
 * @property {string} keys - key sequence to activate the shortcut
 * (space-separated).
 * @property {string} description - Human-readable description of the
 * shortcut (this will go into the shortcuts panel when someone types
 * '?').
 * @property {string} category - The shortcut category (for grouping
 * on the shortcuts display).
 * @property {string} [href] - The route (or external URL) to go to
 * when this shortcut is invoked.
 * @property {Function} [handler] - Handler function to run when the
 * shortcut is invoked.
 * @property {string} [capability] - Capability required to use this
 * shortcut, if the user does not have this capability the shortcut
 * will be ignored.
 */

/**
 * Register global shortcuts.  Any `useShortcuts` hooks that are
 * mounted will contribute to the global mapping of shortcuts.
 *
 * @param  {ShortcutConfig[]} configs - An array of shortcut configs.
 */
export function useShortcuts( configs ) {
  const normed = useMemo( () => normalize( configs ), [ configs ] );
  const options = useOptions( normed, 'deep' );
  useEffect( () => registry.register( options ), [ options ] );
}
export function useShortcut( options ) { useShortcuts( options ); }
export function useShortcutsConfig() { return useStore( 'shortcuts' ); }
