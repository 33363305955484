import { Badge } from 'reactstrap';

import type { Member } from '../Member';

export function MemberStatus( { member }: { member: Member } ) {
  const badge = getBadgeInfo( member );
  if ( ! badge ) return <td>&nbsp;</td>;
  const { label, ...props } = badge;
  return (
    <td>
      <Badge key={label} {...props} className='mr-2'>
        {label}
      </Badge>
    </td>
  );
}

const colors = {
  add    : 'success',
  update : 'info',
  remove : 'warning',
} as const;

const labels = {
  add    : 'Adding',
  update : 'Updating',
  remove : 'Removing',
} as const;
const pending_labels = {
  add    : 'Add In Progress',
  update : 'Update In Progress',
  remove : 'Remove In Progress',
} as const;

type BadgeInfo = {
  color: string;
  label: string;
};

function getBadgeInfo( member: Member ): BadgeInfo {
  let color: string = '';
  let label: string = '';

  let error: boolean = false;
  if ( member.error ) error = true;
  if ( member.change_type !== 'none' ) {
    color = colors[ member.change_type ];
    label = labels[ member.change_type ];
  }

  if ( member.old_change ) {
    const chg = member.old_change;
    color = colors[ chg.change_type ];
    label = pending_labels[ chg.change_type ];
    if ( chg.error ) error = true;
  }

  if ( error && label ) {
    return {
      color : 'danger',
      label : `${label} (Error)`,
    };
  }
  if ( member.is_loading ) return { color : 'info', label : 'Loading' };
  if ( color && label ) return { color, label };
}
