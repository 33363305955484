import _ from 'lodash';
import { Table } from 'reactstrap';
import { PropTypes } from '~/utils';
import { Dump } from '.';

export function PropTable( { data, ...props } ) {
  return (
    <Table bordered striped>
      <tbody>
        { _.map( data, ( val, key ) => {
          return (
            <tr key={key}>
              <th scope="row">{key}</th>
              <td>{renderData( val, props )}</td>
            </tr>
          );
        } ) }
      </tbody>
    </Table>
  );
}
PropTable.propTypes = {
  data : PropTypes.shape( {} ).isRequired,
};

function renderData( val, opts={} ) {
  if ( _.isString( val ) ) return val;
  if ( _.isUndefined( val ) ) return <i>undefined</i>;
  if ( _.isNull( val ) ) return <i>null</i>;
  if ( _.isNumber( val ) || _.isBoolean( val ) ) return String( val );
  return <Dump {...opts}>{val}</Dump>;
}
