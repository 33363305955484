import { Fragment } from 'react';
import { PropTypes } from '~/utils';
import { useActionList } from '~/actions/useActionList';
import { Icon } from '~/icons';

export const title = 'Actions Help';
export default function ActionMenuHelpModal( { resource } ) {
  const actions = useActionList( resource ).filter( action => action.help );
  return (
    <>
      <p>
        Actions are how you interact with your resources.
      </p>
      {actions.map( ( { label, icon, id, help } ) => (
        <Fragment key={id}>
          <h5 className="mb-0"><Icon icon={icon} fw label={label} /></h5>
          <p className="ml-1">{help}</p>
        </Fragment>
      ) ) }
    </>
  );
}
ActionMenuHelpModal.propTypes = {
  resource : PropTypes.resource.isRequired,
};
