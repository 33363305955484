import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { ResourceCard } from '~/views/collection/card/ResourceCard';
import { PropTypes } from '~/utils';
import { useResource } from '~/hooks';

const style = {
  position      : 'absolute',
  left          : '-305px',
  top           : '-100px',
  border        : '1px solid gray',
  padding       : '0',
  width         : '300px',
  borderRadius  : '5px',
} as const;

function ResourcePopCard( props ) {
  const resource = useResource( props.resource, { label : 'ResourcePopCard' } );

  if ( ! resource ) return null;
  const face = resource.schema.getFace( 'card', props.face );
  if ( ! face ) return null;
  return <ResourceCard resource={resource} face={face} hideActionMenu />;
}
ResourcePopCard.propTypes = {
  face      : PropTypes.string,
  resource  : PropTypes.resource,
};

export function PopCard( props ) {
  const {
    isOpen, resource, children, trigger, target, hideArrow, hoverable,
  } = props;
  return (
    <>
      {trigger}
      {isOpen && target.current && (
        <UncontrolledPopover
          isOpen
          target={target}
          hideArrow={hideArrow}
          trigger={hoverable ? 'legacy' : null}
        >
          <PopoverBody data-testid='popcard-body' css={style}>
            {resource ? <ResourcePopCard {...props} /> : children}
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
}
PopCard.propTypes = {
  trigger   : PropTypes.renderable.isRequired,
  face      : PropTypes.string,
  isOpen    : PropTypes.bool,
  target    : PropTypes.ref.isRequired,
  resource  : PropTypes.resource,
  children  : PropTypes.children,
  hoverable : PropTypes.bool,
  hideArrow : PropTypes.bool,
};
PopCard.defaultProps = {
  face      : 'summary',
  isOpen    : false,
  hoverable : false,
  hideArrow : false,
};
