import _ from 'lodash';
import { PropTypes } from '~/utils';
import { Container as ContainerRS, Row, Col } from 'reactstrap';
import { css } from '@emotion/react';
import { DataItem } from '../DataItem';
import styled from '@emotion/styled';

const Container = styled( ContainerRS )( ( { theme } ) => css`
  padding: 0;
  .row {
    > div.label, > div.col > span.label {
      color: ${theme.dataview.label};
      font-weight: 700;
      padding-right: 0;
    }
  }
` );

function PropTableRow( { component : Item, ...props } ) {
  const item = <Item {...props} />;
  if ( props.labelOnTop ) {
    return (
      <Row className="mb-1 px-0">
        <Col>
          <span className="label">{props.label}</span>
          <span>{item}</span>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="mb-1 px-0">
        <Col md="4" className="label">{props.label}</Col>
        <Col md="8">{item}</Col>
      </Row>
    );
  }
}
PropTableRow.propTypes = {
  component   : PropTypes.elementType.isRequired,
  label       : PropTypes.string.isRequired,
  labelOnTop  : PropTypes.bool,
  className   : PropTypes.className,
};

export function PropTable( { items, labelOnTop, ...props } ) {
  // This lets you specify `labelOnTop : [ 'foo', 'bar' ]` to get some
  // rows to have labels on top, or `labelOnTop : true` to get all
  // rows that way, or `labelOnTop : false` (the default) to get no
  // rows with the label on top.
  if ( labelOnTop === true ) {
    labelOnTop = _.map( items, 'name' );
  } else if ( labelOnTop === false ) {
    labelOnTop = [];
  }
  const onTop = ( item ) => {
    if ( item.labelOnTop ) return true;
    if ( labelOnTop === true ) return true;
    return _.isArray( labelOnTop ) && labelOnTop.includes( item.name );
  };
  return (
    <Container fluid>
      {_.map( items, item => (
        <PropTableRow
          key={item.name}
          {...props}
          {...item}
          labelOnTop={onTop( item )}
        /> ) )}
    </Container>
  );
}
PropTable.propTypes = {
  items : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
  labelOnTop  : PropTypes.oneOfType( [
    PropTypes.bool,
    PropTypes.arrayOf( PropTypes.string ),
  ] ),
};
PropTable.defaultProps = {
  labelOnTop  : false,
};
