import { Form as FormRS } from 'reactstrap';
import { css } from '@emotion/react';

import { Card, CardBody, CardFooter, CardHeader } from '~/components';
import { PropTypes, cx } from '~/forms/utils';
import {
  FormError, FormMessages, FormButtons, FormState, FormData, FormFields,
} from '..';

const style = css`
  // width : 100%;

  .has-error {
    border: 1px solid #f00;
    border-radius: 10px;
    .col-form-label, .form-control-feedback, .col-form-legend { color: #f00; }
  }

  .required-symbol { color: #de5f20; }

  .formbtn {
    color: #495057;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  > .card > .card-body { padding: 0.8em 1.2em; }
`;

export function Form( { form, config, handleSubmit, className } ) {
  const FormContent = config.cardless ? CardlessForm : FormInCard;

  return (
    <FormRS
      id={config.id}
      className={cx( 'form', config.className, className )}
      onSubmit={handleSubmit}
      css={style}
    >
      <FormContent config={config} form={form} />
    </FormRS>
  );
}
Form.propTypes = {
  form          : PropTypes.formApi.isRequired,
  handleSubmit  : PropTypes.func.isRequired,
  className     : PropTypes.className,
  config        : PropTypes.formConfig.isRequired,
};

function FormInCard( { form, config } ) {
  const { title, icon } = config;
  return (
    <Card>
      { title && <CardHeader title={title} icon={icon} /> }
      <FormError form={form} config={config} />
      <FormMessages />
      <CardBody>
        <FormFields config={config} />
      </CardBody>
      <FormData config={config} />
      <CardFooter className="text-right">
        <FormButtons config={config} />
        <FormState config={config} />
      </CardFooter>
    </Card>
  );
}
FormInCard.propTypes = {
  form          : PropTypes.formApi.isRequired,
  config        : PropTypes.formConfig.isRequired,
};

function CardlessForm( { form, config } ) {
  return (
    <>
      <FormError form={form} config={config} />
      <FormMessages />
      <FormFields config={config} />
      <FormData config={config} />
      <div className="text-right">
        <FormButtons config={config} />
        <FormState config={config} />
      </div>
    </>
  );
}
CardlessForm.propTypes = {
  form          : PropTypes.formApi.isRequired,
  config        : PropTypes.formConfig.isRequired,
};
