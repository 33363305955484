import type { StepType } from '@reactour/tour';
import { Content } from '~/layout/tours/content';

const steps : StepType[] = [
  {
    content: <Content>Hello</Content>,
    position: 'right',
    selector: 'div[data-testid="headerbar"] > h1 > span',
  },
];

export default steps;
