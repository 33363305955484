import _ from 'lodash';
import b62 from 'base62/lib/ascii';

export function hashString( value='' ) {
  if ( ! _.isString( value ) ) {
    try {
      value = JSON.stringify( value );
    } catch ( err ) {
      value = String( value ) + String( err );
    }
  }
  const code = hashCode( value );
  const hash = b62.encode( Math.abs( code ) );
  return hash;
}

function hashCode( value ) {
  /* eslint-disable no-bitwise */
  let hash = 0, i, chr;
  if ( value.length === 0 ) return hash;
  for ( i = 0 ; i < value.length ; i++ ) {
    chr = value.charCodeAt( i );
    hash = ( ( hash << 5 ) - hash ) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
  /* eslint-enable no-bitwise */
}
