import { Page } from '~/layout';
import { useRouteParam, useSubject, useResource, useMemo } from '~/hooks';
import { UserView } from './UserView';
import { DB } from '@ssp/database';

export default function UserPage() {
  const subject = useSubject();
  const ident = useRouteParam( 'user_ident', 'me' );
  const rsrc = useMemo( () => {
    return ( ident === 'me' ) ? subject : DB.SSP.User.fromIdent( ident );
  }, [ ident, subject ] );
  const user = useResource( rsrc, { label : 'UserPage' } );

  return (
    <Page resource={user} title={user?.display_name}>
      <UserView />
    </Page>
  );
}
