import { PropTypes } from '~/utils';
import { useRelativeTimes, useSetting } from '~/hooks';

export function DateTime( { value } ) {
  const abs = useSetting( 'show_absolute_timestamps', false );
  const times = useRelativeTimes( value );

  return (
    <time
      title={abs ? times.relative : times.formatted}
      dateTime={times.dateTime}
    >{abs ? times.formatted : times.relative}</time>
  );
}
DateTime.propTypes = { value : PropTypes.instanceOf( Date ).isRequired };
