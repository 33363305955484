import _ from 'lodash';
import { DebugPanel } from '~/widgets';
import { useMembers } from '../context';

export function MembersDebug() {
  const members = useMembers();

  return (
    <DebugPanel
      title='Member Manager Debug Info'
      shortcut='D m'
      table={() => make_data( members )}
    />
  );
}

function make_data( members ) {
  return {
    'Members Info'  : {
      ..._.pick( members, [
        'size',
        'action',
        'bulk',

        'is_saving',
        'is_loading',
        'is_loaded',
        'is_editing',
        'can_edit',

        'config_field_names', 'user_type',
        'can_manage', 'can_manage_reason',
        'has_changes', 'can_commit',
      ] ),
      'Provision Counts'        : members.provision_counts,
    },
    ..._.mapValues( _.keyBy( members.members, 'name' ), member => _.omitBy( {
      ..._.pick( member, [
        'name', 'email', 'status', 'error', 'key', 'user_id', 'service_user_id',
        'is_loading', 'is_loaded',
        'has_changes',
        'can_edit', 'can_commit', 'is_complete',
        'showingEditor', 'showingDetail',
        'needs', ...members.config_field_names,
      ] ),
      memberinfo  : _.pick( member.memberinfo, [
        'user_id', 'service_user_id', ...members.config_field_names,
      ] ),
      old_change : _.pick( member.old_change, [
        'change_type', 'config', 'discovered',
        'requested_by', 'requested_at',
      ] ),
      new_change : _.pick( member.new_change, [
        'change_type', 'config', 'discovered',
      ] ),
    }, val => {
      if ( _.isPlainObject( val ) || _.isArray( val ) ) return _.isEmpty( val );
      return _.isNil( val );
    } ) ),
  };
}
