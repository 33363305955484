import { PropTypes } from '~/utils';
import { ResourceBreadcrumb } from './ResourceBreadcrumb';

export function LinkingBreadcrumb( props_in ) {
  const { from, to, joiner } = props_in;

  return (
    <>
      <ResourceBreadcrumb key="from" resource={from} />
      {joiner}
      <ResourceBreadcrumb key="to" resource={to} />
    </>
  );
}
LinkingBreadcrumb.propTypes = {
  // For "multi-parent" resources like SSP.TeamLink include from and
  // to refs, and optionally a joiner (the default joiner is just ' to ')
  from      : PropTypes.resource,
  to        : PropTypes.resource,
  joiner    : PropTypes.node,
};
LinkingBreadcrumb.defaultProps = { joiner : ' to ' };
