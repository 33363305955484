import _ from 'lodash';
import { useBreakpoint } from './useBreakpoint';
import { PropTypes } from 'prop-types';

/** @module "UI.hooks" */

const breakpoints = [ 'xs', 'sm', 'md', 'lg', 'xl' ];

export function useBreakpointValues( props ) {
  const bp = useBreakpoint();
  const possibles
    = breakpoints.slice( 0, breakpoints.indexOf( bp ) + 1 ).reverse();
  return parseInt( props[ _.find( possibles, x => props[ x ] ) ] );
}
useBreakpointValues.propTypes = {
  xs : PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
  sm : PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
  md : PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
  lg : PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
  xl : PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
};
