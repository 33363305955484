import { InputGroupAddon } from 'reactstrap';
import { AddonPropTypes } from './utils';
import { FormButton } from '../elements';

export function Button( { addon, field } ) {
  return (
    <InputGroupAddon {...addon.getAddonProps()}>
      <FormButton {...addon.getProps()} />
    </InputGroupAddon>
  );
}
Button.propTypes = AddonPropTypes;
