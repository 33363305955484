import _ from 'lodash';
import { useMemo } from 'react';
// import { useToggle } from '~/hooks';
import { Icon } from '~/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from '~/router';

import {
  Card as CardRS,
  CardBody as CardBodyRS,
  CardHeader as CardHeaderRS,
  CardFooter,
} from 'reactstrap';

export { CardFooter };

export const CardWrapper = styled( CardRS )( ( { theme } ) => {
  const colors = theme.cards.body;
  return {
    border          : 'none',
    boxShadow       : 'unset',
    backgroundColor : colors.bg,
    color           : colors.fg,
    width           : '100%',
  };
} );

export const CardHeaderWrapper = styled( CardHeaderRS )( ( { theme } ) => css`
  background-color: ${theme.cards.header.bg};
  color : ${theme.cards.header.fg};
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 49px;
  a {
    color: white;
    display: block;
    white-space: nowrap;
    text-overflow : ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }
  svg {
    cursor: pointer;
  }
` );

export const CardBodyWrapper = styled( CardBodyRS )( ( { theme } ) => {
  const colors = theme.cards.body;
  return {
    backgroundColor : colors.bg,
    color           : colors.fg,
    padding         : '12px 16px',
    display         : 'inline-block',
  };
} );

export function CardBody( props_in ) {
  const { children, ...props } = props_in;
  return <CardBodyWrapper {...props}>{children}</CardBodyWrapper>;
}

const LeftSpan = styled.span`
  margin: 5px;
  position: absolute;
  left: 0;
  top: 0;
`;
const RightSpan = styled.span`
  margin: 12px;
  position: absolute;
  right: 0;
  top: 0;
  > svg { margin : 5px 10px 7px 0px };
`;

export function CardHeader( props_in ) {
  const {
    title, link, icon, left, right,
    children, render,
    ...props
  } = props_in;

  const Left = useMemo( () => {
    if ( ! left ) return null;
    return ( <LeftSpan>{left}</LeftSpan> );
  }, [ left ] );

  const Right = useMemo( () => {
    if ( ! right ) return null;
    return ( <RightSpan>{right}</RightSpan> );
  }, [ right ] );

  const Content = useMemo( () => {
    if ( _.isFunction( render ) ) return render( { children, ...props } );
    if ( _.isFunction( children ) ) return children( props );
    if ( children ) return children;
    if ( title && link && icon ) {
      return <Icon icon={icon} label={title} to={link} />;
    } else if ( title && link ) {
      return <Link to={link}>{title}</Link>;
    } else if ( title ) {
      return <span>{title}</span>;
    } else if ( link ) {
      return <Link to={link} />;
    }
    return null;
  }, [ render, children, title, link, icon, props ] );

  return (
    <CardHeaderWrapper>
      {Left}
      {Content}
      {Right}
    </CardHeaderWrapper>
  );
}

export function Card( props_in ) {
  const {
    title, link, header, footer, menu, status,
    collapsed : collapsed_in, collapsible,
    children,
    ...props
  } = props_in;
  /* TODO?
  const [ collapsed, toggleCollapsed ] = useToggle( collapsed_in );

  const Footer = useMemo( () => {
    if ( _.isFunction( footer ) ) return footer();
    if ( footer ) return footer;
    if ( status ) return <span>{status}</span>;
    return null;
  }, [ footer, status ] );

  const Body = useMemo( () => {
    if ( collapsed ) return null;
    if ( _.isFunction( children ) ) return children();
    return children;
  }, [ children, collapsed ] );
  */

  return ( <CardWrapper {...props}>{children}</CardWrapper> );
}
