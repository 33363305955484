import { ResourceForm } from '~/forms';
import { Icon } from '~/icons';
import { Panel } from '~/components';
import { useRoutePath, useRouteParam, useRouteUrl } from '~/hooks';
import { Table } from 'reactstrap';
import { getModel, getSchemas } from '@ssp/database';
import { Switch, Route } from '~/router';

export function ResourceForms() {
  const path = useRoutePath();

  return (
    <Switch>
      <Route path={path} exact component={ResourceList} />
      <Route
        path={`${path}/:resource_type`}
        exact
        component={ResourceFormWrapper}
      />
    </Switch>
  );
}

function ResourceList() {
  const url = useRouteUrl();
  return (
    <Panel title="Resource Forms">
      <Table borderless hover>
        <tbody>
          {getSchemas( 'is_resource' ).map( schema => {
            const { id, name, icon = 'far:question-circle' } = schema;
            return (
              <tr key={id}>
                <td>
                  <Icon fw icon={icon} label={name} to={`${url}/${id}`} />
                </td>
              </tr>
            );
          } )}
        </tbody>
      </Table>
    </Panel>
  );
}

function ResourceFormWrapper() {
  const type = useRouteParam( 'resource_type' );
  const resource = getModel( type ).create();
  return <ResourceForm resource={resource} title={`${type} Form`} />;
}
ResourceFormWrapper.propTypes = {
};
