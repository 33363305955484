import { ResultSet } from '@ssp/database';
import { Deprecated } from '@ssp/utils';

import { ErrorBox, ErrorBoundary, Loading } from '~/widgets';
import { PropTypes } from '~/utils';
import { useFace } from '~/hooks';
import { ResultSetContextProvider, useResultSetContext } from '~/context';
import { Markdown } from '~/components';

import { getView } from './subviews';
import { Controls } from './controls';

export function CollectionView( props ) {
  [
    'minlength',
    'limit',
  ].forEach( x => {
    if ( ! props[x] ) return;
    throw new Deprecated( `CollectionView no longer accepts ${x}` );
  } );
  return (
    <ErrorBoundary name="CollectionView">
      <ResultSetContextProvider {...props}>
        <Controls />
        <CollectionSubview />
      </ResultSetContextProvider>
    </ErrorBoundary>
  );
}
CollectionView.propTypes = {
  resultset   : PropTypes.instanceOf( ResultSet ).isRequired,
  view        : PropTypes.string,
  face        : PropTypes.string,
  defaultView : PropTypes.string,
  defaultFace : PropTypes.string,
};

export function CollectionSubview( props ) {
  const context = useResultSetContext();
  const { resultset, loading, message, schema, error } = context;
  const face = useFace( schema, props );
  if ( message ) return <ResourceInfo schema={schema} message={message} />;
  if ( loading ) return <Loading centered width={120} testId='csv-loading' />;
  if ( error ) {
    log.debug( 'CollectionSubview error:', error );
    throw error;
  }
  if ( resultset && resultset.loaded && resultset.length ) {
    const SubView = getView( face.type );
    if ( ! SubView ) return <ErrorBox error={`Invalid view "${face.type}"`} />;
    return (
      <SubView
        face={face}
        resultset={resultset}
        data-testid='collectionview-subview'
        css={{ width : '100%', height : '100%', flex : '1' }}
      />
    );
  }
  if ( resultset && resultset.loaded ) {
    return (
      <ResourceInfo schema={schema} message="No matching resources found" />
    );
  }
  return <Loading width={120} testId='csv-loading' />;
}
CollectionSubview.propTypes = {
  view        : PropTypes.string,
  face        : PropTypes.string,
  defaultView : PropTypes.string,
  defaultFace : PropTypes.string,
};

function ResourceInfo( { schema, message } ) {
  const markdown = schema.getMarkdown( 'details' )
    || schema.service.getMarkdown( 'details' );
  return (
    <>
      {message && <h1>{message}</h1>}
      {markdown && <Markdown content={markdown} />}
    </>
  );
}
ResourceInfo.propTypes = {
  schema  : PropTypes.schema.isRequired,
  message : PropTypes.string,
};
