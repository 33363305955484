import _ from 'lodash';
import { TourId, TourRegistryItem } from './hooks';
import { tourMetadata } from './tour-metadata';

export function normalize( conf ) {
  if ( _.isArray( conf ) ) {
    conf = _.compact( _.map( conf, normalize ) );
    return conf.length ? conf : undefined;
  }
  if ( ! _.isPlainObject( conf ) ) return;
  return conf;
}

export function makeRegistryItemFromId( tourId : TourId )
  : TourRegistryItem {
  return { id: tourId, ...tourMetadata[ tourId ] };
}
