import { useMemo } from 'react';
import { Form as FormRFF } from 'react-final-form';

import { FormContextProvider, PropTypes } from '~/forms/utils';
import { FormErrorBoundary } from '~/forms/elements';
import { useRouter } from '~/hooks';

export function Form( { config, className } ) {
  const router = useRouter();

  const rff_props = useMemo(
    () => ( { ...config.rff( { router } ), className } ),
    [ config, router, className ],
  );

  return (
    <FormContextProvider config={config}>
      <FormErrorBoundary config={config}>
        <FormRFF {...rff_props} config={config} />
      </FormErrorBoundary>
    </FormContextProvider>
  );
}
Form.propTypes = {
  config    : PropTypes.formConfig.isRequired,
  className : PropTypes.className,
};
