import { useEffect, useReducer } from 'react';
import { tryPromise } from '@ssp/utils';

/** @module "UI.hooks" */

function reducer( state, action ) {
  switch ( action.type ) {
    case 'pending': return { state : 'pending' };
    case 'resolved': return { result : action.result, state : 'resolved' };
    case 'rejected': return { error : action.error, state : 'rejected' };
    /* istanbul ignore next */
    // default: return state;
    default: throw new Error( `Invalid reducer type` );
  }
}

export function usePromise( promise, inputs=[] ) {
  const [ data, dispatch ] = useReducer( reducer, { state : 'pending' } );

  useEffect( () => {
    // Eslint will warn that this assignment to `promise` will be lost
    // on each render, but that's Ok, because if it was a promise then
    // `tryPromise` is just going to return it, so it won't have
    // actually changed, and if it was a function then
    // `tryPromise` is going to run it and once we've registered
    // the `.then` handlers for it below we don't need the actual
    // promise any more.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    promise = tryPromise( promise );
    if ( ! promise ) return;
    let cancelled = false;
    dispatch( { type : 'pending' } );
    promise.then( result => {
      if ( ! cancelled ) dispatch( { type : 'resolved', result } );
    }, error => {
      if ( ! cancelled ) dispatch( { type : 'rejected', error } );
    } );
    return () => cancelled = true;
    // We don't want `promise` to be in the dependency list, because
    // if it's a function then it may be a new function every time
    // this runs.  If it's not a function and you need `usePromise` to
    // rerun when `promise` changes then just include the value in the
    // dependency list that you pass in...
  }, inputs );

  data.loading = ( data.state !== 'resolved' && data.state !== 'rejected' );

  return data;
}

export function usePromiseResult( promise, inputs=[] ) {
  const res = usePromise( promise, inputs );
  if ( res.state === 'error' ) {
    log.debug( 'usePromiseResult error:', res.error );
    throw res.error;
  }
  if ( res.state === 'resolved' ) return res.result;
  return;
}
