import { createRegistry } from '@ssp/utils';
import { globals } from './globals';

export const registry = createRegistry( {
  name        : 'tours',
  description : 'Registry of tours',
  merge       : 'squish',
  unique      : 'id',
} );
registry.update( 'globals', globals );
