import _ from 'lodash';
import { Component } from 'react';
import { Container, Jumbotron } from 'reactstrap';
import { isNotFoundError } from '@ssp/utils';
import { recordError } from '@ssp/sentry';

import { ErrorBox } from './ErrorBox';
import { PropTypes } from '~/utils';

export class ErrorBoundary extends Component {

  state = {
    eventId         : '',
    errorInfo       : '',
    hasError        : false,
    isNotFoundError : false,
  };

  componentDidMount() {
    /*
    if ( this.props.root ) {
      window.addEventListener( 'error', this.onError );
      window.addEventListener( 'unhandledrejection', this.onReject );
    }
    */
  }
  componentWillUnmount() {
    /*
    if ( this.props.root ) {
      window.removeEventListener( 'error', this.onError );
      window.removeEventListener( 'unhandledrejection', this.onReject );
    }
    */
  }

  onError = ( event ) => {
    log.error( 'onError event:', event );
    const { message, filename, lineno, colno, error } = event;
    this.showError( error || message, { message, filename, lineno, colno } );
    event.preventDefault();
  };

  onReject = ( event ) => {
    log.error( 'onReject event:', event );
    const { reason, promise } = event;
    this.showError( reason, { promise } );
    event.preventDefault();
  };

  showError( error, data={} ) {
    this.setState( was => ( {
      ...was,
      retry : error.retry,
      ...data,
      hasError : true,
      error,
      isNotFoundError : isNotFoundError( error ),
    } ) );
    log.error( 'ErrorBoundary Error:', error, data );
    this.maybeReport( error, data );
  }

  maybeReport( error, data ) {
    if ( isNotFoundError( error ) ) return;
    if ( ! SSP.Sentry ) return;
    SSP.Sentry.withScope( ( scope ) => {
      scope.setExtras( data );
      scope.setExtras( 'error', error );
      const eventId = recordError( error );
      this.setState( { eventId } );
    } );
  }

  componentDidCatch( error, errorInfo ) {
    this.showError( error, errorInfo );
  }

  render() {
    if ( ! this.state.hasError ) {
      if ( _.isNil( this.props.children ) ) return null;
      return this.props.children;
    }
    if ( this.props.component ) {
      const { component : Comp, ...props } = this.props;
      return <Comp {...props} {...this.state} />;
    }
    if ( this.state.isNotFoundError ) return <NotFoundError />;
    return <ErrorBox {...this.props} {...this.state} />;
  }

}
ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children    : PropTypes.any,
  component   : PropTypes.renderable,
  root        : PropTypes.bool,
  name        : PropTypes.string,
};


export function NotFoundError() {
  return (
    <Container fluid className="error-box"
      data-error-status="not-found" data-error-level="info"
    >
      <Jumbotron>
        <h1>Oops! Page not found.</h1>
        <p>
          Sorry, the page you are looking for does not exist or
          you do not have permission to view it.
        </p>
        <p>
          If you believe this is an error, please submit a { ' ' }
          <a href='https://servicedesk.boozallencsn.com'>CSN support</a>
          { ' ' } ticket.
        </p>
      </Jumbotron>
    </Container>
  );
}
