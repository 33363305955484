import { css } from '@emotion/react';

import { PropTypes } from '~/utils';
import { Icon } from '~/icons';
import { Breadcrumbs } from './breadcrumbs';
import { ActionMenu } from '~/actions';

const style = ( theme ) => css`
  padding: 5px 25px 12px 25px;
  border-bottom: 1px solid ${theme.colors.very_light_gray};
  > h1 {
    padding-top: 8px;
    line-height: 1.0;
    > span {
      line-height: 95%;
      padding: 15px;
      margin-left: -8px;
      white-space: normal;
    }
    > div.dropdown {
      display: inline;
      vertical-align: text-top;
    }
  }
`;

export function HeaderBar( props ) {
  // eslint-disable-next-line prefer-const
  let { title, subtitle, icon, resource, breadcrumbs } = props;

  return (
    <div css={style} data-testid="headerbar">
      <Breadcrumbs {...{ resource, breadcrumbs }} />
      <h1>
        <Icon icon={icon} label={title} />
        {resource && (
          <ActionMenu
            button
            label="Actions"
            icon="far:cogs"
            resource={resource}
          />
        )}
      </h1>
      { subtitle && <h3>{subtitle}</h3>}
    </div>
  );
}
HeaderBar.propTypes = {
  title       : PropTypes.string,
  subtitle    : PropTypes.string,
  icon        : PropTypes.string,
  resource    : PropTypes.resource,
  breadcrumbs : Breadcrumbs.propTypes.breadcrumbs,
};
