import _ from 'lodash';
import { Input } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';
import { jsonStringify } from '@ssp/utils';

export function Static( { field } ) {
  const input = useInput( field, { type : 'text' } );
  const value = _.isString( input.value )
    ? input.value
    : jsonStringify( input.value, { space : 2 } );
  return <Input plaintext {...input} value={value} />;
}
Static.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
