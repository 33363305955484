import _ from 'lodash';
import { Watchable, verboseCheckControls, isResource,
} from '@ssp/database';
import { TabItemGroup } from './TabItemGroup';

import type { TResource, TComponent, WatchableOptions } from '@ssp/database';
import type { TabItemOptions } from './TabItem';
import type { TabItemGroupOptions } from './TabItemGroup';

function mkrt( ...args ) {
  return _.compact( _.flattenDeep( args ) ).join( '/' ).replace( /\/+/ug, '/' );
}

export type TabBuilderOptions = {
  router: $TSFixMe;
  match: $TSFixMe;
  location: $TSFixMe;
  resource: TResource<any>;
  component:TComponent<any>;
  subject: TResource<'SSP.User'>;
  [key: string]: any;
};
export class TabBuilder extends Watchable {

  declare router: $TSFixMe;
  declare match: $TSFixMe;
  declare location: $TSFixMe;

  declare resource?: TResource<any>;
  declare subject: TResource<'SSP.User'>;
  resources: TResource<any>[] = [];

  groups: Record<string, TabItemGroup> = {};
  didService: Record<string, boolean> = {};

  constructor( options: TabBuilderOptions ) {
    const { router, match, location, resource, subject, ...opts } = options;
    super();
    if ( ! router ) throw new Error( `TabBuilder requires router` );
    if ( ! location ) throw new Error( `TabBuilder requires location` );
    if ( ! match ) throw new Error( `TabBuilder requires match` );
    if ( ! subject ) throw new Error( `TabBuilder requires subject` );
    _.assign( this, { router, resource, subject } );
    if ( resource ) this.resources.push( resource );
    _.each( opts, ( val, key ) => {
      if ( isResource( val ) ) this.resources.push( val );
      this[ key ] = val;
    } );
    this.router = router;
    this.location = location;
    this.match = match;
  }

  watch( callback, options: WatchableOptions = {} ) {
    return super.watch( callback, {
      ...options,
      children : _.flatten( [ options.children, this.resources ] ),
    } );
  }

  route( ...args ) {
    if ( args[ 0 ].startsWith( '/' ) ) {
      throw new Error( `Absolute route!` );
    }
    return mkrt( this.match.path, ...args );
  }
  url( ...args ) {
    if ( args[ 0 ].startsWith( '/' ) ) {
      throw new Error( `Absolute route!` );
    }
    return mkrt( this.match.url, ...args );
  }

  get loading() { return _.reject( this.resources, 'loaded' ).length > 0; }
  get loaded() { return _.every( this.resources, 'loaded' ); }

  add( ...items: ( TabItemOptions | string )[] ) {
    return this.group( 'main' ).add( ...items );
  }

  #counter: number = 1;

  group( id: string, config: Omit<TabItemGroupOptions, 'id'> = {} ) {
    return this.groups[ id ] || ( this.groups[ id ] = new TabItemGroup( {
      id, rank : this.#counter++, ...config,
    }, this ) );
  }

  support( ...items: TabItemOptions[] ) {
    const tools =  this.group( 'support', {
      title       : 'Support Tools',
      icon        : 'fad:hat-wizard',
      access      : 'support',
      rank        : 1000,
      // css         : { border : '1px solid red' },
      collapsible : true,
      collapsed   : true,
    } );
    if ( items.length ) tools.add( ...items );
    return tools;
  }

  addAdditionalStandardTabs() {
    if ( this.resource ) this.support().rawdata();
  }

  authorize( route ) {
    if ( ! ( route.access || route.capability ) ) {
      return [ true, 'No restrictions' ];
    }
    return verboseCheckControls(
      this.subject, this.resource,
      route.access, route.capability,
    );
  }
  redirect( src, tgt ) { return this.group( 'main' ).redirect( src, tgt ); }

  schema<T=unknown>( key: string, defaultValue?: T ): T {
    return _.get( this, [ 'resource', 'schema', key ], defaultValue );
  }

  summary( opts={} ) { return this.group( 'main' ).summary( opts ); }
  edit( opts={} ) { return this.group( 'main' ).edit( opts ); }

  _defaults: Record<string, any> = {};
  defaults( type, values ) {
    if ( ! this._defaults ) this._defaults = {};
    if ( ! this._defaults[ type ] ) this._defaults[ type ] = {};
    _.assign( this._defaults[ type ], values );
    return this._defaults[ type ];
  }

  getGroups() {
    let groups = _.values( this.groups );
    groups = _.filter( groups, 'accessible' );
    return _.sortBy( groups, 'rank', 'title' );
  }

  getRoutes() {
    return _.compact( _.flatMap( this.getGroups(), 'tabs' ) );
  }

  toString() { return `TabBuilder<>`; }
}
