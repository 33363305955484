import { PropTypes } from '~/utils';
import { DataPanel } from '~/widgets';
import { useResource } from '~/hooks';

export function RawDataPanel( props ) {
  const resource = useResource( props.resource, { label : 'RawDataPanel' } );
  if ( ! resource ) return null;
  return (
    <DataPanel
      title="Raw Data (Only visible to Support)"
      name={resource.toString()}
      data={resource.toJSON()}
    />
  );
}
RawDataPanel.propTypes = {
  resource   : PropTypes.resource.isRequired,
};
