import _ from 'lodash';
import { Schema, Watchable } from '@ssp/database';
import { Member, MemberOptions } from './Member';

import type { MembershipHelper } from '@ssp/database';

const ACTIONS = [
  'add', 'remove', 'confirm', 'commit', 'committing',
] as const;
export type Action = typeof ACTIONS[number];
export function isAction( value: any ): value is Action {
  return ACTIONS.includes( value );
}

const defaultFormMessage = '';
export class Members extends Watchable {

  action: Action | null = null;
  bulk: boolean = false;

  members: Member[] = [];

  is_saving: boolean = false;

  config_fields: $TSFixMe[] = []; /* Field[] */
  config_field_names: string[] = [];

  no_changes: boolean = false;

  can_manage: boolean = false;
  can_manage_reason?: string = undefined;

  declare schema: Schema;
  declare user_schema: Schema;
  declare MemberInfo: $TSFixMe;
  declare user_type: string;

  constructor( public resource: $TSFixMe ) {
    super();
    this.schema = resource.schema;
    this.MemberInfo = resource.getMemberInfo();
    this.user_type = this.MemberInfo.getUserType();
    this.user_schema = Schema.demand( this.user_type );
    this.config_fields = this.MemberInfo.getConfigFields();
    this.config_field_names = _.map( this.config_fields, 'name' );
    this.reset();
  }

  get is_editing() {
    return this.has_changes || this.action || this.is_saving;
  }

  get total_columns() {
    let columns = 3 // name, email, status
      + this.config_fields.length; // configs
    if ( this.show_tools ) columns += 1; // tools
    return columns;
  }
  get can_edit() {
    return this.can_manage && ! this.resource.is_archived;
  }

  get show_tools() { return this.can_edit; }

  get length() { return this.members.length; }
  get size() { return this.members.length; }

  declare error?: Error;

  get has_changes() { return _.some( this.members, 'has_changes' ); }
  get has_errors() { return this.error || _.some( this.members, 'error' ); }
  get can_commit() { return _.every( this.members, 'can_commit' ); }

  declare _loading?: Promise<any>;
  get is_loading() { return !!this._loading; }
  get is_loaded() { return _.every( this.members, 'is_ready' ); }
  declare _started: number;

  declare _helper: MembershipHelper;
  async getHelper() {
    if ( this._helper ) return this._helper;
    const helper = await this.resource.getMembershipHelper();
    const changes = _.compact( _.invokeMap( this.members, 'getChange' ) );
    await helper.processChanges( changes );
    return this._helper = helper;
  }

  load() {
    if ( this._loading ) return this._loading;
    return this._loading = this._load().catch( error => {
      this.error = error;
    } ).then( () => {
      this._loading = null;
      // This needs to be called on super, or it will trigger another load
      super.changed();
    } );
  }

  async _load() {
    if ( this.is_loaded ) return;
    const todos = [];

    for ( const member of this.members ) {
      if ( ! member.is_ready ) todos.push( member.load() );
    }
    if ( todos.length ) {
      await Promise.all( todos );
      return this._load();
    }
  }

  getFormMessages() {
    /*
    const all_msgs = this.resources.map( rsrc => rsrc.getFormMessages() );
    */
    return { message : defaultFormMessage };
  }

  changed = () => {
    if ( this.no_changes ) return;
    this.load();
    return super.changed();
  };

  member_changed = _.debounce( () => this.changed(), 1000 );

  add( data: MemberOptions ): Promise<Member>;
  add( data: MemberOptions[] ): Promise<Member[]>;
  async add( data ) {
    if ( ! data ) return;
    if ( _.isArray( data ) ) {
      this.no_changes = true;
      const res = await Promise.all( _.map( data, m => this.add( m ) ) );
      this.no_changes = false;
      if ( _.compact( res ).length ) this.changed();
      return res;
    }
    const member = new Member( data, this );
    for ( const x of [ member.user_id, member.email ] ) {
      if ( ! ( _.isString( x ) && x.length ) ) continue;
      const have = this.get( x );
      if ( have ) { have.update( data ); return have; }
    }
    this.members.push( member );
    if ( this.is_loaded && ! member.is_ready ) {
      await member.load();
    } else {
      this.changed();
    }
    return member;
  }

  async remove( member: string|string[] ) {
    if ( ! member ) return;
    if ( _.isArray( member ) ) {
      this.no_changes = true;
      const res = await Promise.all( _.map( member, m => this.remove( m ) ) );
      this.no_changes = false;
      this.changed();
      return res;
    }
    return this.get( member )?.remove();
  }

  nuke( member: Member ) {
    _.pull( this.members, member );
    this.changed();
  }

  get( value: string ) {
    for ( const member of this.members ) {
      if ( member.matches( value ) ) return member;
    }
  }

  has( value: string ) {
    for ( const member of this.members ) {
      if ( member.matches( value ) ) return true;
    }
  }

  update = ( data: Partial<Members> = {}, quietly=false ) => {
    let changes = 0;
    _.each( data, ( val, key ) => {
      if ( _.isEqual( this[ key ], val ) ) return;
      this[ key ] = val;
      changes++;
    } );
    if ( changes && ! quietly ) this.changed();
  };

  act = ( action: Action | null ) => this.update( { action } );
  save = () => {
    delete this._helper;
    this.act( 'confirm' );
  };

  /** Commit changes to the resource. */
  commit = async () => {
    this.update( { is_saving : true } );
    const helper = await this.getHelper();
    await helper.commit();
    this.reset();
  };
  /** Cancel committing changes and return to editing. */
  cancel = () => {
    delete this._helper;
    this.act( null );
  };

  reset = () => {
    this.no_changes = true;
    delete this._helper;
    const showingDetail: Set<string> = new Set();
    const showingEditor: Set<string> = new Set();
    for ( const member of this.members ) {
      if ( member.showingDetail ) showingDetail.add( member.user_id );
      if ( member.showingEditor ) showingEditor.add( member.user_id );
    }
    this.members = [];
    for ( const memberinfo of this.resource.members ) {
      this.add( {
        memberinfo,
        showingDetail : showingDetail.has( memberinfo.user_id ),
        showingEditor : showingEditor.has( memberinfo.user_id ),
      } );
    }
    this.no_changes = false;
    this.update( { is_saving : false, action : null } );
  };

  get can_save() {
    return ( this.has_changes && this.can_commit ) && ! this.is_saving;
  }

  onResourceUpdate( resource ) {
    if ( this.is_editing ) return;
    this.resource = resource;
    this.reset();
  }
  onSubjectUpdate( subject ) {
    const res = this.resource.canBeManagedBy( subject );
    this.update( { can_manage : res[0], can_manage_reason : res[1] } );
  }
}
