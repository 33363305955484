import { CustomInput } from 'reactstrap';
import { PropTypes, useInput, renderOptions } from '~/forms/utils';

export function Select( { field } ) {
  const input = useInput( field, { type : 'select' } );
  return (
    <CustomInput {...input}>
      {renderOptions( field.getOptions() )}
    </CustomInput>
  );
}
Select.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
