import { useState, useEffect } from 'react';

export function useResourceCounts( rsrc ) {
  const [ counts, update ] = useState( {} );

  const origin = rsrc?.broker?.origin;
  useEffect( () => origin?.counts?.watch( 'counts', update ), [ origin ] );
  return counts;
}

export function useResourceIds( rsrc ) {
  const [ ids, update ] = useState( {} );

  const origin = rsrc?.broker?.origin;
  useEffect( () => origin?.counts?.watch( 'ids', update ), [ origin ] );
  return ids;
}
