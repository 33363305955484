import { useStore } from './useStore';
import { useResource } from './useResource';

export function useSubject() {
  const store = useStore( 'auth' );
  return useResource( store?.subject, { label : 'useSubject' } );
}

export function useImpersonator() {
  const store = useStore( 'auth' );
  return useResource( store?.impersonator, { label : 'useImpersonator' } );
}
