import type { StepType } from '@reactour/tour';
import { Button } from 'reactstrap';
import { useTour } from '@reactour/tour';
import { Link } from '~/router';
import { Content } from '~/layout/tours/content';
import { useSettings } from '~/hooks';
import { useCloseTour, useLaunchTour } from '../hooks';

const steps : StepType[] = [
  {
    content: <StepOne />,
    position: 'center',
    selector: null,
  },
  {
    content: <StepTwo />,
    position: 'bottom',
    selector: 'li[data-testid="navmenu-help-menu"]',
  },
  {
    content: <StepThree />,
    position: 'center',
    selector: null,
  },
];

function StepOne() {
  return (
    <Content>
      Welcome! This tour and others like it can guide
      you through the Self Service Portal (SSP)!
    </Content>
  );
}

function StepTwo() {
  const settings = useSettings();
  const { setCurrentStep, currentStep } = useTour();

  const handleNope = async () => {
    settings.auto_launch_tours = 'Never';
    await settings.save();
    setCurrentStep( currentStep + 1 );
  };
  const handleKeep = async () => {
    settings.auto_launch_tours = 'New and Updated';
    await settings.save();
    setCurrentStep( currentStep + 1 );
  };

  return (
    <Content>
      <p>
        You can access tours anytime using this Help menu.
      </p>
      <p>
        Tours launch automatically on your first visit and whenever new or
        updated tours become available. Do you want to disable automatic launch?
      </p>
      <p>
        Do you want to disable automatic launching of tours?
      </p>
      <div className="d-flex justify-content-around">
        <Button onClick={handleNope} className="mr-1 sm">
          Turn Them Off
        </Button>
        <Button onClick={handleKeep} className="ml-1 sm">
          Keep Showing Me (Recommended!)
        </Button>
      </div>
      <p>
        Don't worry, you can adjust this setting anytime on
        your <Link to="/user/me/settings">User Settings</Link> page.
      </p>
    </Content>
  );
}

function StepThree() {
  const close = useCloseTour();
  const navTour = useLaunchTour( 'getting-around' );

  return (
    <Content>
      Continue with the next tour, Getting Around SSP.
      <div className="d-flex justify-content-around mt-2">
        <Button onClick={close}>Maybe Later</Button>
        <Button onClick={navTour}>Launch Now</Button>
      </div>
    </Content>
  );
}

export default steps;
