import { PropTypes } from '~/forms/utils';
import { Label } from 'reactstrap';

export function FieldLabel( { field, ...props } ) {
  if ( ! field.label ) return null;
  return (
    <Label htmlFor={field.id} sm={field.labelWidth} {...props}>
      {field.label}
      {field.required && <span className="required-symbol"> *</span>}
    </Label>
  );
}
FieldLabel.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};
