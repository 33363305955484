import { PropTypes } from '~/utils';
import { Member } from '../Member';
import { Icon } from '~/icons';

export function MemberTools( { member } ) {

  const buttons: {
    icon: string;
    title: string;
    onClick: () => void;
  }[] = [];

  buttons.push( {
    icon    : 'far:info-circle',
    title   : 'More Information',
    onClick : member.toggleDetail,
  } );

  if ( member.can_edit ) {
    buttons.push( {
      icon    : 'fad:user-edit',
      title   : 'Edit',
      onClick : member.toggleEditor,
    } );
  }
  if ( member.change_type === 'remove' ) {
    buttons.push( {
      icon    : 'fad:user-plus',
      title   : 'Add',
      onClick : member.unremove,
    } );
  } else {
    buttons.push( {
      icon    : 'fad:user-times',
      title   : 'Remove',
      onClick : member.remove,
    } );
  }

  if ( member.error ) {
    buttons.push( {
      icon    : 'fad:sync',
      title   : 'Reload',
      onClick : member.reload,
    } );
  }

  return (
    <td key="tools" align="right">
      {buttons.map( button => (
        <Icon key={button.title} className="mr-1" {...button} />
      ) )}
    </td>
  );
}
MemberTools.propTypes = {
  member    : PropTypes.instanceOf( Member ).isRequired,
};
