import { Component } from 'react';
import { PropTypes } from '~/utils';

export class ClickOutside extends Component {

  render() {
    const { children, onClickOutside, ...props } = this.props;
    return <div {...props} ref={e => this.container = e}>{children}</div>;
  }

  componentDidMount() {
    document.addEventListener( 'click', this.handle, true );
  }

  componentWillUnmount() {
    document.removeEventListener( 'click', this.handle, true );
  }

  handle = ev => {
    const { onClickOutside } = this.props;
    const el = this.container;
    if ( ! el.contains( ev.target ) ) onClickOutside( ev );
  };

}
ClickOutside.propTypes = {
  onClickOutside  : PropTypes.func.isRequired,
  children        : PropTypes.node.isRequired,
};
