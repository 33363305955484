import { DB, getIdentity, ResultSet, Face } from '@ssp/database';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ResourceView } from '~/views';
import { PropTypes } from '~/utils';
import { VisibilityWrapper, Progress } from '~/widgets';
import { useResource } from '~/hooks';
import { ActionMenu } from '~/actions';

const RowWrapper = styled( 'div' )( ( { theme } ) => {
  return css`
    background-color : white;
  `;
} );

export function ListView( { face, resultset } ) {
  return (
    <div className="collection-view-list" role="list">
      {resultset.mapSync( ( resource ) => (
        <ListRow
          key={getIdentity( resource )}
          resource={resource}
          face={face}
        />
      ) )}
    </div>
  );
}
ListView.propTypes = {
  resultset : PropTypes.instanceOf( ResultSet ).isRequired,
  face      : PropTypes.instanceOf( Face ).isRequired,
};
ListView.config = {
  name      : 'list',
  title     : 'List View',
  icon      : 'far:list',
};

export function ListRow( { resource : rsrc_in, face } ) {
  const resource = useResource( rsrc_in, { label : 'ListRow' } );
  return (
    <VisibilityWrapper>
      <RowWrapper className="d-flex mb-1 p-3">
        <ListHeadSwitch resource={resource} />
        <div className="d-flex flex-column flex-grow-1"
          css={{ rowGap : '5px' }}
        >
          <ResourceView resource={resource} face={face} />
        </div>
        <div className="align-self-center p-2">
          <ActionMenu resource={resource} right />
        </div>
      </RowWrapper>
    </VisibilityWrapper>
  );
}
ListRow.propTypes = {
  resource  : PropTypes.resource.isRequired,
  face      : PropTypes.instanceOf( Face ).isRequired,
};

function ListHeadSwitch( { resource } ) {
  switch ( resource.schema.id ) {
    case 'SYSTEM.Job' : return <JobListHead resource={resource} />;
    default : return null;
  }
}
ListHeadSwitch.propTypes = {
  resource : PropTypes.resource.isRequired,
};

function JobListHead( { resource } ) {
  const { completionPct } = resource.getStepProgress();
  return (
    <Progress
      className="mr-2"
      css={{ maxWidth : 30 }}
      value={completionPct}
      color={DB.SYSTEM.Job.cssColorMap[resource.status]}
    />
  );
}
JobListHead.propTypes = {
  resource : PropTypes.resource.isRequired,
};
