import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { NavLink as NavLinkRS } from 'reactstrap';
import {
  NavLink as NavLinkRR, Link as LinkRR,
  useHistory, useLocation, useParams, useRouteMatch,
} from 'react-router-dom';
import { qs, PropTypes } from './utils';

export { Switch, Route, Redirect } from 'react-router-dom';
export { useHistory, useLocation, useRouteMatch, useParams as useRouteParams };

function transform_props( props_in ) {
  const { href, ...props } = props_in;

  if ( href ) props.to = href;
  if ( _.isObject( props.to ) && _.isFunction( props.to.route ) ) {
    const rsrc = props.to;
    props.to = rsrc.route();
    if ( _.isFunction( rsrc.findDisplayName ) && ! props.title ) {
      props.title = rsrc.findDisplayName();
    }
  }
  if ( ! props.title ) props.title = '';
  if ( ! props.children ) props.children = props.title;
  if ( ! props.to ) return props.title;
  return props;
}

function BareLink( props ) {
  return <a href={props.to}>{props.title || props.children}</a>;
}
BareLink.propTypes = {
  to        : PropTypes.string.isRequired,
  title     : PropTypes.string,
  children  : PropTypes.children,
};

export function isExternalLink( url ) {
  if ( ! _.isString( url ) ) return false;
  return ( url.startsWith( 'http://' ) || url.startsWith( 'https://' ) )
    && ! url.startsWith( window.location.origin );
}

export function NavLink( props_in ) {
  const props = transform_props( props_in );
  if ( _.isString( props ) ) return <span>{props}</span>;
  if ( _.isNil( props ) ) return null;
  if ( isExternalLink( props.to ) ) return <BareLink {...props} />;
  return <NavLinkRS tag={NavLinkRR} {...props} />;
}
NavLink.propTypes = {
  to        : PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.shape( { route : PropTypes.func } ),
  ] ),
  href      : PropTypes.string,
  title     : PropTypes.string,
  children  : PropTypes.children,
};

export function Link( props_in ) {
  const props = transform_props( props_in );
  if ( _.isString( props ) ) return <span>props</span>;
  if ( _.isNil( props ) ) return null;
  if ( isExternalLink( props.to ) ) return <BareLink {...props} />;
  return <LinkRR {...props} />;
}
Link.propTypes = {
  to        : PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.shape( { route : PropTypes.func } ),
  ] ),
  href      : PropTypes.string,
  title     : PropTypes.string,
  children  : PropTypes.children,
};

/**
 * @typedef {object} RouterNavResult
 */

/**
  *
  * @returns {RouterNavResult}
  */
export function useRouter( opts ) {
  const history = useHistory();
  const params = useMemo( () => {
    if ( ! history?.location?.search ) return;
    return qs.parse( history.location.search, {
      ignoreQueryPrefix : true,
      charsetSentinel   : true,
    } );
  }, [ history?.location?.search ] );
  /**
   * Given a target URL and query parameters, compute the appropriate
   * value to use to navigate.
   *
   * If `target` is not provided then only the query params will be
   * updated.
   *
   * @param {string} [target] - Target URL (or query params).
   * @param {object} [query] - Query params.
   * @param {boolean} [merge] - Whether to merge or replace any
   * existing query parameters.  If not specified then the parameters
   * will be merged if there is no `target` (or if the `target`
   * specifies the same pathname as the current url) and replaced
   * otherwise (with the assumption that if you aren't changing pages
   * then you just want to update the params).
   * @param {boolean} [replace=false] - Set to true to navigate with
   * `history.replace` instead of `history.push`.
   */
  const nav = useCallback( ( target, query, merge, replace = false ) => {
    const navto = {};
    // TODO - Do we need to resolve relative targets here?
    if ( _.isString( target ) ) navto.pathname = target;
    if ( _.isNil( merge ) ) {
      merge = navto.pathname === history.location.pathname || ! target;
    }
    if ( merge ) {
      _.each( query, ( val, key ) => {
        if ( _.isNil( val ) ) {
          delete params[ key ];
        } else {
          params[ key ] = val;
        }
      } );
      navto.search = qs.stringify( params );
    } else {
      navto.search = qs.stringify( query || {} );
    }
    if ( replace ) {
      history.replace( navto );
    } else {
      history.push( navto );
    }
  }, [ params, history ] );

  return useMemo( () => {
    return {
      go( target, param, merge ) { return nav( target, param, merge ); },
      push( target, param, merge ) { return nav( target, param, merge ); },
      replace( target, param, merge ) {
        return nav( target, param, merge, true );
      },
      params( param ) { return nav( null, param, true ); },
      addParams( param ) { return nav( null, param, true ); },
      mergeParams( param ) { return nav( null, param, true ); },
      clearParams( param ) { return nav( null, {}, false ); },
      replaceParams( param ) { return nav( null, param, false ); },
      back() { return history.goBack(); },
      goBack() { return history.goBack(); },
    };
  }, [ nav, history ] );
}

export function useRouteParam( name, defaultValue ) {
  const params = useParams();
  return _.get( params, name, defaultValue );
}

export function useRouteUrl() {
  const { url } = useRouteMatch();
  return url;
}

export function useRoutePath() {
  const { path } = useRouteMatch();
  return path;
}

export function useRouteQuery() {
  const { search } = useLocation();
  return qs.parse( search, { ignoreQueryPrefix : true } );
}
