import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSubject, useOptions, useRouter } from '~/hooks';
import { TabBuilder } from './TabBuilder';

import type { TabBuilderOptions } from './TabBuilder';

export type UseTabsOptions = {
  builder: ( config: TabBuilder ) => void;
} & TabBuilderOptions;

export function useTabs( options: UseTabsOptions ) {
  const router = useRouter();
  const subject = useSubject();
  const location = useLocation();
  const match = useRouteMatch();
  const { builder : handler, ..._opts } = options;
  const opts = useOptions( _opts );

  return useMemo( () => {
    if ( ! ( router && subject && location && match && handler ) ) return;
    try {
      const builder = new TabBuilder( {
        router, subject, location, match,
        ...opts,
      } );
      handler( builder );
      builder.addAdditionalStandardTabs();
      return builder;
    } catch ( error ) {
      return error;
    }
  }, [ handler, opts, router, subject, match, location ] );
}
