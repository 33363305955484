import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { PropTypes, useForm, useFormContext } from '~/forms/utils';
import { Button } from 'reactstrap';
import { cx, toast, useRouter } from '~/utils';
import { Icon } from '~/icons';
import { isResource } from '@ssp/database';

/**
 * A button that when clicked calls it's `onClick` prop with an object
 * containing all the useful stuff you would need in order to interact
 * with the form.
 *
 * Properties that will always be included in this object are:
 *  * `form` - The final-form API instance.
 *  * `router` - The navigation object returned by `useRouter`.
 *  * `config` - The FormConfig instance.
 *
 * Any additional properties that you passed to the `Form` component,
 * which were then included in the `FormContext`, will also be
 * available (as long as they don't have any of the names listed
 * above).
 *
 * It also helps you with the common case of a button containing an
 * Icon, if you pass it `icon` and `label` properties instead of
 * `children` (or `icon` and `title`, in which case the label won't be
 * shown, but will be made available for screen readers and as
 * a hover-tip).
 *
 * It can also help you with common responses to button clicking.  If
 * you return one of the following types of objects then the indicated
 * actions will result:
 *
 * * The literal string `BACK` will navigate to the previous page
 *   (using `router.back()`).
 * * The literal string `RESET` will reset the form state to it's
 *   initial values.
 * * An instance of `Error` will create a `toast.error` popup.
 * * An instance of `DB.SYSTEM.Job` will create a job notification
 *   popup.
 * * An instance of `Resource` will redirect to that resources
 *   `route` (if it's able to create one) With this you can return
 *   a resource to get easily redirected to it's view page.
 * * Anything with a `route` property or method will redirect to that
 *   route.
 * * Anything with an `href` property will redirect to that URL.
 *
 * If your `onClick` handler returns a promise, it will be resolved
 * before the `onResponse` handler is called.
 *
 * @param {Object} props_in - Props
 */
export function FormButton( props_in ) {
  const {
    className, children, label, icon, title, large, small,
    onClick, onResponse=clickResponse,
    context : props_context, field,
    ...props
  } = props_in;
  const form = useForm();
  const form_context = useFormContext();
  const router = useRouter();
  const payload = useMemo( () => {
    return { field, ...form_context, ...props_context, form, router };
  }, [ form, field, form_context, props_context, router ] );
  props.className = cx( {
    formbtn : ! ( props.outline || props.color ),
  }, className );
  if ( small ) props.size = 'sm';
  if ( large ) props.size = 'lg';

  props.onClick = useCallback( async () => {
    if ( ! onClick ) return;
    try {
      const res = await onClick( payload );
      onResponse( res, payload );
    } catch ( err ) {
      onResponse( err, payload );
    }
  }, [ payload, onClick, onResponse ] );
  props[ 'aria-label' ] = props.label;

  return (
    <Button {...props}>
      {children || <Icon name={icon} label={label} title={title} />}
    </Button>
  );
}
FormButton.propTypes = PropTypes.buttonShape;

function clickResponse( res, { router, form } ) {
  if ( res === false ) return;
  if ( res === 'BACK' ) return router.back();
  if ( res === 'RESET' ) return form.reset();
  if ( _.isError( res ) ) return toast.error( res );
  if ( isResource( res ) ) {
    if ( res.schema.id === 'SYSTEM.Job' ) {
      toast.jobinfo( res );
    } else {
      const route = res.route();
      if ( route ) return router.go( res.route() );
    }
  }
  if ( _.isObject( res ) ) {
    if ( _.isString( res.route ) ) return router.go( res.route );
    if ( _.isFunction( res.route ) ) {
      const route = res.route();
      if ( route ) return router.go( res.route() );
    }
    if ( _.isString( res.href ) ) return location.href = res.href;
  }
}
