import type { StepType } from '@reactour/tour';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTour } from '@reactour/tour';
import { Link } from '~/router';
import { Content } from '~/layout/tours/content';
import { useCloseTour } from '../hooks';

const steps : StepType[] = [
  {
    content: Forewarn,
    position: 'center',
    selector: null,
  },
  {
    content: <Content ensureRoute="/user/me">
      This header takes you to your start page (which you can set using
      the bookmark symbol).</Content>,
    position: 'bottom',
    selector: 'a#NavbarBrand',
  },
  {
    content: PrimaryNav,
    position: 'bottom',
    selector: 'nav > div.navbar-collapse',
  },
  {
    content: UserSettings,
    position: 'bottom',
    selector: 'li[data-testid="navmenu-user-menu"]',
  },
  {
    content: Help,
    position: 'bottom',
    selector: 'li[data-testid="navmenu-help-menu"]',
  },
  {
    content: <Content ensureRoute="/user/me">
      Click on this bookmark symbol on any page to make it your start page when
      you open SSP.
    </Content>,
    position: 'bottom',
    selector: 'button > span > svg[data-icon="bookmark"]',
  },
  {
    content: Breadcrumbs,
    position: 'right',
    selector: 'div[data-testid="bc-wrapper"]',
  },
  {
    content: Title,
    position: 'bottom',
    selector: 'div[data-testid="headerbar"] > h1 > span',
  },
  {
    content: SecondaryNav,
    position: 'right',
    selector: 'div.router-tabs > div:nth-child(1)',
  },
  {
    content: HomeTab,
    position: 'right',
    selector: 'li[data-title="Home"]',
  },
  {
    content: HomeTabBody,
    position: 'left',
    selector: 'div[data-testid="router-body-content"]',
  },
  {
    content: Actions,
    position: 'top',
    selector: 'div.action-panel',
  },
  {
    content: EnableAccounts,
    position: 'bottom',
    selector: 'button[data-testid="ap-btn-enable-user-accounts"]',
  },
  {
    content: <Projects />,
    position: 'center',
    selector: null,
  },
];

export default steps;

function Forewarn() {
  const history = useHistory();
  const isAtPage = history.location.pathname === '/user/me';
  const { setCurrentStep, currentStep } = useTour();
  const close = useCloseTour();

  const handleGo = () => {
    setCurrentStep( currentStep + 1 );
  };

  return (
    <Content>
      <p>
        This tour demonstrates how to navigate the
        Self Service Portal (SSP) and explores your user account page.
      </p>
      { !isAtPage &&
        <div className="d-flex justify-content-around">
          <Button onClick={handleGo} className="mr-1 sm">
            OK take me there!
          </Button>
          <Button onClick={close} className="ml-1 sm">Not now</Button>
        </div>
      }
    </Content>
  );
}

function Title() {
  return (
    <Content ensureRoute="/user/me">
      The title here describes what this page is
      about: your user account page.
    </Content>
  );
}

function Help() {
  return (
    <Content ensureRoute="/user/me">
      Use this Help menu to find documentation, a detailed user guide, and
      more self-guided tours to help you get the most out of SSP.
    </Content>
  );
}

function PrimaryNav() {
  return (
    <Content ensureRoute="/user/me">
      This primary navigation bar takes you to major sections of the site.
    </Content>
  );
}

function Breadcrumbs() {
  return (
    <Content ensureRoute="/user/me">
      Breadcrumbs show where you are in SSP. You can use these links
      to jump to other sections of the site.
    </Content>
  );
}

function SecondaryNav() {
  return (
    <Content ensureRoute="/user/me">
      These tabs show specific resources within your user
      account: available projects, teams, jobs, user settings,
      and service user accounts (Slack user, Jira user, etc.).
    </Content>
  );
}

function HomeTab() {
  return (
    <Content ensureRoute="/user/me">
      We're currently on the home tab.
    </Content>
  );
}

function HomeTabBody() {
  return (
    <Content ensureRoute="/user/me">
      The home tab displays available actions
      and a summary of your user account.
    </Content>
  );
}

function Actions() {
  return (
    <Content ensureRoute="/user/me">
      Actions are things you can do on this page.
    </Content>
  );
}

function EnableAccounts() {
  return (
    <Content ensureRoute="/user/me">
      For example, if your service user accounts
      (Slack user, Jira user, etc.) are
      unexpectedly disabled (for example, if you haven’t
      logged into Jira in 60 days), select Enable Accounts
      in your Actions panel to reenable them across the board.
    </Content>
  );
}

function UserSettings() {
  return (
    <Content ensureRoute="/user/me">
      <p>
        Visit your user account page anytime
        by selecting your name.
      </p>
      <p>
        The URL path is always <Link to="/user/me">/user/me</Link>,
        which makes it easy to send as a link to guide others to their
        own user account page.
      </p>
    </Content>
  );
}

function Projects() {
  const closeAndSuppresAutoLaunch = useCloseTour();
  const closeNoSuppress = useCloseTour( false );
  const goToProjects = () => {
    history.push( '/projects' );
    closeNoSuppress();
  };
  const history = useHistory();
  return (
    <Content>
      Congrats! Now you know how to get around SSP. To get started,
      let's select or create a <Link to="/projects">Project</Link>.
      <div className="d-flex justify-content-around mt-2">
        <Button onClick={closeAndSuppresAutoLaunch}>Not Now</Button>
        <Button onClick={goToProjects}>
          Go to Projects
        </Button>
      </div>
    </Content>
  );
}
