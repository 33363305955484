import _ from 'lodash';
import { Nav } from 'reactstrap';
import { useToggle } from '~/hooks';
import { PropTypes, cx } from '~/utils';
import { Icon } from '~/icons';

import { Tab, NavItem } from './Tab';
import { TabItemGroup } from './TabItemGroup';
import { TabBuilder } from './TabBuilder';

const style = {
  borderRadius  : '3px',
  boxShadow     : 'unset',
};

export function TabGroup( { group, ...props } ) {
  const [ isOpen, toggleOpen ] = useToggle( ! group.collapsed );
  const { title, icon, css, collapsible } = group;
  const tabs = group.getTabs();
  if ( ! tabs.length ) return null;
  const tpadding = 'py-2 px-3';
  const padding = title ? 'py-2 pl-4 pr-2' : tpadding;
  const showtabs = collapsible ? ( isOpen || group.active ) : true;
  return (
    <Nav vertical css={[ style, css ]}>
      {( title || icon ) && (
        <NavItem
          className={cx( 'router-tab', 'router-tab-header', tpadding )}
          data-title={title}
          data-group={title}
          css={{
            flexDirection   : 'row',
            flexWrap        : 'nowrap',
            ...( collapsible ? { cursor : 'pointer' } : {} ),
          }}
          {...( collapsible ? { onClick : toggleOpen } : {} )}
        >
          <Icon icon={icon} fw size="2x" />
          <span className="title"><b>{title}</b></span>
          {collapsible && <Icon
            className="tab-chevron ml-2"
            icon={ isOpen ? 'far:chevron-up' : 'far:chevron-down' }
            css={{ marginLeft : 'auto' }}
          />}
        </NavItem>
      )}
      {showtabs && _.map( tabs, ( tab, idx ) => (
        <Tab className={padding}
          key={tab.key} tab={tab} index={idx} {...props}
        />
      ) ) }
    </Nav>
  );
}
TabGroup.propTypes = {
  group  : PropTypes.instanceOf( TabItemGroup ),
  counts : PropTypes.objectOf( PropTypes.number ),
  config : PropTypes.instanceOf( TabBuilder ),
};
