import { lazy } from 'react';

export * from './Alert';
export * from './Button';
export * from './ClickOutside';
export * from './DebugPanel';
export * from './ErrorBoundary';
export * from './ErrorBox';
export * from './Loading';
export * from './MoreInfo';
export * from './PopCard';
export * from './Progress';
export * from './PropTable';
export * from './SupportOnly';
export * from './Tooltip';
export * from './VisibilityWrapper';
export * from './cards';

export const DataPanel = lazy(
  () => import( /* webpackChunkName: "widgets" */ './DataPanel' ),
);
export const Dump = lazy(
  () => import( /* webpackChunkName: "widgets" */ './Dump' ),
);
