import { PropTypes } from '~/utils';
import { Panel } from '~/components';

export function MoreInfo( { label, children, isOpen } ) {
  if ( isOpen ) {
    return ( <Panel title={label}>{children}</Panel> );
  } else {
    return ( <Panel title={label} collapsible collapsed>{children}</Panel> );
  }
}
MoreInfo.propTypes = {
  children    : PropTypes.node.isRequired,
  label       : PropTypes.string.isRequired,
  // Can use <MoreInfo isOpen> to override and force it open, see
  // ErrorBox for an example...
  isOpen      : PropTypes.bool,
};
MoreInfo.defaultProps = {
  name        : 'more-info',
  label       : 'More Information',
};
