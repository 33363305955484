import _ from 'lodash';
import { PropTypes, getInputForField } from '~/forms/utils';
import { Alert } from '~/widgets';

export function FormFields( { config } ) {
  if ( ! config ) throw new Error( 'No CONFIG!' );

  if ( config.fields.length === 0 ) {
    return (
      <Alert color="warning">
        <h4>This resource has no editable fields</h4>
      </Alert>
    );
  }

  return _.map( config.fields, field => {
    const FieldElement = getInputForField( field );
    return <FieldElement key={field.id} field={field} config={config} />;
  } );
}
FormFields.propTypes = {
  config  : PropTypes.formConfig.isRequired,
};
