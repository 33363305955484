import { useField as useRealField } from 'react-final-form';
import { makesub } from '../subscriptions';

/**
 * Field Subscription Items:
 * 'active',
 * 'data',
 * 'dirty',
 * 'dirtySinceLastSubmit',
 * 'error',
 * 'initial',
 * 'invalid',
 * 'length',
 * 'modified',
 * 'pristine',
 * 'submitError',
 * 'submitFailed',
 * 'submitSucceeded',
 * 'submitting',
 * 'touched',
 * 'valid',
 * 'value',
 * 'visited',
 * 'validating'
 */

export function useFieldMeta( name, ...items ) {
  const sub = makesub( items, 'field' );
  return useRealField( name, { subscription : sub } ).meta;
}
