import _ from 'lodash';
import { PropTypes, getInputElement } from '~/forms/utils';
import { InputGroup } from 'reactstrap';
import { Dump, Alert } from '~/widgets';
import { FieldAddons } from '..';

export function FieldInput( props ) {
  const { field } = props;

  if ( ! field.type ) {
    return (
      <Alert color="danger">
        <h2>No type for field!</h2>
        <Dump data={field} />
      </Alert>
    );
  }
  const Input = getInputElement( field.type );
  if ( _.isEmpty( field.addons ) ) return <Input {...props} />;

  return (
    <InputGroup>
      <FieldAddons field={field} prepend />
      <Input {...props} />
      <FieldAddons field={field} append />
    </InputGroup>
  );
}
FieldInput.propTypes = {
  field         : PropTypes.fieldConfig.isRequired,
  setLabelProps : PropTypes.func,
};
