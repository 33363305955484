import _ from 'lodash';
import { useDebounce, useOptions, useMemo } from '.';
import { useResultSetWatcher, useResultSetResolver } from './useResultSet';

/**
 * Given a ResultSet and a search string, run a quicksearch and return
 * the results.
 *
 * @param {import('@ssp/database').ResultSet|string} rs_in - The
 * starting ResultSet to run the search against.
 * @param {string} [search] - The string to quicksearch for.
 * @param {object} [options] - Options object. All options are
 * passed through to the resultset.
 * @returns {import('./useResultSet').ResultSetContext}
 */
export function useQuickSearch( rs_in, search='', options ) {
  const opts = useOptions( options );
  const resolved_rs = useResultSetResolver( rs_in, opts );
  const store = useMemo( () => {
    // This builds a new store cache whenever the resultset changes,
    const istore = new Map();
    const rs = resolved_rs;
    if ( ! rs ) return ( _str: string ) => { return undefined; };
    return ( str: string ) => {
      if ( ! _.isString( str ) ) {
        throw new Error( `Search is not a string (${str})` );
      }
      if ( ! str.length ) return rs;
      if ( istore.has( str ) ) return istore.get( str );
      const res = rs.quicksearch( str );
      istore.set( str, res );
      return res;
    };
  }, [ resolved_rs ] );

  const debounced_search = useDebounce( search ?? '', 500 );
  const resultset = useMemo(
    () => store( debounced_search ),
    [ debounced_search, store ],
  );
  return useResultSetWatcher( resultset );
}
