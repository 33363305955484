import _ from 'lodash';
import { useState, useMemo } from 'react';
import { getModel, getSchemas } from '@ssp/database';
import { CollectionView } from '~/views';
import { PropTypes } from '~/utils';
import { Panel } from '~/components';
import { Button } from '~/widgets';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { useRouter, useRoutePath, useRouteParam, useRouteUrl } from '~/hooks';
import { Switch, Route } from '~/router';

export function SearchPage() {
  const path = useRoutePath();
  return (
    <Switch>
      <Route path={path} exact>
        <SearchTypesList />
      </Route>
      <Route path={`${path}/:resource_type`}>
        <SearchCollection path={path} />
      </Route>
    </Switch>
  );
}

function SearchCollection( { path } ) {
  const type = useRouteParam( 'resource_type' );
  const resultset = useMemo( () => {
    return getModel( type ).find( {}, { minlength : true } );
  }, [ type ] );
  return (
    <>
      <h3>Searching {resultset.schema.plural_name}&nbsp;
        <Button to={path} label="Search something else" />
      </h3>
      <CollectionView resultset={resultset} />
    </>
  );
}
SearchCollection.propTypes = {
  path : PropTypes.string,
};

function SearchTypesList() {
  const url = useRouteUrl();
  const schemas = getSchemas( 'is_top_schema', 'is_resource' );
  return (
    <Panel body title="Search Globally">
      {
        _.map( schemas, ( { id, name : label, icon } ) => {
          const to = `${url}/${id}`;
          return ( <Button spaced key={id} {...{ to, icon, label }} /> );
        } )
      }
    </Panel>
  );
}

function TypeSelector( { selectedType, setSelectedType } ) {
  const router = useRouter();
  const [ isOpen, setIsOpen ] = useState( false );
  const onToggle = () => setIsOpen( !isOpen );
  const handleChange = event => {
    setSelectedType( event.target.value );
    return router.go( `/admin/search/${event.target.value}` );
  };
  return (
    <Dropdown isOpen={isOpen} toggle={onToggle} direction="right">
      <DropdownToggle caret>
        { selectedType || 'Select Resource Type...' }
      </DropdownToggle>
      <DropdownMenu>
        {getSchemas( 'is_component' ).map( schema => (
          <DropdownItem
            onClick={handleChange}
            key={schema.id} value={schema.id}
          >{schema.name}</DropdownItem>
        ) )}
      </DropdownMenu>
    </Dropdown>
  );
}
TypeSelector.propTypes = {
  selectedType    : PropTypes.resourceTypeName,
  setSelectedType : PropTypes.func,
};
