import { useState, useEffect } from 'react';

/** @module "UI.hooks" */

export function useDebounce( value, ms ) {
  // State and setters for debounced value
  const [ state, setState ] = useState( value );

  useEffect( () => {
    // Set debouncedValue to value (passed in) after the specified delay
    const tid = setTimeout( () => setState( value ), ms );

    return () => clearTimeout( tid );
  }, [ value, ms ] );

  return state;
}
