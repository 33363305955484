import _ from 'lodash';
import { Watchable } from '@ssp/database';
import { useEffect, useState, useReducer, useOptions, useMemo } from '.';

/** @module "UI.hooks" */

/**
 * Use this hook to ensure that a Watchable subclass is loaded
 * before rendering.
 *
 * @param {Watchable} watchable_in - The watchable to manage.
 * @param {Object} [options={}] - Options to pass to the `.watch`
 * method.
 *
 * @returns {Watchable} The watchable instance being managed
 * (wrapped in it's Proxy).
 *
 * @example
 * import { useWatchable } from '~/hooks';
 *
 * function MyComponent( props ) {
 *   const thing = useWatchable( props.thing );
 *   return <ThingView thing={thing} />;
 * }
 */
export function useWatchable<T extends Watchable = Watchable>(
  watchable_in: T,
  options={},
): T {
  const realWatchable = Watchable.unwrap( watchable_in );
  const [ watchable, setWatchable ] = useState( watchable_in );
  const opts = useOptions( options, 'deep' );

  useEffect( () => {
    if ( realWatchable ) return realWatchable.watch( setWatchable, opts );
  }, [ realWatchable, opts ] );

  if ( _.isError( watchable ) ) {
    log.debug( 'useWatchable error:', watchable );
    throw watchable;
  }
  return watchable;
}

export function useWatchables<T extends Watchable[] = Watchable[]>(
  watchables_in: T,
  options = {},
): T {
  const unwrappedWatchables = useMemo(
    () => _.map( watchables_in, Watchable.unwrap ),
    [ watchables_in ],
  );
  const realWatchables = useOptions( unwrappedWatchables, 'shallow' );
  const [ watchables, dispatch ] = useReducer( reducer, watchables_in );
  const opts = useOptions( options || {}, 'deep' );

  useEffect( () => {
    const watch = ( watchable, index ) => watchable && watchable.watch(
      res => dispatch( { index, watchable : res } ), opts,
    );
    const un = _.filter( _.map( realWatchables, watch ), _.isFunction );
    return () => un.forEach( u => u() );
  }, [ realWatchables, opts ] );

  for ( const watchable of watchables ) {
    if ( _.isError( watchable ) ) {
      log.debug( 'useWatcahbles error:', watchable );
      throw watchable;
    }
  }
  return watchables;
}

function reducer( state, action ) {
  const new_state = [ ...state ];
  if ( _.isNumber( action.index ) && action.watchable ) {
    new_state[ action.index ] = action.watchable;
  }
  return new_state;
}
