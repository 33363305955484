import _ from 'lodash';
import { Component } from 'react';
import { PropTypes } from '~/forms/utils';
import { Dump, Alert } from '~/widgets';

export class FieldErrorBoundary extends Component {

  state = { hasError : false };

  componentDidCatch( error, info ) {
    log.error( 'FIELD ERROR', error, info );
    this.setState( { hasError : true, error, info } );
  }

  render() {
    if ( this.state.hasError ) {
      const { field } = this.props;
      const { error } = this.state;
      const message = error.message || String( error );
      return (
        <Alert color="danger">
          <h2>{field.name}</h2>
          <h3>{message}</h3>
          {/* @ts-ignore */}
          <Dump data={field} />
        </Alert>
      );
    }
    if ( _.isNil( this.props.children ) ) return null;
    return this.props.children;
  }

}

FieldErrorBoundary.propTypes = {
  field     : PropTypes.fieldConfig.isRequired,
  children  : PropTypes.node.isRequired,
};

