import _ from 'lodash';
import { parse, stringify } from 'qs';

/**
 * Overloaded URL query string management.  If given a string, parses
 * it, if given an object stringifies it.
 */
export function qs( arg, opts ) {
  if ( typeof arg === 'string' ) {
    return qs_parse( arg, opts );
  } else {
    return qs_stringify( arg, opts );
  }
}
qs.parse = qs_parse;
qs.stringify = qs_stringify;

function qs_parse( arg, opts={} ) {
  return parse( arg, {
    ignoreQueryPrefix   : true,
    charsetSentinel     : true,
    depth               : 5,
    parameterLimit      : 50,
    delimiter           : ';',
    // delimiter         : /[;,]/u,
    allowDots           : true,
    arrayLimit          : 10,
    parseArrays         : true,
    comma               : true,
    strictNullHandling  : false,
    // charset
    // interpretNumericEntities
    // decoder ( str, defaultEncoder, charset, type ) {
    //   // type will be 'key' or 'value'
    // }
    ...opts,
  } );
}
function qs_stringify( arg, opts={} ) {
  return stringify( arg, {
    encode              : true,
    indices             : false,
    arrayFormat         : 'comma', // 'indices'|'brackets'|'repeat'|'comma'
    allowDots           : true,
    addQueryPrefix      : true,
    strictNullHandling  : false,
    format              : 'RFC1738', // Spaces encoded as '+'
    // format              : 'RFC3986', // Spaces encoded as '%20'
    // encoder ( str, defaultEncoder, charset, type ) {
    //   // type will be 'key' or 'value'
    // }
    // serializeDate( date ) { return date.getTime(); },
    // sort( a, b ) { return a.localeCompare( b ); },
    // filter( prefix, value ) {},
    // filter : [ ...keys_to_include ],
    ...opts,
  } );
}

export function extractFromHash( key ) {
  const regex = _.isRegExp( key )
    ? key : new RegExp( '(?:^|&|#)' + key + '(?::(b64))?=([^&#]*)', 'u' );
  const res = window.location.hash.match( regex );
  if ( ! res ) return;
  window.location.hash = window.location.hash.replace( regex, '' );
  if ( res[1] === 'b64' ) return atob( res[2] );
  return res[2];
}
