import { useRef } from 'react';

/** @module "UI.hooks" */

/**
 * This ensures that a value is really only created once during the
 * lifetime of a component.  The built-in useMemo hook is only a hint
 * and doesn't guarantee that it won't re-run.  See
 * https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 */
export function useConstant( init ) {
  const ref = useRef();
  // The value is so that it won't re-run every time if your init
  // function returns something falsy
  if ( ! ref.current ) {
    if ( typeof init === 'function' ) {
      ref.current = { value : init() };
    } else {
      ref.current = { value : init };
    }
  }
  return ref.current.value;
}
