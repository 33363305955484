import _ from 'lodash';
import { Table } from 'reactstrap';
import { css } from '@emotion/react';

import { PropTypes } from '~/utils';
import { ErrorBox, ErrorBoundary, Loading, Alert } from '~/widgets';
import { Switch, Redirect, Route } from '~/router';
import { NotFoundPage } from '~/pages';
import {
  useMemo, useDebugFlag, useWatchable, useLoading, useResource,
  useResourceCounts,
} from '~/hooks';
import { Panel } from '~/components';

import { TabGroup } from './TabGroup';
import { TabBuilder } from './TabBuilder';
import { useTabs } from './useTabs';

const rowStyle = css`
  flex: 1;
`;

const listStyle = theme => css`
  ul:not(:last-child) {
    li:last-child {
      border-bottom: 1px solid ${theme.routertabs.border};
    }
  }
  min-width: 300px;
`;

const contentStyle = theme => css`
  background-color: ${theme.content_pane};
`;

function TabsList( { config } ) {
  const groups = useMemo( () => config?.getGroups(), [ config ] );
  const counts = useResourceCounts( config.resource );
  if ( ! groups ) return null;
  return (
    <div css={listStyle} className="p-0">
      {_.map( groups, ( grp, i ) => (
        <TabGroup key={i} group={grp} config={config} counts={counts} />
      ) )}
      <Outdated resource={config.resource} />
      <ResourceInfo resource={config.resource} />
    </div>
  );
}
TabsList.propTypes = {
  config : PropTypes.instanceOf( TabBuilder ),
};

function Bodies( { config : config_in } ) {
  const config = useWatchable( config_in );
  if ( ! config?.loaded ) {
    return <Loading label="Tabs Config" loading={config} />;
  }
  return (
    <div data-testid="router-body-content"
      css={contentStyle} className="w-100 p-3"
    >
      <ErrorBoundary name="router-body">
        <Switch>
          {config.getRoutes().map( tab => {
            if ( tab.redirect ) {
              return (
                <Redirect
                  key={tab.path}
                  from={tab.url}
                  to={tab.redirect}
                />
              );
            } else if ( tab.component || tab.render ) {
              return (
                <Route
                  key={tab.path}
                  path={tab.route}
                  exact={tab.exact}
                  render={tab.renderer}
                />
              );
            } else {
              return null;
            }
          } )}
          <NotFoundPage key="not-found" />
        </Switch>
      </ErrorBoundary>
      <RoutesInfo config={config} />
    </div>
  );
}
Bodies.propTypes = {
  config : PropTypes.instanceOf( TabBuilder ),
};

function RoutesInfo( props ) {
  const flag = useDebugFlag(
    'show-routes', 'Show routes info from RouterTabs',
  );
  if ( ! flag ) return null;
  return (
    <Table bordered striped>
      <thead>
        <tr>
          <th>Key</th>
          <th>Path</th>
          <th>Exact</th>
        </tr>
      </thead>
      <tbody>
        {_.map( props.config.routes, tab => {
          const info = tab.routeInfo;
          if ( ! info ) return null;
          return (
            <tr>
              <td>{info.key}</td>
              <td>{info.path}</td>
              <td>{info.exact}</td>
              <td>{info.renderer}</td>
            </tr>
          );
        } )}
      </tbody>
    </Table>
  );
}
RoutesInfo.propTypes = {
  config : PropTypes.instanceOf( TabBuilder ),
};

function Outdated( props ) {
  const resource = useResource( props.resource, { label : 'Outdated' } );
  if ( ! resource ) return null;
  const outdated = resource.broker.outdated;
  if ( ! outdated ) return null;
  return (
    <Alert color="warning">
      <h4>Resource is Outdated!</h4>
      <ul>
        <li><b>Displayed Version: </b>{resource._version}</li>
        <li><b>Latest Version: </b>{outdated.version}</li>
      </ul>
    </Alert>
  );
}
Outdated.propTypes = { resource : PropTypes.resource };

function ResourceInfo( { resource } ) {
  const flag = useDebugFlag(
    'show-resource-info', 'Show resource information in RouterTabs',
  );
  if ( ! flag ) return null;
  const fields = {
    _id       : 'ID',
    _version  : 'Version',
    name      : 'Name',
  };
  return (
    <Panel title="Resource Metadata">
      <Table bordered striped css={{ bottom: 0 }}>
        <tbody>
          {_.map( fields, ( label, field ) => (
            <tr>
              <th>{label}</th>
              <td>{resource[ field ]}</td>
            </tr>
          ) )}
        </tbody>
      </Table>
    </Panel>
  );
}
ResourceInfo.propTypes = { resource : PropTypes.resource };

export function RouterTabs( props ) {
  const tabs = useTabs( props );
  const config = useWatchable( _.isError( tabs ) ? null : tabs );
  const loading = useLoading( 'Router Tabs Config', config || null );

  if ( _.isError( tabs ) ) {
    log.error( tabs );
    return <ErrorBox error={tabs} />;
  }
  if ( _.isError( config ) ) {
    log.error( config );
    return <ErrorBox error={config} />;
  }

  if ( loading ) return loading;

  return (
    <div className="router-tabs d-flex p-0 m-0" css={rowStyle}>
      <TabsList config={config} />
      <Bodies config={config} />
    </div>
  );
}
RouterTabs.propTypes = {
  builder   : PropTypes.func,
  resource  : PropTypes.resource,
};
