import { useFormState as useFormStateRFF } from 'react-final-form';
import { makesub } from '../subscriptions';

/**
 * Form Subscription Items:
 * 'active',
 * 'dirty',
 * 'dirtyFields',
 * 'dirtySinceLastSubmit',
 * 'error',
 * 'errors',
 * 'hasSubmitErrors',
 * 'hasValidationErrors',
 * 'initialValues',
 * 'invalid',
 * 'modified',
 * 'pristine',
 * 'submitting',
 * 'submitError',
 * 'submitErrors',
 * 'submitFailed',
 * 'submitSucceeded',
 * 'touched',
 * 'valid',
 * 'validating',
 * 'values',
 * 'visited'
 */

export function useFormState( ...items ) {
  return useFormStateRFF( makesub( items, 'form' ) );
}
