import _ from 'lodash';

function PropTypeError(
  message, props, propName, componentName, location, fullName,
) {
  this.message = message;
  if ( propName ) this.message += ` (${propName}=${props[ propName ]})`;
  this.stack = '';
}
// Make `instanceof Error` still work for returned errors.
PropTypeError.prototype = Error.prototype;

export function createChainableTypeChecker( validate ) {
  function checkType(
    isRequired,
    props,
    propName,
    componentName,
    location,
    propFullName,
    ...rest
  ) {
    componentName = componentName || '<<anonymous>>';
    propFullName = propFullName || propName;

    if ( _.isNil( props[ propName ] ) ) {
      if ( isRequired ) {
        const value = props[ propName ] === null ? 'null' : 'undefined';
        return new PropTypeError(
          `The ${location} \`${propFullName}\` is marked as required ` +
          `in \`${componentName}\`, but its value is \`${value}\`.`,
          props, propName, componentName, location, propFullName,
        );
      }
      return null;
    }

    return validate(
      props,
      propName,
      componentName,
      location,
      propFullName,
      ...rest,
    );
  }

  const chainedCheckType = checkType.bind( null, false );
  chainedCheckType.isRequired = checkType.bind( null, true );

  return chainedCheckType;
}

function createCallbackTypeChecker( callback, message ) {
  return function callbackTypeChecker(
    props, propName, componentName, location, fullName,
  ) {
    componentName = componentName || '<<anonymous>>';
    fullName = fullName || propName;

    const err = msg => {
      return new PropTypeError(
        `Invalid ${location} \`${fullName}\` in \`${componentName}\`: ${msg}`,
        props, propName, componentName, location, fullName,
      );
    };

    if ( typeof callback !== 'function' ) {
      return err(
        'callback passed to createCallbackTypeChecker must be a function',
      );
    }
    if ( typeof message !== 'string' ) {
      return err(
        'message passed to createCallbackTypeChecker must be a string',
      );
    }

    const res = callback( props[ propName ], props, propName );
    if ( res === true ) {
      return null;
    } else if ( res === false ) {
      return err( message );
    } else {
      return err( `validator for custom propTypes must return boolean` );
    }
  };
}

// These are the main API
export function createPropType( callback, message ) {
  return createChainableTypeChecker(
    createCallbackTypeChecker( callback, message ),
  );
}
