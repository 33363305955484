import _ from 'lodash';
import React from 'react';
import { PropTypes } from '~/utils';
import { useShortcuts, useToggle } from '~/hooks';
import { Modal } from '~/components';
import { PropTable } from './PropTable';

export function DebugPanel( props ) {
  const {
    shortcut, title, name, component : Component, render, table, tables,
  } = props;
  const [ isOpen, toggle ] = useToggle();
  useShortcuts( {
    category    : 'Debug Tools',
    description : title,
    name        : name || `debug/${_.snakeCase( title )}`,
    handler     : toggle,
    keys        : shortcut,
  } );
  return (
    <Modal isOpen={isOpen} size='lg' scrollable toggle={toggle} title={title}>
      {Component && isOpen && <Component />}
      {render && isOpen && render()}
      {table && isOpen && <DebugPanelTable data={table} />}
      {tables && isOpen && <DebugPanelTables tables={tables} />}
    </Modal>
  );
}
DebugPanel.propTypes = {
  shortcut  : PropTypes.string.isRequired,
  title     : PropTypes.string.isRequired,
  name      : PropTypes.string,
  component : PropTypes.renderable,
  render    : PropTypes.func,
  table     : PropTypes.oneOfType( [
    PropTypes.shape( DebugPanelTable.propTypes ),
    PropTypes.func,
  ] ),
  tables    : PropTypes.oneOfType( [
    PropTypes.shape( DebugPanelTables.propTypes ),
    PropTypes.func,
  ] ),
};

export function DebugPropTable( props ) {
  const data = _.result( props, 'data' );
  if ( ! data ) return null;
  const opts = _.defaults( _.omit( props, 'data' ), {
    displayObjectSize   : false,
    displayDataTypes    : false,
  } );
  if ( _.isArray( data ) ) {
    const res = _.map( data, ( d, i ) => (
      <PropTable key={i} data={d} {...opts} />
    ) );
    return <>{res}</>;
  } else {
    return <PropTable data={data} {...opts} />;
  }
}

export function DebugPanelTable( props ) {
  const data = _.result( props, 'data' );
  if ( ! data ) return null;

  return (
    <>
      {props.title && <h1>{props.title}</h1>}
      <DebugPropTable data={data} />
    </>
  );
}
DebugPanelTable.propTypes = {
  title   : PropTypes.string,
  data    : PropTypes.oneOfType( [
    PropTypes.shape( {} ),
    PropTypes.func,
  ] ),
};

export function DebugPanelTables( props ) {
  const tables = _.result( props, 'tables' );
  if ( ! tables ) return null;
  const res = _.map( tables, ( data, title ) => (
    <DebugPanelTable key={title} title={title} data={data} />
  ) );
  return <>{res}</>;
}
DebugPanelTables.propTypes = {
  tables    : PropTypes.oneOfType( [
    PropTypes.shape( DebugPanelTable.propTypes ),
    PropTypes.objectOf( DebugPanelTable.propTypes ),
    PropTypes.objectOf( PropTypes.shape( {} ) ),
    PropTypes.func,
  ] ).isRequired,
};
