import { Input } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';

export function Email( { field } ) {
  const input = useInput( field, { type : 'email' } );
  return <Input {...input} />;
}
Email.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
