import { createStore } from '@ssp/utils';
import { useStore } from '~/hooks';

import type { TResource } from '@ssp/database';

export interface AuthStatePending {
  state: 'pending';
}
export interface AuthStateLoading {
  state: 'loading';
}
export interface AuthStateAuthenticated {
  state: 'authenticated';
  subject: TResource<'SSP.User'>;
  settings: TResource<'SSP.User.Settings'>;
  impersonator?: TResource<'SSP.User'>;
}
export interface AuthStateErrored {
  state: 'error';
  error: string | Error;
}
export type AuthStoreState =
  | AuthStatePending
  | AuthStateLoading
  | AuthStateAuthenticated
  | AuthStateErrored;

export type AuthState = AuthStoreState['state'];

declare module '@ssp/utils' {
  export interface GlobalStores {
    auth: AuthStoreState;
  }
}

declare module '@ssp/preload' {
  export interface SSP {
    user: TResource<'SSP.User'>;
  }
}

export const authstore = createStore<AuthStoreState>( {
  name        : 'auth',
  description : 'Authentication Information',
  state       : { state : 'pending' },
} );

export function updateAuthStore( data: AuthStoreState ) {
  if ( data.state === 'authenticated' ) {
    const { subject, impersonator } = data;
    if ( typeof SSP !== 'undefined' ) {
      SSP.user = subject;
      if ( SSP.Sentry ) {
        SSP.Sentry.setUser( {
          id       : subject._id,
          username : subject.username,
          email    : subject.email,
          ...( impersonator ? { impersonator : String( impersonator ) } : {} ),
        } );
      }
    }
    ( data as any ).error = null;
  }
  authstore.setState( data );
}

export function useAuthStore(): AuthStoreState|undefined {
  const a = useStore<AuthStoreState>( 'auth' );
  if ( ! a ) return;
  if ( a.state === 'error' ) {
    log.debug( 'useAuthStore error:', a.error );
    throw a.error;
  }
  return a;
}

export function useAuthState(): AuthState {
  const store = useAuthStore();
  return store?.state;
}
