import _ from 'lodash';
import { Watchable } from '@ssp/database';
import { PropTypes } from '~/utils';
import { useMemo, useWatchable } from '~/hooks';
import { Col } from 'reactstrap';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Breadcrumb } from './Breadcrumb';
import { BreadcrumbTrail } from './BreadcrumbTrail';
import { normalizeBreadcrumbs } from './utils';

export const BreadcrumbsWrapper = styled( Col )( ( { theme } ) => css`
  padding: 10px 0 0 0;
  color: ${theme.breadcrumbs.inactive};
  .caret {
    margin: 0 10px;
  }
  > div { display: inline; }
  span.bc-label {
    display: inline;
    color: ${theme.breadcrumbs.inactive};
    font-weight: 700;
  }
  a.bc-label {
    display: inline;
    padding: unset;
  }
  button.bc-popover-trigger {
    background-color: ${theme.defaults.bg};
    border: 1px dashed ${theme.breadcrumbs.inactive};
    color: ${theme.text};
    &:hover {
      background-color: ${theme.defaults.bg};
      color: ${theme.text};
      border: 1px dashed ${theme.defaults.fg};
    }
  }
` );

function ResourceBreadcrumbs( { resource } ) {
  const rsrc = Watchable.unwrap( resource );
  const trail = useMemo( () => new BreadcrumbTrail( rsrc ), [ rsrc ] );
  const bread = useWatchable( trail );
  if ( ! bread.loaded ) return null;
  return <NormalBreadcrumbs breadcrumbs={[ ...bread.items ]} />;
}
ResourceBreadcrumbs.propTypes = {
  resource  : PropTypes.resource,
};

function NormalBreadcrumbs( props ) {
  if ( ! props.breadcrumbs ) return null;
  const breadcrumbs = normalizeBreadcrumbs( props.breadcrumbs );
  if ( ! breadcrumbs ) return null;
  return (
    <BreadcrumbsWrapper data-testid="bc-wrapper">
      {_.compact( breadcrumbs.flatMap( crumb => ( [
        <Breadcrumb key={crumb.index} {...crumb} />,
        ( ! crumb.last ) && (
          <span key={`caret-${crumb.index}`} className="caret">&gt;</span>
        ),
      ] ) ) )}
    </BreadcrumbsWrapper>
  );
}
NormalBreadcrumbs.propTypes = {
  breadcrumbs : PropTypes.arrayOf( PropTypes.shape( Breadcrumb.propTypes ) ),
};

export function Breadcrumbs( { resource, breadcrumbs } ) {
  if ( resource ) {
    return <ResourceBreadcrumbs resource={resource} />;
  } else {
    return <NormalBreadcrumbs breadcrumbs={breadcrumbs} />;
  }
}
Breadcrumbs.propTypes = {
  resource    : ResourceBreadcrumbs.propTypes.resource,
  breadcrumbs : PropTypes.arrayOf( PropTypes.shape( Breadcrumb.propTypes ) ),
};

