import _ from 'lodash';
import { DataItem } from '../DataItem';
import { PropTypes } from '~/utils';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Container = styled( 'div' )( ( { theme } ) => css`
  padding: 0;
  div > span.label {
    color: ${theme.dataview.label};
    font-weight: 700;
    padding-right: 0;
  }
` );

function LabelAndValue( { component : Item, ...props_in } ) {
  const { label } = props_in;
  const item = <Item {...props_in} />;
  return (
    <div>
      <span className="label">{label}</span>&nbsp;
      <span>{item}</span>
    </div>
  );
}
LabelAndValue.propTypes = {
  component   : PropTypes.elementType.isRequired,
  label       : PropTypes.string.isRequired,
  className   : PropTypes.className,
};

export function LabelsAndValues( { items, ...props } ) {
  return (
    <Container fluid>
      {_.map( items, item => (
        <LabelAndValue
          key={item.name}
          {...props}
          {...item}
        /> ) )}
    </Container>
  );
}
LabelsAndValues.propTypes = {
  items : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
};
