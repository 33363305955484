import _ from 'lodash';
import { Badge } from 'reactstrap';
import { useTheme } from '@emotion/react';
import { useResourceContext } from '~/hooks';
import { Button } from '~/widgets';
import { useMembers } from '../context';
import { Confirmation } from './Confirmation';
import { AddRemoveForm } from './AddRemoveForm';
import { DebugBadges } from './DebugBadges';

export function Controls() {
  const action = useAction();
  const theme = useTheme() as any;
  return (
    <div className="py-2 sticky-top mr-auto"
      css={{ backgroundColor : theme.content_pane }}
    >
      <div>
        <Buttons />
        <DebugBadges />
        <SummaryOfChanges />
      </div>
      {action}
    </div>
  );
}

function useAction() {
  const members = useMembers();
  switch ( members.action ) {
    case 'confirm': return <Confirmation />;
    case 'add': return <AddRemoveForm />;
    case 'remove': return <AddRemoveForm />;
    default: return null;
  }
}

function SummaryOfChanges() {
  const members = useMembers();
  const { adding, removing } = _.countBy( members.members, 'status' );

  return (
    <h5 className="mt-3 mb-2 float-right mr-2">
      <Badge pill>{members.size} members</Badge>
      {adding > 0 && <Badge color="success" pill>{adding} added</Badge>}
      {removing > 0 && <Badge color="danger" pill>{removing} removed</Badge>}
    </h5>
  );
}

function Buttons() {
  const members = useMembers();
  const resource = useResourceContext();
  if ( ! members.can_manage ) return null;

  if ( resource.is_archived ) {
    return <h2>Archived Resource - Cannot manage members</h2>;
  }
  return (
    <>
      <Button
        spaced
        label='Add Members'
        icon='fad:user-plus'
        onClick={() => members.update( { action : 'add' } )}
      />
      <Button
        spaced
        label='Remove Members'
        icon='fad:user-times'
        onClick={() => members.update( { action : 'remove' } )}
      />
    </>
  );
}
