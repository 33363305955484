import { useShortcuts } from './hooks';
import { useSubject } from '~/hooks';

const supportShortcuts = [
  {
    name        : 'admin/home',
    keys        : 'A a',
    description : 'Admin Home Page',
    category    : 'Administration',
    href        : '/admin',
  },
  {
    name        : 'admin/orphanage',
    keys        : 'A o',
    description : 'Orphanage',
    category    : 'Administration',
    href        : '/project/ORPHANAGE',
  },
  {
    name        : 'admin/actions',
    keys        : 'A r',
    description : 'Resource Actions',
    category    : 'Administration',
    href        : '/admin/actions',
  },
  {
    name        : 'admin/search',
    keys        : 'A s',
    description : 'Global Search',
    category    : 'Administration',
    href        : '/admin/search',
  },
];

export function useSupportShortcuts() {
  const subject = useSubject();
  return useShortcuts( subject?.isSupport() ? supportShortcuts : [] );
}
