import { CustomInput } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';

export function Checkbox( { field } ) {
  const input = useInput( field, { type : 'checkbox' } );
  const message = field.get( 'message' );
  return <CustomInput {...input} label={message} field={field} />;
}
Checkbox.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
