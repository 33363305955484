import { useState } from 'react';
import { Col, FormGroup } from 'reactstrap';
import { cx, PropTypes, useFieldError } from '~/forms/utils';
import {
  FieldLabel, FieldState, FieldInput, FieldError, FieldHelp,
  FieldErrorBoundary,
} from '..';

export function FieldElementNormal( props ) {
  const { field } = props;
  const [ labelProps, setLabelProps ] = useState( {} );
  const error = useFieldError( field.name );
  const classes = cx( field.groupClassName, { 'has-error' : error } );

  const { valueWidth } = field;
  return (
    <FieldErrorBoundary {...props}>
      <FormGroup row className={classes}>
        {field.label === false ? (
          <Col>
            <FieldInput {...props} setLabelProps={setLabelProps} />
            <FieldHelp {...props} />
            <FieldError {...props} error={error} />
          </Col>
        ) : (
          <>
            <FieldLabel field={field} {...labelProps} />
            <Col sm={valueWidth}>
              <FieldInput {...props} setLabelProps={setLabelProps} />
              <FieldHelp {...props} />
              <FieldError {...props} error={error} />
            </Col>
          </>
        )}
        <FieldState {...props} />
      </FormGroup>
    </FieldErrorBoundary>
  );
}
FieldElementNormal.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};
