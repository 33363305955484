import _ from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavItem as NavItemRS } from 'reactstrap';
import { Schema } from '@ssp/database';

import { PropTypes, cx } from '~/utils';
import { Link, useLocation } from '~/router';
import { Icon } from '~/icons';
import { TabItem } from './TabItem';
import { TabBuilder } from './TabBuilder';
import { useDebugFlag } from '~/hooks';

export const NavItem = styled( NavItemRS )( ( { theme, active, indent } ) => {
  const colors = theme.routertabs;
  return [
    css`
      background-color: ${colors.bg};
      color: ${colors.fg};
      display: flex;
      align-items: center;
      span { vertical-align: middle; }
      a {
        color: inherit;
        background-color: inherit;
        display: block;
        width: 100%;
        text-decoration: none;
        vertical-align: middle;
      }
      svg {
        margin-right: 0.5em;
        margin-bottom: 0.1em;
        color: ${theme.icon.fg};
      }
    `,
    active && css`
      background-color: ${colors.active.bg};
      color: ${colors.active.fg};
    `,
    indent && css`padding-left: ${indent}em;`,
  ];
} );

export function Tab( props ) {
  const showAllTabs = useDebugFlag( 'show-all-tabs' );
  const { tab, tree, index, counts={} } = props;
  const location = useLocation();
  const active = Boolean( tab.matches( location.pathname ) );

  if ( ! ( tab.visible || showAllTabs ) ) return null;

  // If it's a hidden route (but has a title) then we only render it's
  // tab when it's active
  if ( tab.hidden && ! active ) return null;

  const count = counts[ tab.count || tab.service ];
  const schema = tab.service && Schema.get( tab.service );

  const hideZero = tab.hideZero ?? schema?.hideZero;
  const isZero = count === 0 || _.isNil( count );
  if ( isZero && ( hideZero || tab.attachable === false ) ) return null;

  const opts = _.pick( props, 'disabled' );
  _.assign( opts, {
    active, indent : tree ? index : 0,
    className : cx( props.className, 'router-tab' ),
  } );
  if ( tab.titles ) {
    _.assign( opts, {
      'data-title'    : tab.titles[0],
      'data-titles'   : tab.titles[1],
    } );
  } else {
    _.assign( opts, { 'data-title' : tab.title } );
  }
  _.assign( opts, { 'data-service' : tab.service } );

  const title = tab.getTitleFor( count );

  return (
    <NavItem {...opts}>
      <Link to={tab.url}>
        <Icon icon={tab.icon} fw size="2x" />
        <span className="title">
          {title}
          {_.isFinite( count ) && <span className="count ml-1">({count})</span>}
        </span>
        {tab.subtitle && <small className="subtitle">{tab.subtitle}</small>}
      </Link>
    </NavItem>
  );
}
Tab.propTypes = {
  tab       : PropTypes.instanceOf( TabItem ).isRequired,
  active    : PropTypes.bool,
  tree      : PropTypes.bool,
  index     : PropTypes.number,
  config    : PropTypes.instanceOf( TabBuilder ),
  counts    : PropTypes.objectOf( PropTypes.number ),
  className : PropTypes.className,
};
