import { useFormState } from '~/forms/utils';
import { useDebugFlag } from '~/utils';
import { Dump } from '~/widgets';
import { Panel } from '~/components';

export function FormData() {
  const flag = useDebugFlag( 'show-form-data' );
  if ( ! flag ) return null;
  return <FormDataBody />;
}
function FormDataBody() {
  const { values } = useFormState( 'values' );
  return ( <Panel title="Form Data"><Dump data={values} /></Panel> );
}
