import { ButtonToolbar } from 'reactstrap';
import { css } from '@emotion/react';
import { Search, SearchInfo } from './Search';
import { Sort } from './Sort';
import { Filters } from './Filters';
import { AddResourceButton } from './AddResourceButton';
import { ViewSwitch } from './ViewSwitch';
import { Actions } from './Actions';
import styled from '@emotion/styled';

const CVCWrapper = styled( 'div' )( ( { theme } ) => css`
  .btn {
    background-color: ${theme.buttons.bg};
    color: ${theme.buttons.fg};
    border: ${theme.buttons.border};
  }

  & > .btn-toolbar {
    & > .btn-group {
      margin-right: 8px;
    }

    & > .btn {
      margin-right: 8px;
    }

    & > div {
      margin-right: 8px;
      > h4 {
        margin-top: 8px;
      }
    }
  }
` );

export function Controls() {
  return (
    <CVCWrapper className="collection-view-controls">
      <Search />
      <ButtonToolbar className="d-flex my-2 align-items-center">
        <ViewSwitch />
        <Filters />
        <Sort />
        <AddResourceButton />
        <Actions />
        <SearchInfo className="align-self-center" />
      </ButtonToolbar>
    </CVCWrapper>
  );
}
