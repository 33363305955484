import { useWatchable } from '~/hooks';

import { Member, Status } from '../Member';
import { useMembers } from '../context';

import { MemberTools } from './MemberTools';
import { MemberEditor } from './MemberEditor';
import { MemberDetail } from './MemberDetail';
import { MemberDebug } from './MemberDebug';
import { MemberStatus } from './MemberStatus';

const classes: Omit<{ [Key in Status]: string|undefined; }, 'current'> = {
  error    : 'table-error',
  loading  : 'table-warning',
  adding   : 'table-success',
  updating : 'table-info',
  removing : 'table-warning',
} as const;

function MemberError( { member, columns }: {
  member: Member;
  columns: number;
} ) {

  let label = 'Error';
  let error = '';
  if ( member.error ) {
    error = member.error;
  } else if ( member.old_change?.error ) {
    label = 'Pending Change Error';
    error = member.old_change.error;
  }
  if ( ! error ) return null;
  return (
    <tr>
      <td colSpan={columns} className='table-danger'>{label}: {error}</td>
    </tr>
  );
}

export function MemberRow( props: { member: Member; } ) {
  const members = useMembers();
  const member = useWatchable( props.member );
  const { config_field_names, show_tools, total_columns } = members;

  return (
    <>
      <tr className={classes[ member.status ]}>
        <th
          css={{ cursor: 'pointer' }}
          scope="row"
          tabIndex={0}
          onClick={member.toggleDetail}
        >{member.name}</th>
        <td
          css={{ cursor: 'pointer' }}
          onClick={member.toggleDetail}
          aria-hidden
        ><a href={`mailto:${member.email}`}>{member.email}</a></td>
        {config_field_names.map( name => (
          <td key={name}>{member.getDisplayValue( name )}</td>
        ) )}
        <MemberStatus member={member} />
        {show_tools && <MemberTools member={member} />}
      </tr>
      <MemberError columns={total_columns} member={member} />
      {member.showingEditor ? <MemberEditor member={member} /> : null}
      {member.showingDetail ? <MemberDetail member={member} /> : null}
      <MemberDebug member={member} />
    </>
  );
}
