import { Page } from '~/layout';
import { NotFoundError } from '~/widgets';

export function NotFoundPage() {
  return (
    <Page title='Page Not Found' icon='far:search-minus'>
      <NotFoundError />
    </Page>
  );
}
