import { Page } from '~/layout';

import { SearchPage } from './SearchPage';
import { AdminHome } from './AdminHome';
import { ActionsPage } from './ActionsPage';

function handleBuilder( builder ) {
  builder.group( 'main' ).add(
    {
      path      : '',
      exact     : true,
      title     : 'Admin Home',
      icon      : 'far:badger-honey',
      component : AdminHome,
    },
    {
      path      : 'search',
      title     : 'Search Globally',
      exact     : false,
      icon      : 'fas:search',
      component : SearchPage,
    },
    {
      path      : 'actions',
      title     : 'Resource Actions',
      exact     : true,
      icon      : 'fas:search',
      component : ActionsPage,
    },
  );
}

export default function AdminPage( props ) {
  return (
    <Page title='Admin Tools' icon='far:badger-honey' builder={handleBuilder} />
  );
}
