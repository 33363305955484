import { PropTypes, cx } from '~/utils';

export function Alert( { className, color, children } ) {
  const classes = cx( className, 'alert', `alert-${color}` );
  return ( <div className={classes}>{children}</div> );
}
Alert.propTypes = {
  color     : PropTypes.reactstrapColor,
  className : PropTypes.className,
  children  : PropTypes.children,
};
Alert.defaultProps = {
  color : 'success',
};
