import _ from 'lodash';
import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cx, PropTypes } from '~/utils';
import { Tooltip } from '~/widgets/Tooltip';
import { useToggle, useRef } from '~/hooks';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from '~/router';

const propMap = {
  fw    : 'fixedWidth',
  li    : 'listItem',
};

const IconWrapper = styled.span( ( props ) => {
  const { pointer, color, background } = props;
  return css`
    vertical-align: inherit;

    .fa.disabled,
    .fa[disabled],
    .disabled > .fa,
    [disabled] > .fa
    { opacity: 0.5; }
    white-space: nowrap;
    span { margin-left: 0.3em; }
    ${pointer ? 'cursor: pointer;' : ''}
    ${color ? `color: ${color}` : ''}
    ${background ? `background: ${background}` : ''}
  `;
} );

export function Icon( props_in ) {
  /* eslint-disable prefer-const */
  let {
    icon, name, mask, className, disabled, label, color, background,
    to, tooltip, offset, placement, pointer,
    onClick, matchLinkToColor, ...props
  } = props_in;
  /* eslint-enable prefer-const */
  _.each( propMap, ( tgt, src ) => {
    if ( ! props[ src ] ) return;
    props[ tgt ] = props[ src ];
    delete props[ src ];
  } );
  const iconinfo = _.compact( ( icon || name || 'ssp:blank' ).split( ':' ) );
  props.icon = iconinfo.splice( 0, 2 );
  const classes = [ 'icon', className, { disabled } ];
  for ( const info of iconinfo ) {
    if ( info.startsWith( 'fa-' ) ) {
      classes.push( info );
    } else {
      log.warn( `Unknown icon option "${info}"` );
    }
  }
  if ( props.icon.length !== 2 ) {
    log.warn( 'INVALID ICON', { icon, name } );
  }
  props.className = cx( classes );
  if ( mask ) props.mask = mask.split( ':' );
  if ( onClick && _.isNil( pointer ) ) pointer = true;

  const [ tooltipOpen, toggleTooltipOpen ] = useToggle();
  const ref = useRef();

  _.defaults( props, {
    'aria-hidden' : false,
    'aria-label'  : label || props.title,
  } );

  icon = [ <FontAwesomeIcon {...props} key="icon" /> ];
  if ( label ) icon.push( <span key="label">{label}</span> );
  if ( to ) icon = <Link to={to}>{icon}</Link>;
  if ( to && matchLinkToColor && color ) {
    const style = css`
      a, a:link {
        color : ${color};
        text-decoration: underline;
      }
    `;
    icon = <Link css={style} to={to}>{icon}</Link>;
  }

  const tooltip_props = {
    key       : 'tooltip',
    isOpen    : tooltipOpen,
    target    : ref,
    toggle    : toggleTooltipOpen,
    placement : placement || 'bottom',
    offset    : offset || '0, 10',
  };
  const outer_props = { pointer, onClick, color, background, ref };

  return (
    <Fragment>
      <IconWrapper {...outer_props}>{icon}</IconWrapper>
      {tooltip && <Tooltip {...tooltip_props}>{tooltip}</Tooltip>}
    </Fragment>
  );
}
Icon.propTypes = {
  border           : PropTypes.bool,
  pointer          : PropTypes.bool,
  onClick          : PropTypes.func,
  className        : PropTypes.className,
  mask             : PropTypes.string,
  fixedWidth       : PropTypes.bool,
  inverse          : PropTypes.bool,
  flip             : PropTypes.oneOf( [ 'horizontal', 'vertical', 'both' ] ),
  icon             : PropTypes.string,
  listItem         : PropTypes.bool,
  placement        : PropTypes.string,
  pull             : PropTypes.oneOf( [ 'left', 'right' ] ),
  pulse            : PropTypes.bool,
  rotation         : PropTypes.oneOf( [ 90, 180, 270 ] ),
  size             : PropTypes.oneOf( [
    'xs', 'sm', 'lg', ..._.map( _.range( 1, 10 ), x => `${x}x` ),
  ] ),
  offset           : PropTypes.string,
  spin             : PropTypes.bool,
  symbol           : PropTypes.oneOfType( [
    PropTypes.string, PropTypes.bool,
  ] ),
  title            : PropTypes.string,
  transform        : PropTypes.oneOfType( [
    PropTypes.string, PropTypes.object,
  ] ),
  disabled         : PropTypes.bool,
  color            : PropTypes.string,
  background       : PropTypes.string,
  label            : PropTypes.string,
  to               : PropTypes.string,
  tooltip          : PropTypes.string,
  tooltip_props    : PropTypes.object,
  matchLinkToColor : PropTypes.bool,
  fw               : PropTypes.bool,
  li               : PropTypes.bool,
};
Icon.defaultProps = {
  disabled    : false,
  border      : false,
  className   : null,
  mask        : null,
  fixedWidth  : false,
  inverse     : false,
  flip        : null,
  icon        : null,
  listItem    : false,
  pull        : null,
  pulse       : false,
  rotation    : null,
  size        : null,
  spin        : false,
  symbol      : false,
  title       : null,
  transform   : null,
};
