import { Alert } from 'reactstrap';
import { useMembers } from '../context';
import { Button } from '~/widgets';
import { Icon } from '~/icons';

export function SaveChangesButton() {
  const members = useMembers();

  if ( members.is_saving ) {
    return (
      <ButtonWrapper heading='Saving Changes!'>
        <Icon icon='fad:sync' spin label='Saving Changes' />
      </ButtonWrapper>
    );
  }
  if ( members.action === 'confirm' ) {
    return (
      <>
        <ButtonWrapper heading='Please confirm changes!'>
          <Button
            spaced large
            label='Go Back'
            icon='fas:chevron-circle-left'
            onClick={members.cancel}
          />
          <Button
            spaced large
            label='Confirm'
            icon='far:check'
            onClick={members.commit}
          />
        </ButtonWrapper>
      </>
    );
  }
  if ( members.can_save ) {
    return (
      <ButtonWrapper heading='You have unsaved changes!'>
        <Button
          spaced large
          label='Save Changes'
          icon='fad:cloud-upload'
          onClick={() => members.act( 'confirm' )}
        />
      </ButtonWrapper>
    );
  }
  if ( members.has_changes && members.is_loaded ) {
    return (
      <ButtonWrapper heading='Changes not saved!'>
        <p> Please edit or remove rows with error. </p>
      </ButtonWrapper>
    );
  }
  return null;
}
function ButtonWrapper(
  { heading, children }: { heading: string; children: any },
) {
  return (
    <>
      <div className="p-3" />
      <div className="fixed-bottom d-flex justify-content-end mr-2">
        <Alert className="d-flex align-items-center" color="warning">
          <h4 className="alert-heading pr-2">{heading}</h4>
          {children}
        </Alert>
      </div>
    </>
  );
}
