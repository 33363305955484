import _ from 'lodash';
import {
  Watchable, isResource, isProject, isUser, isResourceType,
} from '@ssp/database';
import { mkdebug, background } from '@ssp/utils';
import {
  stringifyBreadcrumbs, BreadcrumbOptions, normalizeBreadcrumbs,
} from './utils';

import type { TResource } from '@ssp/database';

const debug = mkdebug( 'ssp:client:breadcrumbs' );

export class BreadcrumbTrail extends Watchable {

  items: BreadcrumbOptions[] = [];
  loaded: boolean = false;

  declare _loading: Promise<any>;

  get loading() { return !! this._loading; }

  constructor( public readonly resource: TResource<any> ) {
    super();
    debug( 'Starting BreadcrumbTrail with', resource );
  }

  add( item ) {
    if ( _.isNil( item ) ) return;
    debug( 'Adding:', item );
    this.items.unshift( item );
    this.changed();
  }

  async getInfo( resource: TResource<any> ) {
    if ( ! isResource( resource ) ) return;
    // Make sure the resource is loaded
    try {
      await resource.load();
    } catch ( err ) {
      log.error( `Got error loading resource ${resource}:`, err, resource );
      throw err;
    }
    const type = resource.schema.id;
    const info = { resource, url : resource.route(), type };

    if ( typeof resource.getBreadcrumbInfo === 'function' ) {
      _.assign( info, await resource.getBreadcrumbInfo() );
    }

    if ( type !== 'SYSTEM.Job' ) {
      // If it has a different owner than the top, add the owner to
      // the info payload (except jobs are special)
      const owner = await this.getOwner( resource );
      if ( owner && owner._id !== this.owner?._id ) info.owner = owner;
    }

    return info;
  }

  load() {
    if ( ! this.resource ) return;
    if ( this.loaded ) return;
    if ( this._loading ) return this._loading;
    const promise = this._load();
    this._loading = promise;
    background( promise.then( ( res ) => {
      delete this._loading;
      return res;
    } ) );
    return promise;
  }

  declare owner: TResource<any>;

  async _load() {
    try {
      // We start with the end of the list, this will be the last item
      // in the resulting breadcrumbs trail.
      let iter = this.resource;
      await this.resource.load();

      // Record it's owner to detect intermediate links owned by
      // different projects
      this.owner = await this.getOwner( this.resource );

      // Keep track of the resources we've seen already, so we don't end
      // up in a loop
      const seen = [];

      // bail out if somebody gets all recursy
      while ( iter && ! seen.includes( iter._id ) ) {
        seen.push( iter._id );

        // Get the information for the current resource and push it
        // into the loop.
        const info = await this.getInfo( iter );
        if ( info ) this.add( info );

        const next = this.getNext( iter );
        if ( next ) await next.load();
        // Then get the information for the resource type, since the
        // list of those types should be the next item up the trail.
        const type = this.getTypeInfo( iter, next );
        if ( type ) this.add( type );

        // Then move up to the next item above this one in the heirarchy
        iter = next;
      }
      this.loaded = true;
      this.changed();
    } catch ( err ) {
      log.error( 'ERROR LOADING BREADCRUMBS:', err );
      throw err;
    }
  }

  async getOwner( rsrc ) {
    if ( ! rsrc ) return;
    // Projects and Users don't have owners
    if ( isProject( rsrc ) || isUser( rsrc ) ) return;
    const owner = _.isFunction( rsrc.getBreadcrumbOwner )
      ? await rsrc.getBreadcrumbOwner() : rsrc.owner;
    if ( owner ) return owner.load();
    await rsrc.reload();
    if ( rsrc.owner ) {
      return rsrc.owner.load();
    } else {
      log.error( 'Unable to find owner for:', rsrc );
    }
  }

  getNext( rsrc ) {
    if ( ! isResource( rsrc ) ) return;

    // if this item has a different owner than our resource, then we
    // skip back to our owner to finish the path (unless it's a Job,
    // because they are owned by people and not resources)
    if ( rsrc.schema.id !== 'SYSTEM.Job' ) {
      if ( rsrc.owner && this.owner ) {
        if ( rsrc.owner._id !== this.owner._id ) return this.owner;
      }
    }

    if ( _.isFunction( rsrc.getBreadcrumbParent ) ) {
      return rsrc.getBreadcrumbParent();
    }
    if ( _.isFunction( rsrc.getParent ) ) { return rsrc.getParent(); }
    return rsrc.owner;
  }

  getTypeInfo( rsrc, next ) {
    if ( ! isResource( rsrc ) ) return;
    if ( isUser( rsrc ) ) return { label : 'Users', url : '/users' };
    if ( isProject( rsrc ) ) return { label : 'Projects', url : '/projects' };
    if (
      isResourceType( 'GitHub.Repository', rsrc )
      || isResourceType( 'GitHub.Team', rsrc )
    ) {
      return {
        label : rsrc.schema.plural_name,
        url   : rsrc.project.route( rsrc.schema.id ),
      };
    }
    if ( isResourceType( 'SYSTEM.Job', rsrc ) ) {
      return {
        label : 'Jobs',
        url   : next ? next.route( '/jobs' ) : '/jobs',
      };
    }
    return {
      label : rsrc.schema.plural_name,
      url   : next?.route( rsrc.schema.id ),
    };
  }

  toString() { return stringifyBreadcrumbs( this.items ); }
  toArray() { return normalizeBreadcrumbs( this.items ); }
}
