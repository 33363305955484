import { PropTypes } from '~/utils';
import { ResourceView } from '~/views';
import { ActionPanel } from '~/actions';
import { Panel } from '~/components';
import { useResource } from '~/hooks';

export function SummaryPage( props ) {
  const resource = useResource( props.resource, { label : 'SummaryPage' } );

  return (
    <>
      <ActionPanel resource={resource} />
      <Panel title="Summary" body>
        <ResourceView resource={resource} face='summary' view='panel' />
      </Panel>
    </>
  );
}
SummaryPage.propTypes = {
  resource   : PropTypes.resource.isRequired,
};
