import { TourId } from './hooks';

export type TourMetadata = {
  [ id : TourId ]: {
    version : number,
    title : string,
    goToRoute? : string,
    autoLaunchRoute? : RegExp,
  }
}

export const tourMetadata : TourMetadata = {
  'intro'          : { version: 1, title: 'Introduction to Tours' },
  'getting-around' : { version: 1, title: 'Getting Around SSP' },
  // 'project-members' : {
  //   version: 1,
  //   title: 'Members Page',
  //   autoLaunchRoute: /^\/project\/[^/]+\/members$/u,
  // },
};
