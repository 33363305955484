import _ from 'lodash';
import { PropTypes } from '~/forms/utils';
import styled from '@emotion/styled';
import { SubmitButton, CancelButton, GenerateButton, FormButton } from '.';

const FormButtonsWrapper = styled.div( {
  button : { marginRight : '1em' },
} );

export function FormButtons( props ) {
  return (
    <FormButtonsWrapper>
      {BUILD.isDev && <GenerateButton {...props} />}
      {_.map( props.config.extra_buttons, button => (
        <FormButton key={button.label} className="float-left" {...button}>
          {button.label}
        </FormButton>
      ) )}
      <CancelButton {...props} />
      <SubmitButton {...props} />
    </FormButtonsWrapper>
  );
}
FormButtons.propTypes = {
  config  : PropTypes.formConfig.isRequired,
};
