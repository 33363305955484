import { getSchema } from '@ssp/database';
import { PropTypes } from '~/forms/utils';
import { Input as InputRS } from 'reactstrap';
import styled from '@emotion/styled';

type InputWrapperProps = {
  isOpen: boolean;
};
const InputWrapper = styled( InputRS )<InputWrapperProps>(
  {
    width         : '100%',
    wordWrap      : 'break-word',
    lineHeight    : '1em',
    whiteSpace    : 'normal',
    background    : '#fff',
    display       : 'inline-block',
    color         : 'rgba(0,0,0,.87)',
    boxShadow     : 'none',
    border        : '1px solid rgba(34,36,38,.15)',
    borderRadius  : '.30rem',
    transition    : 'box-shadow .1s ease, width .1s ease',
  },
  ( { isOpen } ) => ( isOpen && { borderBottomRightRadius : 0 } ),
);

export function Input( props_in ) {
  const { type, getInputProps, openMenu } = props_in;

  const props = getInputProps( type ? {
    disabled    : false,
    placeholder : `Select ${getSchema( type ).name}...`,
    onClick     : () => { openMenu(); },
  } : {
    disabled    : true,
    placeholder : '<-----',
  } );
  return <InputWrapper {...props} />;
}
Input.propTypes = {
  type          : PropTypes.string,
  isOpen        : PropTypes.bool,
  getInputProps : PropTypes.func.isRequired,
};
