import { Page } from '~/layout';
import { useRouteParam, useMemo } from '~/hooks';
import { ComponentView } from './ComponentView';
import { getModel } from '@ssp/database';

export default function ComponentPage() {
  const type = useRouteParam( 'resource_type' );
  const comp = useMemo( () => getModel( type ), [ type ] );

  return (
    <Page component={comp} title={comp?.schema.name}>
      <ComponentView component={comp} />
    </Page>
  );
}
