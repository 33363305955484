import _ from 'lodash';
import { Container, Col, Row } from 'reactstrap';
import { PropTypes } from '~/utils';
import { Dump, Alert } from '~/widgets';
import { Link, useRouteParams, useLocation, useRouteMatch } from '~/router';
import { useCallback } from '~/hooks';
import { MoreInfo } from './MoreInfo';
import Ansi from 'ansi-to-react';
import { Button } from './Button';

function Err( props ) {
  const { label, value : val } = props;
  return [
    <h2 key="label">{label}</h2>,
    <Dump key="value" data={val} />,
  ];
}
Err.propTypes = {
  label : PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value : PropTypes.any.isRequired,
};

export function MoreErrorBox( { error, info, name, ...props } ) {
  const params = useRouteParams();
  const location = useLocation();
  const match = useRouteMatch();

  const { componentStack, ...infos } = info || {};

  const data = {
    'Error Boundary'    : name,
    'Stack Trace'       : error.stack,
    'Component Stack'   : componentStack,
    'Route Params'      : params,
    'Route Location'    : location,
    'Route Match'       : match,
    // 'Error Details'     : error,
    // 'Other Props'       : props,
    ...infos,
  };

  return _.compact( _.map( data, ( value, label ) => {
    if ( _.isNil( value ) || _.isEmpty( value ) ) return;
    return <Err key={label} label={label} value={value} />;
  } ) );
}
MoreErrorBox.propTypes = {
  name  : PropTypes.string,
  info  : PropTypes.shape( {} ),
  error : PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.instanceOf( Error ),
  ] ).isRequired,
};

function UncontainedErrorMessage( props ) {
  const message = String( props.error.message || props.error );

  return (
    <Alert color="danger">
      <h1>Oops! It looks like you have encountered an error!</h1>
      <pre><Ansi>{String( message )}</Ansi></pre>
      <p>
        This error has been reported.
        If this error is unexpected, please visit the
        <Link to='/help' className="alert-link"> SSP Help </Link>
        page.
      </p>
      { props.name && <h3>{props.name}</h3>}
      <MoreInfo isOpen={!! window.Cypress}>
        <MoreErrorBox {...props} />
      </MoreInfo>
      <ErrorFeedbackButton eventId={props.eventId} />{' '}
      <RetryButton retry={props.retry} />{' '}
    </Alert>
  );
}
UncontainedErrorMessage.propTypes = {
  name      : PropTypes.string,
  error     : PropTypes.oneOfType( [
    PropTypes.string,
    PropTypes.instanceOf( Error ),
  ] ).isRequired,
  eventId   : PropTypes.string,
  retry     : PropTypes.func,
};

function ErrorFeedbackButton( { eventId } ) {
  const onClick = useCallback( () => {
    SSP.Sentry.showReportDialog( { eventId } );
  }, [ eventId ] );
  if ( ! ( SSP.Sentry && eventId ) ) return null;
  return ( <Button onClick={onClick}>Add Comment</Button> );
}
ErrorFeedbackButton.propTypes = { eventId : PropTypes.string };

function RetryButton( { retry } ) {
  if ( ! retry ) return null;
  return ( <Button onClick={retry}>Retry this Operation</Button> );
}
RetryButton.propTypes = { retry : PropTypes.func };

function ContainedErrorMessage( props ) {
  return (
    <Container fluid className="error-box"
      data-error-status={props.error.status || 'unknown'}
      data-error-level={props.error.level || 'unknown'}
    >
      <Row>
        <Col md={{ size : 8, offset : 2 }}>
          <UncontainedErrorMessage {...props} />
        </Col>
      </Row>
    </Container>
  );
}
ContainedErrorMessage.propTypes = {
  name      : PropTypes.string,
  error     : PropTypes.oneOfType( [
    PropTypes.string, PropTypes.instanceOf( Error ),
  ] ).isRequired,
  eventId   : PropTypes.string,
};

export function ErrorBox( props ) {
  const Comp = props.component
    || ( props.container ? ContainedErrorMessage : UncontainedErrorMessage );
  return <Comp {...props} />;
}
ErrorBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error     : PropTypes.oneOfType( [
    PropTypes.string, PropTypes.instanceOf( Error ),
  ] ),
  container : PropTypes.bool,
  wrapper   : PropTypes.elementType,
  name      : PropTypes.string,
  component : PropTypes.elementType,
  eventId   : PropTypes.string,
};
ErrorBox.defaultProps = {
  container : true,
};
