import _ from 'lodash';
import { createContext, useContext, useMemo } from 'react';
import { Watchable } from '@ssp/database';
import {
  useResource, useShortcuts,
  useLoading, useWatchables,
} from '~/hooks';
import { PropTypes } from '~/utils';
import { DebugPanel, Loading } from '~/widgets';

export const ResourceContext = createContext( undefined );

export function ResourceContextProvider( props ) {
  const _resource = useMemo(
    () => Watchable.unwrap( props.resource ),
    [ props.resource ],
  );
  const resource = useResource( _resource, {
    label : 'ResourceContextProvider',
  } );
  const resources = useWatchables( props.addl_resources );
  useShortcuts( useMemo( () => {
    if ( ! _resource ) return;
    return [
      {
        name        : 'cache/reload',
        keys        : 'D r',
        description : 'Reload this resource from the server, ignoring cache',
        category    : 'Debug Tools',
        handler() { return _resource.broker?.origin?.reload(); },
      },
    ];
  }, [ _resource ] ) );

  const loading = useLoading(
    'Resource Context Provider', resource || null, ...resources,
  );

  // component is passed in the case where the "resource" being
  // displayed is actually a resource type, like for displaying
  // a ResourceForm in order to enqueue a class job or a service job
  if ( props.component ) {
    return <Provider resource={props.component} children={props.children} />;
  }
  if ( loading ) return loading;
  if ( ! resource ) {
    return (
      <Loading
        label="Resource Context Provider (no resource yet)"
        width={120}
        centered
      />
    );
  }
  return <Provider resource={resource} children={props.children} />;
}
ResourceContextProvider.propTypes = {
  resource        : PropTypes.resource,
  addl_resources  : PropTypes.arrayOf( PropTypes.resource ),
  component       : PropTypes.component,
  children        : PropTypes.children,
};
ResourceContextProvider.defaultProps = {
  addl_resources : [],
};
function Provider( { resource, children } ) {
  return (
    <ResourceContext.Provider value={resource}>
      {children}
      <DebugPanel
        shortcut='D C'
        title='Context Resource Info'
        tables={() => makeResourceTables( resource )}
      />
      <DebugPanel
        shortcut='D B'
        title='Context Resource Broker Info'
        tables={() => makeBrokerTables( resource )}
      />
    </ResourceContext.Provider>
  );
}
Provider.propTypes = {
  resource  : PropTypes.resource.isRequired,
  children  : PropTypes.children.isRequired,
};

export function useResourceContext() {
  return useContext( ResourceContext );
}

function makeResourceTables( rsrc ) {
  const schema = rsrc.schema;
  const fnames = ( ...tags ) => schema.getFieldNames( ...tags ).sort();
  const fields = ( ...tags ) => _.pick( rsrc, fnames( ...tags ) );
  const data = {
    'Regular Fields'  : fields( '-@metadata', '-@virtual', '-@computed' ),
    'Metadata Fields' : fields( '@metadata', '-@virtual', '-@computed' ),
    'Computed Fields' : fields( '@computed' ),
    'Virtual Fields'  : fields( '@virtual' ),
  };
  return data;
}

function makeBrokerTables( rsrc ) {
  const meta = rsrc.getMeta();
  return {
    'Resource Meta' : _.omit( meta, 'broker' ),
    'Broker Meta'   : _.omit( meta.broker, 'origin' ),
    'Origin Meta'   : _.omit( meta.broker.origin, 'emitter' ),
    'Emitter Meta'  : meta.broker.origin.emitter,
  };
}
