import { PropTypes } from '~/utils';
import {
  ResourceBreadcrumb,
  LinkingBreadcrumb,
  NormalBreadcrumb,
  OwnedResourceBreadcrumb,
} from './types';

export function Breadcrumb( props ) {
  if ( props.to && props.from ) {
    return <LinkingBreadcrumb {...props} />;
  } else if ( props.resource && props.owner ) {
    return <OwnedResourceBreadcrumb {...props} />;
  } else if ( props.resource ) {
    return <ResourceBreadcrumb {...props} />;
  } else {
    return <NormalBreadcrumb {...props} />;
  }
}
Breadcrumb.propTypes = {
  // For a "normal" resource all you need is resource, unless it
  // belongs to a different owner, then include the owner prop too
  resource  : PropTypes.resource,
  owner     : PropTypes.resource,
  // For "multi-parent" resources like SSP.TeamLink include from and
  // to refs, and optionally a joiner (the default joiner is just ' to ')
  from      : PropTypes.resource,
  to        : PropTypes.resource,
  joiner    : PropTypes.node,
  // For regular, non-resource breadcrumbs
  url       : PropTypes.string,
  label     : PropTypes.string,
  // These come from the `Breadcrumbs` component, you don't specify
  // them
  first     : PropTypes.bool,
  last      : PropTypes.bool,
  index     : PropTypes.number,
};
