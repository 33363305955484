import { Sentry } from '@ssp/sentry';
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css, Global, ThemeProvider } from '@emotion/react';
import { BrowserRouter, useHistory, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { useSupportShortcuts, usePersonalShortcuts } from './shortcuts';

import { Modals } from '~/modals';
import Tours, { TourProvider } from '~/layout/tours';
import { ErrorBoundary } from '~/widgets';

import { MainLayout } from './MainLayout';

import sspTheme from './theme';

const toastConfigs = {
  position          : toast.POSITION.TOP_RIGHT,
  autoClose         : 15000, // ms
  closeOnClick      : false,
  pauseOnFocusLoss  : true,
};

// TODO[someday] store default ViewContext state in localStorage
export const App = Sentry.withProfiler( function App() {
  useSupportShortcuts();
  usePersonalShortcuts();
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ThemeProvider theme={sspTheme}>
          <TourProvider>
            <ErrorBoundary name="app">
              <Global styles={globalStyles} />
              <MainLayout />
            </ErrorBoundary>
            <Modals />
            <Tours />
          </TourProvider>
        </ThemeProvider>
        <Navigator />
      </QueryParamProvider>
      <ToastContainer {...toastConfigs} />
    </BrowserRouter>
  );
} );

function globalStyles( theme ) {
  return css`
    body {
      background-color: ${theme.defaults.bg};
      color: ${theme.text};
      transition: all .5s ease;
    }
    .highvis.visible { border: 2px solid green; }
    .highvis.hidden { border: 2px solid green; }
    #root {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      > main {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }
  `;
}

function Navigator() {
  const history = useHistory();
  useEffect( () => { SSP.go = ( rt ) => history.push( rt ); }, [ history ] );
  return null;
}
