import _ from 'lodash';

/**
 * These are the transforms that can be applied to field values.
 * There are two types of definitions.
 *
 * If the value of the transform is a function, then that transform is
 * applied to the value that is going to be stored in the database and
 * when it is retrieved from the database later for more editing, the
 * transformed value will be displayed in the form.
 *
 * If the value of the transform is an array of two functions, then it
 * transforms in both directions.  The first function will be used to
 * format values for storage in the database ({@link
 * FieldConfig#format}) and the second one will be used to transform
 * values back into the format required by the form ({@link
 * FieldConfig#parse}).
 */
export const Transforms = {
  lowercase       : _.toLower,
  uppercase       : _.toUpper,
  lowercasewords  : _.lowerCase,
  uppercasewords  : _.upperCase,
  capitalize      : _.startCase,
  startcase       : _.startCase,
  kebabcase       : _.kebabCase,
  snakecase       : _.snakeCase,
  camelcase       : _.camelCase,
};

export function getTransforms( transform, where ) {
  const transforms = _.flow( [
    x => _.flattenDeep( [ x ] ),
    x => _.flatMap( x, y => y.split( /[\s,]+/u ) ),
    _.compact,
    _.uniq,
    y => _.at( Transforms, y ),
    _.compact,
  ] )( transform || '' );
  const array_idx = where === 'parse' ? 1 : 0;
  return transforms.map( x => ( _.isArray( x ) ? x[ array_idx ] : x ) );
}

export function runTransforms( transform, where, value, ...args ) {
  const transforms = getTransforms( transform, 'format' );
  return _.reduce( transforms, ( val, fn ) => fn( val, ...args ), value );
}
