import _ from 'lodash';
import React from 'react';
import { Form, FormConfig } from '~/forms';
import { Dump } from '~/widgets';
import { fake } from '@ssp/database';
import { useState, useConstant } from '~/hooks';

// import { FormDataViewer } from '~/forms/widgets';

const addons = [
  {
    type      : 'Button',
    position  : 'append',
    icon      : 'far:magic',
    title     : 'Apply Magic',
    onClick( { form, field, config } ) {
      fake( 'project.name' ).then( val => {
        form.change( field.name, val );
      } );
      return false;
    },
  },
  /*
  {
    type      : 'Button',
    icon      : 'fab:superpowers',
    position  : 'append',
    outline   : true,
    title     : 'Grant Superpowers',
    onClick( opts ) { console.log( 'CLICKY', opts ); },
  },
  */
];
/*
const buttonTypes = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',
];
const buttonAfter = _.map( buttonTypes, type => {
  const props = {
    key       : type,
    id        : `button-${type}`,
    icon      : 'fab:superpowers',
    [ type ]  : true,
  };
  return <Button {...props} />;
} );
*/

const fields = [
  {
    name          : 'form-controls',
    type          : 'Buttons',
    label         : 'Form Controls',
    groups        : [
      [
        {
          icon      : 'fad:bomb',
          label     : 'Make all fields errors',
          onClick( { form : { mutators : { setValue } }, field } ) {
            setValue( field.name, prev => ( { ...prev, errors : true } ) );
          },
        },
        {
          icon      : 'fad:bomb',
          label     : 'Remove all errors',
          onClick( { form : { mutators : { setValue } }, field } ) {
            setValue( field.name, prev => ( { ...prev, errors : false } ) );
          },
        },
      ],
      [
        {
          icon      : 'fad:fingerprint',
          label     : 'Touch All Fields',
          onClick( { form } ) {
            const fn = _.partialRight( form.mutators.setFieldTouched, true );
            _.each( form.getRegisteredFields(), fn );
          },
        },
        {
          icon      : 'fad:fingerprint',
          label     : 'Untouch All Fields',
          onClick( { form } ) {
            const fn = _.partialRight( form.mutators.setFieldTouched, false );
            _.each( form.getRegisteredFields(), fn );
          },
        },
      ],
    ],
  },
  /*
  {
    name          : 'form-controls',
    type          : 'SwitchGroup',
    label         : 'Form Controls',
    options       : [
      {
        icon      : 'fad:bomb',
        label     : 'Make all fields errors',
        value     : 'errors',
      },
      {
        icon      : 'fad:fingerprint',
        label     : 'Make all fields touched',
        value     : 'touch',
      },
    ],
    onChange( ...args ) {
      console.log( 'onChange', ...args );
    },
  },
  */
  /*
  {
    name  : 'boolean',
    type  : 'Boolean',
  },
  */
  {
    name          : 'checkbox',
    type          : 'Checkbox',
    label         : 'Checkbox',
    inputLabel    : 'Single Checkbox',
  },
  {
    name          : 'switch',
    type          : 'Switch',
    label         : 'Switch',
    inputLabel    : 'Enable Advanced Frobnicator',
    faker         : 'datatype.boolean',
  },
  {
    name    : 'checkbox-group',
    type    : 'CheckboxGroup',
    help    : 'Checkbox Group',
    options : [ 'One', 'Two', 'Three', 'Four', 'Five' ],
    faker   : 'samples( "One", "Two", "Three", "Four", "Five", 3 )',
  },
  {
    name    : 'switch-group',
    type    : 'SwitchGroup',
    help    : 'Switch Group',
    options : [ 'One', 'Two', 'Three', 'Four', 'Five' ],
    faker   : 'samples( "One", "Two", "Three", "Four", "Five", 3 )',
  },
  {
    name    : 'email',
    type    : 'Email',
    faker   : 'internet.email',
  },
  {
    name    : 'file',
    type    : 'File',
  },
  {
    name    : 'number',
    type    : 'Number',
    faker   : 'random.number',
  },
  {
    name    : 'radio-group',
    type    : 'RadioGroup',
    options : [ 'One', 'Two', 'Three', 'Four', 'Five' ],
  },
  {
    name    : 'select',
    type    : 'Select',
    options : [ 'One', 'Two', 'Three', 'Four', 'Five' ],
    help    : 'Simple Select Field',
    faker   : 'sample( "One", "Two", "Three", "Four", "Five" )',
  },
  /* Multi-select is discouraged, something like SwitchGroup or
   * CheckboxGroup is a better option.
  {
    name    : 'multiselect',
    type    : 'Select',
    options : [ 'One', 'Two', 'Three', 'Four', 'Five' ],
    help    : 'Multiple Select Field',
    multi   : true,
    faker   : 'samples( "One", "Two", "Three", "Four", "Five", 2 )',
  },
  */
  {
    name          : 'static',
    type          : 'Static',
    initialValue  : 'Static values cannot be edited, their value appears here.',
    faker         : 'lorem.sentence',
  },
  {
    name    : 'tel',
    type    : 'Tel',
    faker   : 'phone.phoneNumber',
  },
  {
    name    : 'text',
    type    : 'Text',
    faker   : 'hacker.phrase',
  },
  {
    name    : 'text-array',
    type    : 'Text',
    array   : true,
    faker   : 'hacker.phrase',
  },
  {
    name    : 'text-with-addons',
    type    : 'Text',
    label   : 'Text With Addons',
    addons,
  },
  {
    name    : 'textarea',
    type    : 'Textarea',
    faker   : 'lorem.paragraphs',
  },
  {
    name    : 'url',
    type    : 'Url',
    faker   : 'internet.url',
  },
  {
    name    : 'single-resource',
    type    : 'ResourceSelector',
    model   : 'SSP.Project',
    query     : {},
    options   : {},
  },
  {
    name    : 'single-resources',
    type    : 'ResourceSelector',
    model   : [ 'SSP.Project', 'SSP.User' ],
    query     : {},
    options   : {},
  },
  {
    name  : 'markdown',
    type  : 'Markdown',
    label : 'Markdown',
  },
];

const allErrors = _.fromPairs( _.map( fields, f => (
  [ f.name, 'Oh no! An Error!' ]
) ) );

export function FormKitchenSink( props ) {
  const [ submitted, setSubmitted ] = useState();

  const config = useConstant( () => new FormConfig( {
    id      : 'kitchen-sink',
    title   : 'Forms Kitchen Sink',
    validate( values ) {
      /*
      if ( _.includes( values[ 'form-controls' ], 'errors' ) ) {
        return allErrors;
      }
      */
      if ( _.get( values, [ 'form-controls', 'errors' ] ) ) {
        return allErrors;
      }
    },
    onSubmit( data ) {
      setSubmitted( _.omit( data, 'allErrors' ) );
      return false;
    },
    fields,
  } ) );

  return (
    <>
      {submitted && <Dump data={submitted} />}
      <Form config={config} />
    </>
  );
}
