import _ from 'lodash';
import { PropTypes } from '~/utils';
import { NavLink } from '~/router';

export function NormalBreadcrumb( props ) {
  const { url, label } = props;
  if ( ! url && ! _.isString( label ) ) return null;
  return <NavLink className="bc-label" to={url}>{label}</NavLink>;
}
NormalBreadcrumb.propTypes = {
  url     : PropTypes.string,
  label   : PropTypes.string,
};
