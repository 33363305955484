import { FormConfig } from './config';
import { Form } from './Form';
import { PropTypes, useDebugFlag } from '~/utils';
import { useOptions, useMemo, useResourceContext, useResource } from '~/hooks';
import { Loading } from '~/widgets';

export function ResourceForm( props ) {
  const resource = useResource( props.resource, { label : 'ResourceForm' } );
  const opts = useOptions( props, 'shallow' );
  const debug = useDebugFlag( 'debug-form', 'Enable debug in final-form' );
  const resourceContext = useResourceContext();
  const config = useMemo( () => {
    if ( ! resource ) return;
    if ( resource.stored && ! resource.loaded ) return;
    const schema = resource.schema;
    const title = resource.creating
      ? `Create ${resource.schema.name}`
      : `Edit ${resource.name || resource.schema.name}`;

    const onSubmit = async function onSubmit( data ) {
      try {
        return await resource.save( data );
      } catch ( err ) {
        log.error( 'SUBMIT ERROR:', err );
        throw err;
      }
    };

    return new FormConfig( {
      onSubmit,
      title,
      debug,
      icon : resource.schema.icon,
      ...opts,
      resource, creating : resource.creating, schema,
      resourceContext,
    } );
  }, [ debug, opts, resource, resourceContext ] );
  if ( ! config ) return <Loading label="ResourceForm resource loading" />;
  return <Form config={config} />;
}

ResourceForm.propTypes = {
  resource  : PropTypes.resource.isRequired,
};
