import _ from 'lodash';
import { ResourceForm } from '~/forms';
import { ActionView, SummaryPage, ServiceView, RawDataPanel } from '~/pages/builder';
import { DeleteResource } from '~/pages/project/DeleteResource';
import { TabItem } from './TabItem';
import { getSchema } from '@ssp/database';
import type { TabBuilder } from './TabBuilder';
import type { TabItemOptions } from './TabItem';

export type TabItemGroupOptions = {
  id: string;
  title?: string;
  icon?: string;
  access?: string;
  capability?: string;
  rank?: number;
  /** If true, can be collapsed */
  collapsible?: boolean;
  /** If true, start out collapsed */
  collapsed?: boolean;
};

export class TabItemGroup {

  tabs: TabItem[] = [];
  rank: number = 0;

  id: string;
  title?: string;
  icon?: string;

  access?: string;
  capability?: string;

  accessible: boolean;
  accessible_reason: string;

  collapsible: boolean = false;
  collapsed: boolean = false;

  #builder: TabBuilder;

  constructor( options: TabItemGroupOptions, builder: TabBuilder ) {
    this.#builder = builder;
    const {
      id, title, icon, access, capability,
      ...opts
    } = options;
    _.assign( this, opts );

    this.id = id;
    this.title = title;
    this.icon = icon;
    this.access = access;
    this.capability = capability;

    const [ accessible, accessible_reason ] = builder.authorize( this );
    this.accessible = accessible;
    this.accessible_reason = accessible_reason;
  }

  get active() { return _.some( this.tabs, 'active' ); }

  get key() { return `group-${this.title}`; }
  get visible() { return Boolean( this.accessible && this.title ); }

  add( ...items: ( TabItemOptions | string )[] ) {
    _.each( items, item => {
      try {
        if ( _.isNil( item ) ) return;
        if ( _.isString( item ) ) {
          if ( typeof this[ item ] === 'function' ) {
            return this[ item ]();
          } else {
            log.warn( `"${item}" is not a function`, item );
            return;
          }
        }
        if ( _.isArray( item ) ) {
          const [ name, ...args ] = item;
          return this[ name ]( ...args );
        }
        if ( _.isFunction( item ) ) return item( this );
        if ( ! _.isPlainObject( item ) ) {
          throw new Error( `Invalid route config ${item}` );
        }
        this.tabs.push( new TabItem( item, this.#builder ) );
      } catch ( err ) {
        log.error( `Cannot add item to tablist`, item, err );
      }
    } );
    return this;
  }

  redirect( src, tgt ) {
    return this.add( { path : src, redirect : tgt } );
  }

  schema<T=unknown>( key: string, defaultValue?: T ): T {
    return this.#builder.schema( key, defaultValue );
  }

  summary( opts={} ) {
    return this.add( {
      path      : '',
      title     : 'Home',
      icon      : this.schema( 'icon' ),
      component : SummaryPage,
      ...opts,
    } );
  }

  edit( opts={} ) {
    return this.add( {
      path      : 'edit',
      hidden    : true,
      title     : `Edit ${this.schema( 'name' )}`,
      icon      : 'far:edit',
      component : ResourceForm,
      ...opts,
    } );
  }

  delete( opts={} ) {
    return this.add( {
      path      : 'delete',
      hidden    : true,
      title     : 'Delete',
      icon      : 'far:trash-alt',
      component : DeleteResource,
      ...opts,
    } );
  }

  action( opts={} ) {
    return this.add( {
      path      : 'action/:action',
      hidden    : true,
      exact     : false,
      title     : 'Action',
      icon      : 'far:cogs',
      component : ActionView,
      ...opts,
    } );
  }

  service( service, config={} ) {
    if ( this.#builder.didService[ service ] ) return;
    this.#builder.didService[ service ] = true;
    const schema = getSchema( service );

    return this.add( {
      service,
      path      : service,
      exact     : false,
      title     : schema.plural_name,
      component : ServiceView,
      ..._.pick( schema, 'icon', 'featured', 'hideZero' ),
      ...config,
    } );
  }

  rawdata() {
    this.add( {
      path      : 'raw-data',
      title     : 'Raw Data',
      icon      : 'fad:database',
      component : RawDataPanel,
      access    : 'support',
    } );
  }

  getTabs() {
    let tabs = _.values( this.tabs );
    tabs = _.filter( tabs, 'accessible' );
    return tabs;
  }

}
