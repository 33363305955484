import { getSchema } from '@ssp/database';

import { ResourceCard } from '~/views/collection/card/ResourceCard';

import type { TResource } from '@ssp/database';

export function UserCard( { user }: { user: TResource<'SSP.User'> } ) {
  const face = getSchema( 'SSP.User' ).getFace( 'card', 'summary' );
  return <ResourceCard resource={user} face={face} hideActionMenu />;
}
export function JobCard( { job }: { job: TResource<'SYSTEM.Job'> } ) {
  const face = getSchema( 'SYSTEM.Job' ).getFace( 'card', 'summary' );
  return <ResourceCard resource={job} face={face} hideActionMenu />;
}
