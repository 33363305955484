import _ from 'lodash';
import { Transport, TransportResponse } from '@ssp/database';
import { metrics } from '@ssp/metrics';
import {
  mkdebug, jsonParse, jsonStringify, BadGateway, NotAuthenticated,
} from '@ssp/utils';

import { requests } from './requests-registry';
import * as socket from './sockets';

import type { TransportResponseOptions } from '@ssp/database';

const duration = metrics.get( {
  type   : 'histogram',
  name   : 'ssp_browser_request_duration_seconds',
  help   : 'Duration of request from the browser, in seconds',
  labels : [ 'schema', 'method', 'status' ],
} );

const debug = mkdebug( 'ssp:client:transport' );

export class BrowserTransport extends Transport {

  constructor() {
    super();
    debug( 'Constructed BrowserTransport' );
  }

  async withAuth<T=unknown>(
    fn: () => Promise<T>,
    error?: Error,
    attempts: number = 0,
  ): Promise<T> {
    if ( attempts > 20 ) throw new Error( `Authentication failed repeatedly` );
    try {
      if ( error ) await SSP.auth.login();
      return await fn();
    } catch ( err ) {
      if ( err.name !== 'NotAuthenticated' ) throw err;
      return this.withAuth( fn, err, attempts + 1 );
    }
  }

  async perform( payload ) {
    return this.withAuth( () => this._perform( payload ) );
  }

  async _perform( payload ) {
    const timer = duration.timer( _.pick( payload, 'schema', 'method' ) );
    const unreg = requests.register( payload );
    try {
      const res = await this.postData( payload );
      if ( ! res ) log.error( 'BROWSER TRANSPORT returned no results' );
      const response = new TransportResponse( res );
      timer( { status : response.ok ? 'success' : 'failure' } );
      return response;
    } finally {
      unreg();
    }
  }

  async postData( payload ): Promise<TransportResponseOptions> {
    const { schema } = payload;

    const controller = new AbortController();

    try {
      const token = SSP.auth.token;
      if ( ! token ) throw new NotAuthenticated( 'No token' );
      const headers: Record<string, string> = {
        'Content-Type' : 'application/json',
      };
      if ( token ) headers.Authorization = `Bearer ${token}`;
      const options = {
        method          : 'POST',
        mode            : 'same-origin' as const,
        cache           : 'no-cache' as const,
        credentials     : 'same-origin' as const,
        headers,
        redirect        : 'error' as const,
        referrerPolicy  : 'same-origin' as const,
        body            : jsonStringify( payload ),
        signal          : controller.signal,
      };
      const url = `/api/transport/${schema}`;
      const response = await fetch( url, options );
      const text = await response.text();
      if ( BUILD.isDev ) {
        if ( text.startsWith( 'Error occurred while trying to proxy' ) ) {
          throw new BadGateway( text );
        }
      }
      try {
        return jsonParse( text );
      } catch ( err ) {
        log.error( 'TEXT:', text );
        throw err;
      }
    } catch ( error ) {
      log.error( 'ERROR:', error );
      throw error;
    }
  }

  get socket() { return socket; }
}
