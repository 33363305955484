import { RouterTabs } from '~/pages/builder';
import { PropTypes } from '~/utils';
import { useResource } from '~/hooks';
import { SummaryPage } from './SummaryPage';

function handleBuilder( builder ) {
  const job = builder.resource;
  builder.group( 'main' ).add( {
    icon      : job.schema.icon,
    path      : '',
    title     : 'Home',
    component : SummaryPage,
  } );
}

export function JobView( props ) {
  const job = useResource( props.job, { label : 'JobView' } );
  return <RouterTabs builder={handleBuilder} resource={job} />;
}
JobView.propTypes = {
  job : PropTypes.resource.isRequired,
};
