import _ from 'lodash';
import { getModel, getSchema, Schema } from '@ssp/database';
import { PropTypes } from '~/utils';
import { Button } from '~/widgets';
import { Icon } from '~/icons';
import { Switch, Route } from '~/router';
import { Panel, Modal, Markdown } from '~/components';
import { ResourceForm } from '~/forms';
import {
  useRoutePath, useRouteUrl, useMemo, useResource, useToggle,
  useRouteParam, useLoading,
} from '~/hooks';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Media } from 'reactstrap';

const PanelBodyWrapper = styled( 'div' )( ( { theme } ) => css`
  line-height : 50px;
` );

const MarkdownPanelWrapper = styled( 'div' )( ( { theme } ) => css`
  border-radius : 4px;
  background-color: white;
  padding: 15px;
  margin-bottom: 15px;
  svg {
    color : ${theme.icon.fg};
    margin-right: 12px;
  }
` );

export function AddResources( props ) {
  const { resource } = props;
  const path = useRoutePath();

  return (
    <Switch>
      <Route path={path} exact>
        <ResourceList resource={resource} />
      </Route>
      <Route path={`${path}/:resource_type`} exact>
        <AddResource resource={resource} />
      </Route>
    </Switch>
  );
}
AddResources.propTypes = {
  resource  : PropTypes.resource.isRequired,
};

function ResourceList( { resource } ) {
  const [ schemas, featured ] = useMemo( () => {
    const sch = Schema.filter( s => s.canAttachTo( resource ) );
    return [ sch, _.filter( sch, 'featured' ) ];
  }, [ resource ] );

  return (
    <>
      {featured.length && (
        <SchemasList title="Featured Resources" schemas={featured} />
      )}
      <SchemasList title="All Resources" schemas={schemas} />
    </>
  );
}
ResourceList.propTypes = {
  resource  : PropTypes.resource.isRequired,
};

function SchemasList( { title, schemas } ) {
  const url = useRouteUrl();

  return (
    <Panel body title={title}>
      <PanelBodyWrapper>
        {
          _.map( schemas, ( { id, name : label, icon } ) => {
            const to = `${url}/${id}`;
            return ( <Button spaced key={id} {...{ to, icon, label }} /> );
          } )
        }
      </PanelBodyWrapper>
    </Panel>
  );
}
SchemasList.propTypes = {
  title     : PropTypes.string.isRequired,
  schemas   : PropTypes.arrayOf( PropTypes.schema ).isRequired,
};

function AddResource( { resource } ) {
  const parent = useResource( resource, { label : 'AddResource' } );
  const type = useRouteParam( 'resource_type' );
  const rsrc = useMemo( () => {
    return parent && parent.newResource( type );
  }, [ parent, type ] );
  const loading = useLoading( 'Resource Parent', parent || null );
  if ( loading ) return loading;
  return (
    <>
      <MarkdownPanel resource={rsrc} />
      <ResourceForm resource={rsrc} />
    </>
  );
}
AddResource.propTypes = { resource : PropTypes.resource.isRequired };

function getInfo( rsrc ) {
  if ( _.isString( rsrc ) ) rsrc = getModel( rsrc );
  if ( ! ( rsrc && rsrc.schema ) ) return;
  const service_id = rsrc.schema.service_id;
  const svc = getSchema( service_id );
  const service = {
    summary : svc.getMarkdownSummary( 'details' ),
    details : svc.getMarkdown( 'details' ),
    title   : svc.name,
    icon    : svc.icon,
  };
  const res = {
    summary : rsrc.schema.getMarkdownSummary( 'details' ),
    details : rsrc.schema.getMarkdown( 'details' ),
    title   : rsrc.schema.name,
    icon    : rsrc.schema.icon,
  };
  _.defaults( res, service );
  if ( _.some( res, _.isNil ) ) return;
  return res;
}

function MarkdownPanel( { resource : rsrc } ) {
  const [ isOpen, toggle ] = useToggle( false );
  const info = useMemo( () => {
    if ( ! rsrc ) return;
    return getInfo( rsrc ) || getInfo( rsrc.schema.service );
  }, [ rsrc ] );
  if ( ! info ) return null;
  return (
    <MarkdownPanelWrapper>
      <Media>
        <Media left>
          <Icon icon={info.icon} size="5x" />
        </Media>
        <Media body>
          <Media heading>
            {info.title}
          </Media>
          {info.summary}&nbsp;
          <Button size="sm" onClick={toggle} label="Learn More" />
        </Media>
      </Media>
      <Modal isOpen={isOpen} toggle={toggle} size='lg' scrollable>
        <Markdown content={info.details} />
      </Modal>
    </MarkdownPanelWrapper>
  );
}
MarkdownPanel.propTypes = {
  resource  : PropTypes.resource,
};
