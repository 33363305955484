import _ from 'lodash';
import { PropTypes, getAddon } from '~/forms/utils';
import { AddonConfig } from '../../config';

export function FieldAddons( { field, append, prepend } ) {
  return _.map( field.addons, ( addon, idx ) => {
    if ( append && addon.position !== 'append' ) return null;
    if ( prepend && addon.position !== 'prepend' ) return null;
    const AddOn = getAddon( addon.type );
    return ( <AddOn key={addon.id} field={field} addon={addon} /> );
  } );
}
FieldAddons.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  addons  : PropTypes.arrayOf( PropTypes.instanceOf( AddonConfig ) ),
  append  : PropTypes.bool,
  prepend : PropTypes.bool,
};
