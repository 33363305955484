import _ from 'lodash';
import { FieldSet } from '@ssp/database';
import { hideProps, isSSPError } from '@ssp/utils';
import { FieldConfig } from './FieldConfig';
import { FORM_ERROR } from 'final-form';
import { Form } from '../elements';
import mutators from '../mutators';
import decorators from '../decorators';

let idCounter = 1;

export class FormConfig {

  /**
   * @property id
   *
   * Form id
   *
   * @type {string}
   */
  get id() {
    if ( ! this._id ) this._id = `form-${idCounter++}`;
    return this._id;
  }
  set id( id ) { this._id = id; }

  /**
   * @property title
   *
   * Form title.
   *
   * @type {string}
   */

  /**
   * @property danger
   *
   * Danger message to include at the top of the form.
   *
   * @type {string}
   */
  get danger() { return this._danger; }
  set danger( val ) {
    if ( _.isArray( val ) ) val = val.join( ' ' );
    this._danger = val;
  }

  /**
   * @property warning
   *
   * Warning message to include at the top of the form.
   *
   * @type {string}
   */
  get warning() { return this._warning; }
  set warning( val ) {
    if ( _.isArray( val ) ) val = val.join( ' ' );
    this._warning = val;
  }

  /**
   * @property message
   *
   * General message to include at the top of the form.
   *
   * @type {string}
   */
  get message() { return this._message; }
  set message( val ) {
    if ( _.isArray( val ) ) val = val.join( ' ' );
    this._message = val;
  }

  /**
   * @property cardless
   *
   * Don't wrap the form in a Card component.
   *
   * @type {boolean}
   */
  cardless = false;

  /**
   * CSS classes to add to the form.  You can use anything here that
   * is a valid argument to `cx`.
   */
  className;

  /**
   * @property initialValues
   *
   * The initial values for the fields.
   *
   * @type {object<any>}
   */
  // initialValues = {};
  // defaultValues = {};

  /**
   * @property fields
   *
   * Field configuration objects.
   *
   * @type {FieldConfig[]}
   */
  get fields() { return this._fields; }
  set fields( fields ) {
    if ( fields instanceof FieldSet ) {
      return this._process_fieldset( fields );
    }
    const _fields = _.compact( _.map( fields, field => {
      if ( _.isNil( field ) ) return;
      if ( field instanceof FieldConfig ) return field;
      if ( _.isPlainObject( field ) ) return this._process_field( field );
      log.warn( `FormConfig: Invalid field configuration -`, field );
    } ) );
    this._fields = _.reject( _fields, 'hidden' );
  }

  /**
   * FieldSet for db-based schema forms.
   *
   * @type {FieldSet}
   */
  get fieldset() { return this._fieldset; }
  set fieldset( fs ) { this._process_fieldset( fs ); }

  constructor( opts={}, hidden={} ) {
    if ( hidden instanceof FormConfig ) hidden = { parent : hidden };
    hideProps( this, hidden );
    hideProps( this, [ '_fieldset' ] );
    // If we have a schema that can have a fieldset, then build
    // a suitable fieldset and add it to the options.
    if ( opts.schema && opts.schema.getFieldSet ) {
      if ( ! opts.fieldset ) opts.fieldset = opts.schema.getFieldSet( [] );
      opts.fieldset.fields( opts.fields );
      delete opts.fields;
    }

    _.assign( this, opts );

    const rsrc_cfg = opts.resource?.schema.config.forms;
    if ( typeof rsrc_cfg === 'function' ) {
      _.defaults( this, rsrc_cfg.call( this ) );
    } else {
      _.defaults( this, rsrc_cfg );
    }
  }

  /**
   * Function for comparing current value to initial value.
   *
   * @param {any} a - Value to compare.
   * @param {any} b - Other value to compare.
   */
  initialValuesEqual( a, b ) { return a === b; }

  // async validate( data, isChanged ) { }

  async handleValidate( data, isChanged, opts={} ) {
    try {
      if ( this.validate ) {
        const res = await this.validate.call( this, data, isChanged, opts );
        if ( res ) return res;
      }
    } catch ( err ) {
      return this.simplifyError( err );
    }
  }

  /**
   * @typedef {object} RouterNavResult
   * see useRouter() in app/ssp-webapp-client/src/router.js
   */

  /**
   * If the call to onSubmit returns false, then just return,
   * otherwise do some routing, otherwise return
   * an error message
   *
   * @typedef {undefined|RouterNavResult|SimplifiedError} HandleSubmitResult
   */
  /**

  /**
   * handle a form submission
   *
   * @param {object} data - the data received upon form submission
   * @param {*} opts - options
   * @returns {Promise<HandleSubmitResult>}
   */
  async handleSubmit( data, opts={} ) {
    const { router } = opts;
    if ( this.fieldset ) {
      data = _.pick( data, this.fieldset.getNames() );
    } else {
      data = _.omitBy( data, _.isNil );
    }

    try {
      const result = await this.onSubmit.call( this, data, opts );
      if ( result === false ) return;
      if ( _.isPlainObject( result ) ) return result;
      if ( _.isObject( result ) ) {
        if ( _.isString( result.route ) ) return router.go( result.route );
        if ( _.isFunction( result.route ) ) return router.go( result.route() );
      }
      return router.back();
    } catch ( err ) {
      return this.simplifyError( err );
    }
  }

  /**
   * @typedef {object} SimplifiedError
   */

  /**
   * @returns {SimplifiedError}
   */
  simplifyError( err ) {
    if ( isSSPError( err ) ) {
      const res = err.simplify();
      if ( _.isPlainObject( res ) ) {
        if ( res[ '*' ] ) {
          res[ FORM_ERROR ] = res[ '*' ];
          delete res[ '*' ];
        }
        return res;
      }
      if ( _.isString( res ) && _.isString( err.field ) ) {
        return { [err.field] : res };
      }
    }
    return { [ FORM_ERROR ]: String( err ).split( '\n' )[0] };
  }

  get top() {
    let iter = this;
    while ( iter.parent ) iter = iter.parent;
    return iter;
  }

  get( key, defaultValue, resource ) {
    if ( ! resource ) resource = this.resource;
    const val = _.get( this, key );
    if ( ! _.isNil( val ) ) {
      return ( typeof val === 'function' ) ? val.call( this, resource ) : val;
    }
    if ( this.parent ) return this.parent.get( key, defaultValue, resource );
    return defaultValue;
  }

  pick( ...keys ) {
    return _.fromPairs( _.map( _.compact( _.flattenDeep( keys ) ), key => {
      return [ key, this.get( key ) ];
    } ) );
  }

  _process_fieldset( fs ) {
    this._fieldset = fs;
    if ( this.creating ) {
      fs.fields( fs.empty && '@minimal', '@createonly' );
    } else {
      fs.fields( fs.empty && '@all', '-@createonly' );
    }
    fs.fields( '-@metadata', '-@virtual', '-@computed', '-@hidden' );
    // Remove readonly fields from the form unless they were
    // explicitly added.
    fs.defaultExclude( '@readonly' );

    this.fields = _.compact( fs.getConfigs().map( ( { ...field } ) => {
      if ( ! field.name ) {
        log.warn( 'FormConfig: Unnamed field -', field );
        return false;
      }
      const dbfield = this.schema.getField( field.name );
      if ( dbfield.form === false ) return false;
      _.defaults( field, dbfield.form );
      _.defaults( field, dbfield.type.form );
      if ( this.creating && dbfield.form?.create ) {
        _.assign( field, dbfield.form?.create );
      }
      _.assign( field, _.pick( dbfield, [
        'list', 'array', 'object', 'subdoc', 'subdocObj', 'readonly',
        'required', 'fields',
      ] ) );

      return this._process_field( field, { dbfield } );
    } ) );
  }

  _process_field( { form={}, ...field }, hidden={} ) {
    if ( this.prefix ) {
      const prefix = this.prefix.replace( /\.?$/u, '.' );
      if ( ! field.name.startsWith( prefix ) ) {
        field.name = prefix + field.name;
      }
    }
    if ( field.readonly ) field.disabled = true;
    return new FieldConfig( field, { ...form, form : this, ...hidden } );
  }

  /**
   * Build config props for react-final-form.
   *
   * @param {object} conf - Configuration object.
   * @param {object} conf.router - React Router instance.
   */
  rff( conf={} ) {
    if ( this.parent ) {
      throw new Error( `Cannot get RFF config from a subform config` );
    }
    _.defaults( conf, _.pick( this, [
      'initialValues',
      'defaultValues',
      'initialValuesEqual',
      'subscription',
    ] ) );
    if ( typeof this.debug === 'function' && ! conf.debug ) {
      conf.debug = this.debug.bind( this );
    }
    if ( this.debug === true && ! conf.debug ) {
      // eslint-disable-next-line no-console
      conf.debug = console.log.bind( console, 'FORM STATE CHANGE' );
    }
    conf.validate = ( data, isChanged ) => {
      return this.handleValidate( data, isChanged, conf );
    };
    conf.onSubmit = ( data ) => this.handleSubmit( data, conf );

    _.assign( conf, {
      component               : Form,
      validateOnBlur          : false, // true = validate on change
      destroyOnUnregister     : true,
      keepDirtyOnReinitialize : true,
      mutators, decorators,
    } );
    return conf;
  }

}
