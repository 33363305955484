import { FormConfig } from './config';
import { Form } from './Form';
import { PropTypes } from '~/utils';
import { useOptions, useMemo, useResourceContext } from '~/hooks';

export function SubDocForm( {
  subdoc, title = '', ...props
} ) {
  const opts = useOptions( props, 'shallow' );
  const resourceContext = useResourceContext();
  const config = useMemo( () => {
    if ( ! subdoc ) return;

    const onSubmit = async function onSubmit( data ) {
      return subdoc.update( data );
    };

    return new FormConfig( {
      onSubmit,
      title         : title || `Edit ${subdoc.name || subdoc.schema.name}`,
      icon          : subdoc.schema.icon,
      ...opts,
      resource      : subdoc,
      schema        : subdoc.schema,
      resourceContext,
    } );
  }, [ opts, subdoc, resourceContext, title ] );
  return <Form config={config} />;
}

SubDocForm.propTypes = {
  subdoc   : PropTypes.subdoc.isRequired,
  title    : PropTypes.string,
  onSubmit : PropTypes.func,
};
