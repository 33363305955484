import _ from 'lodash';
import { PropTypes, useFieldMeta, useFieldError } from '~/forms/utils';
import { Badge } from 'reactstrap';
import { useDebugFlag } from '~/utils';

const flags = {
  active                : 'primary',
  dirty                 : 'warning',
  dirtySinceLastSubmit  : 'warning',
  invalid               : 'danger',
  modified              : 'info',
  pristine              : 'info',
  submitError           : 'danger',
  submitFailed          : 'danger',
  submitSucceeded       : 'success',
  submitting            : 'warning',
  touched               : 'warning',
  valid                 : 'success',
  validating            : 'warning',
  visited               : 'info',
};

export function FieldState( props ) {
  const flag = useDebugFlag( 'show-form-data' );
  if ( ! flag ) return null;
  return <FieldStateBody {...props} />;
}
function FieldStateBody( { field } ) {
  const meta = useFieldMeta( field.name, [ ..._.keys( flags ), 'value' ] );
  const error = useFieldError( field.name );
  return (
    <div css={{ border : '1px solid orange' }}>
      <b css={{ margin : '0.5em' }}>{field.name}</b>
      {_.map( flags, ( color, name ) => {
        if ( ! meta[ name ] ) return null;
        return (
          <Badge key={name} color={color} pill>{_.startCase( name )}</Badge>
        );
      } )}
      <pre><code>{meta.value}</code></pre>
      <span className="danger">{error}</span>
    </div>
  );
}
FieldStateBody.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};
