import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import { ResultSet } from '@ssp/database';
import { ErrorBox } from '~/widgets';
import { isThenable } from '@ssp/utils';
import type { StrictReactNode } from '~/utils';
import type { TabBuilder } from './TabBuilder';
import type { TResource, TComponent } from '@ssp/database';

export type TabItemOptions = {
  path: string;

  component?: ( props: Record<string, any> ) => StrictReactNode;
  redirect?: string;
  render?: ( props: Record<string, any> ) => StrictReactNode;

  resource?: TResource<any> | TComponent<any>;

  title?: string;
  titles?: [ string, string ];
  subtitle?: string;
  icon?: string;
  props?: Record<string, any> | ( () => Record<string, any> );
  resultset?: ResultSet | ( () => ResultSet );

  group?: string;
  strict?: boolean;
  exact?: boolean;
  hidden?: boolean;

  access?: string;
  capability?: string;
};

export class TabItem {

  path: string;
  route: string;
  url: string;

  declare redirect?: string;
  declare component?: ( props: Record<string, any> ) => StrictReactNode;
  declare render?: ( props: Record<string, any> ) => StrictReactNode;

  declare title?: string;
  declare titles?: [ string, string ];
  declare icon?: string;
  declare props?: Record<string, any>;
  declare resultset?: ResultSet | ( () => ResultSet );

  strict: boolean = false;
  exact: boolean = true;
  hidden: boolean = false;
  active: boolean = false;

  accessible: boolean;
  accessible_reason: string;

  declare access?: string;
  declare capability?: string;

  #builder: TabBuilder;

  #resource?: TResource<any> | TComponent<any>;
  get resource() {
    if ( this.#resource ) return this.#resource;
    return this.#builder.resource;
  }
  set resource( rsrc: TResource<any> | TComponent<any> | undefined ) {
    this.#resource = rsrc;
  }

  constructor( options: TabItemOptions, builder: TabBuilder ) {
    this.#builder = builder;
    const {
      title, titles, path, redirect, resource, component,
      access, capability, icon, props,
      ...opts
    } = options;
    _.assign( this, opts );

    this.title = title;
    this.titles = titles;
    this.icon = icon;
    this.access = access;
    this.capability = capability;

    if ( _.isString( path ) ) {
      this.path = path;
      this.route = builder.route( path );
      this.url = builder.url( path );
    } else {
      throw new TypeError( `TabItem requires path` );
    }

    if ( redirect ) this.redirect = builder.route( redirect );

    const [ accessible, accessible_reason ] = builder.authorize( this );
    this.accessible = accessible;
    this.accessible_reason = accessible_reason;
    this.renderer = this.renderer.bind( this );
    this.resource = resource;
    this.component = component;
    this.active = Boolean( this.matches( builder.location.pathname ) );
    this.props = props;
  }

  getTitleFor( count ) {
    if ( ! _.isArray( this.titles ) ) return this.title;
    if ( _.isFinite( count ) && count > 1 ) return this.titles[1];
    return this.titles[0];
  }

  get key() { return this.path; }
  get visible() { return Boolean( this.accessible && this.title ); }
  get disabled() { return ! this.accessible; }

  get routeInfo() {
    return _.mapValues( {
      ..._.pick( this, 'key', 'exact', 'accessible', 'hidden' ),
      path        : this.simplifiedRoute,
      renderer    : this.rendererId,
    }, val => {
      if ( _.isBoolean( val ) ) return val ? 'True' : 'False';
      return val;
    } );
  }
  get simplifiedRoute() {
    if ( ! this.route ) return 'NO ROUTE';
    return this.route.replace( /(:\w+)\(.*?\)/gu, ( _x, y ) => y );
  }
  getProps() {
    if ( typeof this.props === 'function' ) {
      const res = this.props();
      if ( isThenable( res ) ) {
        throw new Error( `RouterTabs props-function must be sync` );
      }
      return res;
    }
    if ( _.isPlainObject( this.props ) ) return this.props;
    const props: Record<string, any> = {};
    for ( const x of [ 'resource', 'service', 'resultset' ] ) {
      props[ x ] = _.result( this, x );
    }
    return props;
  }

  matches( path ) {
    if ( ! path ) return false;
    return matchPath( path, {
      path    : this.route,
      strict  : this.strict,
      exact   : this.exact,
    } );
  }

  renderer( rtprops ) {
    if ( ! this.accessible ) {
      return <ErrorBox error={`Access Denied: ${this.accessible_reason}`} />;
    }
    const props = this.getProps();
    const extra = { ...rtprops, active : !! rtprops.match };
    const { render, component : Component } = this;
    if ( _.isFunction( render ) ) return render( props );
    if ( Component ) return <Component {...props} {...extra} />;
    return <ErrorBox error={`This tab has no render or component props`} />;
  }

  get rendererId() {
    if ( this.redirect ) return `redirect: ${this.redirect}`;
    if ( this.component ) {
      const c = this.component;
      const name = c.displayName || c.name || 'UnnamedComponent';
      return `component: ${name}`;
    }
    if ( this.render ) return 'render function';
  }
}
