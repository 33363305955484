import _ from 'lodash';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import setFieldTouched from 'final-form-set-field-touched';

const mutators = _.assign(
  {},
  arrayMutators,
  { setFieldData, setFieldTouched },
  require( './values' ),
);

export default mutators;
