import _ from 'lodash';
import { useInput } from '~/forms/utils';
import { useMemo, useState, useEffect, useQuickSearch, useCallback } from '~/hooks';
import { useCombobox } from 'downshift';
import { getModel } from '@ssp/database';
import type { Dispatch, SetStateAction } from 'react';
import type { FieldConfig } from '~/forms/config';

export function resolveTypes( field ) {
  let types = field.get( 'model' );
  if ( _.isString( types ) ) return types;
  if ( _.isArray( types ) ) {
    types = _.uniq( _.compact( types ) );
    if ( types.length === 1 ) return types[ 0 ];
    if ( types.length > 1 ) return types;
  }
  const rs = field.get( 'resultset' );
  if ( rs ) return rs.schema.id;
}

export function useTypes( field: FieldConfig ): [
  string,
  string[]|undefined,
  Dispatch<SetStateAction<string>>
] {
  const types = useMemo( () => resolveTypes( field ), [ field ] );
  const [ type, setType ] = useState( _.isString( types ) ? types : '' );
  return [ type, _.isArray( types ) ? types : undefined, setType ];
}

function getResultSet( type: string, field: FieldConfig ) {
  if ( ! type ) return;
  const rs = field.get( 'resultset' );
  if ( rs ) return rs;
  const query = field.get( 'query', {} );
  const options = field.get( 'options', {} );
  return getModel( type ).find( query, options );
}

export function useSearch<T=unknown>( opts_in: {
  field: FieldConfig;
  onChange?: ( selectedItem: T ) => void;
  minlength?: number | boolean;
} ) {
  const { config : _config, field, onChange, setLabelProps, ...opts } = opts_in;
  const [ items, setItems ] = useState( [] );
  const [ type, types, _setType ] = useTypes( field );
  const input = useInput( field, { type : 'text' } );
  const source_resultset = useMemo(
    () => getResultSet( type, field ),
    [ type, field ],
  );

  const combo = useCombobox( {
    items,
    onSelectedItemChange( data ) {
      if ( onChange ) onChange( data.selectedItem );
      input.onChange( data.selectedItem );
    },
  } );

  const {
    isOpen, selectedItem, selectItem, closeMenu, inputValue = '',
  } = combo;

  const context = useQuickSearch( source_resultset, inputValue, opts );
  const { resultset, message, loading, loaded } = context;
  const setType = useCallback( ( t ) => {
    if ( t === type ) return;
    _setType( t );
    selectItem( null );
    closeMenu();
  }, [ _setType, type, selectItem, closeMenu ] );

  useEffect( () => {
    if ( ! resultset ) return;
    if ( ! ( resultset.loaded && resultset.length ) ) return;
    setItems( [ ...resultset ] );
  }, [ resultset ] );

  // If the value was updated then make sure the type matches
  useEffect( () => {
    if ( selectedItem ) _setType( selectedItem.schema.id );
    // Disabled because we want the *only* thing that triggers this to
    // be the selected item changing.  It's a false alarm anyway,
    // _setType is stable, even though ESlint can't tell that it is..
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedItem ] );

  return {
    ...combo,
    isOpen, inputValue, type, types, setType, field, items,
    loaded, loading, message,
  };
}
