import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { useVirtual } from 'react-virtual';
import { PropTypes } from '~/utils';
import { Item } from './Item';
import styled from '@emotion/styled';

import type { InferProps } from 'prop-types';
import type { FieldConfig } from '~/forms/config';

type MenuWrapperProps = {
  height: number;
  field: FieldConfig;
  isOpen: boolean;
};
const MenuWrapper = styled.div<MenuWrapperProps>(
  ( { isOpen, height, field } ) => ( {
    padding           : 0,
    marginTop         : 0,
    position          : 'relative',
    width             : '100%',
    backgroundColor   : 'white',
    overflow          : 'auto',
    transition        : 'opacity .1s ease',
    borderRadius      : '0 0 .3rem .3rem',
    boxShadow         : '0 2px 3px 0 rgba(34,36,38,.15)',
    borderColor       : '#96c8da',
    borderTopWidth    : 0,
    borderRightWidth  : 1,
    borderBottomWidth : 1,
    borderLeftWidth   : 1,
    borderStyle       : 'solid',
    ...( ( isOpen ) ? {
      height,
      visibility        : 'visible',
      minHeight         : field.get( 'minHeight' ),
      maxHeight         : field.get( 'maxHeight' ),
    } : {
      visibility        : 'hidden',
    } ),
  } ),
);
const inner_css = { width : '100%', position : 'relative' } as const;

export type MenuProps = InferProps<typeof Menu.propTypes>;
export function Menu( props: MenuProps ) {
  const {
    isOpen, items, field, highlightedIndex, selectedItem, getItemProps,
  } = props;
  const size = items.length || 0;
  const itemHeight = field.get( 'itemHeight', 50 );
  const estimateSize = useCallback( () => itemHeight, [ itemHeight ] );

  const parentRef = useRef();
  const virt = useVirtual( { size, parentRef, estimateSize } );

  const displayItems = field.get( 'displayItems', 5 );
  const height = _.min( [
    items.length * itemHeight,
    displayItems * itemHeight,
  ] );
  const inner_style = { height : virt.totalSize };

  return (
    <MenuWrapper
      {...{ height, field, isOpen, size }}
      ref={parentRef}
    >
      <div css={inner_css} style={inner_style}>
        {virt.virtualItems.map( row => {
          const item = items[ row.index ];
          return (
            <Item
              key={item?._id || row.index}
              item={item}
              index={row.index}
              height={row.size}
              start={row.start}
              isActive={row.index === highlightedIndex}
              isSelected={item === selectedItem}
              getItemProps={getItemProps}
            />
          );
        } )}
      </div>
    </MenuWrapper>
  );
}
Menu.propTypes = {
  type              : PropTypes.string.isRequired,
  isOpen            : PropTypes.bool,
  items             : PropTypes.arrayOf( PropTypes.shape( {
    _id             : PropTypes.string.isRequired,
    findDisplayName : PropTypes.func.isRequired,
  } ) ),
  field             : PropTypes.fieldConfig.isRequired,
  getItemProps      : PropTypes.func.isRequired,
  highlightedIndex  : PropTypes.number,
  selectedItem      : PropTypes.shape( {} ),
};
