import _ from 'lodash';

let counter = 1;

export class AddonConfig {
  /**
   * Type of addon.
   *
   * - Text (should also have `text` property)
   * - Button (should also have `label`/`title`/`icon` + `onClick`)
   * - Menu (should have `items`)
   * - SplitButton (combination of Button and Menu)
   */
  type;

  icon;
  label;
  title;

  outline = true;

  /**
   * @property position
   *
   * Where to position the addon.  One of `append` or `prepend`.
   *
   * @type {string}
   */
  get position() { return this._position; }
  set position( p ) {
    if ( p === 'append' || p === 'prepend' ) {
      this._position = p;
    } else {
      throw new Error( `Invalid value for addon position: "${p}"` );
    }
  }
  _position = 'append';

  /**
   * @property className
   *
   * Class name (or names) to apply to the addon.
   */
  className;

  constructor( opts, field ) {
    _.assign( this, opts );
    if ( ! this.id ) this.id = `addon_${counter++}`;
    Object.defineProperty( this, 'field', { value : field } );
  }

  getAddonProps() {
    return {
      addonType : this.position,
      className : this.className,
      size      : this.field.bsSize,
      ...( this.attributes || {} ),
    };
  }

  getProps() {
    const props = _.pick( this, [
      'icon', 'label', 'title', 'outline', 'onClick', 'children',
    ] );
    _.assign( props, this.button, { field : this.field } );
    return props;
  }

}
