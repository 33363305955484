import _ from 'lodash';
import { PropTypes } from '~/utils';
import { jsonStringify } from '@ssp/utils';

export function Any( props ) {
  const style = { color : props.color };
  if ( _.isString( props.value ) || _.isNumber( props.value ) ) {
    return <span style={style}>{props.value}</span>;
  } else {
    return <span style={style}>{jsonStringify( props.value )}</span>;
  }
}

Any.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value : PropTypes.any.isRequired,
  color : PropTypes.string,
};
