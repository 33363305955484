import { PropTypes } from '~/forms/utils';
import { useOptions, useEffect } from '~/hooks';
import { useSearch } from './utils';
import { Menu } from './Menu';
import { Input } from './Input';
import { ControllerButton } from './ControllerButton';
import { TypeSelector } from './TypeSelector';
import { Message } from './Message';

export function ResourceSelector( props ) {
  const { config, setLabelProps, ...opts } = props;

  const data = useSearch( opts );
  const {
    isOpen, message, getComboboxProps, getLabelProps, getMenuProps,
  } = data;

  const label_props = useOptions( getLabelProps(), 'deep' );
  useEffect(
    () => { setLabelProps( label_props ); },
    [ label_props, setLabelProps ],
  );

  return (
    <div>
      <div {...getComboboxProps( { className : 'input-group' } )}>
        <TypeSelector {...data} />
        <Input {...data} />
        <ControllerButton {...data} />
      </div>
      <div css={{ position : 'absolute', width : '100%', zIndex : 100 }}>
        {isOpen && message && <Message message={message} />}
        <div {...getMenuProps()}>
          {( isOpen && ! message ) && <Menu {...data} />}
        </div>
      </div>
    </div>
  );
}
ResourceSelector.propTypes = {
  field         : PropTypes.fieldConfig.isRequired,
  config        : PropTypes.formConfig.isRequired,
  onChange      : PropTypes.func,
  setLabelProps : PropTypes.func,
  minlength     : PropTypes.bool,
};
