import { createRegistry, mkdebug } from '@ssp/utils';

const debug = mkdebug( 'ssp:client:requests-registry' );

export const requests = createRegistry( {
  name        : 'requests',
  description : 'Active Server Requests',
  merge       : 'values',
  onRegister( id, data ) {
    debug( '************************** STARTING REQUEST:', id, data );
  },
  onUnregister( id, data ) {
    debug( '************************** FINISHED REQUEST:', id, data );
  },
} );
