import { lazy } from 'react';
import { getSchemas } from '@ssp/database';

import Home from './home';
import About from './about';
import ResourceRedirect from './resource/Redirect';

const Admin = lazy(
  () => import( /* webpackChunkName: "admin" */ './admin' ),
);
const Develop = lazy(
  () => import( /* webpackChunkName: "develop" */ './develop' ),
);
const Job = lazy(
  () => import( /* webpackChunkName: "job" */ './job' ),
);
const Jobs = lazy(
  () => import( /* webpackChunkName: "jobs" */ './jobs' ),
);
const User = lazy(
  () => import( /* webpackChunkName: "user" */ './user' ),
);
const Users = lazy(
  () => import( /* webpackChunkName: "users" */ './users' ),
);
const Project = lazy(
  () => import( /* webpackChunkName: "project" */ './project' ),
);
const Projects = lazy(
  () => import( /* webpackChunkName: "projects" */ './projects' ),
);
const Resource = lazy(
  () => import( /* webpackChunkName: "resource" */ './resource' ),
);
const Component = lazy(
  () => import( /* webpackChunkName: "class" */ './component' ),
);

const resource_types = getSchemas( 'is_resource' ).map( s => s.id );
const resource_type = `:resource_type(${resource_types.join( '|' )})`;
const project_by_ident = `/project/:project_ident`;
const user_by_ident = `/user/:user_ident`;
const resource_by_id = [ resource_type, ':resource_id' ].join( '/' );
const resource_by_ident = `/resource/${resource_type}/:resource_ident`;
const project_resource = [ project_by_ident, resource_by_id ].join( '/' );
const user_resource = [ user_by_ident, resource_by_id ].join( '/' );
const project_resource_job = [ project_resource, 'job/:job_id' ].join( '/' );
const user_resource_job = [ user_resource, 'job/:job_id' ].join( '/' );
const project_job = [ project_by_ident, 'job/:job_id' ].join( '/' );
const user_job = [ user_by_ident, 'job/:job_id' ].join( '/' );
const resource_by_id_redirect = [ resource_by_id, ':remainder+' ].join( '/' );
const resource_by_ident_redirect = [
  resource_by_ident, ':remainder+',
].join( '/' );
const resource_class = [ '', resource_type ].join( '/' );

export const routes = [
  { path : '/', exact : true, component : Home },
  { path : '/user', exact : true, component : User },
  { path : '/about', component : About },
  { path : '/projects', component : Projects },
  { path : '/users', component : Users },
  { path : '/jobs', component : Jobs },
  { path : '/job/:job_id', component : Job },
  { path : project_resource_job, component : Job },
  { path : user_resource_job, component : Job },
  { path : project_job, component : Job },
  { path : user_job, component : Job },
  { path : resource_by_id_redirect, component : ResourceRedirect },
  { path : resource_by_ident_redirect, component : ResourceRedirect },
  { path : project_resource, component : Resource },
  { path : user_resource, component : Resource },
  { path : project_by_ident, component : Project },
  { path : resource_class, component : Component },
  { path : user_by_ident, component : User },
  { path : resource_by_ident, component : ResourceRedirect },
  { path : resource_by_id, component : ResourceRedirect },
  {
    path      : '/admin',
    component : Admin,
    enabled( subject ) { return subject?.isAdmin() || subject?.isSupport(); },
  },
  {
    path      : '/develop',
    component : Develop,
    enabled( subject ) { return subject?.isAdmin() || subject?.isDeveloper(); },
  },
];
