import { PropTypes } from '~/utils';

export function Text( props ) {
  const { color, tag } = props;
  let { value } = props;
  const style = { color };
  if ( Array.isArray( value ) ) {
    value = value.join( ', ' );
  }
  if ( tag ) {
    const Tag = tag;
    return <Tag style={style}>{value}</Tag>;
  }
  return <span style={style}>{value}</span>;
}

Text.propTypes = {
  value : PropTypes.string.isRequired,
  color : PropTypes.string,
  tag   : PropTypes.string,
};

