import _ from 'lodash';
import { useMemo } from 'react';
import { PropTypes } from '~/utils';
import { Switch, Route } from '~/router';
import { CollectionView } from '~/views';
import { ResourceForm } from '~/forms';
import { useRoutePath, useResource } from '~/hooks';

const subroutes = {
  root( resource, service ) {
    const resultset = resource.findResources( service, {}, {
      minlength : false,
    } );
    return <CollectionView resultset={resultset} />;
  },
  create( resource, service ) {
    const newRsrc = resource.newResource( service );
    return <ResourceForm resource={newRsrc} />;
  },
  jobs( resource, service ) {
    const resultset = resource.findResources( 'SYSTEM.Job', {
      resource_type : service,
    }, { limit : 100 } );
    return <CollectionView resultset={resultset} />;
  },
};

const names = _.without( _.keys( subroutes ), 'root' );
export const notIdentRE = `(?!(${names.join( '|' )}))`;

export function ServiceView( props ) {
  const resource = useResource( props.resource, { label : 'ServiceView' } );
  const { service } = props;
  const path = useRoutePath();

  const routes = useMemo( () => {
    return _.map( subroutes, ( func, name ) => {
      const url = name === 'root'
        ? path : `${path}/${name}`.replace( /\/+/gu, '/' );
      return {
        path : url, exact : true,
        render : _.partial( func, resource, service ),
      };
    } );
  }, [ resource, service, path ] );

  return (
    <Switch>
      {_.map( routes, route => ( <Route key={route.path} {...route} /> ) )}
    </Switch>
  );
}
ServiceView.propTypes = {
  resource  : PropTypes.resource.isRequired,
  service   : PropTypes.string.isRequired,
  match     : PropTypes.shape( { path : PropTypes.string.isRequired } ),
};
