import _ from 'lodash';
import {
  resolveURL, NotAuthenticated, init, isAnyError, timeout, delay,
  Timeout, JWT,
} from '@ssp/utils';
import { metrics } from '@ssp/metrics';
import { getModel } from '@ssp/database';

import { toast, extractFromHash } from '~/utils';
import { updateAuthStore } from './auth-store';
import { reconnect } from './sockets';

import type { AnyError } from '@ssp/utils';
import type { TResource } from '@ssp/database';

declare module '@ssp/preload' {
  export interface SSP {
    auth: Auth;
  }
}

const LOAD_TIMEOUT = '20s';
const LOAD_RETRY_DELAY = '2s';

export class Auth {

  jwt: JWT;
  user?: TResource<'SSP.User'>;
  impersonator?: TResource<'SSP.User'>;

  load_timer?: () => void;

  #watchDisposer?: () => void;

  get token() {
    const jwt = this.jwt;
    if ( ! jwt ) return;
    if ( jwt.expired ) return;
    return jwt.token;
  }

  constructor() {
    init.register( {
      id      : 'authentication',
      context : this,
    } );
  }

  async start() {
    await this.login();
  }
  async stop() {
    // no-op?
  }

  async update( jwt: JWT ) {
    if ( jwt.error ) {
      const code = ( jwt.error as any )?.code;
      // For NotAuthenticated errors remove any stored access tokens
      if ( code === 401 || code === 403 ) await this.removeTokens();
      this.reset();
      jwt.fatalize();
    }

    if ( jwt.impersonating ) {
      await this.setSessionToken( jwt.token );
    } else {
      await this.setStorageToken( jwt.token );
    }
    this.jwt = jwt;
    this.user = getUser( jwt.userId );
    if ( jwt.impersonating ) this.impersonator = getUser( jwt.impersonatorId );
    this.rewatch();

    await this.load();
    updateAuthStore( {
      state         : 'authenticated',
      impersonator  : this.impersonator,
      subject       : this.user,
      settings      : this.user.settings,
    } );
    SSP.events.emit( 'login', this );
    reconnect();
  }

  async removeTokens() {
    await this.removeSessionToken();
    await this.removeStorageToken();
  }

  async getFromLocation() {
    if ( ! window.location.hash ) return;

    const token = extractFromHash( 'token' );
    const error = extractFromHash( 'error' );
    if ( error ) throw new NotAuthenticated( error );
    if ( token ) return token;
  }

  async getSessionToken() {
    return sessionStorage.getItem( 'ssp-jwt' );
  }
  async setSessionToken( token ) {
    return sessionStorage.setItem( 'ssp-jwt', token );
  }
  async removeSessionToken() {
    await sessionStorage.removeItem( 'ssp-jwt' );
  }

  async getStorageToken() {
    return localStorage.getItem( 'ssp-jwt' );
  }
  async setStorageToken( token ) {
    return localStorage.setItem( 'ssp-jwt', token );
  }
  async removeStorageToken() {
    return localStorage.removeItem( 'ssp-jwt' );
  }

  /**
   * This method identifies "normal" errors.  That is, things that
   * should trigger the authentication process, but are not considered
   * exceptions and don't need to be shown to the user.
   */
  isNormalError( err: AnyError ) {
    log.debug( 'isNormalError?', err.code, err.message );
    if ( err.code && err.code !== 401 ) return false;
    if ( [
      'No accessToken found in storage',
      'jwt expired',
      // 'No user loaded?',
    ].some( x => err.message.includes( x ) ) ) return true;
    return false;
  }

  get authenticated() { return this.jwt?.valid; }

  /*
  handleSocket( socket: any ) {
    // When the socket disconnects and we are still authenticated, try
    // to reauthenticate right away the websocket connection will
    // handle timeouts and retries
    socket.on( 'disconnect', () => {
      if ( this.authenticated ) this.reAuthenticate( true );
    } );
  }
  */

  async reset() { delete this.jwt; }

  rewatch() {
    if ( this.#watchDisposer ) this.#watchDisposer();
    this.#watchDisposer = this.user.reload_on_related_changes();
  }

  initSAML( error?: AnyError ) {
    const dest = resolveURL( '/saml/login', {
      go  : window.location.pathname + window.location.search,
      err : error?.message,
    } );
    // @ts-ignore - Wrong types for window.location
    // eslint-disable-next-line require-atomic-updates
    window.location = dest;
  }

  #login?: Promise<void>;
  login() { return this.#login || ( this.#login = this._login() ); }
  async _login() {
    extractFromHash( 'RelayState' );
    try {
      const jwt = await this.getJWT();
      if ( ! jwt ) return this.initSAML();
      return await this.update( jwt );
    } catch ( err ) {
      if ( ! isAnyError( err ) ) throw err;
      if ( this.isNormalError( err ) ) {
        this.initSAML();
      } else {
        err.retry = () => this.initSAML();
      }
      log.debug( 'Auth#login caught error:', err );
      throw err;
    }
  }

  async logout() {
    await this.removeTokens();
    this.reset();
    SSP.events.emit( 'logout', this );
  }

  async impersonate( userId: string|null ) {
    const real_token = await this.getStorageToken();
    let new_token: string;

    if ( _.isString( userId ) ) {
      log.debug( 'Beginning impersonation of', userId );
      const response = await fetch( `/api/auth/impersonate/${userId}`, {
        method          : 'POST',
        mode            : 'same-origin',
        cache           : 'no-cache',
        credentials     : 'same-origin',
        headers         : { Authorization : `Bearer ${real_token}` },
        redirect        : 'error', // manual, *follow, error
        referrerPolicy  : 'same-origin',
      } );
      new_token = await response.text();
    } else {
      log.debug( 'Ending impersonation' );
      await this.removeSessionToken();
      new_token = real_token;
    }
    if ( new_token ) return await this.update( new JWT( new_token ) );
  }

  get load_duration_metric() {
    return metrics.get( {
      type   : 'histogram',
      name   : 'ssp_auth_load_duration_seconds',
      help   : 'How long it takes to load the SSP Auth data, in seconds',
      labels : [ 'impersonating' ],
    } );
  }
  get load_attempts_metric() {
    return metrics.get( {
      type    : 'histogram',
      name    : 'ssp_auth_attempts_total',
      help    : 'How many attempts it takes to authenticate',
      labels  : [ 'impersonating' ],
      buckets : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ],
    } );
  }

  get subject() { return this.user; }

  get impersonating() { return this.jwt.impersonating; }
  get loading() { return !! this.load_timer; }

  async getJWT(): Promise<JWT|undefined> {
    const sources = [
      () => this.getFromLocation(),
      () => this.getSessionToken(),
      () => this.getStorageToken(),
    ];
    for ( const src of sources ) {
      const token = await src();
      if ( token ) {
        const jwt = new JWT( token );
        if ( jwt.valid ) return jwt;
      }
    }
  }

  async load() {
    this.load_timer = this.load_duration_metric.timer( {
      impersonating : this.impersonating,
    } );
    updateAuthStore( { state : 'loading' } );
    return this._attempt( 0 );
  }

  private async _load(): Promise<void> {
    await Promise.all( [
      this.user.load( { comment : 'auth', links : true } ),
      this.impersonator?.load( { comment : 'auth' } ),
    ] );
    if ( this.user.is_disabled ) {
      throw new NotAuthenticated( 'Cannot authenticate as a disabled user' );
    }
    this.toast();
  }

  private _attempt( attempt = 0 ) {
    return timeout( LOAD_TIMEOUT, this._load() ).then( res => {
      if ( this.load_timer ) {
        this.load_timer();
        delete this.load_timer;
      }
      this.load_attempts_metric.observe( attempt + 1 );
      return res;
    } ).catch( err => {
      if ( err instanceof Timeout ) {
        this.toast( `Subject load timeout` );
      } else {
        throw err;
      }
      return delay( LOAD_RETRY_DELAY )
        .then( () => this._attempt( attempt + 1 ) );
    } );
  }

  error( msg: string, err: string|Error ) {
    log.error( msg, err );
    updateAuthStore( { state : 'error', error : err } );
    if ( _.isError( err ) ) {
      this.toast( `${msg} ${err.message}`, { type : toast.TYPE.ERROR } );
    } else {
      this.toast( `${msg} ${err}`, { type : toast.TYPE.ERROR } );
    }
  }

  toast( msg?: string, opts?: $TSFixMe ) {
    if ( _.isNil( msg ) ) {
      toast.dismiss( 'auth-toast' );
    } else {
      toast( msg, {
        position      : toast.POSITION.TOP_CENTER,
        autoClose     : false,
        closeOnClick  : false,
        draggable     : false,
        closeButton   : false,
        ...opts,
        toastId       : 'auth-toast',
      } );
    }
  }


}

function getUser( id: string ) {
  return getModel( 'SSP.User' ).fromId( id );
}


SSP.auth = new Auth();
