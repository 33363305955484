import { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Loading } from '~/widgets';
import { App } from '~/layout/App';

const fallback = <Loading label="Suspense Fallback" />;

ReactDOM.render(
  <Suspense fallback={fallback}><App /></Suspense>,
  document.getElementById( 'root' ),
);
