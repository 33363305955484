import { PropTypes } from '~/utils';
import ReactMarkdown from 'react-markdown';
import { css } from '@emotion/react';

const style = ( { markdown : colors } ) => css`
  color: ${colors.text};
  strong: { color: ${colors.strong}; font-weight: 'bolder' }

  h1, h2, h3, h4, h5, h6 { color: ${colors.header}; }
  hr: { border-top: 1px solid ${colors.hr}; }

  code: { color: ${colors.code}; }
  pre: { padding: '5px 8px' }

  ul: {
    list-style: none;
    padding: 0;
    margin: 0;
    > li { text-indent: 1.3em; }
    > li::before {
      content: "\\002022";
      color: ${colors.bullet};
      padding-right: .7em;
    }
  }
`;

export function Markdown( { content } ) {
  return <ReactMarkdown css={style}>{content}</ReactMarkdown>;
}
Markdown.propTypes = {
  content : PropTypes.string.isRequired,
};
