import { useState, useEffect } from 'react';
import {
  getStore, GlobalStores,
  getRegistry, GlobalRegistries,
} from '@ssp/utils';

type First<Item> = Item extends [infer T, any] ? T : Item;
type Second<Item> = Item extends [any, infer T] ? T : Item;

export function useStore<
  Name extends keyof GlobalStores,
  State = First<GlobalStores[Name]>
>( name: Name ): State | undefined;
export function useStore<
  State = unknown
>( name: string ): State | undefined;
export function useStore( name ) {
  const store = getStore( name );

  const curr = store.current();
  const [ state, setState ] = useState( curr );

  useEffect( () => store.watch( setState ), [ store, setState ] );
  return state;
}

export function useRegistry<
  Name extends keyof GlobalRegistries,
  State = Second<GlobalStores[Name]>
>( name: Name ): State | undefined;
export function useRegistry<
  State = unknown
>( name: string ): State | undefined;
export function useRegistry( name ) {
  const registry = getRegistry( name );

  const curr = registry.current();
  const [ state, setState ] = useState( curr );

  useEffect( () => registry.watch( setState ), [ registry, setState ] );
  return state;
}
