export function setValue( [ field, value ], state, utils ) {
  if ( typeof value === 'function' ) {
    utils.changeValue( state, field, value );
  } else {
    utils.changeValue( state, field, () => value );
  }
}

export function toggleValue( [ field ], state, utils ) {
  utils.changeValue( state, field, prev => !prev );
}
