import { Action } from '@ssp/database';
import { Button } from '~/widgets';
import { Icon } from '~/icons';
import { cx, PropTypes } from '~/utils';
import { ActionRenderer } from './ActionRenderer';
import { Panel } from '~/components';
import { useActionList } from './useActionList';
import styled from '@emotion/styled';

const ActionPanelWrapper = styled( 'div' )( () => {
  return {
    lineHeight : '50px',
  };
} );

export function ActionPanelItem( { action, onClick, disabled } ) {
  const tooltip = disabled || action.help;
  return (
    <Button onClick={onClick} disabled={Boolean( disabled )} spaced
      data-testid={`ap-btn-${action.id}`}
    >
      <Icon className="action-icon"
        fw size="lg"
        icon={action.icon}
        label={action.label}
        tooltip={tooltip}
        offset='0, 12'
      />
    </Button>
  );
}
ActionPanelItem.propTypes = {
  action    : PropTypes.instanceOf( Action ).isRequired,
  onClick   : PropTypes.func.isRequired,
  resource  : PropTypes.actionable,
  disabled  : PropTypes.string,
};

export function ActionPanelGroup( { label, icon, children } ) {
  return (
    <>
      <h3><Icon icon={icon} label={label} /></h3>
      {children}
    </>
  );
}
ActionPanelGroup.propTypes = {
  label     : PropTypes.string.isRequired,
  icon      : PropTypes.string,
  children  : PropTypes.node.isRequired,
};

export function ActionPanel( props ) {
  const { resource, icon, label, className, meta } = props;

  const actions = useActionList( resource );

  if ( ! actions.length ) return null;

  const panelProps = {
    className : cx( 'action-panel', className ),
    icon, title : label,
    decorations : [ <Icon
      key='actions-help'
      icon='fal:question'
      onClick={() => SSP.modal.open( { modal : 'actions', resource } )}
      css={{ float : 'right' }}
    /> ],
  };
  return (
    <Panel body {...panelProps}>
      <ActionRenderer
        Action={ActionPanelItem}
        Group={ActionPanelGroup}
        Wrapper={ActionPanelWrapper}
        resource={resource}
        meta={meta}
      />
    </Panel>
  );
}
ActionPanel.propTypes = {
  resource    : PropTypes.actionable.isRequired,
  className   : PropTypes.className,
  icon        : PropTypes.string,
  label       : PropTypes.string.isRequired,
  meta        : PropTypes.bool,
};
ActionPanel.defaultProps = {
  label       : 'Actions',
  icon        : 'far:cog',
  meta        : false,
};
