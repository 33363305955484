import { DB, getIdentity, ResultSet, Face } from '@ssp/database';

import { ResourceView } from '~/views';
import { Card, CardHeader, CardBody, CardFooter } from '~/components';
import { PropTypes } from '~/utils';
import { VisibilityWrapper, Progress } from '~/widgets';
import { useResource } from '~/hooks';
import { ActionMenu } from '~/actions';
import { Badge } from 'reactstrap';

export function PanelsView( { face, resultset } ) {
  return (
    <div className="collection-view-panels" role="list">
      {resultset.mapSync( resource => (
        <PanelView
          key={getIdentity( resource )}
          resource={resource}
          face={face}
        />
      ) )}
    </div>
  );
}
PanelsView.propTypes = {
  resultset   : PropTypes.instanceOf( ResultSet ).isRequired,
  face        : PropTypes.instanceOf( Face ).isRequired,
};
PanelsView.config = {
  name      : 'panel',
  title     : 'Panel View',
  icon      : 'fad:align-justify',
};

export function PanelView( { resource : rsrc_in, face } ) {
  const resource = useResource( rsrc_in, { label : 'PanelView' } );
  if ( ! resource ) return null;
  const render = () => {
    const title = resource.display_name
      || resource.findDisplayName()
      || 'UNKNOWN';
    const icon = resource.schema.icon;
    const link = resource.route();
    return (
      <Card className="panel mb-2">
        <CardHeader
          {...{ title, icon, link }}
          right={<ActionMenu resource={resource} right />}
        />
        <CardBody className="overflow-auto d-flex justify-content-between">
          <ResourceView resource={resource} face={face} />
          <PanelRightSwitch resource={resource} />
          <div />
        </CardBody>
        <FooterSwitch resource={resource} />
      </Card>
    );
  };
  return <VisibilityWrapper render={render} />;
}
PanelView.propTypes = {
  resource    : PropTypes.resource.isRequired,
  face        : PropTypes.instanceOf( Face ).isRequired,
};

function PanelRightSwitch( { resource } ) {
  switch ( resource.schema.id ) {
    case 'SYSTEM.Job' : return <JobProgressPanel resource={resource} />;
    default : return null;
  }
}
PanelRightSwitch.propTypes = {
  resource  : PropTypes.resource.isRequired,
};

function JobProgressPanel( { resource } ) {
  const { completionPct }
    = resource.getStepProgress();
  return (
    <div className="align-self-center">
      <Progress css={{ maxWidth : 400 }} text value={completionPct} />
    </div>
  );
}
JobProgressPanel.propTypes = {
  resource  : PropTypes.resource.isRequired,
};

function FooterSwitch( { resource } ) {
  switch ( resource.schema.id ) {
    case 'SYSTEM.Job' : return <JobFooter resource={resource} />;
    default : return null;
  }
}
FooterSwitch.propTypes = {
  resource  : PropTypes.resource.isRequired,
};

function JobFooter( { resource } ) {
  const { stepsCompleted, totalSteps }
    = resource.getStepProgress();
  return (
    <CardFooter className="px-2 py-2">
      <div className="d-flex justify-content-between">
        <div className="align-self-center">
          <Badge
            pill color={DB.SYSTEM.Job.cssClassMap[resource.status]}
          >
            {resource.status}
          </Badge>
        </div>
        <div className="text-muted">
          Step {stepsCompleted} of {totalSteps} complete
        </div>
        <div />
      </div>
    </CardFooter>
  );
}
JobFooter.propTypes = {
  resource  : PropTypes.resource.isRequired,
};
