import { NavMenu } from './NavMenu';
import { useTourRegistryItems, useCallback,
  useMemo, useSettings } from '~/hooks';
import {
  Badge, DropdownItem,
} from 'reactstrap';
import type { TourId, TourRegistryItem } from './tours';
import { useTour } from '@reactour/tour';
import { Icon } from '~/icons';
import { cx } from '~/forms/utils';

const items = [
  { route : '/about', label : 'About', icon : 'far:info' },
  {
    href  : 'http://info.boozallencsn.com',
    label : 'Docs',
    icon  : 'fad:external-link',
  },
  {
    href  : 'https://confluence.boozallencsn.com/display/INFO/SSP+User+Guide',
    label : 'User Guide',
    icon  : 'fad:external-link',
  },
];

export function HelpMenu() {
  const { setIsOpen, setSteps } = useTour();
  const tourItems = useTourRegistryItems();

  const startTour = useCallback( ( tourId : TourId ) => {
    const loadSteps = async () => {
      const { default : loadedSteps }
        = await import( `./tours/steps/${tourId}` );
      setSteps( loadedSteps );
      SSP.tour.setActive( tourId );
      setIsOpen( true );
    };
    loadSteps();
  }, [ setIsOpen, setSteps ] ) ;

  const moreItems = useMemo( () => {
    const tours = tourItems.map( tour => (
      <TourItem key={tour.id}
        onClick={() => startTour( tour.id ) }
        tour={tour}
      />
    ) );
    if ( tours.length ) {
      return [
        <div key="available" className="text-center text-muted">
          {tours.length} Tours Available
        </div>,
        ...tours,
      ];
    } else {
      return null;
    }
  }, [ startTour, tourItems ] );

  return (
    <NavMenu
      label="Help"
      icon="far:question-circle"
      name="help-menu"
      items={items}
      moreItems={moreItems}
    />
  );
}

const classes = cx(
  'd-flex align-items-center',
  'justify-content-center px-1 border-0 bg-white',
);

function TourItem(
  { onClick, tour } :
  { onClick : () => void, tour : TourRegistryItem },
) {
  const settings = useSettings( { safe : true } );
  const tourInDb = settings?.tour_history?.get( tour );
  const badgeStatus = useMemo( () => {
    if ( tour && tourInDb ) return '';
    if ( tour && tourInDb?.version < tour.version ) return 'Updated';
    return 'New';
  }, [ tourInDb, tour ] );

  return (
    <div className="d-flex justify-content-end">
      <DropdownItem className="nav-link text-nowrap" onClick={onClick}>
        <Icon icon="far:play-circle" label={tour.title} fw />
      </DropdownItem>
      <button className={classes}>
        <Badge>{badgeStatus}</Badge>
      </button>
    </div>
  );
}
