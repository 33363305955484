import _ from 'lodash';
import { useMemo } from 'react';
import { useShortcutsConfig } from '~/hooks';
import { PropTypes } from '~/utils';
import { Row, Col } from 'reactstrap';
import { Card, CardHeader, CardBody } from '~/components';
import styled from '@emotion/styled';

const Kbd = styled.kbd`
  margin-left: 3px;
`;

function Keys( { keys } ) {
  return keys.split( ' ' ).map( ( x, i ) => <Kbd key={i}>{x}</Kbd> );
}
Keys.propTypes = { keys : PropTypes.string.isRequired };

export const title = 'Keyboard Shortcuts';
function ShortcutsModal() {
  const raw_configs = useShortcutsConfig();

  const categories = useMemo( () => {
    const confs = _.filter( _.sortBy( raw_configs, 'name' ), 'description' );
    return _.groupBy( confs, 'category' );
  }, [ raw_configs ] );
  const catnames = useMemo( () => {
    return _.uniq( [ 'Go To...', ..._.keys( categories ) ] );
  }, [ categories ] );

  return catnames.map( name => (
    <Category key={name} label={name} items={categories[name]} />
  ) );
}
ShortcutsModal.propTypes = {
  isOpen  : PropTypes.bool,
  toggle  : PropTypes.func,
};
export default ShortcutsModal;

function Shortcut( { name, keys, description } ) {
  return (
    <Row>
      <Col md={3}><Keys keys={keys} /></Col>
      <Col md={9}>{description}</Col>
    </Row>
  );
}
Shortcut.propTypes = {
  name        : PropTypes.string.isRequired,
  keys        : PropTypes.string.isRequired,
  description : PropTypes.string,
  category    : PropTypes.string,
};
function Category( { label, items } ) {
  return (
    <Card>
      <CardHeader>{label}</CardHeader>
      <CardBody>
        { items.map( item => (
          <Shortcut name={item.name} {...item} key={item.name} />
        ) ) }
      </CardBody>
    </Card>
  );
}
Category.propTypes = {
  label : PropTypes.string.isRequired,
  items : PropTypes.arrayOf( PropTypes.shape( Shortcut.propTypes ) ).isRequired,
};
