import { useMemo } from 'react';
import { useViewContext } from '~/context';
import { useOptions } from './useOptions';

import type { Schema } from '@ssp/database';

export type UseFaceOptions = {
  defaultFace?: string;
  defaultView?: string;
};

/**
 * Determine the face that should be in use and return it's
 * configuration object.
 *
 * @param schema - The schema to get the face from.
 */
export function useFace( schema: Schema, opts={} ) {
  const { defaultFace, defaultView } = useOptions( opts );
  const { face, view } = useViewContext( useMemo( () => ( {
    face      : { type : 'string' as const, default : defaultFace },
    view      : { type : 'string' as const, default : defaultView },
  } ), [ defaultFace, defaultView ] ) );
  return useMemo( () => {
    if ( ! schema ) return;
    return schema.findFace(
      [ view, defaultView ],
      [ face, defaultFace ],
      true,
    );
  }, [ schema, view, face, defaultView, defaultFace ] );
}

