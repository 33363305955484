import _ from 'lodash';
import { useMemo, useOptions } from '~/hooks';
import { useField as useRealField } from 'react-final-form';
import { FieldConfig } from '~/forms';
import { makesub } from '../subscriptions';

/** @module "UI.forms" */

/**
 *  input         - object to destructure into HTML input
 *  input.name
 *  input.value
 *  input.onBlur
 *  input.onChange
 *  input.onFocus
 *  meta          - Meta information about the field
 *  meta.active
 *  meta.data
 *  meta.dirty
 *  meta.dirtySinceLastSubmit
 *  meta.error
 *  meta.initial
 *  meta.invalid
 *  meta.modified
 *  meta.pristine
 *  meta.submitError
 *  meta.submitFailed
 *  meta.submitSucceeded
 *  meta.submitting
 *  meta.touched
 *  meta.valid
 *  meta.validating
 *  meta.visited
 */
export function useField( field, options_in={} ) {
  if ( ! ( field instanceof FieldConfig ) ) {
    throw new Error( `Invalid call to useField` );
  }
  const options = useOptions( options_in, 'deep' );
  const { name, opts, attrs } = useFieldConfig( field, options );
  const info = useRealField( name, opts );
  return useMemo( () => {
    const input = { ...info.input };
    const meta = { ...info.meta };
    _.assign( input, attrs );
    if ( _.isNil( input.value ) && ! opts.allowNull ) input.value = '';
    return { input, meta };
  }, [ info, attrs, opts.allowNull ] );
}

// Not exported, as it shouldn't be used anywhere other than by the
// `useField` hook above..
function useFieldConfig( field, options ) {
  return useMemo( () => {
    const {
      // These are configuration keys that we don't want to pass
      // through, we're going to handle them in this method
      // `configProps` is an object that supplies default values for
      // properties that can be configured by the field.  The
      // `textarea`-like inputs for example, specify defaults for
      // `rows` and `cols` here so that you can provide those props in
      // a field config and have them passed through to the input.
      configProps,
      // `inputLabel` provides a label for an input that needs one.
      // The only current example of this is the `file` input.
      inputLabel,
      ...config
    } = options;
    // The only things that go into opts are the properties accepted
    // by `useField` which can be found at
    // https://github.com/final-form/react-final-form/blob/master/src/Field.js
    const opts = _.assign(
      {},
      _.pick( field, [
        'afterSubmit',
        'beforeSubmit',
        'defaultValue',
        'formatOnBlur',
        'initialValue',
        'isEqual',
        'multiple',
        'validateFields',
        'value',
        'format',
        'parse',
      ] ),
      {
        allowNull     : false,
        subscription  : makesub( field.subscription, 'field' ),
      },
    );
    const optsNames = _.keys( opts ).concat( 'type' );
    _.assign( opts, _.pick( config, optsNames ) );
    opts.validate = field.handleValidate.bind( field );
    const attrs = _.omit( config, optsNames );
    _.each( opts, ( val, key ) => {
      if ( _.isNil( val ) ) delete opts[ key ];
      if ( typeof val === 'function' ) opts[ key ] = val.bind( field );
    } );
    _.defaults( attrs, _.pick( field, 'id', 'className', 'disabled' ) );

    const name = field.name;

    if ( typeof inputLabel === 'function' ) {
      _.defaults( attrs, { label : inputLabel( field ) } );
    }
    if ( _.isString( inputLabel ) ) {
      _.defaults( attrs, { label : inputLabel } );
    }
    if ( configProps ) {
      _.assign( attrs, _.pick( field, _.keys( configProps ) ) );
      _.defaults( attrs, configProps );
    }
    _.assign( attrs, field.attributes );

    return { name, opts, attrs };
  }, [ field, options ] );
}
