import _ from 'lodash';
import { useMemo } from 'react';
import { css } from '@emotion/react';

import { PropTypes } from '~/utils';
import { Form, FormConfig } from '~/forms';
import { Loading } from '~/widgets';
import { Member } from '../Member';

function makeConfig( member ) {
  if ( member.is_loading ) return;
  const members = member.members;
  const schema = members.MemberInfo.schema;

  const opts = {
    cardless      : true,
    schema,
    onCancel() { member.hideEditor(); },
    resourceContext : members.resource,
    icon            : 'fad:user-plus',
    // The validation happens in the members object..
    validate() { return true; },
    className : css`width: 99%`,
  };

  if ( member.error ) {
    _.assign( opts, {
      fieldset : schema.getFieldSet( {
        name      : 'user_id',
        label     : 'Search Again',
        help      : 'User not found? Try searching for a known user',
        dbfield   : false,
        required  : true,
      }, members.config_fields ),
      onSubmit( data ) {
        member.update( {
          error         : '',
          user          : data.user_id,
          showingEditor : false,
        } );
        return false;
      },
    } );
  } else {
    _.assign( opts, {
      fieldset  : schema.getFieldSet( members.config_fields ),
      resource  : member.getEditableMemberInfo(),
      onSubmit( data ) {
        member.update( {
          config        : data,
          error         : '',
          showingEditor : false,
        } );
        return false;
      },
    } );
  }
  return new FormConfig( opts );
}

export function MemberEditor( { member } ) {
  const config = useMemo( () => makeConfig( member ), [ member ] );

  if ( ! config ) return <Loading />;

  return (
    <tr>
      <td colSpan={member.members.total_columns} css={{ padding : 0 }}>
        <Form css={{ width : '99%' }} config={config} />
      </td>
    </tr>
  );
}
MemberEditor.propTypes = {
  member  : PropTypes.instanceOf( Member ).isRequired,
};
