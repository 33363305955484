import { PropTypes } from '~/utils';

export function Email( { value } ) {
  return <a href={`mailto: ${value}`}>{value}</a>;
}

Email.propTypes = {
  value : PropTypes.string.isRequired,
};

