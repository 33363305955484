import { DB } from '@ssp/database';
import { Page } from '~/layout';
import { CollectionView } from '~/views';
import { ResourceForm } from '~/forms';

function handleBuilder( builder ) {
  builder.group( 'main' ).add(
    {
      path        : '',
      title       : 'My Projects',
      icon        : 'far:folder-open',
      component   : CollectionView,
      props       : () => ( {
        resultset : DB.SSP.Project.find( {}, { minlength : false } ).mine(),
      } ),
    },
    {
      path        : 'all',
      title       : 'All Projects',
      icon        : 'far:folder-open',
      component   : CollectionView,
      props       : () => ( {
        resultset : DB.SSP.Project.find( {}, { minlength : true } ),
      } ),
    },
    {
      path        : 'create',
      title       : 'Create Project',
      icon        : 'far:folder-plus',
      capability  : 'create-projects',
      component   : ResourceForm,
      props       : () => ( { resource : DB.SSP.Project.create() } ),
    },
  );
}

export default function ProjectsPage() {
  return (
    <Page title='Projects' icon='far:folder-open' builder={handleBuilder} />
  );
}
