import { PropTypes } from '~/utils';
import { Markdown } from '~/components/Markdown';

export function Banner( { content } ) {
  return (
    <div style={{
      backgroundColor: '#ffc107',
      textAlign: 'center',
      lineHeight: '1.2',
      fontSize: '1rem',
    }} className="px-4 pt-2 pb-1"
    >
      <Markdown content={content} />
    </div>
  );
}
Banner.propTypes = {
  content : PropTypes.string.isRequired,
};
