import { Redirect } from '~/router';
import { useResourceResolver, useRouteParams, useLoading } from '~/hooks';
import { Loading } from '~/widgets';

export default function RedirectPage() {
  const {
    resource_type   : type,
    resource_ident  : ident,
    resource_id     : id,
    remainder,
  } = useRouteParams();
  const resource = useResourceResolver( { type, ident, id } );
  const loading = useLoading( 'Resource Route', resource || null );
  if ( loading ) return loading;

  let route = resource.route();
  // Don't try to redirect yet if it is still a /resource route
  if ( route && ! route.startsWith( '/resource' ) ) {
    if ( remainder ) route = route.replace( /\/*$/u, '/' + remainder );
    return <Redirect to={route} />;
  }
  return <Loading label="Resource Route" />;
}
