import _ from 'lodash';
import { Loading } from '~/widgets';
import { useWatchables } from '.';

/* eslint-disable jsdoc/check-param-names */
/**
 * @typedef {object} Loadable
 *
 * `Loadable` interface.
 *
 * @property {function} load - Method for starting the loading
 * process.
 * @property {boolean} loaded - Should be `true` when loaded, `false`
 * if not yet loaded or currently loading.
 * @property {boolean} loading - Should be `true` when actively
 * loading, `false` otherwise.
 */
/**
 * Given one or more "loadables" anything that implements the
 * `Loadable` interface, meaning it has a `.load()` method, and
 * `.loaded`/`.loading` properties to indicate whether it is currently
 * loading, returns either `undefined` (if all the loadables are
 * loaded) or an instance of the `Loading` component (if any of them
 * are still loading).
 *
 * Note that if any of the passed in values are `undefined` they are
 * simply ignored, but if you pass in any `null` values, they indicate
 * something that does need to be loaded, but isn't available yet, so
 * as long as the loadables list includes nulls this hook will
 * indicate that it's still loading.
 *
 * You can use this to indicate a loading state even for resources
 * that you may not have yet.
 *
 * @param {string} [label] - A label to pass to the `Loading` component.
 * @param {Loadable[]} [loadables] - The loadables to watch.
 *
 * @example
 * const resource = useResourceResolver( data );
 * const loading = useLoading( 'Load the resource', resource || null );
 * if ( loading ) return loading;
 */
export function useLoading( label, ...loadables ) {
  if ( ! _.isString( label ) ) {
    loadables.unshift( label );
    label = null;
  }

  const watchables = useWatchables( loadables );
  // If everything is loaded then we don't return anything
  if ( all_loaded( watchables ) ) return;

  return (
    <Loading
      centered
      label={label}
      width={120}
    />
  );
}

function all_loaded( items ) {
  for ( const item of items ) {
    const loading = is_loading( item );
    if ( loading ) return false;
    if ( is_loading( item ) ) return false;
  }
  return true;
}
function is_loading( item ) {
  // We return different values for `undefined` and `null` because we
  // ignore `undefined` values, but we consider `null` to mean "there
  // is something that needs to be loaded, but it's not available yet"
  if ( _.isUndefined( item ) ) return false;
  if ( _.isNull( item ) ) return true;
  if ( item.loaded ) return false;
  return item.loading;
}
