import { PropTypes } from '~/utils';

PropTypes.formApi = PropTypes.shape( {
  batch                 : PropTypes.func,
  blur                  : PropTypes.func,
  change                : PropTypes.func,
  destroyOnUnregister   : PropTypes.bool,
  focus                 : PropTypes.func,
  getFieldState         : PropTypes.func,
  getRegisteredFields   : PropTypes.func,
  getState              : PropTypes.func,
  initialize            : PropTypes.func,
  isValidationPaused    : PropTypes.func,
  mutators              : PropTypes.objectOf( PropTypes.func ),
  pauseValidation       : PropTypes.func,
  registerField         : PropTypes.func,
  reset                 : PropTypes.func,
  resumeValidation      : PropTypes.func,
  submit                : PropTypes.func,
  subscribe             : PropTypes.func,
  config                : PropTypes.shape( {} ), // TODO
} );

PropTypes.fieldInput = PropTypes.shape( {
  name     : PropTypes.string.isRequired,
  value    : PropTypes.any, // eslint-disable-line react/forbid-prop-types
  onBlur   : PropTypes.func,
  onChange : PropTypes.func,
  onFocus  : PropTypes.func,
} );

PropTypes.fieldMeta = PropTypes.shape( {
  active               : PropTypes.bool,
  data                 : PropTypes.objectOf( PropTypes.shape( {} ) ),
  dirty                : PropTypes.bool,
  dirtySinceLastSubmit : PropTypes.bool,
  error                : PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  initial              : PropTypes.any,
  invalid              : PropTypes.bool,
  modified             : PropTypes.bool,
  pristine             : PropTypes.bool,
  submitError          : PropTypes.string,
  submitFailed         : PropTypes.bool,
  submitSucceeded      : PropTypes.bool,
  submitting           : PropTypes.bool,
  touched              : PropTypes.bool,
  valid                : PropTypes.bool,
  validating           : PropTypes.bool,
  visited              : PropTypes.bool,
} );

PropTypes.fieldConfig = PropTypes.oneOfType( [
  PropTypes.string,
  PropTypes.shape( { name : PropTypes.string } ),
] );
PropTypes.fieldsConfig = PropTypes.oneOfType( [
  PropTypes.fieldConfig,
  PropTypes.arrayOf( PropTypes.fieldConfig ),
] );

PropTypes.error = PropTypes.instanceOf( Error );
PropTypes.fieldConfig = PropTypes.shape( {} ); // TODO
PropTypes.formConfig = PropTypes.shape( {} ); // TODO

PropTypes.buttonShape = {
  active    : PropTypes.bool,
  block     : PropTypes.bool,
  color     : PropTypes.string,
  disabled  : PropTypes.bool,
  outline   : PropTypes.bool,
  onClick   : PropTypes.func,
  size      : PropTypes.string,
  children  : PropTypes.node,
  className : PropTypes.className,
  large     : PropTypes.bool,
  small     : PropTypes.bool,
  label     : PropTypes.string,
  title     : PropTypes.string,
  icon      : PropTypes.string,
  // The Button addon passes field to provide a reference to the field
  // in the payload.
  field     : PropTypes.fieldConfig,
  // This lets you add additional whatever-you-need into the context
  // payload
  context   : PropTypes.shape( {} ),
};

export { PropTypes };
