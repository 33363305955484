import _ from 'lodash';
import { useMemo } from 'react';
import { useSetting } from '~/hooks';
import { useShortcuts } from './hooks';

export function usePersonalShortcuts() {
  const projects = useSetting( 'quick_projects' );

  useShortcuts( useMemo( () => {
    return _.compact( _.flattenDeep( [
      _.map( projects, ( project, idx ) => ( {
        name        : `project${idx}`,
        keys        : `g ${idx}`,
        description : `Go to Project: ${project.display_name}`,
        category    : 'Quick Projects',
        href        : `/project/${project._id}`,
      } ) ),
    ] ) );
  }, [ projects ] ) );
}
