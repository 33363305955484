import _ from 'lodash';
import { Icon } from '~/icons';
import { css } from '@emotion/react';
import { NavLink } from '~/router';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { PropTypes } from '~/utils';
import { useToggle, useMemo } from '~/hooks';

const nowrap = () => css` white-space: nowrap; `;
const toggle_css = css`span > svg { margin-right: 0.3em; }`;

function NavItem( props_in ) {
  const { header, icon, label, route, href, divider, ...props } = props_in;
  if ( divider ) return <DropdownItem divider css={nowrap} />;
  if ( header ) {
    return (
      <DropdownItem header css={nowrap}>
        <Icon icon={icon} label={header} fw />
      </DropdownItem>
    );
  }
  if ( href ) {
    return (
      <DropdownItem tag="a" className="nav-link" href={href} css={nowrap}>
        <Icon icon={icon} label={label} fw />
      </DropdownItem>
    );
  }

  return (
    <DropdownItem tag={NavLink} to={route} css={nowrap} {...props}>
      <Icon icon={icon} label={label} fw />
    </DropdownItem>
  );
}
NavItem.propTypes = {
  divider : PropTypes.bool,
  header  : PropTypes.string,
  route   : PropTypes.string,
  label   : PropTypes.string,
  icon    : PropTypes.string,
  href    : PropTypes.string,
};

export function NavMenu( {
  icon, label, items : items_in, name, moreItems, ...props
} ) {
  const [ isOpen, toggleIsOpen ] = useToggle( false );

  const items = useMemo( () => {
    return items_in.map( ( item, idx ) => {
      if ( _.isString( item ) ) {
        if ( item === '-' || item === 'divider' ) {
          item = { divider : true };
        } else {
          item = { header : item };
        }
      }
      if ( ! _.isPlainObject( item ) ) {
        throw new TypeError( `Invalid NavMenu item "${item}"` );
      }
      return _.defaults( item, {
        key : item.label || item.header || item.href || `item-${idx}`,
      } );
    } );
  }, [ items_in ] );

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={toggleIsOpen}
      data-testid={`navmenu-${name}`}
      css={{ cursor : 'pointer' }}
      {...props}
    >
      <DropdownToggle tag="a" className="nav-link" css={toggle_css}>
        <Icon icon={icon} label={label} />
      </DropdownToggle>
      <DropdownMenu right css={nowrap}>
        { items.map( item => ( <NavItem key={item.label} {...item} /> ) ) }
        { moreItems }
      </DropdownMenu>
    </Dropdown>
  );
}
NavMenu.propTypes = {
  label       : PropTypes.node,
  icon        : PropTypes.string,
  name        : PropTypes.string.isRequired,
  className   : PropTypes.className,
  items       : PropTypes.arrayOf( PropTypes.oneOfType( [
    // A divider
    PropTypes.oneOf( [ '-', 'divider' ] ),
    PropTypes.shape( { divider : PropTypes.bool } ),
    // A header
    PropTypes.string,
    PropTypes.shape( {
      header : PropTypes.string.isRequired,
      icon   : PropTypes.string,
    } ),
    // An external URL
    PropTypes.shape( {
      href  : PropTypes.string.isRequired,
      label : PropTypes.string.isRequired,
      icon  : PropTypes.string.isRequired,
    } ),
    // A local route
    PropTypes.shape( {
      route : PropTypes.string.isRequired,
      label : PropTypes.string.isRequired,
      icon  : PropTypes.string.isRequired,
    } ),
  ] ) ).isRequired,
  moreItems : PropTypes.node,
};
