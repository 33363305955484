import { init } from '@ssp/utils';
import {
  metrics,
  // @ts-ignore
  BrowserRegistry,
  loadStandardBundles,
} from '@ssp/metrics';

loadStandardBundles();

const registry = new BrowserRegistry();
metrics.registry = registry;

init.register( {
  id      : 'metrics',
  before  : [ 'react' ],
  context : registry,
} );

metrics.appinfo( { version : SSP.version } );
log.debug( 'VERSION:', SSP.version );

SSP.metrics = metrics;

declare module '@ssp/preload' {
  export interface SSP {
    metrics: typeof metrics;
  }
}
