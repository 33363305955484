import { useFieldMeta } from './useFieldMeta';

export function useFieldError( name ) {
  const {
    error, submitError, touched, dirtySinceLastSubmit,
  } = useFieldMeta( name, [
    'error', 'submitError', 'touched', 'dirtySinceLastSubmit',
  ] );
  // if ( ! touched ) return;

  // Don't mark validation errors until the field has been touched
  // (since most fields are going to be initially invalid).  You might
  // think we want to also show errors here if the form has attempted
  // to submit, but in that case the same validation errors will turn
  // into submit errors anyway.
  if ( touched && error ) return error;

  // Don't mark submit errors if the field has changed since the last
  // submit attempt (although we still mark validation errors in this
  // case).
  if ( submitError && ! dirtySinceLastSubmit ) return submitError;

  return;
}
