import _ from 'lodash';
import { PropTypes, useFieldError } from '~/forms/utils';
import { FormFeedback } from 'reactstrap';
import { jsonStringify } from '@ssp/utils';

export function FieldError( props ) {
  const { field, error : props_error, wrapper : Wrapper } = props;
  const state_error = useFieldError( field.name );
  let error = props_error || state_error;
  if ( ! error ) return null;
  if ( ! _.isString( error ) ) error = jsonStringify( error );
  const el = (
    <FormFeedback id={`${field.id}/error`} role="alert" className="d-block">
      {error}
    </FormFeedback>
  );
  if ( Wrapper ) return <Wrapper>{el}</Wrapper>;
  return el;
}
FieldError.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
  error   : PropTypes.string,
  wrapper : PropTypes.elementType,
};
