import { useMemo, useOptions, useEffect, useStore,
  useHistory, useLocation, useSettings } from '~/hooks';
import { registry } from './registry';
import { normalize, makeRegistryItemFromId } from './utils';
import { tourMetadata } from './tour-metadata';
import { useTour } from '@reactour/tour';

type TourIds = TourId[];
export type TourId = string;

export type TourRegistryItem = {
  id      : TourId,
  version : number,
  title   : string,
}

/**
 * Register global tours. Any `useTourRegistrations` hooks that are
 * mounted will contribute to the global mapping of tours.
 */
export function useTourRegistrations( tourIds : TourIds ) {

  const normed = useMemo( () => {
    return normalize(
      tourIds.map( tourId => makeRegistryItemFromId( tourId ) ),
    );
  }, [ tourIds ] );
  const options = useOptions( normed, 'deep' );
  useEffect( () => registry.register( options ), [ options ] );
}
export function useTourRegistration( tourId : TourId ) {
  useTourRegistrations( [ tourId ] );
}
export function useTourRegistryItems() : TourRegistryItem[] {
  return useStore( 'tours' );
}

export function useEnsureRoute( path : TourId ) {
  const history = useHistory();
  const location = useLocation();

  useEffect( () => {
    if ( !path ) return;
    if ( location.pathname !== path ) {
      history.push( path );
    }
  }, [ path, location.pathname, history ] );
}

/**
 * Iterate the tour registry to return tours that should be
 * automatically launch based on the following conditions:
 * - whether auto launch is suppressed by a session token
 * to avoid spamming the user when they elected to exit out of
 * a tour in the current browser session
 * - the user has the auto_launch_tours setting turned on
 * - a query to the database to determine whether the user has watched
 * the tour
 * - route-based tour configuration rules
 *
 * @returns {TourId[]} - an array of ids of tours that
 * ought to be automatically launched
 */
export function useAutoLaunchTour() : TourId[] {
  const settings = useSettings();
  const registryTours = useTourRegistryItems();
  const location = useLocation();
  const suppressAutoLaunch = sessionStorage.getItem( 'suppressAutoLaunch' );

  const tours = useMemo( () => {
    if ( !settings || !settings.loaded ) return null;
    if ( settings?.auto_launch_tours === 'Never' ) return null;
    const result = [];

    // auto-launch the intro tour if the user has never
    // set their auto-launch preference via step 2 of the intro
    // tour or via their user settings page
    if ( !suppressAutoLaunch && !settings?.auto_launch_tours
      && !settings?.tour_history?.get( { id : 'intro' } ) ) {
      result.push( 'intro' );
      return result;
    }

    // fetch the user's autolaunch setting
    const shouldLaunchNew = [ 'New and Updated', 'Only New' ]
      .includes( settings?.auto_launch_tours );
    const shouldLaunchUpdated = [ 'New and Updated' ]
      .includes( settings?.auto_launch_tours );

    // iterate through tours that were loaded into the registry
    // and find ones to autolaunch
    registryTours.forEach( tourInRegistry => {

      // Tours can be registered at a different path than they are
      // autolaunched. For example on the /project/{id} page you might want
      // to register a tour for the /members tab just to make it visible
      // on the list of runnable tours in the help menu, while not having it
      // actually autolaunch until the user visits the members tab
      const autoLaunchRegEx =
        tourMetadata[ tourInRegistry.id ]?.autoLaunchRoute;
      const passesRouteCondition =
        !autoLaunchRegEx ? true : // no condition, so pass
          autoLaunchRegEx.test( location.pathname ); // test the path

      if ( !suppressAutoLaunch
        && passesRouteCondition ) {
        const tourInDb = settings?.tour_history?.get( {
          id : tourInRegistry.id,
        } );

        if ( !tourInDb && shouldLaunchNew ) {
          result.push( tourInRegistry.id );
        } else {
          if ( shouldLaunchUpdated
            && tourInDb.version < tourInRegistry.version ) {
            result.push( tourInRegistry.id );
          }
        }
      }

    } );

    return result;
  }, [
    registryTours, settings, location, suppressAutoLaunch,
  ] );

  return tours;
}

export function useCloseTour( suppressAutoLaunch : boolean = true ) {
  const { setIsOpen, setCurrentStep } = useTour();
  const close = () => {
    if ( suppressAutoLaunch ) {
      sessionStorage.setItem( 'suppressAutoLaunch', '1' );
    }
    setCurrentStep( 0 );
    setIsOpen( false );
    SSP.tour.close();
  };
  return close;
}

export function useLaunchTour(
  tourId : TourId,
) {
  const history = useHistory();
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const launch = async () => {
    const { default : loadedSteps }
      = await import( `./steps/${tourId}` );
    if ( tourMetadata[ tourId ]?.goToRoute ) {
      history.push( tourMetadata[ tourId ].goToRoute );
    }
    setSteps( loadedSteps );
    SSP.tour.setActive( tourId );
    setCurrentStep( 0 );
    setIsOpen( true );
  };
  return launch;
}

export function useMarkLastStepFinished() {
  const tourId : TourId = useStore( 'active-tour' );
  const { currentStep, steps } = useTour();
  const settings = useSettings();

  useEffect( () => {
    const markFinished = ( async () => {
      await settings.updateTourHistory( {
        id: tourId,
        status: 'finished',
        version: tourMetadata[ tourId ].version,
      } );
    } );
    if ( currentStep && currentStep === steps.length - 1 ) {
      markFinished();
    }
  }, [ currentStep, steps, tourId, settings ] );
}
