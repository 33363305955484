import _ from 'lodash';
import { PropTypes } from '~/utils';
import { css } from '@emotion/react';
import { DataItem } from '../DataItem';
import styled from '@emotion/styled';
import { Badges } from './Badges.js';

const RowWrapper = styled( 'div' )( ( { theme } ) => css`
  line-height: 1;
  ul {
    margin-bottom: 0;
  }
` );

function Item( props_in ) {
  const { item } = props_in;
  if ( !item ) return null;
  const { component : Comp, name, value, ...props } = item;
  return (
    <div>
      <Comp key={name} name={name} {...props} value={value} />
    </div>
  );
}
Item.propTypes = {
  item : PropTypes.instanceOf( DataItem ).isRequired,
};

// values with badges
export function ItemsAndBadges( { items=[] } ) {
  if ( _.isEmpty( items ) ) return null;
  const badges = _.filter( items, 'badge' );
  return (
    <RowWrapper className="d-flex justify-content-between w-100">
      <div className="d-flex justify-content-between flex-grow-1">
        {items.map( item => {
          if ( item.badge ) return null;
          return <Item key={item.name} item={item} />;
        } )}
      </div>
      { badges && <BadgeBar items={badges} />}
    </RowWrapper>
  );
}
ItemsAndBadges.propTypes = {
  items : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
};

function BadgeBar( props ) {
  const { items } = props;

  if ( !items ) return null;

  return (
    <div className="d-flex justify-content-between pr-2 ml-auto">
      <Badges className="ml-2" items={items} />
    </div>
  );
}
BadgeBar.propTypes = {
  items : PropTypes.arrayOf( PropTypes.instanceOf( DataItem ) ).isRequired,
};

