import cx from 'clsx';
import PropTypes from 'prop-types';

export function IconText( props_in ) {
  const { text, className, inverse, transform, ...props } = props_in;
  props.className = cx( 'fa-layers-text', className, {
    'fa-inverse' : inverse,
  } );
  if ( transform ) props[ 'data-fa-transform' ] = transform;
  return <span {...props}>{text}</span>;
}
IconText.propTypes = {
  text        : PropTypes.string.isRequired,
  className   : PropTypes.className,
  transform   : PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
  inverse     : PropTypes.bool,
};
