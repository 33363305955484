import { Col, FormGroup } from 'reactstrap';
import { cx, PropTypes, useFieldError } from '~/forms/utils';
import {
  FormFields, FieldLabel, FieldState, FieldError, FieldHelp,
  FieldErrorBoundary,
} from '..';

export function FieldElementSubDoc( { field, ...props } ) {
  const error = useFieldError( field.name );
  const classes = cx( field.groupClassName, { 'has-error' : error } );
  const subconfig = field.subform();
  if ( ! subconfig.fields.length ) return null;
  // leveraging react-final-form prefixed fields
  subconfig.fields.forEach( f => f.name = `${field.name}.${f.name}` );
  return (
    <FieldErrorBoundary field={field}>
      <FormGroup row className={classes}>
        <FieldLabel field={field} />
        <Col sm={field.valueWidth}>
          <FormFields config={subconfig} />
          <FieldHelp field={field} />
          <FieldError field={field} error={error} />
        </Col>
      </FormGroup>
      <FieldState field={field} />
    </FieldErrorBoundary>
  );
}
FieldElementSubDoc.propTypes = {
  field   : PropTypes.fieldConfig.isRequired,
};
