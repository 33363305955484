import { PropTypes } from '~/forms/utils';
import { SelectableArrayGroup } from './SelectableArrayGroup';

export function SwitchGroup( props ) {
  // provide fieldType override since "switch" is not a field type
  // recognized by react-final-form, it is a customized checkbox from Bootstrap
  return <SelectableArrayGroup type="switch" fieldType="checkbox" {...props} />;
}
SwitchGroup.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
SwitchGroup.managesArray = true;
