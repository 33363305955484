import { cx, PropTypes } from '~/utils';
import { Action } from '@ssp/database';
import { useActionHandler } from './useActionHandler';

export function ActionItem( props_in ) {
  const handleClick = useActionHandler( props_in );
  const {
    component : Component,
    className,
    disabled,
    ...props
  } = props_in;

  props.className = cx( 'action-item', { disabled }, className );

  return (
    <Component {...props} disabled={disabled} onClick={handleClick} />
  );

}
ActionItem.propTypes = {
  action          : PropTypes.instanceOf( Action ).isRequired,
  resource        : PropTypes.actionable.isRequired,
  component       : PropTypes.renderable.isRequired,
  disabled        : PropTypes.string,
  className       : PropTypes.className,
};
