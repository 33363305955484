import _ from 'lodash';
import { PropTypes } from '~/forms/utils';
import { generate } from '@ssp/database';
import { FormButton } from '.';

export function GenerateButton( props ) {
  const { config : { fields } } = props;
  if ( ! _.some( fields, f => f.get( 'faker' ) ) ) return null;

  return (
    <FormButton color="warning" className="float-left" onClick={handleClick}>
      Generate
    </FormButton>
  );
}
GenerateButton.propTypes = {
  config  : PropTypes.formConfig.isRequired,
};

async function handleClick( { config, form } ) {
  const { resource } = config;
  const data = form.getState().values;
  const fields = _.fromPairs( _.map( config.fields, f => (
    [ f.name, f.get( 'faker' ) ]
  ) ) );

  const newdata = await generate( { resource, fields, data } );

  form.batch( () => _.each( newdata, ( v, k ) => {
    form.change( k, v );
  } ) );
}
