import { DataPanel } from '~/widgets';
import { Page } from '~/layout';

import { DevelopHome } from './DevelopHome';
import { FormKitchenSink } from './FormKitchenSink';
import { ResourceForms } from './ResourceForms';

import { useAuthStore, useSubject, useDimensions } from '~/hooks';

function handleBuilder( builder ) {
  builder.group( 'main' ).add(
    {
      path      : '',
      exact     : true,
      title     : 'Edit Thyself',
      icon      : 'far:code',
      component : DevelopHome,
    },
    {
      path      : 'forms/kitchen',
      exact     : true,
      title     : 'Form Kitchen Sink',
      icon      : 'fab:wpforms',
      component : FormKitchenSink,
    },
    {
      path      : 'forms/resource',
      exact     : false,
      title     : 'Resource Forms',
      icon      : 'fab:wpforms',
      component : ResourceForms,
    },
    /*
    {
      path      : 'info',
      exact     : true,
      title     : 'Misc Info',
      icon      : 'fad:info-circle',
      component : InfoPage,
    },
    */
    {
      path      : 'user',
      exact     : true,
      title     : 'User Information',
      icon      : 'fad:user-circle',
      component : UserDataPanel,
    },
    {
      path      : 'auth',
      exact     : true,
      title     : 'Authentication',
      icon      : 'fad:key',
      component : AuthDataPanel,
    },
    {
      path      : 'dimensions',
      exact     : true,
      title     : 'Browser Dimensions',
      icon      : 'fad:info-circle',
      component : DimensionsPanel,
    },
  );
}

export default function DevelopPage() {
  return (
    <Page title='Developer Tools' icon='far:code' builder={handleBuilder} />
  );
}

function UserDataPanel() {
  const user = useSubject();
  return <DataPanel title="Subject" data={user} />;
}
function AuthDataPanel() {
  const authstore = useAuthStore();
  return <DataPanel title="Authentication" data={authstore} />;
}
function DimensionsPanel() {
  const dims = useDimensions();
  return <DataPanel title="Dimensions" data={dims} />;
}
