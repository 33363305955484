import { CardsView } from './card';
import { ListView } from './list';
import { PanelsView } from './panel';
// import { TableView } from './table';
// import { GridView } from './grid';

const types = [
  CardsView,
  ListView,
  PanelsView,
  // TableView,
  // GridView,
];

export const configs = types.map( type => type.config ).filter( x => x );

export function getView( name ) {
  for ( const view of types ) {
    if ( ! ( view.config && view.config.name ) ) continue;
    if ( view.config.name === name ) return view;
  }
  throw new Error( `Unknown view type "${name}"` );
}
