import { resolveURL, init, delay } from '@ssp/utils';
// @ts-ignore
import client from '~/metadata.json';

SSP.meta.client = client;
SSP.meta.client_version = client.version;
SSP.version = client.version;
SSP.release = 'client@' + client.version;
SSP.env = ( () => {
  const origin = window.location.origin;
  if ( origin.startsWith( 'https://ssp.' ) ) return 'prod';
  if ( origin.startsWith( 'https://test-ssp.' ) ) return 'test';
  return 'dev';
} )();

init.register( {
  id      : 'browser-meta',
  before  : [ 'config', 'database', 'origins' ],
  after   : [ '@start' ],
  start   : getServerMeta,
} );

async function getServerMeta() {
  try {
    const response = await fetch( resolveURL( '/api/meta' ), {
      method : 'GET',
      cache  : 'no-cache',
    } );
    const server = await response.json();
    SSP.meta.server = server;
    SSP.meta.server_version = server.version;
    return server;
  } catch ( error ) {
    await delay( '5 seconds' );
    return getServerMeta();
  }
}
