import _ from 'lodash';
import { useState, useEffect } from 'react';

/** @module "UI.hooks" */

function getDimensions() {
  return {
    width   : window.innerWidth,
    height  : window.innerHeight,
  };
}

export function useDimensions() {
  const [ dimensions, setDimensions ] = useState( getDimensions() );

  function handleResize() {
    const dim = getDimensions();
    if ( _.isEqual( dim, dimensions ) ) return;
    setDimensions( dim );
  }
  useEffect( () => {
    window.addEventListener( 'resize', handleResize );
    return () => window.removeEventListener( 'resize', handleResize );
  } );

  return dimensions;
}
