import _ from 'lodash';
import { MemberInfoPendingChange } from '@ssp/database';
import { Alert } from 'reactstrap';

import { UserCard, JobCard } from '~/widgets';
import { Icon } from '~/icons';

import { useMembers } from '../context';

import type { Member } from '../Member';

export function MemberDetail( { member }: { member: Member } ) {
  const members = useMembers();
  const { user } = member;

  return (
    <tr data-testid='member-detail'>
      <td colSpan={members.total_columns}
        css={{ padding : 0, position : 'relative' }}
      >
        <div css={{ width : '50%', float : 'left' }}>
          <UserCard user={user} />
        </div>
        <div css={{ width : '50%', float : 'left' }}>
          {member.old_change?.error && (
            <Alert color='danger'>
              <h4 className="alert-heading">Pending Change Error</h4>
              <p>{member.old_change.error}</p>
            </Alert>
          )}
          {member.old_change && (
            <Alert color={member.new_change ? 'secondary' : 'warning'}>
              <h4 className="alert-heading">Pending Change</h4>
              <ChangeInfo change={member.old_change} />
            </Alert>
          )}
          {member.old_change && member.new_change &&
            <Icon icon="far:arrow-down"
              label="Pending Change will be superseded by New Change"
              size="2xl"
            />
          }
          {member.new_change && (
            <Alert color="success">
              <h4 className="alert-heading">New Change</h4>
              <ChangeInfo change={member.new_change} />
            </Alert>
          )}
          {member.old_change?.preparation_job && (
            <Alert color='info'>
              <h4 className="alert-heading">Preparing</h4>
              <JobCard job={member.old_change.preparation_job} />
            </Alert>
          )}
        </div>
      </td>
    </tr>
  );
}

function ChangeInfo( { change }: { change: MemberInfoPendingChange } ) {
  const types = { add : 'Adding', remove : 'Removing', update : 'Updating' };
  return (
    <ul>
      <li>{types[ change.change_type ]}</li>
      {_.map( change.config, ( val, key ) => (
        <li key={key}>{`${key}: ${val}`}</li>
      ) )}
      {change.error && ( <li>{`Error: ${change.error}`}</li> )}
      {change.requested_by && (
        <li>{`Requested By: ${change.requested_by}`}</li>
      )}
      {change.requested_at && (
        <li>{`Requested At: ${change.requested_at}`}</li>
      )}
    </ul>
  );
}
