import { Input } from 'reactstrap';
import { PropTypes, useInput } from '~/forms/utils';

export function Number( { field } ) {
  const input = useInput( field, { type : 'number' } );
  return <Input {...input} />;
}
Number.propTypes = {
  field : PropTypes.fieldConfig.isRequired,
};
