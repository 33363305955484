import { DB } from '@ssp/database';
import { Page } from '~/layout';
import { useMemo } from 'react';
import { useResource, useRouteParam } from '~/hooks';
import { ProjectView } from './ProjectView';

export default function ProjectPage() {
  const ident = useRouteParam( 'project_ident' );
  const rsrc = useMemo( () => DB.SSP.Project.fromIdent( ident ), [ ident ] );
  const project = useResource( rsrc, { label : 'ProjectPage' } );

  return (
    <Page resource={project} title={project?.display_name}>
      <ProjectView />
    </Page>
  );
}
