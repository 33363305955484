import _ from 'lodash';
import { useFormState } from '~/forms/utils';
import { useDebugFlag } from '~/utils';
import { Badge } from 'reactstrap';

const flags = {
  active                : 'primary',
  dirty                 : 'warning',
  dirtySinceLastSubmit  : 'warning',
  hasSubmitErrors       : 'danger',
  hasValidationErrors   : 'danger',
  invalid               : 'danger',
  modified              : 'info',
  pristine              : 'info',
  submitting            : 'warning',
  submitFailed          : 'danger',
  submitSucceeded       : 'success',
  touched               : 'warning',
  valid                 : 'success',
  validating            : 'warning',
  visited               : 'info',
};

export function FormState() {
  const flag = useDebugFlag( 'show-form-state' );
  if ( ! flag ) return null;
  return <FormStateBody />;
}

function FormStateBody() {
  const vals = useFormState( _.keys( flags ) );
  return (
    <div>
      {_.map( flags, ( color, name ) => {
        if ( ! vals[ name ] ) return null;
        return (
          <Badge key={name} color={color} pill>{_.startCase( name )}</Badge>
        );
      } )}
    </div>
  );
}
