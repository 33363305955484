import _ from 'lodash';
import { InputGroupAddon, InputGroupText } from 'reactstrap';
import { AddonPropTypes } from './utils';

export function Text( { addon, field } ) {
  return (
    <InputGroupAddon {...addon.getAddonProps()}>
      <InputGroupText {...addon.getProps()}>
        {_.result( addon, 'text' )}
      </InputGroupText>
    </InputGroupAddon>
  );
}
Text.propTypes = AddonPropTypes;
