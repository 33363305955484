import { useFormConfig } from '~/forms/utils';
import { Alert } from '~/widgets';

export function FormMessages( props ) {
  const form = useFormConfig();
  const msgs = [];
  if ( form.danger ) {
    msgs.push( <Alert color="danger" key="danger">{form.danger}</Alert> );
  }
  if ( form.warning ) {
    msgs.push( <Alert color="warning" key="warning">{form.warning}</Alert> );
  }
  if ( form.message ) {
    msgs.push( <Alert color="info" key="message">{form.message}</Alert> );
  }
  if ( ! msgs.length ) return null;
  return msgs;
}
