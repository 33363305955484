import _ from 'lodash';
import { useMemo, useCallback } from 'react';
import {
  useQueryParams,
  BooleanParam,
  StringParam,
  NumberParam,
  withDefault,
} from 'use-query-params';
import { createRegistry } from '@ssp/utils';
import { useOptions, useEffect, useRegistry } from '~/hooks';
import {
  encodeDelimitedArray,
  decodeDelimitedArray,
} from 'serialize-query-params';

/** Uses a dash to delimit entries. e.g. ['a', 'b'] => qp?=a-b */
const DashArrayParam = {
  encode : ( arr ) => encodeDelimitedArray( arr, '-' ),
  decode : ( str ) => decodeDelimitedArray( str, '-' ),
};

type BaseType<S extends 'string' | 'strings' | 'boolean' | 'number'>
  = S extends 'strings' ? string[]
  : S extends 'string' ? string
  : S extends 'number' ? number
  : S extends 'boolean' ? boolean
  : unknown;

const typemap = {
  strings : DashArrayParam,
  string  : StringParam,
  number  : NumberParam,
  boolean : BooleanParam,
};
type TypeMap = typeof typemap;
type ItemType = keyof TypeMap;
type DefaultFn<S extends ItemType> = () => BaseType<S> | null;
type AugmentFn<S extends ItemType> = ( value: BaseType<S>, opts: {
  data: Record<string, string | string[] | boolean | number>;
} ) => Record<string, any>;
type TransformFn<S extends ItemType> = ( value: any, opts: {
  data: Record<string, string | string[] | boolean | number>;
} ) => BaseType<S> | null;
type UpdateFn = ( data: Record<string, any> ) => Record<string, any>;
type Methods = Record<string, ( this: UpdateFn, ...args: any[] ) => void>;

type ViewItem<S extends ItemType = ItemType> = {
  type: ItemType;
  default?: BaseType<S> | null | DefaultFn<S>;
  transform?: TransformFn<S>;
  augment?: AugmentFn<S>;
  methods?: Methods;
};
export type ViewConfig = Record<string, ViewItem>;

const viewRegistry = createRegistry<ViewConfig, ViewConfig>( {
  name        : 'view-params',
  description : 'Type configuration for view context query params',
  merge       : 'merge',
  normalize( config: ViewConfig ) { return config; },
} );

export type ViewData<T extends ViewConfig> = {
  [K in keyof T]?: BaseType<T[K]['type']>;
};
export type ViewMethods<T extends ViewConfig> = {
  [K in keyof T]: T[K] extends { methods: infer U } ? U : never;
};
export type ViewDefaults<T extends ViewConfig> = {
  [K in keyof T]?: T[K] extends { default: infer U }
    ? U extends DefaultFn<infer V> ? V : U : never;
};
export type ViewAugments<T extends ViewConfig> = {
  [K in keyof T]: T[K] extends {
    augment( ...args: any[] ): infer U
  } ? U : never;
};
export type ViewBooleans<T extends ViewConfig> = {
  [K in keyof T]: BaseType<T[K]['type']> extends boolean ? K : never;
}[keyof T];
export type ViewSpecial<T extends ViewConfig> = {
  update( data: ViewData<T> ): ViewData<T>;
  toggle( key: ViewBooleans<T> ): void;
};
export type View<T extends ViewConfig>
  = ViewData<T> & ViewMethods<T> & ViewAugments<T> & ViewSpecial<T>;

export function useViewContext<T extends ViewConfig>(
  configs: T = {} as T,
): View<T> {
  const options = useOptions( configs, 'deep' );
  useEffect( () => viewRegistry.register( options ), [ options ] );

  const registry = useRegistry<ViewConfig>( 'view-params' );

  const handleAugments = useCallback( ( data ) => {
    const res: ViewData<T> = { ...data };
    for ( const [ key, conf ] of Object.entries( registry ) ) {
      if ( _.isFunction( conf.augment ) ) {
        const x = conf.augment( res[ key ], res as any );
        if ( _.isPlainObject( x ) ) _.assign( res, x );
      }
    }
    return res;
  }, [ registry ] );

  const defaults: ViewDefaults<T> = useMemo( () => (
    _.mapValues( registry, x => _.result( x, 'default' ) )
  ), [ registry ] );

  const config = useMemo( () => _.mapValues( registry, ( conf, key ) => (
    withDefault( typemap[ conf.type ] as $TSFixMe, defaults[ key ] )
  ) ), [ registry, defaults ] );
  const [ _view, _update ] = useQueryParams( config );

  const view = useMemo( () => {
    return handleAugments( { ...defaults, ..._view as any } );
  }, [ handleAugments, _view, defaults ] );

  const update = useCallback( ( data ) => _update( ( prev: ViewData<T> ) => {
    if ( typeof data === 'function' ) data = data( handleAugments( prev ) );
    /*
    data = _.pick( data, _.keys( registry ) );
    const extra = _.omit( data, _.keys( registry ) );
    if ( ! _.isEmpty( extra ) ) {
      log.warn( 'View Context ignoring unregistered options:', extra );
    }
    */
    data = { ...prev, ...data };
    for ( const [ key, conf ] of Object.entries( registry ) ) {
      if ( _.isFunction( conf.transform ) ) {
        const x = conf.transform( data[ key ], prev[ key ] as any );
        if ( _.isPlainObject( x ) ) {
          _.assign( data, x );
        } else if ( _.isNil( x ) ) {
          // no-op
        } else {
          data[ key ] = x;
        }
      }
    }
    // filtering defaults for cleaner url
    data = _.mapValues( data, ( val, key ) => {
      const defval = defaults[ key ];
      if ( _.isEqual( val, defval ) ) return null;
      return val;
    } );
    // Remove undefined values from the data (because we treat
    // undefined as "ignore this param")
    data = _.omitBy( data, _.isUndefined );
    // Then transform null into undefined to remove them from the
    // query
    data = _.mapValues( data, val => ( _.isNull( val ) ? undefined : val ) );
    return data;
  } ), [ _update, registry, defaults, handleAugments ] );

  const toggle = useCallback( ( name: string ) => {
    update( ( prev: ViewData<T> ) => ( { [name] : !prev[ name ] } ) );
  }, [ update ] );

  const methods = useMemo( () => {
    const res: Record<string, ( ...args: any[] ) => void>
      = Object.assign( {}, ...Object.values( registry ).map( r => r.methods ) );
    return _.mapValues( res, val => val.bind( update ) );
  }, [ registry, update ] );

  return useMemo(
    () => ( { ...view, ...methods, update, toggle } ),
    [ view, methods, update, toggle ],
  ) as unknown as View<T>;
}
