import { useMemo } from 'react';
import {
  Input, InputGroup, InputGroupText, InputGroupAddon,
} from 'reactstrap';

import { Icon } from '~/icons';
import { useViewContext, useResultSetContext } from '~/context';

export function Search() {
  const { search, update } = useViewContext( useMemo( () => ( {
    search    : { type : 'string' as const, default : '' },
    sensitive : { type : 'boolean' as const, default : false },
    exact     : { type : 'boolean' as const, default : false },
  } ), [ ] ) );

  return (
    <InputGroup>
      <Input
        className="border-right-0 border"
        id="search-input"
        aria-label="Search"
        placeholder="Search..."
        onChange={ev => update( { search : ev.target.value ?? '' } )}
        value={search ?? ''}
      />
      <InputGroupAddon addonType="append">
        <InputGroupText className="bg-transparent">
          <Icon
            aria-label="Clear Search"
            icon="fal:times-circle"
            onClick={() => update( { search : '' } )}
          />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
}

export function SearchInfo() {
  const data = useResultSetContext();
  const msg = getMessage( data );
  if ( ! msg ) return null;
  return (
    <div
      aria-label="Search Status"
      className="flex-grow-1 justify-content-end"
      css={theme => ( { display : 'inline-flex', color : theme.heading } )}
    >
      <h4>{msg}</h4>
    </div>
  );
}
function getMessage( data ) {
  if ( data.message ) return data.message;
  const rs = data.resultset;
  if ( ! rs ) return;
  if ( rs.loading ) return 'loading';
  if ( rs.loaded ) {
    const { length, total } = rs;
    return [
      length,
      ...( length === total ? [] : [ 'of', total ] ),
      total === 1 ? 'result' : 'results',
    ].join( ' ' );
  }
}
