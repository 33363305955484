import _ from 'lodash';
import { createPropType } from './custom-proptypes';
import ReactPropTypes from 'prop-types';
import {
  isComponent, isProject,
  isResourceType, isResourceTypeName, isUser, isResource,
  isSubDocument, isModel, isSchema, isResultSet, isDBRecord,
  Face, FaceSection,
} from '@ssp/database';
/**
 * This file exports a combination of all the possible PropTypes from
 * the different components we use, so that you don't have to remember
 * which ones come from where (and also so you have this handy list to
 * see which PropTypes are available to you).
 *
 * # PropTypes #
 * * From react:
 *  * Primitives:
 *   * string
 *   * number
 *   * bool
 *   * object
 *   * func
 *   * any (generally shouldn't be used, use shape instead)
 *  * Elements:
 *   * element
 *   * node
 *  * Enumerables:
 *   * oneOf([])
 *   * oneOfType([])
 *  * Arrays and Objects:
 *   * array
 *   * arrayOf()
 *   * instanceOf()
 *   * shape({})
 * * From ssp:
 *  * component
 *  * resource
 *  * project
 *  * user
 *  * team
 *
 * @module "UI.utils"
 */

const PropTypes = {};
export default PropTypes;
_.assign( PropTypes, ReactPropTypes );

PropTypes.component = createPropType(
  prop => isComponent( prop ), `Expected SSP Component`,
);

PropTypes.project = createPropType(
  prop => isProject( prop ), `Expected SSP.Project`,
);

PropTypes.resourceType = ( type ) => {
  return createPropType(
    prop => isResourceType( type, prop ),
    `Expected resource of type ${type}`,
  );
};

PropTypes.resourceTypeName = createPropType(
  prop => isResourceTypeName( prop ),
  `Expected resource type name`,
);

PropTypes.user = createPropType(
  prop => isUser( prop ), `Expected SSP.User`,
);

PropTypes.resource = createPropType(
  prop => isResource( prop ), `Expected Resource`,
);
PropTypes.subdoc = createPropType(
  prop => isSubDocument( prop ), `Expected SubDocument`,
);

PropTypes.typeOrArrayOfType = ( subtype ) => {
  return PropTypes.oneOfType( [ PropTypes.arrayOf( subtype ), subtype ] );
};

PropTypes.reactstrapColor = PropTypes.oneOf( [
  'success', 'warning', 'danger', 'primary',
  'secondary', 'info', 'light', 'dark',
] );

PropTypes.className = PropTypes.typeOrArrayOfType( PropTypes.oneOfType( [
  PropTypes.string,
  PropTypes.object,
  PropTypes.array,
] ) );

_.each( {
  Model       : isModel,
  Schema      : isSchema,
  ResultSet   : isResultSet,
  Record      : isDBRecord,
  Face        : PropTypes.instanceOf( Face ),
  FaceSection : PropTypes.instanceOf( FaceSection ),
}, ( chk, type ) => {
  const key = type.toLowerCase();
  const msg = `Expected a Database ${_.startCase( type )}`;
  PropTypes[ key ] = createPropType( chk, msg );
} );

PropTypes.actionable = PropTypes.oneOfType( [
  PropTypes.func,
  PropTypes.shape( {} ),
] );

const renderable = [
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape( { $$typeof : PropTypes.symbol, render : PropTypes.func } ),
];
PropTypes.renderable = PropTypes.oneOfType( [
  ...renderable,
  PropTypes.arrayOf( renderable ),
] );

PropTypes.children = PropTypes.any;

PropTypes.ref = PropTypes.oneOfType( [
  PropTypes.func,
  // PropTypes.shape( { current : PropTypes.instanceOf( Element ) } ),
  PropTypes.shape( { current : PropTypes.shape() } ),
] );
