import { useMemo } from 'react';
import { ActionForm } from '~/forms';
import { useRouteParam, useResourceContext } from '~/hooks';

export function ActionView( props ) {
  const resource = useResourceContext();
  const action_name = useRouteParam( 'action' );
  const action = useMemo( () => {
    if ( ! ( resource && action_name ) ) return;
    return resource.schema.getAction( action_name );
  }, [ action_name, resource ] );
  if ( ! action ) return null;
  return ( <ActionForm resource={resource} action={action} /> );
}
ActionView.propTypes = {};
