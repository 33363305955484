import { Switch, Redirect, Route } from '~/router';
import { useSubject } from '~/hooks';
import { ErrorBoundary } from '~/widgets';
import { routes } from './main-routes';
import { NotFoundPage } from './NotFoundPage';

export * from './builder';
export { routes, NotFoundPage };

export default function MainRoutes() {
  const subject = useSubject();
  return (
    <ErrorBoundary name="main-routes">
      <Switch>
        <Redirect exact path='/index.html' to='/' />
        <Redirect exact path='/job' to='/jobs' />
        { routes.map( route => {
          if ( route.enabled && ! route.enabled( subject ) ) return null;
          return <Route {...route} key={route.path} />;
        } ) }
        <Route component={NotFoundPage} />
      </Switch>
    </ErrorBoundary>
  );
}
