import _ from 'lodash';
import { watchableUnwrap, isResource } from '@ssp/database';
import { useEffect, useState } from '.';

const comparators = {
  deep    : ( state, update ) => _.isEqual( state, update ),
  shallow : ( state, update ) => {
    const keys1 = _.keys( state ).sort();
    const keys2 = _.keys( update ).sort();
    if ( keys1.length !== keys2.length ) return false;
    if ( ! _.isEqual( keys1, keys2 ) ) return false;
    return _.every( state, ( val, key ) => {
      if ( isResource( val ) && isResource( update[ key ] ) ) {
        return watchableUnwrap( val ) === watchableUnwrap( update[ key ] );
      }
      if ( isResource( val ) || isResource( update[ key ] ) ) return false;
      return val === update[ key ];
    } );
  },
};

/**
 * One of the downsides to using hooks is that if you do something
 * like `useThing( { foo, bar, baz } );` then that object is going to
 * change on every render, even if the values of foo/bar/baz didn't.
 * This lets you encapsulate an options object so that doesn't happen.
 *
 * If the `compare` parameter is a string it's a key from the
 * `comparators` function in
 * `ssp-webapp-client/src/hooks/useOptions.js`.  Currently the
 * allowable string values are `deep` (which just uses `_.isEqual`)
 * and `shallow`, which returns true if all the keys and values in the
 * object are the same.  The default is `shallow` because that's what
 * you usually want.  Be careful about deep comparisons, which may get
 * really slow if the options include things like DB resources which
 * end up recursing basically the whole DB library.
 *
 * @param {object} [data={}] - Options data.
 * @param {Function|string} [compare] - Comparison function to
 * determine if the objects are equal.
 *
 * @returns {object} Options data.
 */
export function useOptions( data, compare='shallow' ) {
  if ( _.isString( compare ) ) compare = comparators[ compare ];
  const [ state, setState ] = useState( data );
  useEffect( () => {
    if ( ! compare( state, data ) ) setState( data );
  }, [ state, data, compare ] );
  return state;
}
