import { PropTypes } from '~/utils';

export function Url( { value } ) {
  return <a href={value}>{value}</a>;
}

Url.propTypes = {
  value : PropTypes.string.isRequired,
};

