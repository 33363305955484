import _ from 'lodash';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Table as TableRS } from 'reactstrap';

import { PropTypes } from '~/utils';
import { ErrorBox } from '~/widgets';
import { Icon } from '~/icons';

import { useMembers, useMembersViewContext } from '../context';
import { MemberRow } from './MemberRow';

const Table = styled( TableRS )( ( { theme }: $TSFixMe ) => css`
  border-collapse: collapse;
  thead {
    background-color : ${theme.table.thead.bg};
    color : ${theme.table.thead.fg};
    tr { font-weight: normal; }
    th { cursor: pointer; }
  }
  tbody {
    background-color : ${theme.table.tbody.bg};
    color : ${theme.table.tbody.fg};
    tr { font-weight: normal; }
  }
` );

function Header( { name, label } ) {
  const {
    sortDirection, sortBackwards, sortKey, sortIcon, updateSort,
  } = useMembersViewContext();
  const active = sortKey === name;
  const props = {
    tabIndex  : 0,
    scope     : 'col',
  };
  if ( name && label ) {
    _.assign( props, {
      role      : 'button',
      onClick   : () => updateSort( name ),
      ...( active ? {
        'aria-label' : `${label} : activate to sort column ${sortBackwards}`,
        'aria-sort'  : sortDirection,
      } : {
        'aria-label' : `${label} : activate to sort column ${sortDirection}`,
      } ),
    } );
  }
  return (
    <th {...props}>
      {label}
      {active && ( <Icon
        aria-hidden='true'
        className="pl-2"
        icon={sortIcon}
        color="white"
      /> )}
    </th>
  );
}
Header.propTypes = {
  name  : PropTypes.string,
  label : PropTypes.string,
};

export function MembersView() {
  const members = useMembers();
  const { sortAscending, sortKey } = useMembersViewContext();
  const list: Member[] = useMemo( () => {
    const items = _.sortBy( members.members, member => {
      return member.getValue( sortKey )?.toLowerCase();
    } );
    if ( ! sortAscending ) items.reverse();
    return items;
  }, [ members, sortAscending, sortKey ] );

  if ( members.error ) return <ErrorBox error={members.error} />;
  if ( members.action === 'confirm' ) return null;
  return (
    <Table hover responsive>
      <thead>
        <tr>
          <Header name='name' label='Name' />
          <Header name='email' label='Email' />
          {members.config_fields.map( ( { name, label } ) => (
            <Header key={name} {...{ name, label }} />
          ) )}
          <Header name='status' label='Status' />
          { members.show_tools && <th /> }
        </tr>
      </thead>
      <tbody>
        {list.map( member => (
          <MemberRow key={member.key} member={member} />
        ) )}
      </tbody>
    </Table>
  );
}
