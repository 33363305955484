import { FieldConfig } from '~/forms';
import { useField } from './useField';

export function useInput( field, options ) {
  if ( ! ( field instanceof FieldConfig ) ) {
    log.error( `Invalid call to useInput`, { field } );
    throw new Error( `Invalid call to useInput` );
  }
  return useField( field, options ).input;
}
