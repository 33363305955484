import _ from 'lodash';
import { Icon } from '~/icons';
import {
  ButtonDropdown, DropdownToggle,
  DropdownMenu, Input, Label,
} from 'reactstrap';
import { useResultSetContext, useViewContext } from '~/context';
import { useToggle, useConstant } from '~/hooks';

export function Filters() {
  const [ isFilterOpen, toggleFilterOpen ] = useToggle( false );
  const { schema } = useResultSetContext();
  const {
    filters : view_filters = [], updateFilters,
  } = useViewContext( useConstant( () => ( {
    filters   : {
      type    : 'strings' as const,
      default : [],
      methods : {
        updateFilters( ...changes: string[] ) {
          this( ( { filters = [] } ) => {
            for ( const change of changes ) {
              if ( ! change ) continue;
              if ( change.startsWith( '-' ) ) {
                filters = _.without( filters, change.slice( 1 ) );
              } else if ( change.startsWith( '!' ) ) {
                if ( filters.includes( change.slice( 1 ) ) ) {
                  filters = _.without( filters, change.slice( 1 ) );
                } else {
                  filters = filters.concat( change.slice( 1 ) );
                }
              } else if ( change.startsWith( '+' ) ) {
                filters = filters.concat( change.slice( 1 ) );
              } else {
                filters = filters.concat( change );
              }
            }
            return { filters : _.uniq( filters ) };
          } );
        },
      },
    },
  } ) ) );

  const { filters : schema_filters } = schema.config || {};
  if ( _.isEmpty( schema_filters ) ) return null;

  return (
    <ButtonDropdown
      isOpen={isFilterOpen}
      toggle={toggleFilterOpen}
      aria-label="Choose Filter options"
    >
      <DropdownToggle caret aria-label="Choose filters">
        <Icon icon={view_filters.length ? 'fas:filter' : 'far:filter'} />
      </DropdownToggle>
      <DropdownMenu aria-label="Filter options"
        data-testid="filters-list" role="menu"
        className="px-5 py-2"
      >
        {_.map( schema_filters, ( { label }, id ) => (
          <Label check key={id} className="text-nowrap">
            <Input
              key={id}
              type="checkbox"
              checked={view_filters.includes( id )}
              onChange={( e ) => {
                updateFilters( ( e.target.checked ? '+' : '-' ) + id );
              }}
            />{' '}{label}
          </Label>
        ) )}
      </DropdownMenu>
    </ButtonDropdown>
  );
}
