import _ from 'lodash';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import {
  Modal as RSModal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import { cx, PropTypes } from '~/utils';

export function Modal( props_in ) {
  const {
    title,
    className,
    children,
    header,
    footer,
    buttons,
    portal,
    isOpen,
    toggle,
    ...props
  } = props_in;
  const classes = cx( 'modal', className );
  const footerElem = useMemo( () => {
    if ( footer ) return footer;
    if ( buttons ) {
      return _.map( buttons, ( { label, ...conf } ) => {
        return <Button key={label} {...conf}>{label}</Button>;
      } );
    }
  }, [ footer, buttons ] );
  const elem = (
    <RSModal isOpen={isOpen} toggle={toggle} className={classes} {...props}>
      <ModalHeader toggle={toggle}>{title}{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>{footerElem}</ModalFooter>
    </RSModal>
  );
  return ReactDOM.createPortal( elem, document.getElementById( portal ) );
}

Modal.propTypes = {
  title       : PropTypes.string,
  className   : PropTypes.className,
  children    : PropTypes.node.isRequired,
  header      : PropTypes.node,
  footer      : PropTypes.node,
  autoFocus   : PropTypes.bool,
  // if modal should be centered vertically in viewport
  centered    : PropTypes.bool,
  // corresponds to bootstrap's modal sizes, ie. 'lg' or 'sm'
  size        : PropTypes.string,
  role        : PropTypes.string, // defaults to "dialog"
  // used to reference the ID of the title element in the modal
  labelledBy  : PropTypes.string,
  // if body of modal should be scrollable when content is long
  scrollable  : PropTypes.bool,
  // called on componentDidMount
  onEnter     : PropTypes.func,
  // called on componentWillUnmount
  onExit      : PropTypes.func,
  // called when done transitioning in
  onOpened    : PropTypes.func,
  // called when done transitioning out
  onClosed    : PropTypes.func,
  // boolean to control whether the fade transition occurs (default: true)
  fade        : PropTypes.bool,
  isOpen      : PropTypes.bool.isRequired,
  toggle      : PropTypes.func.isRequired,
};

Modal.defaultProps = {
  portal      : 'modals-root',
  fade        : true,
  centered    : true,
};
