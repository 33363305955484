import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useOptions } from './useOptions';

/** @module "UI.hooks" */

const throttleInterval = 150;

function checkVisibility( el, { partial, overscan, scrollable } ) {
  // The jsdom implementation of getBoundingClientRect always returns
  // an object where all the values are 0, so when running tests under
  // mocha we just pretend everything is always visible.
  // https://github.com/jsdom/jsdom/issues/1590
  if ( BUILD.isTest ) return true;
  if ( ! el ) return false;

  const wh = scrollable.innerHeight;
  const ww = scrollable.innerWidth;

  const rect = el.getBoundingClientRect();
  let { top, right, bottom, left } = rect;
  const { height, width } = rect;
  if ( width === 0 ) return false;

  if ( overscan ) {
    top -= overscan;
    bottom += overscan;
    left -= overscan;
    right += overscan;
  }

  if ( partial ) {
    const vertInView = ( top <= wh ) && ( ( top + height ) >= 0 );
    const horInView = ( left <= ww ) && ( ( left + width ) >= 0 );
    return ( vertInView && horInView );
  } else {
    return ( top >= 0 && left >= 0 && bottom <= wh && right <= ww );
  }
}

export function useVisibility( element, options={} ) {
  _.defaults( options, {
    partial     : true,
    scrollable  : window,
    overscan    : 0,
  } );
  const opts = useOptions( options );
  const [ visible, setVisible ] = useState(
    () => checkVisibility( element, opts ),
  );
  const update = useCallback( () => {
    setVisible( checkVisibility( element, opts ) );
  }, [ element, opts ] );

  useEffect( () => {
    // Checking visible here means that once we become visible we stay
    // that way and stop checking.  This avoids unmounting and
    // remounting all the time as you scroll, and just keeps things
    // initially hidden until you scroll to them.
    if ( visible || ! element ) return;

    const handleScrollOrResize = _.debounce( update, throttleInterval );

    opts.scrollable.addEventListener( 'scroll', handleScrollOrResize );
    window.addEventListener( 'resize', handleScrollOrResize );

    update();

    return () => {
      opts.scrollable.removeEventListener( 'scroll', handleScrollOrResize );
      window.removeEventListener( 'resize', handleScrollOrResize );
    };
  }, [ visible, element, update, opts ] );

  return visible;
}
