import { DB, Schema } from '@ssp/database';
import { RouterTabs } from '~/pages/builder';
import { CollectionView } from '~/views';
import { useResourceContext } from '~/hooks';
import { ResourceForm } from '~/forms';
import { SummaryPage } from './SummaryPage';
import { ListPanel, ProjectIds } from './utils';

// eslint-disable-next-line max-lines-per-function
function handleBuilder( builder ) {
  const user = builder.resource;
  if ( ! user ) return;
  const main = builder.group( 'main' );
  main.add( {
    icon : 'far:user-crown',
    path      : '',
    title     : 'Home',
    component : SummaryPage,
    props     : () => ( { user } ),
  } );
  if ( ! BUILD.isProd ) {
    main.add(
      {
        path      : 'edit',
        title     : 'Edit',
        icon      : 'far:edit',
        component : ResourceForm,
        props     : () => ( { resource : user } ),
      },
    );
  }
  main.add(
    'action',
    {
      path        : 'projects',
      title       : 'Projects',
      count       : 'SSP.Project',
      icon        : 'far:folder-open',
      component   : CollectionView,
      props       : () => ( {
        resultset : DB.SSP.Project
          .find( {}, { minlength : false } )
          .user( user._id ),
      } ),
    },
    {
      path        : 'teams',
      title       : 'Teams',
      count       : 'SSP.Team',
      icon        : 'fal:users-class',
      component   : CollectionView,
      props       : () => ( {
        resultset : DB.SSP.Team.find( { 'members.user_id' : user._id }, {
          minlength : false,
        } ),
      } ),
    },
    {
      path      : 'jobs',
      title     : 'Jobs',
      count     : 'SYSTEM.Job',
      icon      : 'far:history',
      component : CollectionView,
      props     : () => ( {
        resultset : DB.SYSTEM.Job.find( { 'user.id' : user._id }, {
          minlength : false,
          limit     : 100,
        } ),
      } ),
    },
    {
      path        : 'settings',
      title       : 'User Settings',
      icon        : 'fad:sliders-v-square',
      access      : 'admin',
      component   : ResourceForm,
      props       : () => ( { resource : user.settings } ),
    },
  );

  const accounts = builder.group( 'accounts' );
  const nonaccounts = builder.group( 'non-accounts' );

  Schema.filter( 'is_user_resource', '!SSP.User.Settings' ).forEach( schema => {
    const config = {
      titles     : [ schema.name, schema.plural_name ],
      component  : CollectionView,
      attachable : schema.canAttachTo( user ),
      props      : () => ( {
        view      : 'panel',
        face      : 'summary',
        resultset : user.findResources( schema.id, {}, { minlength : false } ),
      } ),
    };
    if ( BUILD.isProd && schema.id === 'Test.User' ) return;
    if ( schema.is_user_account ) {
      accounts.service( schema.id, config );
    } else {
      nonaccounts.service( schema.id, config );
    }
  } );

  builder.support(
    {
      title     : 'System Teams',
      component : ListPanel,
      props     : { title : 'System Teams', item : 'system_teams' },
      path      : 'system-teams',
      access    : 'support',
      icon      : 'fad:database',
    },
    {
      title     : 'Capabilities',
      component : ListPanel,
      props     : { title : 'Capabilities', item : 'capabilities' },
      path      : 'capabilities',
      access    : 'support',
      icon      : 'fad:database',
    },
    {
      title     : 'Project Ids',
      component : ProjectIds,
      path      : 'project-ids',
      access    : 'support',
      icon      : 'fad:database',
    },
  );
}

export function UserView( props ) {
  const user = useResourceContext();
  return <RouterTabs builder={handleBuilder} resource={user} {...props} />;
}
