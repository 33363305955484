import _ from 'lodash';
import { useState, useMemo } from 'react';
import { cx, PropTypes } from '~/utils';
import { useVisibility, useDebugFlag } from '~/hooks';

export function VisibilityWrapper( props_in ) {
  const { children, tag : Component, render } = props_in;
  const [ element, setElement ] = useState( null );
  const visible = useVisibility( element, { partial : true } );
  const highvis = useDebugFlag( 'show-visibility-wrapper' );

  const props = useMemo( () => {
    const style_props = [
      'height', 'width', 'float', 'margin', 'overflowY', 'minHeight',
    ];
    const p = _.omit( props_in, [
      'className', 'children', 'render', 'tag', ...style_props,
    ] );
    style_props.forEach( x => {
      if ( props_in[ x ] ) _.set( p, [ 'style', x ], props_in[ x ] );
    } );
    return p;
  }, [ props_in ] );

  const interior = useMemo( () => {
    if ( ! visible ) return null;
    if ( render ) return render();
    if ( typeof children === 'function' ) return children();
    return children;
  }, [ visible, children, render ] );

  const classes = cx( 'visibility-wrapper', props_in.className, {
    visible, hidden : ! visible, highvis,
  } );
  return (
    <Component {...props} className={classes} ref={setElement}>
      {interior}
    </Component>
  );
}
VisibilityWrapper.propTypes = {
  className   : PropTypes.className,
  children    : PropTypes.oneOfType( [ PropTypes.node, PropTypes.func ] ),
  render      : PropTypes.func,
  height      : PropTypes.string,
  width       : PropTypes.string,
  float       : PropTypes.string,
  margin      : PropTypes.string,
};
VisibilityWrapper.defaultProps = {
  tag         : 'div',
};
