import _ from 'lodash';
import { ResultSet } from '@ssp/database';

import { useState, useEffect, useMemo, useOptions, useCallback } from '.';

import type { ResultSetOptions } from '@ssp/database';
import type { ResultSetContext } from '~/context';

/**
 * Use this hook to ensure that a resultset has loaded before rendering.
 *
 * @see {useResultSetResolver} for more details about these arguments.
 * @param resultset - Resultset
 * @param options - Options
 */
export function useResultSet(
  resultset: ResultSet,
  options: ResultSetOptions = {},
): ResultSetContext {
  const rs = useResultSetResolver( resultset, options );
  return useResultSetWatcher( rs );
}

/**
 * Given a `ResultSet`, attempts to load it and provide an updated
 * context whenever it changes.
 *
 * @param resultset - The ResultSet instance to watch.
 */
export function useResultSetWatcher( resultset: ResultSet ): ResultSetContext {
  const [ state, setState ] = useState<ResultSetContext>( {
    resultset, loading : false, loaded : false, schema : resultset?.schema,
  } );
  const update = useCallback( ( data ) => {
    setState( prev => ( { ...prev, ...data } ) );
  }, [ setState ] );

  useEffect( () => {
    if ( ! resultset ) return;
    const message = resultset.preload();
    if ( _.isString( message ) ) return update( { message } );
    if ( ! resultset.loaded ) update( { loading : true } );
    return resultset.watch( ( rs: ResultSet ) => {
      if ( _.isError( rs ) ) {
        update( {
          resultset : null,
          message   : rs.message,
          error     : rs,
          loaded    : false,
          loading   : false,
          schema    : resultset?.schema,
        } );
      } else {
        const msg = ( rs.loaded && rs.length === 0 )
          ? 'No matches found' : null;
        update( {
          resultset : rs,
          message   : msg,
          error     : null,
          loaded    : Boolean( rs.loaded ),
          loading   : Boolean( rs.loading ),
        } );
      }
    }, {
    } );
  }, [ resultset, update ] );

  return { ...state, schema : state.resultset?.schema };
}

/**
 * Takes a variety of inputs that can be used to provide a resultset
 * and resolves them into a resultset with options applied.  This is
 * the first step in getting a complete and usable ResultSet in the
 * browser, but you probably shouldn't be using it directly.
 * Generally you want to use either `useResultSet` or
 * `useQuickSearch`, which both call this internally.
 *
 * @param {ResultSet} resultset - The ResultSet
 * to manage (or the id of one to create, or a FieldConfig instance to
 * create it for)
 * @param {object} [options] - Options object, any options not
 * explicitly mentioned here are passed through to the resultset.
 * @param {object} [options.query] - If specified, will get passed to
 * the resultset as the query.
 *
 * @returns {ResultSetContext} The context of the ResultSet instance
 * that was resolved.
 *
 * @example
 * import { useResultSet } from '~/hooks';
 *
 * function MyResourceComponent( props ) {
 *   const resultset = useResultSet( props.resultset );
 *   return <CollectionView resultset={resultset} />;
 * }
 */
export function useResultSetResolver(
  resultset: ResultSet,
  options: ResultSetOptions = {},
): ResultSet | undefined {

  const opts = useOptions( options );
  return useMemo( () => {
    if ( _.isNil( resultset ) ) return;
    if ( resultset instanceof ResultSet ) {
      if ( _.isMatch( opts, resultset.options ) ) return resultset;
      return resultset.addOptions( opts );
    }
    log.error( 'Invalid ResultSet:', resultset );
    throw new Error( `Invalid resultset: "${resultset}"` );
  }, [ resultset, opts ] );
}
