import _ from 'lodash';
import { getSchema } from '@ssp/database';
import { Panel } from '~/components';
import { Icon } from '~/icons';
import { Button, Loading, Alert } from '~/widgets';
import { useToggle, usePromiseResult, useConstant } from '~/hooks';

import { useMembers } from '../context';

import type {
  MembershipHelper, MembershipHelperUser, MembershipHelperResource,
} from '@ssp/database';

const chargebackUrl = [
  'https://boozallen.sharepoint.com',
  '/teams/selfserviceportal/SitePages',
  '/information.aspx#chargeback',
].join( '' );
const supportUrl = 'https://support.boozallencsn.com';
const chargebackFAQ = (
  <a href={chargebackUrl} target="_blank">Chargeback FAQ</a>
);

const labels = {
  add    : 'Adding',
  remove : 'Removing',
  update : 'Updating',
};

export function Confirmation() {
  const members = useConstant( useMembers() );
  // const [ by_user, toggleByUser ] = useToggle( false );
  const by_user = false;
  const [ detailed, toggleDetailed ] = useToggle( false );

  const helper = usePromiseResult( () => members.getHelper(), [ members ] );
  if ( ! helper ) return <Loading />;

  const AdvisoryMsg =  [
    'Please be advised that it is your responsibility ',
    'to actively manage the user list for a resource, ',
    'especially removing users when appropriate.',
  ].join( '' );

  return (
    <Panel title="Change Confirmation" body>
      <Alert color="info">{AdvisoryMsg}</Alert>
      <ProvisioningMessage helper={helper} />
      <h3>The following resources will be affected by this action</h3>
      <FormatSummary {...{ helper, by_user, detailed }} />
      <div>
        <Button css={{ float: 'left' }} onClick={toggleDetailed}>
          <Icon
            fw size="lg"
            icon='fas:info-square'
            label={( detailed ? 'Hide' : 'Show' ) + ' Details'}
          />
        </Button>
      </div>
    </Panel>
  );
}

function FormatSummary( { helper, by_user, detailed }: {
  helper: MembershipHelper;
  by_user: boolean;
  detailed: boolean;
} ) {
  if ( by_user ) {
    return null;
    // return <FormatSummaryByUser {...{ helper, detailed }} />;
  } else {
    return <FormatSummaryByResource {...{ helper, detailed }} />;
  }
}

/*
function FormatSummaryByUser( { helper, detailed }: {
  helper: MembershipHelper;
  detailed: boolean;
} ) {
  const users = _.sortBy( helper.users, 'name' );
  if ( ! users ) return null;

  return (
    <dl>
      {users.map( user => (
        <SummarizeUser key={user.id} {...{ helper, user, detailed }} />
      ) )}
    </dl>
  );
}
*/

function FormatSummaryByResource( { helper, detailed }: {
  helper: MembershipHelper;
  detailed: boolean;
} ) {
  const rsrcs = helper.getResources();
  if ( ! rsrcs ) return null;

  return (
    <dl>
      {rsrcs.map( rsrc => (
        <SummarizeResource key={rsrc.id} {...{ helper, rsrc, detailed }} />
      ) )}
    </dl>
  );
}
function SummarizeResource( props: {
  helper: MembershipHelper;
  rsrc: MembershipHelperResource;
  detailed: boolean;
} ) {

  const { rsrc } = props;

  if ( ! ( props.detailed || rsrc.has_changes ) ) return null;
  const users = _.sortBy( rsrc.users, 'name' );
  return (
    <>
      <dt>{rsrc.name} ({rsrc.resource.schema.name})</dt>
      <dd>
        <ul>
          {users.map( user => (
            <FormatUserForResource key={user.id} {...props} user={user} />
          ) )}
        </ul>
      </dd>
    </>
  );
}
/*
function SummarizeUser( props: {
  helper: MembershipHelper;
  user: MembershipHelperUser;
  detailed: boolean;
} ) {

  const { user } = props;

  const rsrcs = _.sortBy( user.resources, 'name' );
  return (
    <>
      <dt>{user.name}</dt>
      <dd>
        <ul>
          {rsrcs.map( rsrc => (
            <FormatResourceForUser key={user.id} {...props} rsrc={rsrc} />
          ) )}
        </ul>
      </dd>
    </>
  );
}
*/

function FormatUserForResource( { user, rsrc, detailed }: {
  user: MembershipHelperUser;
  rsrc: MembershipHelperResource;
  detailed: boolean;
} ) {
  const info = user.getFinalChangeInfoForResource( rsrc );
  if ( ! info ) return null;

  const label = _.compact( [
    labels[ info.change_type ], user.name,
  ] ).join( ' ' );

  const cfg = _.map( info.config, ( val, key ) => `${key} ${val}` );
  if ( cfg.length > 1 ) {
    const last = cfg.pop();
    cfg.push( `and ${last}` );
  }
  const conf = cfg.length
    ? <span>&nbsp;with&nbsp;{cfg.join( ', ' )}</span>
    : null;

  const details = detailed ? (
    <ul>
      {[
        ...info.included_changes,
        ...info.excluded_changes,
      ].map( ( chg, idx ) => {
        let msg = (
          <span>
            <b>{chg.resource.name}:</b>
            {chg.getMessageDetails()}
          </span>
        );
        if ( ! chg.result ) msg = <s>{msg}</s>;
        return <li key={idx}>{msg}</li>;
      } )}
    </ul>
  ) : null;
  return (
    <li><b>{label}</b>{conf}{details}</li>
  );
}

/*
function SummarizeUser( { user, detailed }: {
  user: MembershipHelperUser;
  detailed: boolean;
} ) {
  return (
    <>
      {user.changes.map( ( chg, idx ) => {
        if ( ! ( chg.result || detailed ) ) return null;
        const label = labels[ chg.change_type ];
        let who = user.name;
        if ( ! _.isEmpty( chg.config ) ) {
          const cfg = _.map( chg.config, ( val, key ) => `${key} ${val}` );
          if ( cfg.length > 1 ) {
            const last = cfg.pop();
            cfg.push( `and ${last}` );
          }
          who += ` with ${cfg.join( ', ' )}`;
        }
        if ( detailed ) {
          if ( chg.teamlink ) {
            who += ` (reason: ${chg.reason}, via teamlink: ${chg.teamlink})`;
          } else {
            who += ` (reason: ${chg.reason})`;
          }
        }
        if ( chg.result ) {
          return ( <li key={idx}><b>{label}:</b> {who}</li> );
        } else {
          return ( <li key={idx}><s><b>NOT {label}:</b> {who}</s></li> );
        }
      } )}
    </>
  );
}
*/

function ProvisioningMessage( { helper }: { helper: MembershipHelper } ) {
  const creating = _.compact( _.map( helper.getUsers(), user => {
    const ids = _.without( user.will_provision_types, 'SSP.User' );
    if ( ! ids.length ) return;
    const types = _.map( ids, t => getSchema( t ).service.name );
    let msg = 'New ';
    if ( types.length === 1 ) {
      msg += `${types[0]} account`;
    } else {
      types.push( 'and ' + types.pop() );
      msg += `${types.join( ', ' )} accounts`;
    }
    return { id : user.id, name : user.name, message : msg };
  } ) );
  if ( ! creating.length ) return null;

  return (
    <>
      <h3>This will create new accounts</h3>
      <p>
        Creating new accounts will likely incur additional cost.
        Visit the {chargebackFAQ} to see what to expect.
      </p>
      <p>
        If you need to add large numbers of people to this resource,
        please open a <a href={supportUrl}>support ticket</a>.
      </p>
      <ul>
        {creating.map( ( { id, name, message } ) => (
          <li key={id}><b>{name}:</b> {message}</li>
        ) )}
      </ul>
    </>
  );
}
