window.process = {
  title     : 'browser',
  browser   : true,
  env       : {},
  argv      : [],
  version   : '',
  versions  : {},

  binding() { throw new Error( 'process.binding is not supported' ); },
  cwd() { return '/'; },
  chdir() { throw new Error( 'process.chdir is not supported' ); },
};
