import _ from 'lodash';
import { PropTypes } from '~/utils';
import { Member } from '../Member';
import { useWatchable, useDebugFlag } from '~/hooks';

const section_style = {
  width     : '33%',
  float     : 'left',
  overflow  : 'scroll',
  padding   : '1em',
} as const;
export function MemberDebug( props ) {
  const member: Member = useWatchable( props.member ) as Member;
  const debug = useDebugFlag( 'show-members-details' );
  if ( ! debug ) return null;

  const member_info = _.pick( member, [
    'name', 'email', 'error', 'key', 'user_id', 'service_user_id', 'user_type',
  ] );
  const has_values = _.fromPairs( _.map( [
    'user', 'memberinfo', 'old_change', 'new_change',
  ], key => ( [ key, _.isNil( member[ key ] ) ? 'No' : 'Yes' ] ) ) );
  const flags = [
    'is_loading',
    'is_loaded',
    'is_complete',

    'has_changes',
    'can_edit',
    'can_commit',

    'showingEditor',
    'showingDetail',
  ].filter( x => member[x] );

  return (
    <tr css={{ border: '1px solid red' }}>
      <td colSpan={member.members.total_columns} css={{ padding : 0 }}>
        <div css={section_style}>
          <List label='Member Info' items={member_info} />
          <List label='Has Values' items={has_values} />
        </div>
        <div css={section_style}>
          <List label='Flags' items={flags} />
          <List label='Needs' items={member.needs} />
        </div>
        <div css={section_style}>
          <ChangeInfo label='Old Pending Change' change={member.old_change} />
          <ChangeInfo label='New Pending Change' change={member.new_change} />
        </div>
      </td>
    </tr>
  );
}
MemberDebug.propTypes = {
  member  : PropTypes.instanceOf( Member ).isRequired,
};

function ChangeInfo( {
  change, label,
}: {
  label: string;
  change?: {
    change_type: 'add' | 'remove' | 'update';
    discovered?: boolean;
    error?: string | Error;
    requested_by?: string;
    requested_at?: Date;
    config?: Record<string, unknown>;
  };
} ) {
  if ( ! change ) return null;
  const data: {
    change_type: 'add' | 'remove' | 'update';
    discovered: 'true' | 'false';
    error?: string;
    requested_by?: string;
    requested_at?: string;
    [key: string]: any;
  } = {
    change_type : change.change_type,
    discovered  : change.discovered ? 'true' : 'false',
    ...( change.config || {} ),
  };
  if ( change.error ) {
    const msg = _.isError( change.error ) ? change.error.message : change.error;
    data.error = String( msg );
  }
  if ( change.requested_at ) data.requested_at = String( change.requested_at );
  if ( change.requested_by ) data.requested_by = change.requested_by;
  return <List label={label} items={data} />;
}

function List( { label, items } ) {
  if ( _.isEmpty( items ) ) return null;
  return (
    <>
      { label && ( <h4>{label}</h4> )}
      <ul>
        {_.isArray( items ) && items.map( x => <li key={x}>{x}</li> )}
        {_.isPlainObject( items ) && _.map( items, ( val, key ) => {
          if ( _.isNil( val ) ) return null;
          return <li key={key}><b>{key}:</b> {val}</li>;
        } )}
      </ul>
    </>
  );
}
List.propTypes = {
  label   : PropTypes.string,
  items   : PropTypes.oneOfType( [
    PropTypes.arrayOf( PropTypes.string ),
    PropTypes.shape( {} ),
  ] ).isRequired,
};
