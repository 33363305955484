import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useSetting } from '.';

/**
 * Given a `Date` object, computes the display value in various
 * formats, and arranges to recompute and trigger an update after an
 * appropriate amount of time.
 *
 * @param {Date} when - The Date object to compute times from.
 */
export function useRelativeTimes( when ) {
  const format = useSetting( 'datetime_format', 'FFF' );
  const [ data, setData ] = useState( computeRelativeTimes( when, format ) );

  useEffect( () => {
    const int = setInterval( () => {
      setData( computeRelativeTimes( when, format ) );
    }, computeUpdateIn( when ) );
    return () => clearInterval( int );
  }, [ when, format, setData ] );
  return data;
}

function computeRelativeTimes( when, format ) {
  const then = DateTime.fromJSDate( when );
  return {
    dateTime    : then.toISO(),
    relative    : then.toRelative(),
    formatted   : then.toFormat( format ),
  };
}

/**
 * Given a `Date` object, determine a suitable "next update at" time
 * for when this should be re-rendered if displaying relative times.
 * This is because you need to update the time in only a couple of
 * seconds if the relative time is going to be displayed as "just
 * now", but don't need to update for quite a while if the relative
 * time is "7 years ago").
 *
 * @param {Date} when - The `Date` that we're computing relative to.
 * @returns {number} How long to wait before updating (in milliseconds).
 */
function computeUpdateIn( when ) {
  // Try updating in 2 seconds if we weren't given a time.
  if ( ! ( when instanceof DateTime ) ) return 2 * 1000;
  const duration = DateTime.now().diff( when );
  const hours = duration.as( 'hours' );
  // If the duration is more than an hour ago then we really don't
  // need to update very often (every 5 minutes should be plenty)
  if ( hours > 1 ) return 5 * 60 * 1000;
  const minutes = duration.as( 'minutes' );
  if ( minutes > 10 ) return 15 * 1000;
  // This is at the end, so the update interval is a minimum of 1 second
  return 1 * 1000;
}
